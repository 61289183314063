export const COACH_LIST = 1;
export const COACH_SWING_MOVIE = 2;
export const CURRICULUM = 3;
export const QUESTIONNAIRE = 4;
export const COACH_INFO_DETAIL = 5;

export type MerchantPropertyType =
    | typeof COACH_LIST
    | typeof COACH_SWING_MOVIE
    | typeof CURRICULUM
    | typeof QUESTIONNAIRE
    | typeof COACH_INFO_DETAIL;
