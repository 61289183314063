import React, { useContext, useMemo } from 'react';

import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import registernotselected from '../../../assets/register_not_selected.png';
import { CustomerRegisterPropertyTabContext } from '../../../contexts/CustomerRegisterPropertyTabContext';
import { ErrorMessages } from '../../../utils/Api';
import ErrorMessage from '../../base/feedback/ErrorMessage';
import {
    CUSTOMER_REGIST_BASIC_PROFILE,
    CUSTOMER_REGIST_DETAIL_PROFILE,
    CUSTOMER_REGIST_QUESTIONNAIRE
} from '../../const/RegisterProperties';
import { colors } from '../../const/Styles';
import CustomerRegisterImageForm from '../../register/CustomerRegisterImageForm';

const useStyles = makeStyles(() =>
    createStyles({
        registerButtonBox: {
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
        },
        registerButton: {
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
            width: 445,
            backgroundColor: `${colors.footerIconSelected}`,
            color: `${colors.black}`,
            fontWeight: 'bold',
            fontSize: 17,
        },
        title: {
            display: 'block',
            color: `${colors.footerIconSelected}`,
            fontSize: 37,
            fontWeight: 'bold',
            position: 'absolute',
            left: 0,
            right: 0,
            margin: 'auto',
            textAlign: 'center',
        },
        titleBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 153,
            position: 'relative',
        },
        titleWrap: {
            width: '75%',
        },
        closeBtnBox: {
            position: 'absolute',
            top: 20,
            right: 40,
        },
        main: {
            position: 'relative',
            width: '100%',
            minWidth: 650,
            border: `1px solid ${colors.black}`,
            margin: 'auto',
        },
        profileImgBox: {
            width: '25%',
            position: 'absolute',
            left: 53,
        },
        lowerFixedBox: {
            width: '100%',
            textAlign: 'center',
            position: 'fixed',
            bottom: 56,
            backgroundColor: 'white',
            minHeight: 48,
            padding: '5px 0px',
            borderTop: '1px solid grey',
            borderBottom: '1px solid grey',
        },
        gridItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        ImgBox: {
            marginRight: 'auto',
        },
    }),
);

type Props = {
    image: string | undefined;
    setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
    setImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
    errorProps: ErrorMessages;
};

const CustomerRegisterFormHeader: React.FC<Props> = ({
    image,
    setImage,
    setImageFile,
    errorProps,
}) => {
    const classes = useStyles();
    const { selected } = useContext(CustomerRegisterPropertyTabContext);
    const title = useMemo(() => {
        if (CUSTOMER_REGIST_BASIC_PROFILE === selected) {
            return '基本情報';
        } else if (CUSTOMER_REGIST_DETAIL_PROFILE === selected) {
            return '詳細情報';
        } else if (CUSTOMER_REGIST_QUESTIONNAIRE === selected) {
            return 'アンケート';
        }
        return;
    }, [selected]);

    return (
        <>
            <Grid container className={classes.titleBox}>
                <Grid className={classes.profileImgBox}>
                    <div className={classes.ImgBox}>
                        <CustomerRegisterImageForm
                            image={image ?? `${registernotselected}`}
                            setImage={setImage}
                            setImageFile={setImageFile}
                        />
                        <ErrorMessage field='profile_picture_filename' errors={errorProps} />
                    </div>
                </Grid>
                <p className={classes.title}>{title}</p>
            </Grid>
            <ErrorMessage field='profile_picture_filename' errors={errorProps} />
        </>
    );
};

export default CustomerRegisterFormHeader;
