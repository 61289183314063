import {
    Box, Grid
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MerchantTabContext } from '../../contexts/MerchantTabContext';
import AuthStore from '../../store/AuthStore';
import TempoStore from '../../store/TempoStore';
import { LessonMasterEntity } from '../../utils/Api';
import { CURRICULUM } from '../const/MerchantProperties';
import { colors, MerChantBackGroundColor } from '../const/Styles';
import CurriculumSettingsForm from './CurriculumSettingsForm';
import CurriculumNameSelector from './input/CurriculumNameSelector';
import LessonMasterNameInputCard from './input/LessonMasterNameInputCard';

type ContainerProps = {
    visible: boolean;
};
const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    flexgrow: 1px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 30,
        },
        TitlePaper: {
            height: 58,
            width: 664,
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `${colors.footerIconUnSelected}`,
            margin: 'auto',
            color: theme.palette.text.secondary,
            position: 'relative',
        },
        select: {
            position: 'relative',
            borderRadius: 0,
            display: 'flex',
            width: 340,
            height: 30,
            backgroundColor: `${colors.white}`,
        },
        courseName: {
            color: `${colors.white}`,
            marginRight: 17,
        },
        lessonBox: {
            width: 664,
            height: 626,
            margin: 'auto',
            border: `1px solid ${colors.lighterGray}`,
            backgroundColor: `${colors.white}`,
        },
        wrapper: {
            backgroundColor: `${MerChantBackGroundColor}`,
            paddingTop: 30,
        },
        icon: {
            color: `${colors.footerIconSelected}`,
            top: -20,
            right: -10,
            fontSize: '4.2rem',
            position: 'absolute',
        },
    }),
);

export default observer(function CurriculumSettings() {
    const classes = useStyles();
    const [selectedLesson, setSelectedLesson] = useState<LessonMasterEntity | null>(null);
    const [isNew, setIsNew] = useState(true);
    const handleChange = useCallback((id: string, isNew: boolean) => {
        const lesson = TempoStore.getLesson(Number(id));
        setSelectedLesson(lesson);
        setIsNew(isNew);
    }, []);

    const handleCreateLessonMaster = (savedLessonMaster: LessonMasterEntity) => {
        setSelectedLesson({ ...savedLessonMaster });
        setIsNew(false);
    }

    const { selected } = useContext(MerchantTabContext);

    const visible = useMemo(() => {
        return CURRICULUM === selected;
    }, [selected]);

    return (
        <Container visible={visible}>
            <div className={classes.wrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <CurriculumNameSelector onChange={handleChange} lessonId={selectedLesson?.id} />
                    </Grid>
                </Grid>
                <Box className={classes.lessonBox}>
                    <LessonMasterNameInputCard visible={isNew} tempoId={AuthStore.tempoId} onSaved={handleCreateLessonMaster} />
                    <CurriculumSettingsForm
                        visible={!isNew}
                        lessonMasterId={selectedLesson?.id}
                    />
                </Box>
            </div>
        </Container>
    );
});
