import React, { useState, useCallback, useRef } from 'react';
import { useMount } from 'react-use';

import { VideoMovie, VideoBlob } from '../components/video/PlayingVideoCard';
import RecordingStore from '../store/RecordingStore';

export enum ToolType {
    straightLine,
    angle,
    rectangle,
    circle,
    freeHand,
    swingPlane,
    frontGuid,
    forwardTiltGuid,
}
export enum LineColor {
    red,
    blue,
    green,
    yellow,
}
export const lineColorToRGB: { [K in LineColor]: string } = {
    [LineColor.red]: 'red',
    [LineColor.blue]: 'blue',
    [LineColor.green]: 'lime',
    [LineColor.yellow]: 'yellow',
};

export interface Values {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    toolType: ToolType;
    lineColor: LineColor;
}
export interface Actions {
    setToolType: React.Dispatch<React.SetStateAction<ToolType>>;
    setLineColor: React.Dispatch<React.SetStateAction<LineColor>>;
    startDrawing: (x: number, y: number) => void;
    draw: (x: number, y: number) => void;
    endDrawing: (x?: number, y?: number) => void;
    drawDefaultImage: (
        video: VideoMovie | VideoBlob,
        image?: File | undefined,
    ) => void;
}

const useEditorCanvas = (
    video: VideoMovie | VideoBlob,
    height: number,
    width: number,
    scaleRatio?: number,
): [Values, Actions] => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [toolType, setToolType] = useState<ToolType>(ToolType.straightLine);
    const [lineColor, setLineColor] = useState<LineColor>(LineColor.red);
    const [drawing, setDrawing] = useState<boolean>(false);

    const drawDefaultImage = useCallback(
        (video: VideoMovie | VideoBlob, image?: File) => {
            const ctx = canvasRef.current?.getContext('2d');
            if (ctx) {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                const src = image
                    ? URL.createObjectURL(image)
                    : RecordingStore.getToolImage(video);
                if (src) {
                    img.src = src;
                }
                img.onload = () => {
                    ctx.drawImage(
                        img,
                        0,
                        0,
                        img.width,
                        img.height,
                        0,
                        scaleRatio ? (height - height * scaleRatio) / 2 : 0,
                        width,
                        scaleRatio ? height * scaleRatio : height,
                    );
                };
            }
        },
        [height, scaleRatio, width],
    );

    useMount(() => {
        drawDefaultImage(video);
    });

    const startDrawing = useCallback(
        (x, y) => {
            const ctx = canvasRef.current?.getContext('2d');
            if (ctx) {
                ctx.strokeStyle = lineColorToRGB[lineColor];
                ctx.lineWidth = 3;
                const img = new Image();
                switch (toolType) {
                    case ToolType.freeHand:
                        setDrawing(true);
                        ctx.beginPath();
                        ctx.moveTo(x, y);
                        break;
                }
                img.onload = function () {
                    ctx.drawImage(img, x - 400, y - 400, 800, 800);
                };
            }
        },
        [lineColor, toolType],
    );

    const draw = useCallback(
        (x, y) => {
            if (!drawing) {
                return;
            }
            const ctx = canvasRef.current?.getContext('2d');
            if (ctx) {
                ctx.lineTo(x, y);
                ctx.stroke();
            }
        },
        [drawing],
    );

    const endDrawing = useCallback(() => {
        const ctx = canvasRef.current?.getContext('2d');
        if (ctx) {
            switch (toolType) {
                case ToolType.freeHand:
                    setDrawing(false);
                    break;
            }
        }
    }, [toolType]);

    const values = { canvasRef, toolType, lineColor };
    const actions = {
        setToolType,
        setLineColor,
        startDrawing,
        draw,
        endDrawing,
        drawDefaultImage,
    };
    return [values, actions];
};

export default useEditorCanvas;
