import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { colors } from '../../const/Styles';
import CustomerNameFilterCell from './CustomerNameFilterCell';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
    border: solid 2px ${colors.gray};
    border-top-style: none;
    border-bottom-style: none;
    box-sizing: border-box;
    overflow: auto;
`;

type Props = {
    onClick: (filterName: string) => void;
};
const CustomerNameFilter: React.FC<Props> = ({ onClick }) => {
    const [selected, setSelected] = useState('ALL');
    const handleClick = useCallback(
        (filterName: string) => {
            setSelected(filterName);
            onClick(filterName);
        },
        [onClick],
    );

    const { lessonStore } = useStore();
    const ALL = useMemo(() => {
        return function ALL() {
            return lessonStore.allCustomers.sort((a, b) =>
                a.name_sei_kana[0].localeCompare(b.name_sei_kana[0], 'ja'),
            );
        };
    }, [lessonStore.allCustomers]);

    return (
        <Wrapper>
            <CustomerNameFilterCell
                filterName='ALL'
                customers={lessonStore.allCustomers}
                onClick={handleClick}
                isActive={ALL.name === selected}
                padding='30px'
            >
                ALL
            </CustomerNameFilterCell>
            {lessonStore.rows.map((row) => (
                <CustomerNameFilterCell
                    key={row}
                    filterName={row}
                    customers={lessonStore.customersPerRow(row)}
                    onClick={handleClick}
                    isActive={row === selected}
                >
                    {row}
                </CustomerNameFilterCell>
            ))}
        </Wrapper>
    );
};

export default observer(CustomerNameFilter);
