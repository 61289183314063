import React, { createContext, useCallback, useMemo, useState } from 'react';

import {
    MerchantPropertyType,
    COACH_LIST,
} from '../components/const/MerchantProperties';

type State = {
    selected: MerchantPropertyType;
    onChange: (value: MerchantPropertyType) => void;
};

export const MerchantTabContext = createContext<State>({
    selected: COACH_LIST,
    onChange: () => undefined,
});

export const MerchantTabProvider: React.FC = ({ children }) => {
    const [selected, setSelected] = useState<MerchantPropertyType>(COACH_LIST);

    const handleChange = useCallback((value: MerchantPropertyType) => {
        setSelected(value);
    }, []);

    const context = useMemo<State>(() => {
        return {
            selected,
            onChange: handleChange,
        };
    }, [selected, handleChange]);

    return (
        <MerchantTabContext.Provider value={context}>
            {children}
        </MerchantTabContext.Provider>
    );
};
