import React from 'react';
import styled from 'styled-components';
import { LineColor } from '../../../hooks/useEditorCanvas';
import { colors } from '../../const/Styles';

type PalletProps = {
    selected: boolean;
    bgColor: string;
};

const ColorPallet = styled.label<PalletProps>`
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 9999999px;
    background-color: ${({ bgColor }) => bgColor};
    border-style: solid;
    border-width: 2px;
    border-color: ${({ selected }) =>
        selected ? colors.white : 'transparent'};
    box-sizing: border-box;
    margin: 5px;
`;

type ColorPalletButtonProps = {
    value: LineColor;
    onClick?: (value: LineColor) => void;
} & PalletProps;

const ColorPalletButton: React.FC<ColorPalletButtonProps> = ({
    bgColor,
    selected,
    value,
    onClick,
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick(value);
        }
    };
    return (
        <ColorPallet
            selected={selected}
            bgColor={bgColor}
            onClick={handleClick}
        />
    );
};

export default ColorPalletButton;
