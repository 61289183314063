import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.figure`
    display: flex;
    flex-grow: 2;
    margin: 0;
    height: 150px;
    width: 150px;
    box-sizing: border-box;
    color: ${colors.white};
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

type Props = {
    src?: string;
    color: string;
};

const CustomerImage: React.FC<Props> = ({ src, color }) => {
    return (
        <Container color={color}>
            <Img src={src}></Img>
        </Container>
    );
};

export default CustomerImage;
