import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
    CustomerTabPropertyType,
    CUSTOMER_TAB_DETAIL_PROFILE,
} from '../components/const/CustomerTabProperties';

type State = {
    selected: CustomerTabPropertyType;
    onChange: (value: CustomerTabPropertyType) => void;
};

export const CustomerTabPropertyTabContext = createContext<State>({
    selected: CUSTOMER_TAB_DETAIL_PROFILE,
    onChange: () => undefined,
});

export const CustomerTabPropertyProvider: React.FC = ({ children }) => {
    const [selected, setSelected] = useState<CustomerTabPropertyType>(
        CUSTOMER_TAB_DETAIL_PROFILE,
    );

    const handleChange = useCallback((value: CustomerTabPropertyType) => {
        setSelected(value);
    }, []);

    const context = useMemo<State>(() => {
        return {
            selected,
            onChange: handleChange,
        };
    }, [selected, handleChange]);

    return (
        <CustomerTabPropertyTabContext.Provider value={context}>
            {children}
        </CustomerTabPropertyTabContext.Provider>
    );
};
