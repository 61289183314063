import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import DisplayDate from '../../base/surface/DisplayDate';
import { colors } from '../../const/Styles';

const AccountTable = styled.table`
    width: 100%;
    font-size: 0.7rem;
    tbody {
        tr {
            td:nth-child(1) {
                text-align: right;
                white-space: nowrap;
            }
            td:nth-child(2) {
                text-align: left;
                white-space: nowrap;
            }
        }
        tr:nth-child(4) {
            td {
                font-size: 1.1rem;
                font-weight: bold;
                color: ${colors.white};
                background-color: ${colors.blue};
                text-align: center;
            }
        }
    }
`;

const ImageWrapper = styled.figure`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 180px;
    justify-content: center;
    margin: 0;
`;

const Img = styled.img`
    object-fit: fill;
    width: 180px;
`;

type Props = {
    customer: CustomerDisplay;
};
const CustomerBasicProfileLeftPain: React.FC<Props> = ({ customer }) => {
    const { tempoStore } = useStore();
    const lessonName = useMemo(() => {
        return tempoStore.getLesson(customer.lesson_master_id)?.name;
    }, [customer.lesson_master_id, tempoStore]);

    return (
        <div>
            <ImageWrapper>
                <Img src={customer.s3Path} />
            </ImageWrapper>
            <AccountTable>
                <tbody>
                    <tr>
                        <td>会員ID</td>
                        <td>{customer.user_name}</td>
                    </tr>
                    <tr>
                        <td>ID</td>
                        <td>{customer.id}</td>
                    </tr>
                    <tr>
                        <td>登録日</td>
                        <td>
                            <DisplayDate source={customer.registered_at} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{lessonName}</td>
                    </tr>
                </tbody>
            </AccountTable>
        </div>
    );
};

export default CustomerBasicProfileLeftPain;
