import React, { useMemo } from 'react';
import { ErrorMessages } from '../../../utils/Api';

type Props = {
    fields: string[];
    errors?: ErrorMessages;
};

const MultiFieldErrorMessage: React.FC<Props> = ({ fields, errors }) => {
    const messages = useMemo(() => {
        const errorMap = new Map<string, string[]>(Object.entries(errors ?? {}));
        return fields.map(field => errorMap.get(field) ?? []).flat();
    }, [errors, fields]);
    if (messages.length === 0) {
        return null;
    }
    return (
        <p style={{ color: '#d50000' }}>
            {messages[0]}
        </p>
    );
}

export default MultiFieldErrorMessage;
