export const RegisterProperies = {
    1: '基本情報',
    2: '詳細情報',
    3: 'アンケート',
};

export const CUSTOMER_REGIST_BASIC_PROFILE = 1;
export const CUSTOMER_REGIST_DETAIL_PROFILE = 2;
export const CUSTOMER_REGIST_QUESTIONNAIRE = 3;

export type CustomerRegisterPropertyType =
    | typeof CUSTOMER_REGIST_BASIC_PROFILE
    | typeof CUSTOMER_REGIST_DETAIL_PROFILE
    | typeof CUSTOMER_REGIST_QUESTIONNAIRE;
