import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import HeaderIcon from '../../assets/swing.svg';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import TitleView from './display/TitleView';
import ReturnPerviousButton from './input/ReturnPerviousButton';
import SelectSortBox from './input/SelectSortBox';
import VideoType from './surface/VideoType';

import { MovieDisplay } from '../../utils/Video';

import { useHistory, useLocation } from 'react-router';

import { Routes } from '../const/Routes';
import CoachMovies from './surface/CoachMovies';
import CustomerMovies from './surface/CustomerMovies';
import FavoritedMovies from './surface/FavoritedMovies';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${colors.white};
`;

interface LocationState {
    from: string;
    customerId: string;
}

const SwingListPage: React.FC = () => {
    const location = useLocation<LocationState>();
    const [selectedVideos, setSelectedVideos] = useState<MovieDisplay[]>([]);
    const selectedVideosRef = useRef<MovieDisplay[]>([]);

    const handleUnSelect = (video: MovieDisplay) => {
        const index = selectedVideosRef.current.findIndex(
            (p) => p.id === video.id,
        );
        selectedVideosRef.current.splice(index, 1);
        setSelectedVideos(selectedVideosRef.current.concat());
    };

    const handleUnSelectAll = () => {
        selectedVideosRef.current = [];
        setSelectedVideos(selectedVideosRef.current.concat());
    };

    const [multiplePlayMode, setMultiplePlayMode] = useState(false);
    const handleChangeMode = (isMultiplePlay: boolean) => {
        setMultiplePlayMode(isMultiplePlay);
    };

    const history = useHistory();
    const handleClickVideo = useCallback(
        (video: MovieDisplay) => {
            // 同時再生モードの場合は再生対象に追加
            if (
                selectedVideosRef.current.some((p) => p.id === video.id) ===
                false &&
                selectedVideosRef.current.length < 2 &&
                multiplePlayMode === true
            ) {
                selectedVideosRef.current.push(video);
                setSelectedVideos(selectedVideosRef.current.concat());
            }
            if (multiplePlayMode === false) {
                // そうでない場合は単純に再生する
                history.push(Routes.SWING_PLAY_SINGLE, video);
            }
        },
        [multiplePlayMode, history],
    );

    const { customerId } = location.state;
    return (
        <Page headerIcon={HeaderIcon} title='スイング一覧'>
            <Container>
                <TitleView
                    onChange={handleChangeMode}
                    selectedVideos={selectedVideos.concat()}
                    onUnSelect={handleUnSelect}
                    onUnSelectAll={handleUnSelectAll}
                />
                <VideoType />
                <SelectSortBox />
                <CustomerMovies
                    customerId={Number(customerId)}
                    onClick={handleClickVideo}
                />
                <CoachMovies onClick={handleClickVideo} />
                <FavoritedMovies
                    customerId={`${customerId}`}
                    onClick={handleClickVideo}>
                </FavoritedMovies>
                <ReturnPerviousButton />
            </Container>
        </Page>
    );
};

export default observer(SwingListPage);
