import { IconButton } from '@material-ui/core';
import PlayArrowSharp from '@material-ui/icons/PlayArrowSharp';
import React, { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const PlaybackRateValues = [1.5, 1, 0.5, 0.1, 0.0625];
const DefaultPlaybackRate = 1;
type Props = {
    onChange?: (value: number) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
`;

const Select = styled.select`
    outline: none;
    border: solid 2px ${colors.white};
    background-color: ${colors.gray};
    color: ${colors.white};
    box-sizing: border-box;
    padding: 5px;
    text-align: center;
`;

type IconProps = {
    rotate: number;
};

const StyledIcon = styled(PlayArrowSharp)<IconProps>`
    transform: rotate(${({ rotate }) => rotate}deg);
    display: flex;
    width: 30px;
    height: 30px;
    color: ${colors.white};
`;

const PlaybackRateSelector: React.FC<Props> = ({ onChange }) => {
    const [playbackRate, setPlaybackRate] = useState(DefaultPlaybackRate);

    const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const val = Number(evt.currentTarget.value);
        setPlaybackRate(val);
        if (onChange) {
            onChange(val);
        }
    };

    const handleIncrease = useCallback(() => {
        const index = PlaybackRateValues.findIndex((p) => p === playbackRate);
        if (typeof index === 'undefined' || !onChange) {
            return;
        }
        const next = PlaybackRateValues[index - 1];
        if (next) {
            setPlaybackRate(next);
            onChange(next);
        }
    }, [onChange, playbackRate]);

    const handleDecrease = useCallback(() => {
        const index = PlaybackRateValues.findIndex((p) => p === playbackRate);
        if (typeof index === 'undefined' || !onChange) {
            return;
        }
        const next = PlaybackRateValues[index + 1];
        if (typeof next === 'number') {
            setPlaybackRate(next);
            onChange(next);
        }
    }, [onChange, playbackRate]);

    return (
        <Container>
            <IconButton onClick={handleIncrease}>
                <StyledIcon rotate={-90} />
            </IconButton>
            <Select value={playbackRate} onChange={handleChange}>
                {PlaybackRateValues.map((value, index) => (
                    <option key={index} value={value}>
                        x{value}
                    </option>
                ))}
            </Select>
            <IconButton onClick={handleDecrease}>
                <StyledIcon rotate={90} />
            </IconButton>
        </Container>
    );
};

export default PlaybackRateSelector;
