import {
    CurriculumAchievementEntity,
    LessonAchievementEntity,
} from '../utils/Api';

/**
 * レッスン実績レスポンス
 */
type LessonAchievementResponse = LessonAchievementEntity;

/**
 * カリキュラム達成状況レスポンス
 */
type CurriculumAchievementResponse = CurriculumAchievementEntity;

export function createCurriculumAchievementItemResponse(
    curriculumAchievementEntities: CurriculumAchievementEntity[],
): CurriculumAchievementResponse[] {
    return curriculumAchievementEntities.map(
        (curriculumAchievementEntity): CurriculumAchievementResponse => {
            return {
                id: curriculumAchievementEntity.id,
                lesson_curriculum_id:
                    curriculumAchievementEntity.lesson_curriculum_id,
                curriculum_name_at_registered:
                    curriculumAchievementEntity.curriculum_name_at_registered,
                curriculum_name_current:
                    curriculumAchievementEntity.curriculum_name_current,
                display_order: curriculumAchievementEntity.display_order,
                rate: curriculumAchievementEntity.rate,
                is_deleted: curriculumAchievementEntity.is_deleted,
                created_at: curriculumAchievementEntity.created_at,
            };
        },
    );
}

export function createLessonAchievementItemResponse(
    lessonAchievementEntity: LessonAchievementEntity,
): LessonAchievementResponse {
    return {
        id: lessonAchievementEntity.id,
        tempo_id: lessonAchievementEntity.tempo_id,
        customer_id: lessonAchievementEntity.customer_id,
        staff_id: lessonAchievementEntity.staff_id,
        lesson_master_id: lessonAchievementEntity.lesson_master_id,
        name: lessonAchievementEntity.name,
        comment: lessonAchievementEntity.comment,
        curriculum_achievements: createCurriculumAchievementItemResponse(
            lessonAchievementEntity.curriculum_achievements,
        ),
        created_at: lessonAchievementEntity.created_at,
    };
}
