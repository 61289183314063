import { GridListTile } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';

import {
    MOVIE_TYPE_FAVORITED,
    VideoTypeTabContext,
} from '../../../contexts/VideoTypeTabContext';
import {
    getMovieComparer,
    getMoviePredicate,
} from '../../../utils/MovieSorting';
import { MovieDisplay } from '../../../utils/Video';
import MovieThumbnail from '../display/MovieThumbnail';
import VideoGridLayout from '../layout/VideoGridLayout';
import { MOVIE_TYPE_LESSON } from '../../const/MovieTypeProperties';

const StyledGridListTile = styled(GridListTile)`
    height: 150px !important;
    width: 150px,
    margin-right: 5px,
`;

type Props = {
    customerId: string;
    onClick?: (video: MovieDisplay) => void;
};

const FavoritedMovies: React.FC<Props> = ({ customerId, onClick }) => {
    const { movieType, SortClub, SortOrder } = useContext(VideoTypeTabContext);

    const { tempoStore } = useStore();
    const { favoriteMovies } = tempoStore;
    const sortedMovies = useMemo(() => {
        if (movieType !== MOVIE_TYPE_FAVORITED) {
            return [];
        }
        return favoriteMovies
            .map((favorite) => favorite.movie)
            .filter((movie) => {
                return (
                    movie.movie_type === MOVIE_TYPE_LESSON &&
                    movie.customer_id_or_staff_id === customerId
                );
            })
            .filter(getMoviePredicate(SortClub))
            .sort(getMovieComparer(SortOrder));
    }, [customerId, SortClub, SortOrder, favoriteMovies, movieType]);

    return (
        <VideoGridLayout isVisible={movieType === MOVIE_TYPE_FAVORITED}>
            {sortedMovies.concat().map((video) => (
                <StyledGridListTile key={video.id}>
                    <MovieThumbnail video={video} onClick={onClick} />
                </StyledGridListTile>
            ))}
        </VideoGridLayout>
    );
};

export default FavoritedMovies;
