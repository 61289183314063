import { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

type SizeType = 'xs' | 'sm' | 'md' | 'lg';

export type InputProps = {
    className?: SizeType;
};

export type PropType = InputHTMLAttributes<HTMLInputElement> & InputProps;

const Input = styled.input<InputProps>`
    display: flex;
    flex-direction: row;
    outline: none;
    background-color: ${colors.formBg};
    border: solid 2px ${colors.lighterGray};
    padding: 5px;
    border-radius: 2px;
    box-sizing: border-box;
    &.xs {
        width: 4em;
    }
    &.sm {
        width: 7em;
    }
    &.md {
        width: 9em;
    }
    &.lg {
        width: 100%;
    }
`;

const BaseInput = forwardRef<HTMLInputElement, PropType>((props, ref) => {
    return <Input {...props} ref={ref}></Input>;
});

export default BaseInput;
