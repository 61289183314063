import React, { FormEvent, useContext } from 'react';
import styled from 'styled-components';
import { CustomerTabPropertyTabContext } from '../../../contexts/CustomerTabContext';
import {
    CustomerTabProperies,
    CustomerTabPropertyType,
} from '../../const/CustomerTabProperties';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.lightGray3};
    border-color: ${colors.lightGray};
    border-top-style: solid;
    border-top-width: 2px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
`;

const Label = styled.label`
    display: block;
    width: 187px;
    text-align: center;
    padding: 2px 0;
    color: ${colors.white};
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 2px ${colors.lightGray};
    background-color: ${colors.gray};
    font-size: 0.8rem;
    &.active {
        border: solid 2px ${colors.blue};
        background-color: ${colors.blue};
        font-weight: bold;
    }
`;

const Radio = styled.input`
    display: none;
`;

const DetailContentsNavigation: React.FC = () => {
    const { selected, onChange } = useContext(CustomerTabPropertyTabContext);

    const handleChange = (evt: FormEvent<HTMLInputElement>) => {
        onChange(Number(evt.currentTarget.value) as CustomerTabPropertyType);
    };
    return (
        <Container>
            {Object.entries(CustomerTabProperies).map(([key, value]) => (
                <Label
                    key={key}
                    className={selected === Number(key) ? 'active' : ''}
                >
                    <Radio
                        name='customerTabProperty'
                        value={key}
                        onInput={handleChange}
                        type='radio'
                    />
                    {value}
                </Label>
            ))}
        </Container>
    );
};

export default DetailContentsNavigation;
