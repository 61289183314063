import React, {
    useCallback,
    useState,
    useMemo,
    useRef,
    useEffect,
} from 'react';
import {
    Container,
    IconButton,
    Box,
    FormControl,
    Select,
    MenuItem,
    Button,
    Modal,
    Slider,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import StopIcon from '@material-ui/icons/Stop';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { observer } from 'mobx-react-lite';

import useVideoJS from '../../hooks/useVideoJs';
import { VideoMovie, VideoBlob } from './PlayingVideoCard';
import RecordingStore from '../../store/RecordingStore';
import DominantButton from '../form/DominantButton';
import VideoEditor from './VideoEditor';
import { getVideoFormat } from '../../utils/utilityFuncs';

const useStyles = makeStyles(() =>
    createStyles({
        singleVideoContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 10,
        },
        videoBox: {
            position: 'relative',
        },
        canvas: {
            position: 'absolute',
        },
        playBackRateForm: {
            margin: 'auto 0',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0',
        },
        videoButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 275,
            marginRight: 3,
        },
        videoButton: {
            padding: 6,
        },
        thumbnailBox: {
            display: 'flex',
            justifyContent: 'center',
            padding: '5px 0',
        },
        thumbnail: {
            width: 55,
            height: 55,
        },
        imgButton: {
            padding: 0,
            minWidth: 0,
        },
        sideButton: {
            width: 105,
            height: 40,
            margin: 'auto 0 auto 3px',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

interface Props {
    videoSrc: VideoMovie | VideoBlob;
    height: number;
    width: number;
    customerId: string | undefined;
    onClickToVideoList: () => void;
}

const PlayingVideoSingle = observer((props: Props): React.ReactElement => {
    const { videoSrc, height, width, customerId, onClickToVideoList } = props;
    const classes = useStyles();

    const src = useMemo(
        () =>
            videoSrc.type === 'movie' ? videoSrc.filepath : videoSrc.videoSrc,
        [videoSrc],
    );
    const { Video, player, thumbnails, actions } = useVideoJS({
        sources: [{ src: src, type: getVideoFormat() }],
        // poster: `${process.env.PUBLIC_URL}/sample_lesson.jpeg`,
        // ...baseOptions,
        height: height,
        width: width,
    });

    const [saveCounter, setSaveCounter] = useState<number>(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const toolImage = RecordingStore.getToolImage(videoSrc);
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const img = new Image();
                img.src = toolImage;
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                };
            }
        }
    }, [canvasRef, saveCounter, videoSrc]);

    const [playing, setPlaying] = useState(false);
    const startBoth = useCallback(() => {
        if (player?.paused()) {
            player?.play();
            setPlaying(true);
        } else {
            player?.pause();
            setPlaying(false);
        }
    }, [player]);
    const onClickScreen = useCallback(() => {
        setPlaying(!playing);
    }, [playing]);

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const [currentPlayingTime, setCurrentPlayingTime] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPlayingTime(player?.currentTime() ?? 0);
        }, 10);
        return () => {
            clearInterval(intervalId);
        };
    }, [player, setCurrentPlayingTime]);
    const onSeekbarChange = useCallback(
        (event, second) => {
            actions.skip(second);
        },
        [actions],
    );

    return (
        <React.Fragment>
            <Container className={classes.singleVideoContainer}>
                <Box className={classes.videoBox}>
                    <canvas
                        style={{ zIndex: 1 }}
                        ref={canvasRef}
                        className={classes.canvas}
                        id='canvas'
                        height={`${height}px`}
                        width={`${width}px`}
                    />
                    <Video playsInline onClick={onClickScreen} />
                </Box>
            </Container>
            <Box className={classes.thumbnailBox}>
                {thumbnails?.map(([thumbnail, currentTime]) => {
                    return (
                        <Button
                            key={currentTime}
                            onClick={() => {
                                actions.skip(currentTime);
                                setCurrentPlayingTime(currentTime);
                            }}
                            className={classes.imgButton}
                        >
                            <img
                                src={thumbnail}
                                className={classes.thumbnail}
                            />
                        </Button>
                    );
                })}
            </Box>
            <Box style={{ width: '80%', margin: '0 auto' }}>
                <Slider
                    defaultValue={0}
                    min={0}
                    max={player?.duration()}
                    value={currentPlayingTime}
                    onChange={onSeekbarChange}
                />
            </Box>
            <Box className={classes.buttonContainer}>
                <Box className={classes.videoButtonContainer}>
                    <IconButton
                        onClick={actions.skipMinus}
                        className={classes.videoButton}
                    >
                        <ChevronLeftIcon color='action' fontSize='large' />
                    </IconButton>
                    <IconButton
                        onClick={startBoth}
                        className={classes.videoButton}
                    >
                        {playing ? (
                            <StopIcon color='action' fontSize='large' />
                        ) : (
                            <PlayArrowRoundedIcon
                                color='action'
                                fontSize='large'
                            />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={actions.skipPlus}
                        className={classes.videoButton}
                    >
                        <ChevronRightIcon color='action' fontSize='large' />
                    </IconButton>
                    <FormControl className={classes.playBackRateForm}>
                        <Select
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            defaultValue={1}
                            onChange={(e) =>
                                actions.onChangePlayBackRate(
                                    e.target.value as number,
                                )
                            }
                        >
                            <MenuItem value={1.5}>x1.5</MenuItem>
                            <MenuItem value={1}>x1</MenuItem>
                            <MenuItem value={0.5}>x0.5</MenuItem>
                            <MenuItem value={0.1}>x0.1</MenuItem>
                            <MenuItem value={0.0625}>x0.0625</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <DominantButton
                    className={classes.sideButton}
                    onClick={handleOpen}
                >
                    各種ツール
                </DominantButton>
                {customerId !== null && (
                    <DominantButton
                        onClick={onClickToVideoList}
                        className={classes.sideButton}
                    >
                        動画一覧へ
                    </DominantButton>
                )}
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                >
                    <VideoEditor
                        videoSrc={videoSrc}
                        height={height}
                        width={width}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </Modal>
            </Box>
        </React.Fragment>
    );
});

export default PlayingVideoSingle;
