import React, { createContext, useContext } from 'react';
import CoachStore from '../store/CoachStore';
import LessonStore from '../store/LessonStore';
import RecordingStore from '../store/RecordingStore';
import TempoStore from '../store/TempoStore';

/** TODO: 実装のタイミングで遂次増やしてく */
type StoreContextState = {
    lessonStore: typeof LessonStore;
    tempoStore: typeof TempoStore;
    recordingStore: typeof RecordingStore;
    coachStore: typeof CoachStore;
};

const rootStore: StoreContextState = {
    lessonStore: LessonStore,
    tempoStore: TempoStore,
    recordingStore: RecordingStore,
    coachStore: CoachStore,
};

export const StoreContext = createContext<StoreContextState>(rootStore);

export const useStore = (): StoreContextState => {
    return useContext(StoreContext);
};

export const StoreProvider: React.FC = ({ children }) => {
    const rootStore = useStore();
    return (
        <StoreContext.Provider value={rootStore}>
            {children}
        </StoreContext.Provider>
    );
};
