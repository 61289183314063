import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { LessonPropertyTabContext } from '../../../contexts/LessonPropertyTabContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import { CUSTOMER_BASIC_PROFILE } from '../../const/LessonProperties';
import { colors } from '../../const/Styles';
import LeftPain from '../surface/CustomerBasicProfileLeftPain';
import RightPain from '../surface/CustomerBasicProfileRightPain';

type ContainerProps = {
    visible: boolean;
};

const GridContainer = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'grid' : 'none')};
    grid-template-columns: 220px 1fr;
    gap: 20px;
    height: 100%;
    margin: 0 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: ${colors.white};
`;

type Props = {
    customer: CustomerDisplay;
};

const CustomerBasicProfile: React.FC<Props> = ({ customer }) => {
    const { selected } = useContext(LessonPropertyTabContext);
    const visible = useMemo(() => {
        return CUSTOMER_BASIC_PROFILE === selected;
    }, [selected]);
    return (
        <GridContainer visible={visible}>
            <LeftPain customer={customer} />
            <RightPain customer={customer} />
        </GridContainer>
    );
};

export default CustomerBasicProfile;
