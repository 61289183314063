import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
    CustomerRegisterPropertyType,
    CUSTOMER_REGIST_BASIC_PROFILE,
} from '../components/const/RegisterProperties';

type State = {
    selected: CustomerRegisterPropertyType;
    onChange: (value: CustomerRegisterPropertyType) => void;
};

export const CustomerRegisterPropertyTabContext = createContext<State>({
    selected: CUSTOMER_REGIST_BASIC_PROFILE,
    onChange: () => undefined,
});

export const CustomerRegisterPropertyTabProvider: React.FC = ({ children }) => {
    const [selected, setSelected] = useState<CustomerRegisterPropertyType>(
        CUSTOMER_REGIST_BASIC_PROFILE,
    );

    const handleChange = useCallback((value: CustomerRegisterPropertyType) => {
        setSelected(value);
    }, []);

    const context = useMemo<State>(() => {
        return {
            selected,
            onChange: handleChange,
        };
    }, [selected, handleChange]);

    return (
        <CustomerRegisterPropertyTabContext.Provider value={context}>
            {children}
        </CustomerRegisterPropertyTabContext.Provider>
    );
};
