import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
);

function App(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant='h4'>WING NOTE</Typography>
            <Typography variant='h4'>For GOLF</Typography>
        </div>
    );
}

export default App;
