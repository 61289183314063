import Slider from '@material-ui/core/Slider';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import MinusIcon from '../../../assets/minus.svg';
import PlusIcon from '../../../assets/plus.svg';
import { colors } from '../../const/Styles';

type ContainerType = {
    progress: string;
};
const Container = styled.div<ContainerType>`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: flex-end;
    color: ${colors.white};
`;

const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-right: 20px;
    box-sizing: border-box;
`;

const Title = styled.label`
    display: block;
    width: 100%;
`;

type SliderProps = {
    slidercolor: string;
};

const StyledSlider = styled(Slider)<SliderProps>`
    &.MuiSlider-root {
        color: ${({ slidercolor }) => slidercolor};
    }
`;

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10px;
    width: 120px;
`;

const ProgressText = styled.span`
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 0.7;
`;

const ProgressUnitText = styled(ProgressText)`
    font-size: 1.5rem;
`;

const Icon = styled(ReactSVG)`
    display: block;
    height: 2.5rem;
    width: auto;
    padding-right: 10px;
    color: ${colors.white};
    > span {
        > svg {
            height: 100%;
            width: auto;
        }
    }
`;

type Props = {
    title: string;
    value: string;
    color: string;
    index: number;
    onChange?: (index: number, value: string) => void;
};

const LessonTargetProgressSlider: React.FC<Props> = ({
    title,
    value,
    color,
    index,
    onChange = () => undefined,
}) => {
    const handleChange = (
        _: React.ChangeEvent<unknown>,
        value: number | number[],
    ) => {
        if (onChange) {
            onChange(index, value.toString());
        }
    };

    const handleClickPlus = () => {
        const sumVal = Math.min(Number(value) + 10, 100);
        onChange(index, sumVal.toString());
    };

    const handleClickMinus = () => {
        const sumVal = Math.max(Number(value) - 10, 0);
        onChange(index, sumVal.toString());
    };

    return (
        <Container progress={value}>
            <SliderContainer>
                <Title>{title}</Title>
                <StyledSlider
                    slidercolor={color}
                    value={Number(value)}
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={10}
                    onChange={handleChange}
                />
            </SliderContainer>
            <ProgressContainer>
                <ProgressText>{value}</ProgressText>
                <ProgressUnitText>%</ProgressUnitText>
            </ProgressContainer>
            <Icon
                src={MinusIcon ?? ''}
                wrapper='span'
                onClick={handleClickMinus}
            />
            <Icon
                src={PlusIcon ?? ''}
                wrapper='span'
                onClick={handleClickPlus}
            />
        </Container>
    );
};

export default LessonTargetProgressSlider;
