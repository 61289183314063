import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from '../const/Routes';
import LessonListPage from './list';
import LessonPastSwingPage from './pastswing';
import LessonStartPage from './start';
import LessonRecordSwingPage from './recordswing';
import LessonPlaySwingPage from './playswing';

const LessonPages: React.FC = () => {
    return (
        <React.Fragment>
            <Route path={Routes.LESSON_LIST} exact>
                <LessonListPage />
            </Route>
            <Route path={Routes.LESSON_START} exact>
                <LessonStartPage />
            </Route>
            <Route path={Routes.LESSON_PAST_SWING} exact>
                <LessonPastSwingPage />
            </Route>
            <Route path={Routes.LESSON_RECORD_SWING} exact>
                <LessonRecordSwingPage />
            </Route>
            <Route path={Routes.LESSON_PLAY_SWING} exact>
                <LessonPlaySwingPage />
            </Route>
        </React.Fragment>
    );
};

export default LessonPages;
