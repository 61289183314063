import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import dateFormat from 'date-fns/format';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';

import { useCallback, useEffect, useMemo } from 'react';
import { FavoriteIcon, useFavorite } from '../../../hooks/useFavorite';
import moviesamplebtn from '../../../assets/swingicon.png';

const MovieInfo = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 3;
    background-color: ${colors.black}B3;
    color: ${colors.white};
    position: absolute;;
    width: 100%;
    top: 0;
    left: 0;
    padding: 4px;
    box-sizing: border-box;
    font-size: 0.7rem;
`;

const FavBtn = styled(FavoriteIcon)`
    position: absolute;
    bottom: 30px;
    right: 20px;
    width: 20px;
    height: 20px;
`;

const ThumbnailBox = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    margin-right: 3px;
`;

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            width: 40,
            height: 40,
            color: 'white',
        },
        selectedVideo: {
            outline: '5px solid blue',
            outlineOffset: '-5px',
        },
        thumbnail: {
            posistion: 'relative',
            height: '100%',
            width: 100,
        },
    }),
);

interface Props {
    video: MovieDisplay;
    onClick?: (video: MovieDisplay) => void;
    isVisibleFavoriteButton?: boolean;
}

const MovieThumbnail: React.FC<Props> = ({
    video,
    onClick,
    isVisibleFavoriteButton = true,
}) => {
    const [faved, toggleFav] = useFavorite(video);
    useEffect(() => {
        video.fetchSrc();
    }, [video]);

    const classes = useStyles();

    const createdAt = useMemo(() => {
        return video.created_at
            ? dateFormat(new Date(video.created_at), 'yyyy/MM/dd')
            : '----';
    }, [video.created_at]);

    const handleClickImage = useCallback(() => {
        if (onClick) {
            onClick(video);
        }
    }, [video, onClick]);

    return video.thumbnail_signed_url ? (
        <ThumbnailBox>
            <img
                className={clsx(classes.thumbnail)}
                src={video.thumbnail_signed_url}
                onClick={handleClickImage}
            />
            <MovieInfo>
                <div>{video.club ? video.club : '---'}</div>
                <div>{video.yards ? video.yards : '---'} y</div>
                <div>{createdAt}</div>
                {isVisibleFavoriteButton && (
                    <FavBtn faved={faved} onClick={toggleFav} />
                )}
            </MovieInfo>
        </ThumbnailBox>
    ) : (
        <ThumbnailBox>
            <img
                className={clsx(classes.thumbnail)}
                src={moviesamplebtn}
                onClick={handleClickImage}
            />
            <MovieInfo>
                <div>{video.club ? video.club : '---'}</div>
                <div>{video.yards ? video.yards : '---'} y</div>
                <div>{createdAt}</div>
                <FavBtn faved={faved} onClick={toggleFav} />
            </MovieInfo>
        </ThumbnailBox>
    );
};

export default observer(MovieThumbnail);
