import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import { colors } from '../../const/Styles';
import CustomerSelectableCard from '../surface/CustomerSelectableCard';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 4px));
    grid-template-rows: repeat(auto-fill, 150px);
    grid-gap: 4px;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 4px;
    background-color: ${colors.white};
`;

type Props = {
    customers: CustomerDisplay[];
    onSelect?: (customer: CustomerDisplay) => void;
};

const CustomerGridView: React.FC<Props> = ({ customers, onSelect }) => {
    const handleSelect = (customer: CustomerDisplay) => {
        if (onSelect) {
            onSelect(customer);
        }
    };

    return (
        <Wrapper>
            {customers.map((customer) => (
                <CustomerSelectableCard
                    key={customer.id}
                    customer={customer}
                    onSelect={handleSelect}
                />
            ))}
        </Wrapper>
    );
};

export default observer(CustomerGridView);
