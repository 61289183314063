import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from '../../contexts/StoreContext';
import Api from '../../utils/Api';
import { LessonCurriculumEntity } from '../@types/mysqlEntities';
import CurriculumFillingItem from './display/CurriculumFillingItems';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: 663,
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 23,
            paddingBottom: 31,
            position: 'relative',
        },
        gridContainer: {
            width: '80%',
            margin: 'auto',
        },
    }),
);

interface CurriculumForm {
    curriculum: LessonCurriculumEntity | undefined;
    isEditable: boolean;
    isDeletable: boolean;
}
interface Props {
    visible: boolean;
    lessonMasterId: number | undefined;
}

const CurriculumSettingsForm: React.FC<Props> = ({ visible, lessonMasterId }) => {
    const classes = useStyles();
    const [curriculums, setCurriculums] = useState<LessonCurriculumEntity[]>([]);
    const { tempoStore } = useStore();

    useEffect(() => {
        if (lessonMasterId) {
            Api.fetchCurriculums(lessonMasterId).then((data) => {
                setCurriculums(data.concat());
            }).catch(e => {
                console.log(e);
            });
        }
    }, [lessonMasterId]);

    const handleChangeCurriculums = useCallback((curriculums: LessonCurriculumEntity[]) => {
        // 現在の入力値を更新
        setCurriculums(curriculums.concat());
        // ストア側を更新
        void tempoStore.fetchAllLessons();
    }, [tempoStore])
    const curriculumForm = useMemo<CurriculumForm[]>(() => {
        return Array.from({ length: 10 }).map<CurriculumForm>((_, index) => {
            const current = curriculums[index];
            const previuosData = curriculums[index - 1];
            // 前の項目を見て編集可能かどうか判定
            const editable = (previuosData?.name || '').length > 0;
            const deletable = (current?.name || '').length > 0;
            // 同じく
            return {
                curriculum: current,
                isEditable: index > 0 ? editable : true,
                isDeletable: deletable,
            }
        });
    }, [curriculums]);

    if (!visible || !lessonMasterId) {
        return <Fragment></Fragment>
    }

    return (
        <Grid container className={classes.gridContainer}>
            {curriculumForm.map(({ curriculum, isEditable, isDeletable }, index) => (
                <CurriculumFillingItem
                    key={index}
                    index={index}
                    curriculum={curriculum}
                    lessonMasterId={lessonMasterId}
                    isEditable={isEditable}
                    isDeletable={isDeletable}
                    onChange={handleChangeCurriculums}
                />
            ))}
        </Grid>
    );
}

export default CurriculumSettingsForm;
