import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import AuthStore from '../../../store/AuthStore';
import { CustomerDisplay } from '../../../store/LessonStore';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import CustomerConditionFilterCell from './CustomerConditionFilterCell';
import CustomerNameFilterCell from './CustomerNameFilterCell';
import CustomerRegisterCell from './CustomerRegisterCell';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    min-width: 200px;
    height: 100%;
    border: solid 4px ${colors.gray};
    border-top-style: none;
    border-bottom-style: none;
    box-sizing: border-box;
    overflow: auto;
`;

type Props = {
    onClick: (customers: CustomerDisplay[]) => void;
};
const CustomerNameFilter: React.FC<Props> = ({ onClick }) => {
    const [selected, setSelected] = useState('ALL');
    const handleClick = useCallback(
        (filterName: string, customers: CustomerDisplay[]) => {
            setSelected(filterName);
            onClick(customers);
        },
        [onClick],
    );

    const { lessonStore } = useStore();
    const ALL = useMemo(() => {
        return function ALL() {
            return lessonStore.allCustomers;
        };
    }, [lessonStore.allCustomers]);

    const history = useHistory();
    const handleClickRegister = () => {
        history.push(Routes.CUSTOMER_REGISTER);
    };

    const handleChangeCondition = useCallback(async (checked: boolean) => {
        await lessonStore.fetchAllCustomers(AuthStore.tempoId, checked);

        setSelected(selected);
        onClick(selected === 'ALL' ? lessonStore.allCustomers : lessonStore.customersPerRow(selected));
    }, [selected, onClick, lessonStore])

    useEffect(() => {
        const fetchCustomers = async () => {
            await lessonStore.fetchAllCustomers(AuthStore.tempoId, lessonStore.isInclusionDeletedCustomer);
        }
        void fetchCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <CustomerConditionFilterCell onChange={handleChangeCondition} />
            <CustomerNameFilterCell
                filterName='ALL'
                customers={lessonStore.allCustomers}
                onClick={handleClick}
                isActive={ALL.name === selected}
                padding='30px'
            >
                ALL
            </CustomerNameFilterCell>
            {lessonStore.rows.map((row) => (
                <CustomerNameFilterCell
                    key={row}
                    filterName={row}
                    customers={lessonStore.customersPerRow(row)}
                    onClick={handleClick}
                    isActive={row === selected}
                >
                    {row}
                </CustomerNameFilterCell>
            ))}
            <CustomerRegisterCell onClick={handleClickRegister} />
        </Wrapper>
    );
};

export default observer(CustomerNameFilter);
