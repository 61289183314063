import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
    lineColorToRGB,
} from '../../../hooks/useEditorCanvas';
import {
    Values as PlaneValues,
    Actions as PlaneActions,
    MovingType,
} from '../../../hooks/useEditorAngle';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgPlaneRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    planeValues: PlaneValues;
    planeActions: PlaneActions;
    height: number;
    width: number;
}

function SwingPlaneSVG(props: Props): React.ReactElement {
    const {
        svgPlaneRef,
        canvasValues,
        planeValues,
        planeActions,
        height,
        width,
    } = props;
    const classes = useStyles();
    return (
        <svg
            ref={svgPlaneRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.swingPlane && classes.front,
            )}
            onPointerUp={planeActions.onUpCircle}
            onPointerMove={(e) =>
                planeActions.onMoveCircle(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                )
            }
            onClick={(e) =>
                planeActions.stamp(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                    ToolType.swingPlane,
                    canvasValues.lineColor,
                )
            }
        >
            <g display={planeValues.display ? 'inline' : 'none'}>
                <path
                    d={`M ${planeValues.tipX1} ${planeValues.tipY1} L ${planeValues.angleX} ${planeValues.angleY} L ${planeValues.tipX2} ${planeValues.tipY2}`}
                    fill='transparent'
                    stroke={lineColorToRGB[planeValues.color]}
                    strokeWidth='3'
                />
                <path
                    d={`M ${planeValues.angleX} ${planeValues.angleY} L ${planeValues.tipX3} ${planeValues.tipY3}`}
                    fill='transparent'
                    stroke='blue'
                    strokeWidth='3'
                />
                <circle
                    cx={planeValues.tipX1}
                    cy={planeValues.tipY1}
                    r='10'
                    fill='transparent'
                    stroke={lineColorToRGB[planeValues.color]}
                    strokeWidth='1'
                    onPointerDown={() =>
                        planeActions.onDownCircle(MovingType.tip1)
                    }
                />
                <circle
                    cx={planeValues.angleX}
                    cy={planeValues.angleY}
                    r='10'
                    fill='transparent'
                    stroke={lineColorToRGB[planeValues.color]}
                    strokeWidth='1'
                    onPointerDown={() =>
                        planeActions.onDownCircle(MovingType.angle)
                    }
                />
                <circle
                    cx={planeValues.tipX2}
                    cy={planeValues.tipY2}
                    r='10'
                    fill='transparent'
                    stroke={lineColorToRGB[planeValues.color]}
                    strokeWidth='1'
                    onPointerDown={() =>
                        planeActions.onDownCircle(MovingType.tip2)
                    }
                />
                <circle
                    cx={planeValues.tipX3}
                    cy={planeValues.tipY3}
                    r='10'
                    fill='transparent'
                    stroke='blue'
                    strokeWidth='1'
                    onPointerDown={() =>
                        planeActions.onDownCircle(MovingType.tip3)
                    }
                />
            </g>
        </svg>
    );
}

export default SwingPlaneSVG;
