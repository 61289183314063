import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
    lineColorToRGB,
} from '../../../hooks/useEditorCanvas';
import {
    Values as LineValues,
    Actions as LineActions,
} from '../../../hooks/useEditorLine';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgLineRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    lineValues: LineValues;
    lineActions: LineActions;
    height: number;
    width: number;
}

function LineSVG(props: Props): React.ReactElement {
    const { svgLineRef, canvasValues, lineValues, lineActions, height, width } =
        props;
    const classes = useStyles();

    return (
        <svg
            ref={svgLineRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.straightLine && classes.front,
            )}
            onPointerDown={(e) =>
                lineActions.onPointerDown(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                    canvasValues.lineColor,
                )
            }
            onPointerMove={(e) =>
                lineActions.onPointerMove(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                )
            }
            onPointerUp={lineActions.onPointerUp}
        >
            {lineValues.drawnLines.map((line) => {
                return line.startX && line.startY && line.endX && line.endY ? (
                    <line
                        key={line.startX}
                        x1={line.startX}
                        y1={line.startY}
                        x2={line.endX}
                        y2={line.endY}
                        stroke={lineColorToRGB[line.color]}
                    />
                ) : (
                    []
                );
            })}
            {lineValues.startX &&
            lineValues.startY &&
            lineValues.endX &&
            lineValues.endY ? (
                <line
                    key={lineValues.startX}
                    x1={lineValues.startX}
                    y1={lineValues.startY}
                    x2={lineValues.endX}
                    y2={lineValues.endY}
                    stroke={lineColorToRGB[lineValues.color]}
                />
            ) : (
                []
            )}
        </svg>
    );
}

export default LineSVG;
