import { Alert } from '@material-ui/lab';
import React from 'react';

import MainContainer from './MainContainer';

export default function NotFound(): React.ReactElement {
    return (
        <MainContainer>
            <Alert severity='error'>404 Not Found</Alert>
        </MainContainer>
    );
}
