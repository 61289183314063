import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { personalityMap } from '../../../store/LessonStore';

const Row = styled.dl`
    margin: 0 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    &:nth-child(1) {
        border-bottom: solid 1px ${colors.lightGray};
    }
`;

const DescriptionTitle = styled.dt`
    white-space: nowrap;
`;
type DescriptionProps = {
    active: boolean;
};
const Description = styled.dd<DescriptionProps>`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    margin: 0;
    width: 100%;
    justify-content: center;
    color: ${({ active }) =>
        active === true ? colors.white : colors.lightGray};
    background-color: ${({ active }) =>
        active === true ? colors.blue : 'transparent'};
`;

type PersonalityType = 'action' | 'sense' | 'observe' | 'think' | 'unknown';

type Props = {
    data: PersonalityType;
};

const LearningStyleDescription: React.FC<Props> = ({ data, children }) => {
    return (
        <Row>
            <DescriptionTitle>{children}</DescriptionTitle>
            {Object.entries(personalityMap)
                .filter((p) => p[0] !== 'unknown')
                .map(([key, value]) => (
                    <Description key={key} active={key === data}>
                        {value}
                    </Description>
                ))}
        </Row>
    );
};

export default LearningStyleDescription;
