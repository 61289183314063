import { Button, StyleRules, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules<'root'> => ({
    root: {
        padding: 10,
        width: 150,
        backgroundColor: theme.palette.grey[500],
        fontSize: 16,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.grey[500],
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
});

const DominantButton = withStyles(styles, { name: 'DominantButton' })(Button);
DominantButton.displayName = 'DominantButton';

export default DominantButton;
