import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BottomNavigationAction } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-use';
import { colors, iconSize } from '../../const/Styles';

type Props = {
    icon?: string;
    label?: string;
    path: string;
    pathRegex: string;
};

const StyledAction = styled(BottomNavigationAction)`
    svg {
        width: auto;
        height: ${iconSize.footer};
    }

    color: ${colors.footerIconUnSelected};

    .MuiBottomNavigationAction-label {
        font-size: 0.8rem;
        font-weight: 600;
        &.Mui-selected {
            color: ${colors.footerIconSelected};
        }
    }

    &.Mui-selected {
        color: ${colors.footerIconSelected};
    }
`;

const FooterItem: React.FC<Props> = ({ icon, label, path, pathRegex }) => {
    const history = useHistory();
    const handleClick = () => {
        history.push(path);
    };
    const location = useLocation();
    const selected = useMemo(() => {
        return new RegExp(pathRegex, 'gi').test(location.pathname ?? '');
    }, [pathRegex, location]);
    const IconComponent = useMemo(() => {
        return icon ? <ReactSVG src={icon} wrapper='span' /> : <></>;
    }, [icon]);

    return (
        <StyledAction
            icon={IconComponent}
            label={label}
            showLabel
            selected={selected}
            onChange={handleClick}
        />
    );
};

export default FooterItem;
