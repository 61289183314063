import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

type Props = {
    value?: string | null;
    onInput?: (value: string) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 26px;
`;

const StyledInput = styled.input`
    apperance: none;
    outline: none;
    background-color: ${colors.white};
    height: 26px;
    font-size: 26px;
    margin-right: 1rem;
`;

const Label = styled.label`
    color: ${colors.white};
`;

const SwingYardInput: React.FC<Props> = ({ value, onInput }) => {
    const handleInput = (evt: FormEvent<HTMLInputElement>) => {
        if (onInput) {
            onInput(evt.currentTarget.value);
        }
    };

    return (
        <Container>
            <StyledInput
                type='number'
                value={value ?? ''}
                onInput={handleInput}
            />
            <Label>ヤード</Label>
        </Container>
    );
};

export default SwingYardInput;
