import { makeAutoObservable } from 'mobx';
import Api from '../utils/Api';
import { LoginKindTypes } from "../components/const/LoginKinds";

class AuthStore {
    tempoId = '';
    companyId = '';
    name = '';
    loginKind: LoginKindTypes | null = null;
    updatedAt = '';
    questionName1 = '';
    questionName2 = '';
    questionName3 = '';
    questionName4 = '';
    questionName5 = '';
    questionName6 = '';
    questionName7 = '';
    questionName8 = '';
    questionName9 = '';
    questionName10 = '';

    get isLoggedIn(): boolean {
        return !!this.tempoId;
    }

    constructor() {
        makeAutoObservable<AuthStore>(this);
    }

    fetchLogin = async (id: string, password: string) => {
        const response = await Api.fetchLogin(id, password);
        this.tempoId = response.id;
        this.companyId = response.company_id;
        this.name = response.name;
        this.loginKind = response.login_kind;
        this.updatedAt = response.updated_at;
        this.questionName1 = response.question_name_1;
        this.questionName2 = response.question_name_2;
        this.questionName3 = response.question_name_3;
        this.questionName4 = response.question_name_4;
        this.questionName5 = response.question_name_5;
        this.questionName6 = response.question_name_6;
        this.questionName7 = response.question_name_7;
        this.questionName8 = response.question_name_8;
        this.questionName9 = response.question_name_9;
        this.questionName10 = response.question_name_10;
        this.isLoaded = true;
    };

    isLoaded = false;
    checkLogin = async () => {
        const response = await Api.checkLogin();
        this.tempoId = response.id;
        this.companyId = response.company_id;
        this.name = response.name;
        this.loginKind = response.login_kind;
        this.updatedAt = response.updated_at;
        this.questionName1 = response.question_name_1;
        this.questionName2 = response.question_name_2;
        this.questionName3 = response.question_name_3;
        this.questionName4 = response.question_name_4;
        this.questionName5 = response.question_name_5;
        this.questionName6 = response.question_name_6;
        this.questionName7 = response.question_name_7;
        this.questionName8 = response.question_name_8;
        this.questionName9 = response.question_name_9;
        this.questionName10 = response.question_name_10;
        this.isLoaded = true;
    };
}

export default new AuthStore();
