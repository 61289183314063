import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Layout = styled.section`
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${colors.white};
    overflow: hidden;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    margin: auto;
    padding: 15px 90px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
`;

const FooterActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 10%;
    box-sizing: border-box;
    border-top: solid 4px ${colors.lighterGray};
`;

const ActionButton = styled.button`
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: ${colors.black};
    width: 70%;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    outline: none;
    background-color: ${colors.footerIconSelected};
    border-radius: 4px;
`;

type Props = {
    onSave?: () => void;
    containerClassName?: string;
};

const FormPageLayout: React.FC<Props> = ({
    onSave,
    children,
    containerClassName,
}) => {
    return (
        <Layout>
            <FormContainer className={containerClassName}>
                {children}
            </FormContainer>
            <FooterActionContainer>
                <ActionButton onClick={onSave}>登録</ActionButton>
            </FooterActionContainer>
        </Layout>
    );
};

export default FormPageLayout;
