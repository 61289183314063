import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { GenderKeyType, Genders } from '../../const/Gender';
import { colors } from '../../const/Styles';

const StyledRadio = styled(Radio)`
    &.Mui-checked {
        color: ${colors.footerIconSelected};
    }
`;

type Props = {
    selected?: GenderKeyType | null;
    onChange?: (value: GenderKeyType) => void;
};

const GenderSelector: React.FC<Props> = ({ selected, onChange }) => {
    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(evt.currentTarget.value as GenderKeyType);
        }
    };
    return (
        <RadioGroup row onChange={handleChange} value={selected} defaultValue={'unknown'}>
            {Object.entries(Genders).map(([key, value]) => (
                <FormControlLabel
                    value={key}
                    key={key}
                    label={value}
                    control={<StyledRadio />}
                ></FormControlLabel>
            ))}
        </RadioGroup>
    );
};

export default GenderSelector;
