import React, { useMemo, useCallback, useState } from 'react';

export interface LessonState {
    started: boolean;
    changeState: () => void;
}

export const LessonStateContext = React.createContext<LessonState>({
    started: false,
    changeState: () => undefined,
});

interface Props {
    children: React.ReactNode;
}

export function LessonStateContextProvider(props: Props): React.ReactElement {
    const { children } = props;
    const [started, setStarted] = useState<boolean>(false);

    const changeState = useCallback(() => {
        setStarted(!started);
    }, [started]);

    const context = useMemo(
        () => ({
            started,
            changeState,
        }),
        [started, changeState],
    );

    return (
        <LessonStateContext.Provider value={context}>
            {children}
        </LessonStateContext.Provider>
    );
}
