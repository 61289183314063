const CLUB_DRIVER = 'ドライバー';
const CLUB_FAREWAYWOOD = 'フェアウェイウッド';
const CLUB_UTILITY = 'ユーティリティ';
const CLUB_IRON = 'アイアン';
const CLUB_WEDGE = 'ウェッジ';
const CLUB_PATTER = 'パター';

type SwingClubTypes =
    | typeof CLUB_DRIVER
    | typeof CLUB_FAREWAYWOOD
    | typeof CLUB_UTILITY
    | typeof CLUB_IRON
    | typeof CLUB_WEDGE
    | typeof CLUB_PATTER;

export const SwingClubs = [
    CLUB_DRIVER,
    CLUB_FAREWAYWOOD,
    CLUB_UTILITY,
    CLUB_IRON,
    CLUB_WEDGE,
    CLUB_PATTER,
] as const;

export type Club = SwingClubTypes;
