import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

import axios, { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderIcon from '../../assets/register.svg';
import { CustomerRegisterPropertyTabProvider } from '../../contexts/CustomerRegisterPropertyTabContext';
import { useStore } from '../../contexts/StoreContext';
import { useForm } from '../../hooks/useForm';
import AuthStore from '../../store/AuthStore';
import Api, {
    CustomerDetail,
    CustomerEnqueteAnswers,
    ErrorMessages,
    ErrorResponse,
} from '../../utils/Api';
import { CustomerEntity } from '../@types/mysqlEntities';
import {
    CustomerBasicInfomationForm,
    CustomerDetailInfomationForm,
    EnqueteAnswerForm,
} from '../@types/request';
import CustomerRegisterPage from '../base/CustomerRegisterPage';
import { colors } from '../const/Styles';
import CustomerRegisterBasic from './display/CustomerRegisterBasic';
import CustomerRegisterDetail from './display/CustomerRegisterDetail';
import CustomerRegisterHeaderForm from './display/CustomerRegisterHeaderForm';
import CustomerRegisterQuestionnaire from './display/CustomerRegisterQuestionnaire';
import RegisterPropertyNavigation from './navigation/RegisterPropertyNavigation';

const Container = styled.section`
    width: 100%;
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
`;

const DefaultBasicForm: CustomerBasicInfomationForm = {
    lesson_master_id: 0,
    email: '',
    password: '',
    user_name: '',
    name_sei: '',
    name_mei: '',
    name_sei_kana: '',
    name_mei_kana: '',
    date_of_birth: '',
    sex: 'unknown',
    target: '',
    height: null,
    grip_left: null,
    grip_right: null,
    dominant_hand: 'unknown',
    personality_1: 'unknown',
    personality_2: 'unknown',
    purpose_begin: '',
    profile_picture_filename: null,
    profile_picture_filepath: null,
    registered_at: '',
    signed_url: null,
    has_password: false,
};

const DefaultDetailForm: CustomerDetailInfomationForm = {
    best_score: '',
    avg_score: '',
    rounds_per_year: '',
    total_practice: '',
    club_good_at: '',
    club_favorite: '',
    years_golf: '',
    years_sport: '',
    home_course: '',
    memo: '',
    head_speed: '',
    distance: '',
    projectile: '',
};

const DefaultAnswerForm: EnqueteAnswerForm = {
    question_answer_1: '',
    question_answer_2: '',
    question_answer_3: '',
    question_answer_4: '',
    question_answer_5: '',
    question_answer_6: '',
    question_answer_7: '',
    question_answer_8: '',
    question_answer_9: '',
    question_answer_10: '',
};

export interface RegisterLocationState {
    customer?: CustomerEntity & CustomerDetail & CustomerEnqueteAnswers;
}

const CustomerRegister: React.FC = () => {
    const activeColor = colors.blue;

    const useStyles = makeStyles(() =>
        createStyles({
            lowerFixedBox: {
                width: '100%',
                textAlign: 'center',
                position: 'fixed',
                bottom: 56,
                minHeight: 48,
                padding: '5px 0px',
                borderTop: '1px solid grey',
                borderBottom: '1px solid grey',
            },
            gridItem: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            registerButtonBox: {
                display: 'flex',
                justifyContent: 'center',
                margin: 10,
                borderTop: `3px solid ${colors.lighterGray}`,
            },
            registerButton: {
                display: 'flex',
                justifyContent: 'center',
                margin: 10,
                width: 445,
                backgroundColor: `${colors.footerIconSelected}`,
                color: `${colors.black}`,
                fontWeight: 'bold',
                fontSize: 17,
            },
            modal: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            modalBox: {
                width: 466,
                height: 233,
                backgroundColor: `${colors.blue}`,
                opacity: 0.7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: `${colors.white}`,
                fontSize: 25,
            },
        }),
    );

    const classes = useStyles();
    const history = useHistory();
    const [basicFormValue, basicFormDispacher] =
        useForm<CustomerBasicInfomationForm>(DefaultBasicForm);

    const [detailFormValue, detailFormDispacher] =
        useForm<CustomerDetailInfomationForm>(DefaultDetailForm);

    const [answerFormValue, answerFormDispacher] =
        useForm<EnqueteAnswerForm>(DefaultAnswerForm);
    const [image, setImage] = useState<string>();
    const [imageFile, setImageFile] = useState<File>();

    const [errorProps, setErrorProps] = useState<ErrorMessages>({});

    const [open, setOpen] = useState(false);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const { lessonStore } = useStore();
    const onClickRegister = useCallback(async () => {
        let message: string;
        let filename: string;
        try {
            const response = await Api.registerCustomer({
                basicInfo: basicFormValue,
                detailInfo: detailFormValue,
                questionnaire: answerFormValue,
                image: imageFile?.name,
            });
            filename = response.filename;
            message = '登録完了';
        } catch (err) {
            if (axios.isAxiosError(err)) {
                const { response: errorResponse } =
                    err as AxiosError<ErrorResponse>;
                if (errorResponse) {
                    setErrorProps(errorResponse.data.message);
                }
            }
            return;
        }

        try {
            if (imageFile) {
                const presignedUrl =
                    await Api.getS3SignedUrlForUploadCustomerPicture(filename);
                await Api.uploadToS3(presignedUrl.presignedUrl, imageFile);
            }
        } catch (err) {
            // TODO 現状の実装だと画像アップロードが失敗した際にユーザがそれを認識できないので、フォームのバリデーション同様エラーメッセージ出すなどしたい
            if (axios.isAxiosError(err)) {
                console.log(err);
            }
        }

        await lessonStore.fetchAllCustomers(AuthStore.tempoId);

        history.push({
            pathname: '/customer/list',
            state: {
                success: true,
                message: message,
            },
        });
    }, [
        lessonStore,
        history,
        basicFormValue,
        detailFormValue,
        answerFormValue,
        imageFile,
    ]);

    return (
        <CustomerRegisterPage headerIcon={HeaderIcon ?? ''} title='新規登録'>
            <CustomerRegisterPropertyTabProvider>
                <CustomerRegisterHeaderForm
                    image={image}
                    setImage={setImage}
                    setImageFile={setImageFile}
                    errorProps={errorProps}
                />
                <RegisterPropertyNavigation activeColor={activeColor} />
                <Container>
                    <CustomerRegisterBasic
                        basicInfo={basicFormValue}
                        basicInfoDispatch={basicFormDispacher}
                        errorProps={errorProps}
                    />
                    <CustomerRegisterDetail
                        detailInfo={detailFormValue}
                        detailInfoDispatch={detailFormDispacher}
                        errorProps={errorProps}
                    />
                    <CustomerRegisterQuestionnaire
                        questionnaire={answerFormValue}
                        questionnaireDispatch={answerFormDispacher}
                    />
                </Container>
                <div className={classes.registerButtonBox}>
                    <Button
                        onClick={onClickRegister}
                        className={classes.registerButton}
                    >
                        登録
                    </Button>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    className={classes.modal}
                >
                    <div className={classes.modalBox}>登録完了</div>
                </Modal>
            </CustomerRegisterPropertyTabProvider>
        </CustomerRegisterPage>
    );
};

export default observer(CustomerRegister);
