import { makeAutoObservable } from 'mobx';
import Webcam from 'react-webcam';
import { RefObject } from 'react';
import LessonStore from './LessonStore';
import { getVideoFormat } from '../utils/utilityFuncs';

import { VideoMovie, VideoBlob } from '../components/video/PlayingVideoCard';

type CustomerVideoMap = { id: string; videos: SavedToolImageMap[] };
export type SavedToolImageMap = {
    id: number;
    video: Blob;
    videoUrl: string;
    toolImage?: File;
};
export type RegisteredVideoMap = {
    id: number;
    number: string;
    video: Blob | null;
    club: string | null;
    yard: string | null;
    toolImage: File | null;
    isBestSwing: boolean;
};
type CustomerRegisteredVideoMap = {
    id: string;
    videos: RegisteredVideoMap[];
}[];

class RecordingStore {
    constructor() {
        makeAutoObservable<RecordingStore>(this, {}, { autoBind: true });
    }

    recordedChunks: Blob[] = [];
    recordedVideos: CustomerVideoMap[] = [];
    mediaRecorder: MediaRecorder | null = null;
    afterAddHook?: (newData: SavedToolImageMap[]) => void;

    get viewedCustomerId() {
        return String(LessonStore.viewedCustomer?.id ?? '');
    }

    get customerVideos(): Blob[] {
        const target = this.recordedVideos.find((object) => {
            return object.id == this.viewedCustomerId;
        });
        return (
            target?.videos.map((object) => {
                return object.video;
            }) ?? []
        );
    }

    get customerToolImageMap(): SavedToolImageMap[] {
        const target = this.recordedVideos.find((object) => {
            return object.id == this.viewedCustomerId;
        });
        return target?.videos ?? [];
    }

    handleDataAvailable(event: BlobEvent) {
        if (event.data.size > 0) {
            this.recordedChunks.push(event.data);
            this.convertToVideo();
        }
    }

    handleStartCaptureClick(webcamRef: RefObject<Webcam>) {
        if (webcamRef.current?.stream) {
            this.mediaRecorder = new MediaRecorder(webcamRef.current.stream, {
                mimeType: getVideoFormat(),
            });
        }
        if (this.mediaRecorder) {
            this.mediaRecorder.addEventListener(
                'dataavailable',
                this.handleDataAvailable.bind(this),
            );
            this.mediaRecorder.start();
        }
    }

    handleStopCaptureClick() {
        this.mediaRecorder?.stop();
    }

    addLocalMovieToViewedCustomer(file: Blob): SavedToolImageMap {
        const targetIndex = this.recordedVideos.findIndex(({ id }) => id === this.viewedCustomerId);

        const rand = window.crypto.getRandomValues(new Uint32Array(1));
        const savedToolImageMap: SavedToolImageMap = {
            id: rand[0],
            video: file,
            videoUrl: URL.createObjectURL(file),
        };

        if (targetIndex === -1) {
            this.recordedVideos.push({
                id: this.viewedCustomerId,
                videos: [savedToolImageMap]
            });

            if (this.afterAddHook) {
                this.afterAddHook([savedToolImageMap]);
            }

            return savedToolImageMap;
        }

        const target = this.recordedVideos[targetIndex];
        target.videos.push(savedToolImageMap);
        this.recordedVideos.splice(targetIndex, 1, target);

        if (this.afterAddHook) {
            this.afterAddHook(target.videos);
        }

        return savedToolImageMap;
    }

    convertToVideo() {
        if (this.recordedChunks.length) {
            const blob = new Blob(this.recordedChunks, {
                type: getVideoFormat(),
            });

            let target = this.recordedVideos.find((object) => {
                return object.id == this.viewedCustomerId;
            });
            const rand = window.crypto.getRandomValues(new Uint32Array(1));
            if (target) {
                const index = this.recordedVideos.findIndex(
                    (p) => p.id === this.viewedCustomerId,
                );
                target.videos.push({
                    id: rand[0],
                    video: blob,
                    videoUrl: URL.createObjectURL(blob),
                });
                this.recordedVideos.splice(index, 1, target);
                if (this.afterAddHook) {
                    this.afterAddHook(target.videos);
                }
            } else {
                target = {
                    id: this.viewedCustomerId,
                    videos: [
                        {
                            id: rand[0],
                            video: blob,
                            videoUrl: URL.createObjectURL(blob),
                        },
                    ],
                };
                this.recordedVideos.push(target);
                if (this.afterAddHook) {
                    this.afterAddHook(target.videos);
                }
            }
            this.recordedChunks = [];
        }
    }

    clearVideos() {
        this.recordedVideos = [];
        this.registeredVideos.splice(0, this.registeredVideos.length);
    }

    clearRegisteredVideosByCustomerId(customerId: string) {
        this.registeredVideos = this.registeredVideos.filter((object) => {
            return object.id !== customerId;
        });
    }

    registeredVideos: CustomerRegisteredVideoMap = [];

    getRegisteredVideo(customerId: string) {
        const response = this.registeredVideos?.find((object) => {
            return object.id == customerId;
        });
        return response?.videos;
    }

    getRegisteredVideoUrls(customerId: string): string[] {
        const target = this.registeredVideos?.find((object) => {
            return object.id == customerId;
        });
        if (target) {
            return target.videos.map((object) =>
                object.video ? URL.createObjectURL(object.video) : '',
            );
        } else {
            return ['', ''];
        }
    }

    setBestSwingToRegisteredVideos(
        customerId: string,
        videoId: number,
        isBestSwing: boolean
    ) {
        const targetIndex = this.registeredVideos.findIndex(({ id }) => id === customerId)
        const target = this.registeredVideos.find(({ id }) => id === customerId);
        if (!target || targetIndex < 0) {
            throw new Error('modify video map not found');
        }

        const targetVideoIndex = target.videos.findIndex(({ id }) => id === videoId);
        if (targetVideoIndex < 0) {
            throw new Error('modify video not found');
        }

        if (isBestSwing === false) {
            target.videos.splice(targetVideoIndex, 1, {
                ...target.videos[targetVideoIndex],
                isBestSwing: false,
            });
        } else {
            target.videos.forEach((video) => {
                video.isBestSwing = video.id === videoId;
            });
        }

        this.registeredVideos.splice(targetIndex, 1, target);
    }

    /**
     * 登録済み動画取得
     * @param id
     * @returns
     */
    getSingleRegisteredVideo(id?: number): undefined | RegisteredVideoMap {
        const videos = this.getRegisteredVideo(this.viewedCustomerId);
        return videos?.find((p) => p.id === id);
    }

    /**
     * 追加登録可能かどうか
     * @returns
     */
    get canRegisterVideo(): boolean {
        return (
            (this.registeredVideos.find((p) => p.id === this.viewedCustomerId)
                ?.videos.length ?? 0) < 2
        );
    }

    removeVideo(id: number) {
        // 撮影済みから削除
        const recordedParentIndex = this.recordedVideos.findIndex(
            (p) => p.id === this.viewedCustomerId,
        );
        if (recordedParentIndex > -1) {
            const parent = this.recordedVideos[recordedParentIndex];
            const newList = parent.videos.filter((p) => p.id !== id);
            parent.videos.splice(0, parent.videos.length, ...newList);
            this.recordedVideos.splice(recordedParentIndex, 1, parent);
        }
        // 登録済みから解除
        const parentIndex = this.registeredVideos.findIndex(
            (p) => p.id === this.viewedCustomerId,
        );
        if (parentIndex > -1) {
            const parent = this.registeredVideos[parentIndex];
            const newList = parent.videos
                .filter((p) => p.id !== id)
                .map((video, index) => ({ ...video, number: `${index + 1}` }));
            parent.videos.splice(0, parent.videos.length, ...newList);
            this.registeredVideos.splice(parentIndex, 1, parent);
        }
    }

    /**
     * 削除
     * @param customerId
     * @param id
     * @returns
     */
    deregisterVideo(id: number): void {
        if (!this.registeredVideos) {
            return;
        }
        const parentTargetIndex = this.registeredVideos.findIndex(
            (p) => p.id === this.viewedCustomerId,
        );
        if (parentTargetIndex === -1) {
            return;
        }
        const parent = this.registeredVideos[parentTargetIndex];
        const videoIndex = parent.videos.findIndex((p) => p.id === id);
        if (videoIndex > -1) {
            parent.videos.splice(videoIndex, 1);
            const newList = parent.videos.map((video, index) => ({
                ...video,
                number: `${index + 1}`,
            }));
            if (newList.length > 0) {
                parent.videos.splice(0, newList.length, ...newList);
            }
            this.registeredVideos.splice(parentTargetIndex, 1, parent);
        }
    }

    registerVideo(customerId: string, newVideoMap: RegisteredVideoMap) {
        const { id } = newVideoMap;
        if (!this.registeredVideos) {
            this.registeredVideos = [];
        }
        const parentIndex = this.registeredVideos.findIndex((object) => {
            return object.id == customerId;
        });
        // 親レコードが存在する場合は更新
        if (parentIndex > -1) {
            const customerVideo = this.registeredVideos[parentIndex];
            const targetIndex = customerVideo.videos.findIndex((video) => {
                return video.id == id;
            });
            // 既に追加済みの場合はそちらを更新
            if (targetIndex > -1) {
                customerVideo.videos.splice(targetIndex, 1, newVideoMap);
                return;
            }
            if (customerVideo.videos.length === 2) {
                // ２つ追加されてた場合は置き換え
                customerVideo.videos.splice(1, 1, {
                    ...newVideoMap,
                    number: '2',
                });
            } else {
                // 追加されてない場合はそのまま追加
                customerVideo.videos.push({
                    ...newVideoMap,
                    number: `${customerVideo.videos.length + 1}`,
                });
            }
            return;
        }
        // 親レコードが存在しない場合
        this.registeredVideos.push({
            id: customerId,
            videos: [newVideoMap],
        });
    }

    saveToolImage(video: VideoMovie | VideoBlob, imgFile: File) {
        if (video.type === 'movie') {
            const src = URL.createObjectURL(imgFile);
            video.toolImageFilePath = src;
        } else {
            this.recordedVideos.forEach((object) => {
                const target = object.videos.find((object2) => {
                    return object2.video === video.blob;
                });
                if (target) {
                    target.toolImage = imgFile;
                }
            });
        }
    }

    getToolImage(video: VideoMovie | VideoBlob): string {
        if (video.type === 'movie') {
            return video.toolImageFilePath;
        } else {
            let src = '';
            this.recordedVideos.forEach((object) => {
                const target = object.videos.find((object2) => {
                    return object2.video === video.blob;
                });
                if (target && target.toolImage) {
                    src = URL.createObjectURL(target.toolImage);
                }
            });
            return src;
        }
    }
}

export default new RecordingStore();
