import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { Question } from '../../../hooks/useQuestionnaireMapCreater';
import { colors } from '../../const/Styles';
import LessonTargetIndicator from '../surface/LessonTargetIndicator';

type Props = {
    questions: Question[];
    color: string;
};

const Container = styled.section`
    display: block;
    background-color: ${colors.gray};
`;
const LessonTargetIndicators: React.FC<Props> = ({ questions, color }) => {
    return (
        <Container>
            {questions.map((question, index) => (
                <LessonTargetIndicator
                    key={index}
                    title={question.question}
                    progress={question.answer}
                    color={color}
                />
            ))}
        </Container>
    );
};

export default observer(LessonTargetIndicators);
