import { observer } from 'mobx-react-lite';
import React, {
    Fragment,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { MerchantTabContext } from '../../contexts/MerchantTabContext';
import { colors, MerChantBackGroundColor } from '../const/Styles';
import {
    Card,
    createStyles,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import TempoStore from '../../store/TempoStore';
import format from 'date-fns/format';
import { Genders } from '../const/Gender';
import CertificationDisplay from './display/CertificationDisplay';
import DominantButton from '../form/DominantButton';
import StaffUpdateModal from './input/StaffupdateModal';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useAsync } from 'react-use';
import Api from '../../utils/Api';
import { COACH_INFO_DETAIL, COACH_LIST } from '../const/MerchantProperties';
import styled from 'styled-components';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    padding-top: 35px;
    background-color: ${MerChantBackGroundColor};
`;

const useStyles = makeStyles(() =>
    createStyles({
        wrapCard: {
            paddingTop: 35,
            backgroundColor: `${MerChantBackGroundColor}`,
        },
        card: {
            width: '80%',
            height: '100%',
            backgroundColor: `${colors.white}`,
            margin: 'auto',
            paddingTop: 40,
            paddingBottom: 24,
        },
        topTitle: {
            textAlign: 'center',
        },
        infoCard: {
            display: 'flex',
            justifyContent: 'center',
        },
        infoSet: {
            marginTop: 15,
        },
        infoSet_license: {
            width: '90%',
            marginTop: 20,
            margin: 'auto',
        },
        licenseBox: {
            width: 526,
            border: `1px solid ${colors.black}`,
            paddingTop: 20,
            paddingBottom: 32,
        },
        gridItem: {
            width: 304,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${colors.black}`,
        },
        gridItem_title: {
            width: 224,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${colors.black}`,
            backgroundColor: `${colors.lightGray}`,
            color: `${colors.white}`,
        },
        gridItem_title_license: {
            marginTop: 15,
            width: 528,
            height: 32,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${colors.black}`,
            backgroundColor: `${colors.lightGray}`,
            color: `${colors.white}`,
        },
        gridItem_license: {
            width: 528,
            height: 32,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${colors.black}`,
        },
        spanText: {
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: 10,
        },
        editButton: {
            height: 30,
            width: 445,
            color: `${colors.black}`,
            backgroundColor: `${colors.footerIconSelected}`,
            fontWeight: 'bold',
        },
        previousPicture: {
            width: 150,
            height: 150,
        },
        profilebox: {
            display: 'flex',
            justifyContent: 'center',
            lineHeight: 0.1,
        },
        profile_Title: {
            color: `${colors.footerIconSelected}`,
            fontSize: 27,
            fontWeight: 'bold',
        },
        profile_Name: {
            fontSize: 38,
        },
        profile_NameYomi: {
            fontSize: 16,
        },
        profileTitleBox: {
            marginLeft: 31,
            marginBottom: 26,
            paddingBottom: 30,
        },
        editButtonBox: {
            width: '100%',
            borderTop: `4px solid ${colors.lighterGray}`,
            marginTop: 35,
            paddingTop: 15,
            paddingBottom: 15,
            display: 'flex',
            justifyContent: 'center',
        },
    }),
);

type Props = {
    currentStaffId?: number;
};

const MerchantDetailPage: React.FC<Props> = ({ currentStaffId }) => {
    const classes = useStyles();
    const staff = TempoStore.includeDeletedStaffs.find(
        (p) => p.id === currentStaffId,
    );
    const oldProfilePictureAsync: AsyncState<string> = useAsync(async () => {
        if (!staff) {
            return `${process.env.PUBLIC_URL}/sample_account.png`;
        }
        const { profile_picture_filepath } = staff;
        if (!profile_picture_filepath)
            return `${process.env.PUBLIC_URL}/sample_account.png`;
        const { presignedUrl } =
            await Api.getS3SignedUrlForDownloadStaffPicture(
                profile_picture_filepath,
            );
        return presignedUrl;
    }, [currentStaffId]);

    const [openedModal, setOpenedModal] = useState<boolean>(false);
    const { selected, onChange } = useContext(MerchantTabContext);

    const handleOpen = useCallback(() => {
        setOpenedModal(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpenedModal(false);
        // MEMO: 論理削除された場合には一覧ページへ戻す
        if (typeof currentStaffId === 'number' && !TempoStore.allStaffs.some(({ id }) => id === currentStaffId)) {
            onChange(COACH_LIST);
        }
    }, [setOpenedModal, currentStaffId, onChange]);

    const visible = useMemo(() => {
        return COACH_INFO_DETAIL === selected;
    }, [selected]);

    const handleDelete = () => {
        handleClose()
        onChange(COACH_LIST)
    }

    if (!staff) {
        return <Fragment></Fragment>;
    }

    return (
        <Container visible={visible}>
            <Card className={classes.card}>
                <div className={classes.profilebox}>
                    <img
                        className={classes.previousPicture}
                        src={oldProfilePictureAsync.value}
                    ></img>
                    <div className={classes.profileTitleBox}>
                        <Typography className={classes.profile_Title}>
                            コーチプロフィール
                        </Typography>
                        <Typography className={classes.profile_Name}>
                            {staff.name_sei} {staff.name_mei}
                        </Typography>
                        <Typography className={classes.profile_NameYomi}>
                            {staff.name_sei_kana} {staff.name_mei_kana}
                        </Typography>
                    </div>
                </div>
                <div className={classes.infoCard}>
                    <Card>
                        <Grid container className={classes.infoSet}>
                            <Grid item className={classes.gridItem_title}>
                                <Typography>スタッフ種別</Typography>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <Typography>
                                    {staff.type === 'coach'
                                        ? 'コーチ'
                                        : '事務員'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.infoSet}>
                            <Grid item className={classes.gridItem_title}>
                                <Typography>氏名</Typography>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <Typography>
                                    {staff.name_sei} {staff.name_mei}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.infoSet}>
                            <Grid item className={classes.gridItem_title}>
                                <Typography>ふりがな</Typography>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <Typography>
                                    {staff.name_sei_kana} {staff.name_mei_kana}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.infoSet}>
                            <Grid item className={classes.gridItem_title}>
                                <Typography>生年月日</Typography>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <Typography>
                                    {format(
                                        new Date(staff.date_of_birth || ''),
                                        'y/M/d',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.infoSet}>
                            <Grid item className={classes.gridItem_title}>
                                <Typography>性別</Typography>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <Typography>{Genders[staff.sex]}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.gridItem_title_license}>
                            <Typography>資格 / 資格取得年月日</Typography>
                        </Grid>
                        <div className={classes.licenseBox}>
                            <CertificationDisplay
                                name={staff.certification1_name}
                                date={staff.certification1_date}
                            />
                            <CertificationDisplay
                                name={staff.certification2_name}
                                date={staff.certification2_date}
                            />
                            <CertificationDisplay
                                name={staff.certification3_name}
                                date={staff.certification3_date}
                            />
                        </div>

                        <Grid container className={classes.infoSet}>
                            <Grid item className={classes.gridItem_title}>
                                <Typography>スタッフ情報</Typography>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <Typography>
                                    {staff.is_active ? '入社' : '退社'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Card>
            <div className={classes.editButtonBox}>
                <DominantButton
                    className={classes.editButton}
                    onClick={handleOpen}
                >
                    編集
                </DominantButton>
            </div>
            <StaffUpdateModal
                isOpended={openedModal}
                onClose={handleClose}
                data={staff}
                onDelete={handleDelete}
            />
        </Container>
    );
};

export default observer(MerchantDetailPage);
