import Button from '@material-ui/core/Button';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LessonStateContext } from '../../../contexts/LessonStateContext';
import { useStore } from '../../../contexts/StoreContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import CustomerSelectedCard from '../surface/CustomerSelectedCard';

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr min-content;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px 10px;
    box-sizing: border-box;
`;

const Title = styled.h3`
    color: ${colors.white};
    font-weight: 500;
    text-align: center;
    margin: 0;
`;

const LessonStartButton = styled(Button)`
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    font-weight: bold;
    margin-bottom: 4px;
`;

const CustomerList = styled.div`
    display: flex;
    flex-grow: 2;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
`;

const WillLessonCustomers: React.FC = () => {
    const { lessonStore } = useStore();
    const { selectedCustomers, selectedCustomersId } = lessonStore;
    const { changeState, started } = useContext(LessonStateContext);
    const history = useHistory();

    const handleLessonStart = useCallback(() => {
        changeState();
        history.push(Routes.LESSON_START);
    }, [changeState, history]);

    const enableStartLesson = useMemo(() => {
        return selectedCustomers.length > 0;
    }, [selectedCustomers]);

    const customerList: Array<CustomerDisplay | undefined> = useMemo(() => {
        const source: Array<CustomerDisplay | undefined> =
            Array.from(selectedCustomers);
        if (source.length < 5) {
            while (source.length < 5) {
                source.push(undefined);
            }
        }
        return source.concat();
    }, [selectedCustomers]);

    const handleUnSelect = useCallback(
        (customer: CustomerDisplay) => {
            lessonStore.unselectCustomer(customer.id);
        },
        [lessonStore],
    );

    const ButtonText = useMemo(() => {
        if (started === true && selectedCustomersId.length > 0) {
            return '本日のレッスンに戻る';
        }
        return 'レッスンを開始する';
    }, [started, selectedCustomersId]);

    return (
        <Wrapper>
            <Title>MEMBER</Title>
            <CustomerList>
                {customerList.map((customer, index) => (
                    <CustomerSelectedCard
                        key={`${customer?.id ?? ''}_${index}`}
                        customer={customer}
                        index={index}
                        isSelected={typeof customer !== 'undefined'}
                        onUnSelect={handleUnSelect}
                    />
                ))}
            </CustomerList>
            <LessonStartButton
                onClick={handleLessonStart}
                disabled={enableStartLesson === false}
            >
                {ButtonText}
            </LessonStartButton>
        </Wrapper>
    );
};

export default observer(WillLessonCustomers);
