import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

interface Props {
    children?: React.ReactNode;
}

const useStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            flexGrow: 1,
            padding: 40,
        },
    }),
);

export default function MainContainer(props: Props): React.ReactElement {
    const { children } = props;
    const classes = useStyles();
    return <div className={classes.mainContainer}>{children}</div>;
}
