import { observer } from 'mobx-react-lite';
import React from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import Api from '../../../utils/Api';
import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import CustomerBestSwingMovieThumbnail from './CustomerBestSwingMovieThumbnail';


const Container = styled.article`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Header = styled.h3`
    display: block;
    background-color: ${colors.gray};
    font-weight: bold;
    font-size: 1.3rem;
    color: ${colors.footerIconSelected};
    padding: 10px;
    margin: 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.black};
    min-height: 30vh;
    box-sizing: border-box;
    padding: 10px;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`;

type Props = {
    customerId: number
};

const CustomerBestSwingCard: React.FC<Props> = ({ customerId }) => {

    const { recordingStore, lessonStore } = useStore();
    const video = recordingStore.getRegisteredVideo(`${customerId}`);
    const lessonBestSwingMovie = video?.find(p => p.isBestSwing);

    const { value, loading } = useAsync(async () => {
        const response = await Api.fetchCustomerMovies(customerId);
        const bestSwingMovie = response.find(p => p.desirable === true);

        if (!bestSwingMovie) {
            return undefined;
        }

        const movie = new MovieDisplay(bestSwingMovie);
        if (!movie.fetchable) {
            return undefined;
        }

        return movie;
    }, [customerId, lessonStore.pastLessons.get(customerId)?.length]);

    return (
        <Container>
            <Header>ベストスイング</Header>
            <Content>
                <VideoContainer>
                    {
                        !loading && (
                            <CustomerBestSwingMovieThumbnail
                                movieDisplay={value}
                                registeredVideo={lessonBestSwingMovie}
                                customerId={customerId}
                            />
                        )
                    }
                </VideoContainer>
            </Content>
        </Container>
    );
}

export default observer(CustomerBestSwingCard);
