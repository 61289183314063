import { makeAutoObservable } from 'mobx';

import { testCustomers } from './mockData';

export interface CoachDisplay {
    id: number;
    name: string;
    image?: string;
    selected?: boolean;
}

class CoachStore {
    constructor() {
        makeAutoObservable<CoachStore>(this);
    }

    allCoaches: CoachDisplay[] = testCustomers;
    selectedCoach: CoachDisplay = testCustomers[0];
    isInclusionDeletedStaff = false;

    isSelected(coachId: number): boolean {
        return this.selectedCoach.id === coachId;
    }

    selectCoach(coachId: number): void {
        const selected = this.allCoaches.find((coach) => {
            return coach.id === coachId;
        });
        if (selected) {
            this.selectedCoach = selected;
        }
    }
}

export default new CoachStore();
