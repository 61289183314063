import React, { useCallback, useRef } from 'react';
import { Avatar } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import AuthStore from '../../../store/AuthStore';
import { StaffDisplay } from '../../../store/TempoStore';
import { colors } from '../../const/Styles';
import CoarchSelector from '../input/CoachSelector';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    color: ${colors.white};
    box-sizing: border-box;
    padding: 10px;
`;

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
`

const StoreNameWrapper = styled.p`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.2rem;
    margin: 0;
    padding: 0 10px;
    height: 2.5lh;
    width: 100%;
    overflow: hidden;
`

const StyledAvator = styled(Avatar)`
    min-width: 80px;
    min-height: 80px;
    margin: 10px;
    border: 2px solid white;
`;

type Props = {
    showProfile?: boolean;
};
const CoachProfile: React.FC<Props> = ({ showProfile = true }) => {
    const { tempoStore } = useStore();
    const { selectedCoach, allCoaches } = tempoStore;

    const handleSelectCoach = useCallback(
        (coach: StaffDisplay) => {
            tempoStore.selectCoach(coach.id);
        },
        [tempoStore],
    );

    const avatorRef = useRef<HTMLDivElement>(null);

    return (
        <Wrapper>
            <StoreNameWrapper> {AuthStore.name} </StoreNameWrapper>
            <ProfileWrapper>
                {showProfile === true && (
                    <StyledAvator
                        src={selectedCoach?.s3Path ?? '/sample_account.png'}
                        ref={avatorRef}
                    ></StyledAvator>
                )}
                <CoarchSelector
                    coaches={allCoaches}
                    selectedCoach={selectedCoach}
                    onSelect={handleSelectCoach}
                ></CoarchSelector>
            </ProfileWrapper>
        </Wrapper>
    );
};

export default observer(CoachProfile);
