import dateFormat from 'date-fns/format';
import {
    forwardRef,
    HTMLAttributes,
    useCallback,
    useImperativeHandle,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import useVideoJS from '../../../hooks/useVideoJs';
import { RegisteredVideoMap } from '../../../store/RecordingStore';
import { colors } from '../../const/Styles';
import RecordedToolImageViewer from './RecordedToolImageViewer';
import TimelineImage from './TimelineImage';
import BestSwingButton from '../../base/surface/BestSwingIcon';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    .vjs-control-bar {
        z-index: 3;
    }
    .vjs-big-play-button {
        z-index: 3;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    font-weight: bold;
`;

const PlayerName = styled.label`
    display: block;
    font-size: 21px;
    color: ${colors.black};
    font-weight: bold;
`;

const LessonInfomation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-conten: flex-end;
`;

const LessonInfomationColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 12px;
`;

const CreatedAt = styled.div`
    text-align: right;
`;

const InlineList = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    > li {
        display: block;
        box-sizing: border-box;
        padding-right: 15px;
        &:last-child {
            padding-right: 0;
        }
        &:before {
            content: '●';
        }
    }
`;

const MovieIndexTitle = styled.span`
    font-weight: bold;
    font-size: 18px;
    color: ${colors.footerIconSelected};
    text-align: right;
`;

const MovieIndex = styled.span`
    margin: 0;
    padding: 0;
    margin-left: 20px;
    color: ${colors.footerIconSelected};
    font-size: 59px;
    font-weight: bold;
    line-height: 1;
`;

const Timeline = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 480px;
    > img {
        height: auto;
        width: 48px;
    }
`;

const StyledBestSwingButton = styled(BestSwingButton)`
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
`

interface Props extends HTMLAttributes<Element> {
    customerId: string
    movie: RegisteredVideoMap;
    showTimeLine?: boolean;
    showHeader?: boolean;
    movieIndex?: number;
    onChangeDuration?: (time: number) => void;
    reloadToolImage: boolean;
}

export type ForwardProps = {
    advance: () => void;
    goBack: () => void;
    togglePlay: () => Promise<void>;
};

const RecordedMoviePlayer = forwardRef<ForwardProps, Props>(
    (
        {
            customerId,
            movie,
            showTimeLine = false,
            showHeader = true,
            className,
            reloadToolImage,
        },
        ref,
    ) => {
        const { tempoStore, lessonStore, recordingStore } = useStore();
        const { Video, thumbnails, actions } = useVideoJS({
            height: 700,
            width: 550,
            controls: true,
            responsive: true,
            sources: [
                {
                    src: movie.video ? URL.createObjectURL(movie.video) : '',
                    type: movie.video?.type,
                },
            ],
        });

        const { viewedCustomer } = lessonStore;
        const userName = useMemo(() => {
            return (
                tempoStore.resolveUserName(viewedCustomer.id) ||
                lessonStore.resolveCustomerName(viewedCustomer.id) ||
                []
            );
        }, [tempoStore, viewedCustomer.id, lessonStore]);

        const handleClickTimeline = (time: number) => {
            actions.skip(time);
        };

        useImperativeHandle(ref, () => ({
            advance: () => {
                actions.skipPlus();
            },
            goBack: () => {
                actions.skipMinus();
            },
            togglePlay: async () => {
                await actions.togglePlay();
            },
        }));

        const handleClickBestSwingButton = useCallback((isActive: boolean) => {
            if (!movie) {
                return;
            }

            recordingStore.setBestSwingToRegisteredVideos(customerId, movie.id, isActive);
        }, [customerId, movie, recordingStore])

        return (
            <Container className={className}>
                {showHeader && (
                    <HeaderContainer>
                        <PlayerName className='userName'>
                            {userName.join(' ')}
                        </PlayerName>
                        <LessonInfomation>
                            <LessonInfomationColumn>
                                <CreatedAt>
                                    {dateFormat(new Date(), 'yyyy/MM/dd')}
                                </CreatedAt>
                                <InlineList>
                                    <li>{movie.club}</li>
                                    <li>{movie.yard}y</li>
                                </InlineList>
                                <MovieIndexTitle>スイング</MovieIndexTitle>
                            </LessonInfomationColumn>
                            <MovieIndex>{movie.number}</MovieIndex>
                        </LessonInfomation>
                    </HeaderContainer>
                )}
                <VideoContainer>
                    <Video />
                    <RecordedToolImageViewer
                        movie={movie}
                        reload={reloadToolImage}
                    />
                    <StyledBestSwingButton isActive={movie.isBestSwing} onClick={handleClickBestSwingButton} />
                </VideoContainer>
                {showTimeLine && (
                    <Timeline className='timeline'>
                        {thumbnails?.map(([img, time]) => (
                            <TimelineImage
                                key={time}
                                src={img}
                                time={time}
                                onClick={handleClickTimeline}
                            ></TimelineImage>
                        ))}
                    </Timeline>
                )}
            </Container>
        );
    },
);

export default RecordedMoviePlayer;
