import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
    CustomerAttributeType,
    CUSTOMER_ATTRIBUTE_BASIC,
} from '../components/const/CustomerAttributeTabProperties';

type State = {
    selected: CustomerAttributeType;
    onChange: (value: CustomerAttributeType) => void;
};

export const CustomerAttributeTabContext = createContext<State>({
    selected: CUSTOMER_ATTRIBUTE_BASIC,
    onChange: () => undefined,
});

export const CustomerAttributeTabProvider: React.FC = ({ children }) => {
    const [selected, setSelected] = useState<CustomerAttributeType>(
        CUSTOMER_ATTRIBUTE_BASIC,
    );

    const handleChange = useCallback((value: CustomerAttributeType) => {
        setSelected(value);
    }, []);

    const context = useMemo<State>(() => {
        return {
            selected,
            onChange: handleChange,
        };
    }, [selected, handleChange]);

    return (
        <CustomerAttributeTabContext.Provider value={context}>
            {children}
        </CustomerAttributeTabContext.Provider>
    );
};
