import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles, Modal } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseSharp';
import { observer } from 'mobx-react-lite';
import HeaderIcon from '../../assets/windows.svg';
import { MovieDisplay } from '../../utils/Video';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import { VideoMovie } from '../video/PlayingVideoCard';
import VideoEditor from '../video/VideoEditor';
import MovieEditFooter from './navigation/MovieEditFooter';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import CoachMoviePlayer from './surface/CoachMoviePlayer';
import { useHistory, useLocation } from 'react-router-dom';
import GoBack from '../../assets/goback.svg';
import { ReactSVG } from 'react-svg';
import { Routes } from '../const/Routes';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 30px;
    right: 30px;
    color: ${colors.lightGray};
    z-index: 9999;
    svg {
        width: 60px;
        height: 60px;
    }
`;

const GoBackIcon = styled.i`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 80px;
    position: absolute;
    top: 50px;
    right: 50px;
    color: ${colors.footerIconSelected};
    font-size: 13px;
    svg {
        height: 40px;
        width: 40px;
    }
`;

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

const PlayMoviePage: React.FC = () => {
    const location = useLocation<MovieDisplay>();
    const { state } = location;
    const classes = useStyles();
    const [modalOpened, setModalOpened] = useState(false);
    const [saveCounter, setSaveCounter] = useState<number>(0);
    const {
        id,
        src,
        filename,
        customer_id_or_staff_id,
        created_at,
        tool_image_filename,
        tool_image_filepath,
    } = state;
    const videoMovie: VideoMovie = {
        id: Number(id),
        type: 'movie',
        filepath: src ?? '',
        filename: filename,
        customerIdOrStaffId: customer_id_or_staff_id,
        movieCreatedAt: created_at,
        toolImageFilename: tool_image_filename,
        toolImageFilePath: tool_image_filepath,
    };

    const handleModalOpen = () => {
        setModalOpened(true);
    };

    const handleModalClose = () => {
        setModalOpened(false);
    };

    const history = useHistory();

    const handleClickBackIcon = () => {
        history.push({
            pathname: Routes.MERCHANT,
            state: { changePageValue: true },
        });
    };

    // TODO: 構造の問題でなぜか更新処理がuseEffectで実装されているので２系統で分離
    useEffect(() => {
        setModalOpened(false);
    }, [saveCounter]);

    return (
        <Page title='スイング一覧' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation>スイング再生</PlayerHeaderNavigation>
                <GoBackIcon onClick={handleClickBackIcon}>
                    <ReactSVG src={GoBack ?? ''}></ReactSVG>
                    コーチ一覧に戻る
                </GoBackIcon>
                <VideoContainer>
                    <CoachMoviePlayer
                        movieDisplay={state}
                        showFav={false}
                    ></CoachMoviePlayer>
                </VideoContainer>
                <MovieEditFooter onClick={handleModalOpen}>
                    編集
                </MovieEditFooter>
            </ContentContainer>
            <Modal
                className={classes.modal}
                open={modalOpened}
                onClose={handleModalClose}
            >
                <>
                    <StyledIconButton onClick={handleModalClose}>
                        <CloseIcon></CloseIcon>
                    </StyledIconButton>
                    <VideoEditor
                        videoSrc={videoMovie}
                        height={700}
                        width={550}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </>
            </Modal>
        </Page>
    );
};

export default observer(PlayMoviePage);
