import React, { Dispatch, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { CustomerAttributeTabContext } from '../../../contexts/CustomerAttributeTabContext';
import { useStore } from '../../../contexts/StoreContext';
import { FormReducerAction } from '../../../hooks/useForm';
import { CustomerEnqueteAnswers } from '../../../utils/Api';
import { CUSTOMER_ATTRIBUTE_ENQUETE } from '../../const/CustomerAttributeTabProperties';
import { colors } from '../../const/Styles';
import QuestionnaireRow from './QuestionnaireRow';

type VisibleProps = {
    shouldVisible: boolean;
};

const Container = styled.div<VisibleProps>`
    display: ${({ shouldVisible }) => (shouldVisible ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;
`;

const ContentsContainer = styled.div`
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.white};
    overflow: auto;
`;

type Question = {
    question: string;
    answer: string;
    key: keyof CustomerEnqueteAnswers;
};

type Props = {
    values: CustomerEnqueteAnswers;
    dispacher: Dispatch<FormReducerAction<CustomerEnqueteAnswers>>;
};

const EnqueteForm: React.FC<Props> = ({ values, dispacher }) => {
    const { selected } = useContext(CustomerAttributeTabContext);
    const { tempoStore } = useStore();
    const answerQuestionMap = useMemo(() => {
        const answerArray = [
            values.question_answer_1 ?? '',
            values.question_answer_2 ?? '',
            values.question_answer_3 ?? '',
            values.question_answer_4 ?? '',
            values.question_answer_5 ?? '',
            values.question_answer_6 ?? '',
            values.question_answer_7 ?? '',
            values.question_answer_8 ?? '',
            values.question_answer_9 ?? '',
            values.question_answer_10 ?? '',
        ];
        const objectKeys = Object.keys(values);
        return tempoStore.enqueteQuestionNames.map<Question>(
            (question, index) => {
                return {
                    question,
                    answer: answerArray[index],
                    key: objectKeys[index] as keyof CustomerEnqueteAnswers,
                };
            },
        );
    }, [tempoStore, values]);

    const handleChange = (
        value: string,
        objectKey: keyof CustomerEnqueteAnswers,
    ) => {
        dispacher({ value: { [objectKey]: value } });
    };
    return (
        <Container shouldVisible={selected === CUSTOMER_ATTRIBUTE_ENQUETE}>
            <ContentsContainer>
                {answerQuestionMap.map(({ question, answer, key }, index) => (
                    <QuestionnaireRow
                        key={key}
                        question={question}
                        answer={answer}
                        index={index + 1}
                        objectKey={key}
                        onChange={handleChange}
                    ></QuestionnaireRow>
                ))}
            </ContentsContainer>
        </Container>
    );
};

export default EnqueteForm;
