import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import Toggler from '../../../assets/projectile-accordion-toggler.svg';
import {
    projectileImagePath,
    ProjectileType,
    ProjectileType1,
    ProjectileType2,
    ProjectileType3,
    ProjectileType4,
    ProjectileType5,
    ProjectileType6,
    ProjectileType7,
    ProjectileType8,
    ProjectileType9,
} from '../../const/Projectile';
import { colors } from '../../const/Styles';
import ProjectileButton from '../input/ProjectileButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.lightGray3};
    border: solid 2px ${colors.lighterGray};
    box-sizing: border-box;
`;

type ToggleProps = {
    isOpen: boolean;
};

const Header = styled.div<ToggleProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px;
    justify-content: center;
    svg {
        width: 23px;
        height: auto;
        transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
    }
`;

const Body = styled.div<ToggleProps>`
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: row;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
`;

const ImageContainer = styled.div`
    display: block;
    width: 40%;
    background-color: ${colors.white};
    padding: 10px;
    margin-right: 20px;
    img {
        width: 100%;
        object-fit: contain;
    }
`;

const ProjectileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;
    height: 100%;
`;

const ProjectileRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    > * {
        &:nth-child(1) {
            margin-right: 10px;
        }
    }
`;

const ConfirmButton = styled(Button)`
    &.MuiButton-root {
        background-color: ${colors.footerIconSelected};
        color: ${colors.black};
        font-weight: bold;
        font-size: 1.2rem;
        width: 100%;
        padding: 15px 0;
    }
`;

type Props = {
    selected?: string | null | undefined;
    onConfirm?: (value: string) => void;
};

const ProjectileImage: React.FC<Props> = ({ selected, onConfirm }) => {
    const [isOpen, setOpen] = useState(false);
    const [projectile, setProjectile] = useState<string | null | undefined>(
        selected,
    );

    useEffect(() => {
        setProjectile(selected);
    }, [selected]);

    const imagePath = useMemo(() => {
        return projectileImagePath((projectile ?? '1型') as ProjectileType);
    }, [projectile]);

    const handleClickHeader = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen, setOpen]);

    const handleClickButton = (value: ProjectileType) => {
        setProjectile(value);
    };

    const handleClickConfirm = useCallback(() => {
        if (onConfirm && projectile) {
            onConfirm(projectile);
        }
    }, [projectile, onConfirm]);

    return (
        <Container>
            <Header isOpen={isOpen} onClick={handleClickHeader}>
                <ReactSVG src={Toggler ?? ''} />
            </Header>
            <Body isOpen={isOpen}>
                <ImageContainer>
                    <img src={imagePath} />
                </ImageContainer>
                <ProjectileContainer>
                    <ProjectileRow>
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType1}
                            onClick={handleClickButton}
                        />
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType6}
                            onClick={handleClickButton}
                        />
                    </ProjectileRow>
                    <ProjectileRow>
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType2}
                            onClick={handleClickButton}
                        />
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType7}
                            onClick={handleClickButton}
                        />
                    </ProjectileRow>
                    <ProjectileRow>
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType3}
                            onClick={handleClickButton}
                        />
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType8}
                            onClick={handleClickButton}
                        />
                    </ProjectileRow>
                    <ProjectileRow>
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType4}
                            onClick={handleClickButton}
                        />
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType9}
                            onClick={handleClickButton}
                        />
                    </ProjectileRow>
                    <ProjectileRow>
                        <ProjectileButton
                            selected={projectile ?? ''}
                            value={ProjectileType5}
                            onClick={handleClickButton}
                        />
                        <ConfirmButton onClick={handleClickConfirm}>
                            確定
                        </ConfirmButton>
                    </ProjectileRow>
                </ProjectileContainer>
            </Body>
        </Container>
    );
};

export default ProjectileImage;
