import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from '../const/Routes';
import CoarchListPage from './list';
import MerchantDetailPage from './detail';
import PlayMoviePage from './PlayMovie';
import RecordMoviePage from './RecordMovie';

const MerchantPages: React.FC = () => {
    return (
        <React.Fragment>
            <Route path={Routes.MERCHANT} exact>
                <CoarchListPage />
            </Route>
            <Route path={Routes.MERCHANT_DETAIL} exact>
                <MerchantDetailPage />
            </Route>
            <Route path={Routes.MERCHANT_SWING} exact>
                <PlayMoviePage />
            </Route>
            <Route path={Routes.MERCHANT_SWING_RECORD} exact>
                <RecordMoviePage />
            </Route>
        </React.Fragment>
    );
};

export default MerchantPages;
