import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import SelectImg from '../../assets/select_img.svg';
const useStyles = makeStyles(() =>
    createStyles({
        hiddenInput: {
            display: 'none',
        },
        imageUploadCard: {
            display: 'flex',
            marginTop: 5,
            position: 'relative',
            width: '20%',
        },
        absoluteLabel: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        profileImage: {
            width: 112,
            height: 112,
        },
        iconbox: {
            height: 10,
            marginLeft: 5,
        },
    }),
);

interface Props {
    image: string;
    setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
    setImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

function CustomerRegisterImageForm(props: Props): React.ReactElement {
    const { image, setImage, setImageFile } = props;
    const classes = useStyles();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const imgFile = event.target.files[0];
        setImageFile(imgFile);
        setImage(window.URL.createObjectURL(event.target.files[0]));
    };

    return (
        <div className={classes.imageUploadCard}>
            <img src={image} className={classes.profileImage} />
            <input
                accept='image/*'
                className={classes.hiddenInput}
                id='profile_image'
                type='file'
                onChange={handleFileChange}
            />
            <label htmlFor='profile_image' className={classes.iconbox}>
                <img src={SelectImg} />
            </label>
        </div>
    );
}

export default CustomerRegisterImageForm;
