import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { MerchantTabContext } from '../../../contexts/MerchantTabContext';
import {
    CURRICULUM,
    COACH_SWING_MOVIE,
    QUESTIONNAIRE,
    COACH_LIST,
    COACH_INFO_DETAIL,
} from '../../const/MerchantProperties';
import SwitchButton from './SwitchButton';
import { colors } from '../../const/Styles';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    background-color: ${colors.lightGray};
`;

type Props = {
    activeColor: string;
    setIsVisibleBackButton: (state: boolean) => void;
};

const MerchantPropertyNavigation: React.FC<Props> = ({
    activeColor,
    setIsVisibleBackButton,
}) => {
    const { selected } = useContext(MerchantTabContext);

    useEffect(() => {
        selected === COACH_INFO_DETAIL
            ? setIsVisibleBackButton(true)
            : setIsVisibleBackButton(false);
    }, [selected, setIsVisibleBackButton]);

    return (
        <Wrapper>
            <SwitchButton
                typeVal={COACH_LIST}
                selected={selected}
                activeColor={activeColor}
                isVisible={true}
            >
                コーチ一覧
            </SwitchButton>
            <SwitchButton
                typeVal={COACH_INFO_DETAIL}
                selected={selected}
                activeColor={activeColor}
                isVisible={false}
            >
                コーチ詳細
            </SwitchButton>
            <SwitchButton
                typeVal={COACH_SWING_MOVIE}
                selected={selected}
                activeColor={activeColor}
                isVisible={true}
            >
                コーチスイング
            </SwitchButton>
            <SwitchButton
                typeVal={CURRICULUM}
                selected={selected}
                activeColor={activeColor}
                isVisible={true}
            >
                カリキュラム
            </SwitchButton>
            <SwitchButton
                typeVal={QUESTIONNAIRE}
                selected={selected}
                activeColor={activeColor}
                isVisible={true}
            >
                アンケート
            </SwitchButton>
        </Wrapper>
    );
};

export default MerchantPropertyNavigation;
