import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.article`
    display: flex;
    flex-direction: column;
`;

const Header = styled.h3`
    display: flex;
    background-color: ${colors.gray};
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    margin: 0;
`;

const Title = styled.label`
    display: block;
    font-weight: bold;
    font-size: 1.3rem;
    color: ${colors.footerIconSelected};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    color: ${colors.white};
    box-sizing: border-box;
    min-height: 30vh;
`;

const IconContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
    }
    svg {
        height: 1.5rem;
        color: ${colors.footerIconSelected};
    }
`;

const IconPrefixText = styled.span`
    margin-right: 10px;
    color: ${colors.footerIconSelected};
    font-size: 1rem;
    font-weight: lighter;
`;

type Props = {
    title: string;
    icon: string;
    iconPrefix?: string;
    onClick?: () => void;
};

const ActionableLessonCard: React.FC<Props> = ({
    title,
    icon,
    iconPrefix,
    onClick,
    children,
}) => {
    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                <IconContainer>
                    <IconPrefixText>{iconPrefix}</IconPrefixText>
                    <ReactSVG
                        src={icon}
                        onClick={onClick}
                        wrapper='span'
                    ></ReactSVG>
                </IconContainer>
            </Header>
            <Content>{children}</Content>
        </Container>
    );
};

ActionableLessonCard.defaultProps = {
    iconPrefix: '',
};

export default ActionableLessonCard;
