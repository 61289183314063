import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { SwingClubs, Club } from '../../const/SwingClub';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
    width: fit-content;
    margin: 40px 0;
    &:after {
        content: '▼';
        font-weight: bold;
        font-size: 26px;
        line-height: 1;
        position: absolute;
        color: ${colors.white};
        right: 1.5rem;
        top: calc((100% - 26px) / 2);
        margin-top: auto;
        margin-bottom: auto;
    }
`;

const StyledSelect = styled.select`
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;
    margin: 0 1rem;
    padding: 0 3rem;
    justify-content: center;
    appearance: none;
    outline: none;
    border: solid 2px ${colors.lighterGray};
    font-size: 26px;
    min-width: 268px;
    background-color: ${colors.gray};
    color: ${colors.white};
    option {
        text-align: center;
    }
`;

type Props = {
    value?: string | null;
    onChange?: (value: Club) => void;
};

const SwingClubSelector: React.FC<Props> = ({ value, onChange }) => {
    const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
            onChange(evt.currentTarget.value as Club);
        }
    };

    return (
        <Container>
            <StyledSelect value={value ?? ''} onChange={handleChange}>
                <option value=''>(クラブ選択)</option>
                {SwingClubs.map((club) => (
                    <option key={club} value={club}>
                        {club}
                    </option>
                ))}
            </StyledSelect>
        </Container>
    );
};

export default SwingClubSelector;
