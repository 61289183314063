import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import Button from '@material-ui/core/Button';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding: 10px 60px;
    justify-content: center;
`;

const CloseButton = styled(Button)`
    outline: none;
    border: none;
    padding: 4px;
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    font-weight: bold;
    font-size: 1.2rem;
    width: 100%;
    border-radius: 4px;
`;

type Props = {
    onClick?: () => void;
    isEnabled: boolean;
};

const TodayLessonCloser: React.FC<Props> = ({ onClick, isEnabled }) => {
    return (
        <Container>
            <CloseButton onClick={onClick} disabled={isEnabled === false}>
                レッスンを終了する
            </CloseButton>
        </Container>
    );
};

export default TodayLessonCloser;
