import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import dateFormat from 'date-fns/format';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import MovieEntryBtn from '../../assets/movie_entry.svg';
import moviesamplebtn from '../../assets/swingicon.png';
import { StaffDisplay } from '../../store/TempoStore';
import { MovieDisplay } from '../../utils/Video';
import { Routes } from '../const/Routes';
import { colors } from '../const/Styles';

type CoarchMovieListType = {
    isOpen: boolean;
};
const CoarchMovieList = styled.div<CoarchMovieListType>`
    display: ${({ isOpen }) => (isOpen === true ? 'div' : 'none')};
    flexw-wap: 'wrap';
    background-color: ${colors.lightGray3};
    paddingbottom: 15px;
`;

const MovieInfo = styled.div`
    display flex;
    flex-direction: column;
    z-index: 3;
    background-color: ${colors.black}B3;
    color: ${colors.white};
    position: absolute;
    top: 0;
    left: 25;
    padding: 4px;
    box-sizing: border-box;
    font-size: 0.7rem;
    width: 100px;
`;

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            flexWrap: 'wrap',
            backgroundColor: `${colors.lightGray3}`,
            width: 664,
            paddingBottom: 15,
        },
        title: {
            color: theme.palette.secondary.light,
        },
        thumbnail: {
            width: 100,
            height: 150,
        },
        selectedVideo: {
            outline: '5px solid blue',
            outlineOffset: '-5px',
        },
        playButtonBox: {
            width: '100%',
            height: '100%',
            padding: 0,
        },
        entryBtn: {
            width: 150,
            height: 150,
            marginRight: 5,
            marginLeft: 15,
            border: `1px solid ${colors.lightGray4}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        movieBox: {
            position: 'relative',
            width: 150,
            height: 150,
            marginRight: 5,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'center',
        },
        movieDetail: {
            position: 'absolute',
            top: 0,
            left: 25,
            height: 50,
            width: 100,
            fontSize: 10,
        },
        wrapMovieBox: {
            width: 664,
            display: 'flex',
            flexWrap: 'wrap',
        },
        favBtn: {
            position: 'absolute',
            top: -14,
            right: 0,
            color: `${colors.white}`,
            width: 30,
            height: 30,
        },
        createdAt: {
            position: 'relative',
            height: 20,
            display: 'flex',
            justifyContent: 'space-between',
        },
    }),
);

interface Props {
    videos: MovieDisplay[];
    selectedVideo: string | null;
    isOpen: boolean;
    coach: StaffDisplay;
}

function CoachModalVideoList(props: Props): React.ReactElement {
    const { videos, selectedVideo, isOpen, coach } = props;
    const classes = useStyles();
    const history = useHistory();

    const handleClickVideo = useCallback(
        (video: MovieDisplay) => {
            history.push(Routes.MERCHANT_SWING, video);
        },
        [history],
    );

    const handleRecordMovie = useCallback(() => {
        history.push(Routes.MERCHANT_SWING_RECORD, { ...coach });
    }, [history, coach]);

    return (
        <CoarchMovieList isOpen={isOpen}>
            <div className={classes.wrapMovieBox}>
                <div className={classes.entryBtn}>
                    <img
                        onClick={handleRecordMovie}
                        src={MovieEntryBtn}
                        alt=''
                    />
                </div>

                {videos.map(
                    (video) =>
                        video.src && (
                            <div
                                className={classes.movieBox}
                                id={video.id}
                                key={video.id}
                            >
                                {video.thumbnail ? (
                                    <img
                                        className={clsx(
                                            classes.thumbnail,
                                            selectedVideo === video.src &&
                                                classes.selectedVideo,
                                        )}
                                        src={video.thumbnail}
                                        onClick={() => handleClickVideo(video)}
                                    />
                                ) : (
                                    <a href={video.thumbnail}>
                                        <img
                                            className={clsx(
                                                classes.thumbnail,
                                                selectedVideo === video.src &&
                                                    classes.selectedVideo,
                                            )}
                                            src={moviesamplebtn}
                                            onClick={() =>
                                                handleClickVideo(video)
                                            }
                                        />
                                    </a>
                                )}
                                <MovieInfo>
                                    <div>{video.club ? video.club : '---'}</div>
                                    <div>
                                        {video.yards ? video.yards : '---'} y
                                    </div>
                                    <div className={classes.createdAt}>
                                        {video.created_at
                                            ? dateFormat(
                                                  new Date(video.created_at),
                                                  'yyyy/MM/dd',
                                              )
                                            : '----'}
                                    </div>
                                </MovieInfo>
                            </div>
                        ),
                )}
            </div>
        </CoarchMovieList>
    );
}

export default CoachModalVideoList;
