import React from 'react';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import { border, colors } from '../../const/Styles';
import CustomerImage from './CustomerImage';

const Wrapper = styled.article`
    display: flex;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    height: 150px;
`;

const NameContainer = styled.div`
    display flex;
    flex-direction: column;
    z-index: 3;
    background-color: ${colors.black};
    opacity: 0.7;
    position: absolute;;
    width: 100%;
    bottom: 0;
    left: 0;
`;

const NameLabel = styled.label`
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    color: ${colors.white};
    text-align: center;
`;

const KanaLabel = styled(NameLabel)`
    font-size: 0.4rem;
`;

type Props = {
    customer: CustomerDisplay;
    onSelect: (customer: CustomerDisplay) => void;
};

const CustomerSelectableCard: React.FC<Props> = ({ customer, onSelect }) => {
    const handleSelect = () => {
        onSelect(customer);
    };

    return (
        <Wrapper onClick={handleSelect}>
            <CustomerImage
                color={border.none}
                src={customer?.s3Path}
            ></CustomerImage>
            <NameContainer>
                <NameLabel>
                    {customer.name_sei} {customer.name_mei}
                </NameLabel>
                <KanaLabel>
                    {customer?.name_sei_kana} {customer?.name_mei_kana}
                </KanaLabel>
            </NameContainer>
        </Wrapper>
    );
};

export default CustomerSelectableCard;
