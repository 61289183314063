import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { MerchantTabContext } from '../../../contexts/MerchantTabContext';
import {
    MerchantPropertyType,
    COACH_LIST,
} from '../../const/MerchantProperties';
import { colors } from '../../const/Styles';
const Radio = styled.input`
    display: none;
`;

type ButtonLikeProps = {
    activeColor: string;
};
const ButtonLike = styled.label<ButtonLikeProps>`
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    margin: 0 5px;
    color: ${colors.white};
    background-color: ${colors.gray};
    border: solid 4px ${colors.footerIconUnSelected};
    font-size: 1rem;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    &.active {
        font-weight: bold;
        background-color: ${({ activeColor }) => activeColor};
        border-color: ${({ activeColor }) => activeColor};
    }
    width: 100%;
`;
const ButtonLikeHidden = styled.label<ButtonLikeProps>`
    display: none;
    flex-direction: row;
    flex-grow: 2;
    margin: 0 5px;
    color: ${colors.white};
    background-color: ${colors.gray};
    border: solid 4px ${colors.footerIconUnSelected};
    font-size: 1rem;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    &.active {
        font-weight: bold;
        background-color: ${({ activeColor }) => activeColor};
        border-color: ${({ activeColor }) => activeColor};
    }
    width: 100%;
`;

type Props = {
    typeVal: MerchantPropertyType;
    selected?: MerchantPropertyType;
    activeColor: string;
    isVisible?: boolean;
};
const LessonPropertyItem: React.FC<Props> = ({
    typeVal,
    selected = COACH_LIST,
    activeColor,
    children,
    isVisible,
}) => {
    const activeClass = useMemo(() => {
        return typeVal === selected ? 'active' : '';
    }, [typeVal, selected]);

    const { onChange } = useContext(MerchantTabContext);
    const handleChange = () => {
        if (onChange) {
            onChange(typeVal);
        }
    };

    if (isVisible === false) {
        return (
            <ButtonLikeHidden
                activeColor={activeColor}
                className={activeClass}
                onClick={handleChange}
            >
                <Radio type='radio' value={0} />
                {children}
            </ButtonLikeHidden>
        );
    }

    return (
        <ButtonLike
            activeColor={activeColor}
            className={activeClass}
            onClick={handleChange}
        >
            <Radio type='radio' value={0} />
            {children}
        </ButtonLike>
    );
};

export default LessonPropertyItem;
