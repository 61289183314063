import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { LessonPropertyTabContext } from '../../../contexts/LessonPropertyTabContext';
import { LessonPropertyType, TODAY_LESSON } from '../../const/LessonProperties';
import { colors } from '../../const/Styles';
const Radio = styled.input`
    display: none;
`;

type ButtonLikeProps = {
    activeColor: string;
};
const ButtonLike = styled.label<ButtonLikeProps>`
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    margin: 0 5px;
    color: ${colors.white};
    background-color: ${colors.black};
    border: solid 2px ${colors.gray};
    font-size: 1rem;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    &.active {
        font-weight: bold;
        background-color: ${({ activeColor }) => activeColor};
        border-color: ${({ activeColor }) => activeColor};
    }
    width: 100%;
`;

type Props = {
    typeVal: LessonPropertyType;
    selected?: LessonPropertyType;
    activeColor: string;
};
const LessonPropertyItem: React.FC<Props> = ({
    typeVal,
    selected = TODAY_LESSON,
    activeColor,
    children,
}) => {
    const activeClass = useMemo(() => {
        return typeVal === selected ? 'active' : '';
    }, [typeVal, selected]);

    const { onChange } = useContext(LessonPropertyTabContext);
    const handleChange = () => {
        if (onChange) {
            onChange(typeVal);
        }
    };

    return (
        <ButtonLike
            activeColor={activeColor}
            className={activeClass}
            onClick={handleChange}
        >
            <Radio type='radio' value={0} />
            {children}
        </ButtonLike>
    );
};

export default LessonPropertyItem;
