import React, { ChangeEvent } from 'react';
import { colors } from '../../const/Styles';
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import makeStyles from '@material-ui/styles/makeStyles'
import createStyles from '@material-ui/styles/createStyles'


const useStyles = makeStyles(() =>
    createStyles({
        checkColor: {
            '&.Mui-checked': {
                color: `${colors.footerIconSelected}`,
            },
        },
    }),
);

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const QuestionnaireOptions: React.FC<Props> = ({ value, onChange }) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    }

    const classes = useStyles();
    return (
        <FormControl>
            <RadioGroup
                aria-label='position'
                name='position'
                defaultValue=''
                value={value}
                onChange={handleChange}
                row={true}
            >
                <FormControlLabel
                    label='当てはまる'
                    value='当てはまる'
                    control={<Radio className={classes.checkColor} />}
                />
                <FormControlLabel
                    label='やや当てはまる'
                    value='やや当てはまる'
                    control={<Radio className={classes.checkColor} />}
                />
                <FormControlLabel
                    label='当てはまらない'
                    value='当てはまらない'
                    control={<Radio className={classes.checkColor} />}
                />
            </RadioGroup>
        </FormControl>
    );
}

export default QuestionnaireOptions;
