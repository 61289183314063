import { Dispatch, Reducer, useReducer } from 'react';

export type FormReducerAction<T> = {
    value: Partial<T>;
};

export function useForm<T>(init: T): [T, Dispatch<FormReducerAction<T>>] {
    return useReducer<Reducer<T, FormReducerAction<T>>>(
        (state, action: FormReducerAction<T>) => {
            return { ...state, ...action.value };
        },
        init,
    );
}
