import React, { useContext } from 'react';
import styled from 'styled-components';
import { CustomerRegisterPropertyTabContext } from '../../../contexts/CustomerRegisterPropertyTabContext';
import {
    CUSTOMER_REGIST_BASIC_PROFILE,
    CUSTOMER_REGIST_DETAIL_PROFILE,
    CUSTOMER_REGIST_QUESTIONNAIRE,
} from '../../const/RegisterProperties';
import RegisterPropertyItem from './RegisterPropertyItem';
import { colors } from '../../const/Styles';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    background-color: ${colors.lightGray3};
`;

type Props = {
    activeColor: string;
};
const RegisterPropertyNavigation: React.FC<Props> = ({ activeColor }) => {
    const { selected } = useContext(CustomerRegisterPropertyTabContext);
    return (
        <Wrapper>
            <RegisterPropertyItem
                typeVal={CUSTOMER_REGIST_BASIC_PROFILE}
                selected={selected}
                activeColor={activeColor}
            >
                基本情報
            </RegisterPropertyItem>

            <RegisterPropertyItem
                typeVal={CUSTOMER_REGIST_DETAIL_PROFILE}
                selected={selected}
                activeColor={activeColor}
            >
                詳細情報
            </RegisterPropertyItem>

            <RegisterPropertyItem
                typeVal={CUSTOMER_REGIST_QUESTIONNAIRE}
                selected={selected}
                activeColor={activeColor}
            >
                アンケート
            </RegisterPropertyItem>
        </Wrapper>
    );
};

export default RegisterPropertyNavigation;
