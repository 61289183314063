import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MovieDisplay } from '../../../utils/Video';
import { Routes } from '../../const/Routes';
import MovieThumbnail from '../display/MovieThumbnail';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 10px;
`;

type Props = {
    movies: MovieDisplay[];
};
const MovieThumbnails: React.FC<Props> = ({ movies }) => {
    const history = useHistory();
    const handleClickThumbnail = (movie: MovieDisplay) => {
        const index = movies.findIndex((p) => p.id === movie.id);
        if (index > -1) {
            history.push(Routes.LESSON_PAST_SWING, {
                movie: movie,
                movieIndex: index + 1,
            });
        }
    };
    return (
        <Container>
            {movies.map((movie) => (
                <MovieThumbnail
                    key={movie.id}
                    movie={movie}
                    onClick={handleClickThumbnail}
                />
            ))}
        </Container>
    );
};

export default MovieThumbnails;
