import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';

// TODO: class-validatorのi18nを導入すべき
export const showErrorMessageGeneral = (
    props: Array<string | undefined>,
    id: string,
): React.ReactElement => {
    if (
        props.includes(id + ' should not be empty') ||
        props.includes(id + ' must be a string')
    ) {
        return (
            <p style={{ color: '#d50000', textAlign: 'center' }}>
                入力してください
            </p>
        );
    }
    if (props.includes(id + ' must be a boolean value')) {
        // booleanの値をユーザ目線で選択できるか不明なため下記文言にする
        return (
            <p style={{ color: '#d50000', textAlign: 'center' }}>
                もう一度入力してください
            </p>
        );
    }
    return <></>;
};

// TODO: class-validatorのi18nを導入すべき
export const showErrorMessageGeneralTwo = (
    props: Array<string | undefined>,
    id1: string,
    id2: string,
): React.ReactElement => {
    if (
        props.includes(id1 + ' should not be empty') ||
        props.includes(id2 + ' should not be empty')
    ) {
        return (
            <p style={{ color: '#d50000', textAlign: 'center' }}>
                入力してください
            </p>
        );
    }
    return <></>;
};

// TODO: class-validatorのi18nを導入すべき
export const showErrorMessageSelect = (
    props: Array<string | undefined>,
    id: string,
): React.ReactElement => {
    if (
        props.includes(id + ' should not be empty') ||
        props.includes(id + ' must be a string')
    ) {
        return (
            <p style={{ color: '#d50000', textAlign: 'center' }}>
                選択してください
            </p>
        );
    }

    return <></>;
};

export const showErrorMessageProfilePicture = (
    props: Array<string | undefined>,
    id: string,
): React.ReactElement => {
    if (
        props.includes(id + ' should not be empty') ||
        props.includes(id + ' must be a string')
    ) {
        return <p style={{ color: '#d50000' }}>選択してください</p>;
    }

    return <></>;
};

export function useCols(): number {
    const theme = useTheme<Theme>();
    const matches = {
        xl: useMediaQuery(theme.breakpoints.up('xl')),
        lg: useMediaQuery(theme.breakpoints.up('lg')),
        md: useMediaQuery(theme.breakpoints.up('md')),
        sm: useMediaQuery(theme.breakpoints.up('sm')),
        xs: useMediaQuery(theme.breakpoints.up('xs')),
    };
    return matches.md ? 4 : matches.sm ? 3 : matches.xs ? 2 : 1;
}

export function addToMultiMap<K, V>(
    multiMap: Map<K, V[]>,
    key: K,
    valueToAdd: V,
): void {
    let group = multiMap.get(key);
    if (group === undefined) {
        group = [];
        multiMap.set(key, group);
    }
    group.push(valueToAdd);
}

export function groupAsMultiMapBy<K, V>(
    values: V[],
    getKey: (value: V) => K,
): Map<K, V[]> {
    const map = new Map<K, V[]>();
    for (const value of values) {
        addToMultiMap(map, getKey(value), value);
    }
    return map;
}

export function isNotNull<T>(x: T): x is T extends null ? never : T {
    return x !== null;
}

export function isNotUndefined<T>(x: T): x is T extends undefined ? never : T {
    return x !== undefined;
}

export function isNotEmptyString(source: unknown): boolean {
    return typeof source === 'string' && source.length > 0;
}

/**
 * [0, length) の区間の整数を返す。
 * @param length 区間の長さ。負の値・非整数でもよい。
 * length=+Infinityでは例外 RangeError: Invalid array length が発生する。
 */
export function range(length: number): number[];
/**
 * [start, end) の区間の整数を返す。
 * @param start 区間の下限。非整数の場合、切り上げられる。
 * @param end 区間の上限。非整数の場合、切り上げられる。
 *
 */
export function range(start: number, end: number): number[];
export function range(first: number, second?: number): number[] {
    const [start, end] =
        typeof second === 'undefined'
            ? [0, Math.ceil(first)]
            : [Math.ceil(first), Math.ceil(second)];
    const length = end - start;
    return Array.from({ length }, (v, k) => k + start);
}

export function getVideoFormat(): string {
    if (navigator.userAgent.indexOf('Chrome') >= 0) {
        return 'video/webm';
    } else {
        return 'video/mp4';
    }
}

// customer詳細情報でゴルフ歴やスポーツ歴の表示を以下のようにする
// 2021年10月時点ではどうちさんと以下の表示をするよう同意ずみだが後から変更の可能性あり
// 1年未満，1年, 2年, ...., 10年, 11年以上
export function convertYearsToYearsString(later: Date, older: Date): string {
    const yearsDiff = later.getFullYear() - older.getFullYear();

    if (yearsDiff < 1) {
        return '1年未満';
    } else {
        return `${yearsDiff}年`;
    }
}

/**
 * 年数の差分取得
 * MEMO: そもそも差分をDBで持てばいい部分なので止めたい
 * @param source
 * @returns {string | undefined}
 */
export function extractDiffYears(source?: string): string {
    if (!source) {
        return '';
    }
    try {
        const date = new Date(source);
        const currentDate = new Date();
        return (currentDate.getFullYear() - date.getFullYear()).toString();
    } catch (e) {
        return '';
    }
}
