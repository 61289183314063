import React from 'react';
import RecordingVideo from './RecordingVideo';

function Recording(): React.ReactElement {
    return (
        <React.Fragment>
            <RecordingVideo />
        </React.Fragment>
    );
}

export default Recording;
