import React from 'react';
import makeStyle from '@material-ui/styles/makeStyles'
import createStyle from '@material-ui/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { colors } from '../../const/Styles';
import dateFormat from 'date-fns/format';

const useStyles = makeStyle(() => createStyle({
    infoSet_license: {
        width: '90%',
        marginTop: 20,
        margin: 'auto',
    },
    gridItem_license: {
        width: 528,
        height: 32,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.black}`,
    },
}));
type Props = {
    name: string | null;
    date: string | null
}

const CertificationDisplay: React.FC<Props> = ({ name, date }) => {
    const classes = useStyles();
    if (!name || !date) {
        return <></>;
    }
    return (
        <Grid
            container
            className={classes.infoSet_license}
        >
            <Grid
                item
                className={classes.gridItem_license}
            >
                <Typography>
                    {name}
                </Typography>
                <Typography>
                    {dateFormat(
                        new Date(date),
                        'y年M月d日',
                    )}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CertificationDisplay;
