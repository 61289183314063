import React from 'react';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import CreateStaffForm from '../CreateStaffForm';

const StyledModal = styled(Modal)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    box-sizing: border-box;
    background-color: blue;
    overflow-y: auto;
    overflow-x: hidden;
`;

type Props = {
    isOpended: boolean;
    onClose: () => void;
};

const MerchantTargetModal: React.FC<Props> = ({ isOpended, onClose }) => {
    return (
        <StyledModal open={isOpended} onClose={onClose}>
            <Content>
                <CreateStaffForm isOpended={isOpended} onClose={onClose} />
            </Content>
        </StyledModal>
    );
};

export default observer(MerchantTargetModal);
