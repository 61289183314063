import { useState, useCallback } from 'react';

export interface Values {
    x: number;
    y: number;
    display: boolean;
}

export interface Actions {
    onMouseMove: (x: number, y: number) => void;
    onMouseUp: () => void;
    stamp: (x: number, y: number) => void;
    reset: () => void;
}

const useEditorGuidStamp = (): [Values, Actions] => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [moving, setMoving] = useState(false);

    const [display, setDisplay] = useState(false);
    const stamp = useCallback((x: number, y: number) => {
        setX(x);
        setY(y);
        setDisplay(true);
        setMoving(true);
    }, []);
    const reset = useCallback(() => {
        setDisplay(false);
    }, []);
    const onMouseUp = useCallback(() => {
        setMoving(false);
    }, []);
    const onMouseMove = useCallback(
        (x, y) => {
            if (moving) {
                setX(x);
                setY(y);
            }
        },
        [moving],
    );

    const values = {
        x,
        y,
        display,
    };
    const actions = { onMouseMove, onMouseUp, stamp, reset };

    return [values, actions];
};

export default useEditorGuidStamp;
