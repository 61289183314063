import { EnqueteQuestionEntity } from "../components/@types/mysqlEntities";
import { EnqueteItemEntity } from "../utils/Api";

export class EnqueteItemFactory {

    public static createEnqueteItemEntity(datas: EnqueteQuestionEntity[]): EnqueteItemEntity {
        return {
            id: datas.map(data => data.id).join('_'),
            question_name_1: datas[0]?.name || '',
            question_name_2: datas[1]?.name || '',
            question_name_3: datas[2]?.name || '',
            question_name_4: datas[3]?.name || '',
            question_name_5: datas[4]?.name || '',
            question_name_6: datas[5]?.name || '',
            question_name_7: datas[6]?.name || '',
            question_name_8: datas[7]?.name || '',
            question_name_9: datas[8]?.name || '',
            question_name_10: datas[9]?.name || '',
        }
    }

}
