import React, { HTMLAttributes } from 'react';

export const CrownIcon = React.forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((
    { className, ...props },
    ref,
) => {
    return (
        <svg version="1.1" id="_x32_"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink='http://www.w3.org/1999/xlink'
            xmlSpace='preserve'
            x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" className={className}
            {...props}
            ref={ref}>
            <g>
                <path fill='currentColor' d="M61.188,116.688c0,16.891-13.703,30.594-30.594,30.594C13.688,147.281,0,133.578,0,116.688 s13.688-30.594,30.594-30.594C47.484,86.094,61.188,99.797,61.188,116.688z"></path>
                <path fill='currentColor' d="M512,116.688c0,16.891-13.688,30.594-30.594,30.594c-16.891,0-30.594-13.703-30.594-30.594 s13.703-30.594,30.594-30.594C498.313,86.094,512,99.797,512,116.688z"></path>
                <path fill='currentColor' d="M286.594,65.438c0,16.891-13.703,30.578-30.594,30.578s-30.594-13.688-30.594-30.578 c0-16.906,13.703-30.594,30.594-30.594S286.594,48.531,286.594,65.438z" ></path>
                <polygon fill='currentColor' points="435.359,381.156 462.094,174.656 353.109,251.672 256,123.391 158.891,251.672 49.906,174.656 76.641,381.156"></polygon>
                <polygon fill='currentColor' points="80.781,413.156 89.063,477.156 256,477.156 422.938,477.156 431.219,413.156"></polygon>
            </g>
        </svg>

    )
});
