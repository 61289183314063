import { makeAutoObservable, runInAction } from 'mobx';
import { EnqueteQuestionEntity } from '../components/@types/mysqlEntities';
import { EnqueteItemFactory } from '../factories/EnqueteItemFactory';

import { StaffEntity } from '../components/@types/mysqlEntities';
import Api, {
    EnqueteItemEntity,
    LessonMasterEntity,
    StaffFavResponse,
} from '../utils/Api';
import { MovieFav } from '../utils/Video';
import AuthStore from './AuthStore';

export interface StaffDisplay extends StaffEntity {
    s3Path: string;
}

class TempoStore {
    constructor() {
        makeAutoObservable<TempoStore>(this);
    }

    isAllStaffsLoaded = false;
    allStaffs: StaffDisplay[] = [];
    includeDeletedStaffs: StaffDisplay[] = [];

    appendStaff(staffProfile: StaffDisplay): void {
        this.allStaffs.push(staffProfile);
    }

    async fetchAllStaffs(): Promise<void> {
        const staffProfileImageFetcher = async (staff: StaffEntity): Promise<StaffDisplay> => {
            // ticket:256で改修予定
            // 場当たり的対応 ラムダの画像縮小処理が終わっておらずprofile_picture_filepathというattributeが存在しないstaffに対しtも
            // presigenurlfordownloadをたたきに行ってしまっていたので急遽if文で対応
            // staff新規登録時に/settingに画面遷移した際にprofile_picture_filepathというattributeが存在しない(undefined)のケースがありうる
            let presignedUrl;
            if (staff.profile_picture_filepath) {
                presignedUrl =
                    await Api.getS3SignedUrlForDownloadStaffPicture(
                        staff.profile_picture_filepath,
                    );
            } else {
                presignedUrl = {
                    presignedUrl: `${process.env.PUBLIC_URL}/sample_account.png`,
                };
            }
            return { ...staff, s3Path: presignedUrl.presignedUrl };
        };
        const activeStaffs = await Api.fetchStaffs(AuthStore.tempoId, false);
        const allStaffs = await Promise.all(activeStaffs.map(staffProfileImageFetcher));

        const includeInActiveStaffs = await Api.fetchStaffs(AuthStore.tempoId, true);
        const includeDeletedStaffs = await Promise.all(includeInActiveStaffs.map(staffProfileImageFetcher));

        runInAction(() => {
            this.allStaffs = allStaffs;
            this.includeDeletedStaffs = includeDeletedStaffs;
            this.isAllStaffsLoaded = true;
        });

        const defaultCoach = this.allStaffs.find((staff) => {
            return staff.type == 'coach';
        });
        if (defaultCoach) {
            this.selectCoach(defaultCoach.id);
        }
    }

    get allCoaches(): StaffDisplay[] {
        return this.allStaffs.filter((staff) => {
            return staff.type == 'coach' && staff.is_active == true;
        });
    }

    selectedCoach: StaffDisplay | undefined = this.allStaffs[0];
    selectCoach(coachId: number): void {
        const selected = this.allCoaches.find((coach) => {
            return coach.id === coachId;
        });
        if (selected) {
            this.selectedCoach = selected;
        }
    }

    favs: MovieFav[] = [];
    isFavsLoaded = false;
    loadFavoriteMovies = async (customerId: number) => {
        this.isFavsLoaded = false;
        const response: StaffFavResponse[] =
            (await Api.fetchFavMovies(customerId)) ?? [];
        runInAction(() => {
            const receives = response.map((fav) => new MovieFav(fav));
            this.favs.splice(0, this.favoriteMovies.length, ...receives);
            this.isFavsLoaded = true;
        });
    };

    reloadFavoriteMovies = async (customerId: number) => {
        // MEMO: お気に入り後に最新のデータを取得するための関数
        // お気入り後に所定のレスポンスを返せるようになればこちらは不要
        const response = await Api.fetchFavMovies(customerId);
        runInAction(() => {
            const receives = response.map((fav) => new MovieFav(fav));
            this.favs.splice(0, this.favoriteMovies.length, ...receives);
        });
    };

    get favoriteMovies(): MovieFav[] {
        return this.favs;
    }

    resolveUserName(customerOrStaffId: number): string[] | undefined {
        const coach = this.allCoaches.find((p) => p.id === customerOrStaffId);
        if (coach) {
            return [coach.name_sei, coach.name_mei];
        }
        const staff = this.allStaffs.find((p) => p.id === customerOrStaffId);
        if (staff) {
            return [staff.name_sei, staff.name_mei];
        }
    }

    isAllLessonsLoaded = false;
    allLessons: LessonMasterEntity[] = [];
    async fetchAllLessons(): Promise<LessonMasterEntity[]> {
        const response = await Api.fetchLessons(AuthStore.tempoId);
        runInAction(() => {
            this.allLessons = response;
            this.isAllLessonsLoaded = true;
        });
        return response;
    }

    getLesson(id: number): LessonMasterEntity | null {
        const lesson = this.allLessons.find((lesson) => {
            return lesson.id == id;
        });
        return lesson || null;
    }

    addLesson(data: LessonMasterEntity): void {
        this.allLessons.push(data);
    }

    async deleteLesson(lessonMasterId: number): Promise<void> {
        await Api.deleteLessonCourse(lessonMasterId);
        await this.fetchAllLessons();
    }

    isEnqueteItemsLoaded = false;
    enqueteItems?: EnqueteItemEntity;
    get enqueteQuestionNames(): string[] {
        return this.enqueteItems
            ? [
                this.enqueteItems.question_name_1,
                this.enqueteItems.question_name_2,
                this.enqueteItems.question_name_3,
                this.enqueteItems.question_name_4,
                this.enqueteItems.question_name_5,
                this.enqueteItems.question_name_6,
                this.enqueteItems.question_name_7,
                this.enqueteItems.question_name_8,
                this.enqueteItems.question_name_9,
                this.enqueteItems.question_name_10,
            ]
            : [];
    }

    async fetchEnqueteItems(): Promise<EnqueteQuestionEntity[]> {
        const response = await Api.fetchEnqueteItems(AuthStore.tempoId);
        runInAction(() => {
            this.enqueteItems =
                EnqueteItemFactory.createEnqueteItemEntity(response);
            this.isEnqueteItemsLoaded = true;
        });
        return response;
    }
}

export default new TempoStore();
