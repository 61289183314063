import React, { ChangeEvent, useCallback, useRef } from 'react';
import styled from 'styled-components';
import IconImage from '../../../assets/moviechoice.png';

const Wrapper = styled.i`
    display: flex;
    flex-direction: column;
    padding: 0 12px;
`;

const Icon = styled.img`
    cursor: pointer;
    height: 80px;
    width: auto;
`;

const StyledInput = styled.input`
    display: none;
`

type Props = {
    className?: string
    onChange: (data: Blob) => void
}

const LocalMovieSelectButton: React.FC<Props> = ({
    className,
    onChange
}) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const handleIconClick = () => {
        inputRef.current?.click();
    }

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.currentTarget;
        if (!files) {
            return;
        }

        const file = files.item(0);
        if (!file) {
            return
        }

        if (!/^video\/.*/.test(file.type)) {
            window.alert('動画ファイルを選択してください');
        }

        onChange(file)
    }, [onChange]);

    return (
        <Wrapper className={className}>
            <Icon src={IconImage} onClick={handleIconClick} />
            <StyledInput type='file' accept='video/*' onChange={handleChange} ref={inputRef} />
        </Wrapper>
    )
}

export default LocalMovieSelectButton;
