import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import { colors } from '../../const/Styles';

type WrapperProps = {
    padding?: string;
    active: boolean;
};

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({ padding }) => padding ?? '10px'} 0;
    justify-content: center;
    color: ${({ active }) => {
        return active === true
            ? colors.footerIconSelected
            : colors.footerIconUnSelected;
    }};
    border-bottom: solid 2px ${colors.gray};
    flex-grow: 1;
`;

const FilterConstraint = styled.span`
    font-size: 1.5rem;
    margin-right: 20px;
`;

const CustomerCount = styled.span`
    font-size: 1rem;
`;

type Props = {
    customers: CustomerDisplay[];
    filterName: string;
    onClick: (filterName: string) => void;
    isActive: boolean;
    padding?: string;
};

const CustomerNameFilterCell: React.FC<Props> = ({
    customers,
    filterName,
    onClick,
    isActive,
    padding,
    children,
}) => {
    const handleClick = useCallback(() => {
        onClick(filterName);
    }, [filterName, onClick]);

    return (
        <Wrapper onClick={handleClick} active={isActive} padding={padding}>
            <FilterConstraint>{children}</FilterConstraint>
            <CustomerCount>{customers.length}</CustomerCount>
        </Wrapper>
    );
};

export default CustomerNameFilterCell;
