import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Modal, TextareaAutosize } from '@material-ui/core';
import { colors } from '../../const/Styles';
import { observer } from 'mobx-react-lite';
import { LessonDisplay } from '../../../utils/Lesson';

const StyledModal = styled(Modal)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const StyledTextField = styled(TextareaAutosize)`
    background-color: ${colors.white};
    color: ${colors.black};
    border: solid 2px ${colors.lightGray};
    font-size: 1.1rem;
    padding: 1rem;
`;

const Container = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    gap: 50px;
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 40px 20px;
    border: solid 4px ${colors.white};
    border-radius: 4px;
    background-color: ${colors.black};
    overflow-y: auto;
`;

const Header = styled.h1`
    margin: 0;
    font-weight: bold;
    color: ${colors.footerIconSelected};
    font-size: 2.5rem;
`;

const Footer = styled(Button)`
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    margin: 0 100px;
    font-size: 1.2rem;
    font-weight: bold;
`;

type Props = {
    isVisibleModal: boolean;
    onClose: () => void;
    onSubmit: (text: string) => void;
    lessonAchievement: LessonDisplay | undefined;
};

const PastLessonCommentModal: React.FC<Props> = ({
    isVisibleModal,
    onClose,
    onSubmit,
    lessonAchievement,
}) => {
    const [text, setText] = useState('');
    useEffect(() => {
        setText(lessonAchievement?.comment ?? '');
    }, [lessonAchievement]);

    const handleInput = useCallback((evt: FormEvent<HTMLTextAreaElement>) => {
        setText(evt.currentTarget.value.trimStart());
    }, []);
    const handleSubmit = () => {
        onSubmit(text);
        onClose();
    };

    return (
        <StyledModal open={isVisibleModal} onClose={onClose}>
            <Container>
                <Header>レッスンコメント修正</Header>
                <StyledTextField rows={20} value={text} onInput={handleInput} />
                <Footer onClick={handleSubmit} disabled={text.length === 0}>
                    登録
                </Footer>
            </Container>
        </StyledModal>
    );
};

export default observer(PastLessonCommentModal);
