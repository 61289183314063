import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

import { useHistory } from 'react-router-dom';
import BackIcon from '../../../assets/goback.svg';
import WindowIcon from '../../../assets/windows.svg';
import { Routes } from '../../const/Routes';

const Container = styled.section`
    display: flex;
    flex-redirection: row;
    width: 100%;
    border-top: 3px solid ${colors.lightGray};
    padding: 28px 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${colors.black};
    position: relative;
`;

const Title = styled.h2`
    margin: 0;
    line-height: 1;
    font-size: 35px;
    font-weight: bold;
    color: ${colors.footerIconSelected};
    text-align: center;
`;

const IconRight = styled.i`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    height: 100%;
    padding-right: 70px;
    color: ${colors.footerIconSelected};
    font-size: 14px;
    font-style: normal;
    svg {
        width: 48px;
        height: 48px;
    }
`;

const IconLeft = styled(IconRight)`
    top: 0;
    left: 0;
    padding-left: 70px;
    padding-right: 0;
`;

type Props = {
    customerId: string;
};
const PlayerHeaderNavigation: React.FC<Props> = ({ customerId, children }) => {
    const history = useHistory();
    const handleClickGoSwingList = () => {
        history.push(Routes.SWING_LIST, {
            customerId: customerId,
            from: Routes.LESSON_PAST_SWING,
        });
    };
    const handleClickBackIcon = () => {
        history.goBack();
    };
    return (
        <Container>
            <IconLeft onClick={handleClickGoSwingList}>
                <ReactSVG src={WindowIcon ?? ''} />
                スイング一覧
            </IconLeft>
            <Title>{children}</Title>
            <IconRight onClick={handleClickBackIcon}>
                <ReactSVG src={BackIcon ?? ''} />
                本日のレッスンに戻る
            </IconRight>
        </Container>
    );
};

export default PlayerHeaderNavigation;
