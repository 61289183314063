import { CircularProgress } from '@material-ui/core';
import React, { Fragment, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import { CustomerTabPropertyTabContext } from '../../../contexts/CustomerTabContext';
import Api from '../../../utils/Api';
import DisplayExperienceYear from '../../base/surface/DisplayExperienceYear';
import { CUSTOMER_TAB_DETAIL_PROFILE } from '../../const/CustomerTabProperties';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import CustomerFeatureCard from '../surface/CustomerFeatureCard';
import FeatureDescription from '../surface/FeatureDescription';
import ProfileEditNavigationButton from '../surface/ProfileEditNavigationButton';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'grid' : 'none')};
    grid-template-rows: 1fr auto;
    height: 100%;
    box-sizing: border-box;
    background-color: ${colors.white};
`;

const ContentContainer = styled.div`
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 90px;
    background-color: ${colors.white};
    overflow: auto;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: solid 2px ${colors.lightGray4};
    padding: 10px 0;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 10px;
`;

const PartialDescriptionBox = styled.div`
    border-top: solid 1px ${colors.lighterGray};
`;

type Props = {
    customerId: number;
};

const CustomerDetailedProfile: React.FC<Props> = ({ customerId }) => {
    const { selected } = useContext(CustomerTabPropertyTabContext);

    const visible = useMemo(() => {
        return CUSTOMER_TAB_DETAIL_PROFILE === selected;
    }, [selected]);

    const { loading, error, value } = useAsync(async () => {
        return await Api.getCustomerDetail(customerId);
    }, [customerId]);

    const customerDetail = useMemo(() => {
        return value ? value[0] : undefined;
    }, [value]);

    const PerYearRoundTitle = (
        <>
            年間
            <br />
            ラウンド数
        </>
    );

    const history = useHistory();
    const handleClickEdit = () => {
        history.push(Routes.LIBRARY_EDIT.replace(':id', `${customerId}`));
    };
    return (
        <Container visible={visible}>
            {loading === true && <CircularProgress />}
            {loading === false && error && <>詳細情報の取得に失敗しました</>}
            {loading === false && customerDetail && (
                <Fragment>
                    <ContentContainer>
                        <Grid>
                            <CustomerFeatureCard
                                title='ヘッドスピード'
                                footer='m/s'
                            >
                                {customerDetail.head_speed}
                            </CustomerFeatureCard>
                            <CustomerFeatureCard title='飛距離' footer='y/d'>
                                {customerDetail.distance}
                            </CustomerFeatureCard>
                            <CustomerFeatureCard title='球筋' footer='型'>
                                {customerDetail.projectile}
                            </CustomerFeatureCard>
                            <PartialDescriptionBox>
                                <FeatureDescription title='好きなクラブ'>
                                    {customerDetail.club_favorite}
                                </FeatureDescription>
                                <FeatureDescription title='得意クラブ'>
                                    {customerDetail.club_good_at}
                                </FeatureDescription>
                                <FeatureDescription title='ゴルフ歴'>
                                    <DisplayExperienceYear
                                        years={customerDetail.years_golf}
                                    />
                                </FeatureDescription>
                                <FeatureDescription title='スポーツ歴'>
                                    {customerDetail.years_sport}
                                </FeatureDescription>
                                <FeatureDescription title={PerYearRoundTitle}>
                                    {customerDetail.rounds_per_year}回
                                </FeatureDescription>
                            </PartialDescriptionBox>
                            <CustomerFeatureCard title='ベストスコア' footer=''>
                                {customerDetail.best_score}
                            </CustomerFeatureCard>
                            <CustomerFeatureCard
                                title='平均スコア/HDCP'
                                footer=''
                            >
                                {customerDetail.avg_score}
                            </CustomerFeatureCard>
                        </Grid>
                        <FeatureDescription title='ホームコース'>
                            {customerDetail.home_course}
                        </FeatureDescription>
                        <FeatureDescription title='練習回数(月平均)'>
                            {customerDetail.total_practice}回
                        </FeatureDescription>
                        <FeatureDescription title='メモ(非公開)'>
                            {customerDetail.memo}
                        </FeatureDescription>
                    </ContentContainer>
                    <FooterContainer>
                        <ProfileEditNavigationButton onClick={handleClickEdit}>
                            編集
                        </ProfileEditNavigationButton>
                    </FooterContainer>
                </Fragment>
            )}
        </Container>
    );
};

export default CustomerDetailedProfile;
