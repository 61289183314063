import { Button, Modal } from '@material-ui/core';
import dateFormat from 'date-fns/format';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import LessonMoviePlayer from '../../swing/surface/LessonMoviePlayer';

const Container = styled.section`
    position: absolute;
    top: 56px;
    left: 56px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 112px);
    height: calc(100% - 112px);
    overflow: auto;
    padding: 40px;
    background-color: ${colors.white};
    box-sizing: border-box;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 30px;
`;

const Title = styled.h3`
    color: ${colors.footerIconSelected};
    font-size: 35px;
    font-weight: bold;
    padding: 0;
    margin: 0;
`;

const Infomation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const InfomationList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 10px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-right: 25px;
    li {
        text-align: right;
    }
    .splitted {
        padding-right: 20px;
    }
    .titlized {
        font-size: 18px;
        color ${colors.footerIconSelected};
    }
`;

const NumberDisplay = styled.span`
    color: ${colors.footerIconSelected};
    font-size: 59px;
    font-weight: bold;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
`;

const CloseButton = styled(Button)`
    width: 80%;
    &.MuiButton-root {
        padding: 5px;
        background-color: ${colors.footerIconSelected} !important;
        color: ${colors.black};
        font-weight: bold;
        font-size: 16px;
    }
`;

type Props = {
    isOpened: boolean;
    movie: MovieDisplay;
    movies: MovieDisplay[];
    onClose?: () => void;
};

const PlayMovieModal: React.FC<Props> = ({
    isOpened,
    movie,
    movies,
    onClose,
}) => {
    const movieIndex = useMemo<number>(() => {
        return movies.findIndex((p) => p.id === movie.id);
    }, [movie, movies]);

    const createdAt = useMemo(() => {
        return movie?.created_at
            ? dateFormat(new Date(movie.created_at), 'yyyy/MM/dd')
            : '----';
    }, [movie?.created_at]);

    const club = useMemo(() => {
        return movie.club ? `●${movie.club || '----'}` : '';
    }, [movie?.club]);

    const yards = useMemo(() => {
        return movie.yards ? `●${movie.yards || '----'}y` : '';
    }, [movie?.yards]);

    return (
        <Modal open={isOpened}>
            <Container>
                <Header>
                    <Title>過去のレッスン</Title>
                    <Infomation>
                        {movie && (
                            <InfomationList>
                                <li>{createdAt}</li>
                                <li>
                                    <span className='splitted'>{club}</span>
                                    <span>{yards}</span>
                                </li>
                                <li className='titlized'>スイング</li>
                            </InfomationList>
                        )}
                        <NumberDisplay>{movieIndex + 1}</NumberDisplay>
                    </Infomation>
                </Header>
                <Body>
                    {movie && (
                        <LessonMoviePlayer
                            showHeader={false}
                            movieDisplay={movie}
                            reloadToolImage={false}
                        ></LessonMoviePlayer>
                    )}
                </Body>
                <Footer>
                    <CloseButton onClick={onClose}>閉じる</CloseButton>
                </Footer>
            </Container>
        </Modal>
    );
};

export default PlayMovieModal;
