import { createStyles, IconButton, makeStyles, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseSharp';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';
import HeaderIcon from '../../assets/windows.svg';
import { MovieDisplay } from '../../utils/Video';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import { VideoMovie } from '../video/PlayingVideoCard';
import VideoEditorDouble from '../video/VideoEditorDouble';
import MovieDoubleEditFooter from './navigation/MovieDoubleEditFooter';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import DoublePlayerControl from './surface/DoublePlayerControl';
import LessonMoviePlayer, { ForwardProps } from './surface/LessonMoviePlayer';

type LocationState = {
    movies: MovieDisplay[];
};

const ContentContainer = styled.section`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

const PlayerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 1fr min-content;
    padding: 10px 45px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
`;

const StyledLessonMoviePlayer = styled(LessonMoviePlayer)`
    width: 328px;
    .video-js {
        width: 328px;
        height: 474px;
    }
    .timeline {
        width: 328px;
        img {
            width: calc(328px / 10);
        }
    }
`;

const PlayerControl = styled(DoublePlayerControl)`
    grid-column: 1 / 4;
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 30px;
    right: 30px;
    color: ${colors.lightGray};
    z-index: 9999;
    svg {
        width: 60px;
        height: 60px;
    }
`;

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

const PlayDoublePage: React.FC = () => {
    const { state } = useLocation<LocationState | undefined>();
    const history = useHistory();
    const video1 = useRef<ForwardProps>(null);
    const video2 = useRef<ForwardProps>(null);
    const [modalOpened, setModalOpened] = useState(false);
    const style = useStyles();
    const [, dispathcer] = useAsyncFn(async () => {
        const playCommands = [video1, video2].map((video) => {
            const { current } = video;
            if (!current) {
                return;
            }
            return current.togglePlay();
        });
        await Promise.all(playCommands);
    }, []);

    if (!state) {
        history.goBack();
        return null;
    }

    const videoMovies = state.movies.map<VideoMovie>(
        ({
            id,
            src,
            filename,
            customer_id_or_staff_id,
            created_at,
            tool_image_filename,
            tool_image_filepath,
        }) => {
            return {
                id: Number(id),
                type: 'movie',
                filepath: src ?? '',
                filename: filename,
                customerIdOrStaffId: customer_id_or_staff_id,
                movieCreatedAt: created_at,
                toolImageFilename: tool_image_filename,
                toolImageFilePath: tool_image_filepath,
            };
        },
    );
    const handleClickPlay = () => {
        dispathcer().finally(() => undefined);
    };

    const handleAdvance = () => {
        [video1, video2].forEach((video) => {
            const { current } = video;
            if (!current) {
                return;
            }
            current.advance();
        });
    };

    const handleGoBack = () => {
        [video1, video2].forEach((video) => {
            const { current } = video;
            if (!current) {
                return;
            }
            current.goBack();
        });
    };

    const handleClickEdit = () => {
        setModalOpened(true);
    };

    const handleModalClose = () => {
        setModalOpened(false);
    };

    return (
        <Page title='スイング一覧' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation>
                    スイング同時再生
                </PlayerHeaderNavigation>
                <PlayerContainer>
                    <StyledLessonMoviePlayer
                        movieDisplay={state.movies[0]}
                        showFav={false}
                        showTimeLine={true}
                        ref={video1}
                        reloadToolImage={modalOpened}
                    />
                    <StyledLessonMoviePlayer
                        movieDisplay={state.movies[1]}
                        showFav={false}
                        showTimeLine={true}
                        ref={video2}
                        reloadToolImage={modalOpened}
                    />
                    <PlayerControl
                        onClickPlay={handleClickPlay}
                        advanceSecond={handleAdvance}
                        goBackSecond={handleGoBack}
                    />
                </PlayerContainer>
                <MovieDoubleEditFooter onClick={handleClickEdit}>
                    編集
                </MovieDoubleEditFooter>
            </ContentContainer>
            <Modal open={modalOpened} className={style.modal}>
                <>
                    <StyledIconButton onClick={handleModalClose}>
                        <CloseIcon />
                    </StyledIconButton>
                    <VideoEditorDouble
                        videoSrc={videoMovies}
                        height={474}
                        width={328}
                        scaleRatio={474 / 328}
                    />
                </>
            </Modal>
        </Page>
    );
};

export default PlayDoublePage;
