import { useState } from 'react';
import { useStore } from '../contexts/StoreContext';
import { CustomerDisplay } from '../store/LessonStore';
import {CurriculumNames, LessonDisplay} from '../utils/Lesson';
import { useAsync } from 'react-use';
import Api from '../utils/Api';

export type Question = {
    question: string;
    answer: string;
};

/**
 * カリキュラム名と対応する進捗率のマップを作成する
 *
 * @param names カリキュラム名
 * @param records カリキュラムに対する進捗の入力値
 */
const createCurriculumMap = (
    names: CurriculumNames,
    records: string[],
): Question[] => {
    const {
        name1,
        name2,
        name3,
        name4,
        name5,
        name6,
        name7,
        name8,
        name9,
        name10,
    } = names;

    return [
        name1,
        name2,
        name3,
        name4,
        name5,
        name6,
        name7,
        name8,
        name9,
        name10,
    ]
        .filter((p) => Boolean(p))
        .reduce((carry: Question[], question, index) => {
            carry.push({ question, answer: records[index] ?? '0' });
            return carry;
        }, []);
};

export function useQuestionnaireMapCreater(
    customer: CustomerDisplay,
): Question[] {
    const { lessonStore, tempoStore } = useStore();
    const lessonMaster = tempoStore.getLesson(customer.lesson_master_id);
    const { lessonRecordPerCustomer } = lessonStore;
    const target = lessonRecordPerCustomer.find(
        (p) => p.customerId === String(customer.id),
    );
    const [questions, setQuestions] = useState<Question[]>([]);
    useAsync(async () => {
        // 本日の進捗が未入力の場合、現在のカリキュラムに対応する最新のレッスン実績があれば、参照してレッスン進捗率の初期値を設定
        if (target?.records === undefined) {
            const lessonAchievement = await Api.fetchLatestLessonAchievement(
                customer.id,
                customer.lesson_master_id,
            );
            const curriculumAchievements =
                lessonAchievement?.curriculum_achievements ?? [];

            if (lessonAchievement !== null) {
                const filteredAchievements = curriculumAchievements
                    .filter(
                        (curriculumAchievement) =>
                            curriculumAchievement.curriculum_name_current !==
                            '',
                    );
                const questions: Question[] = filteredAchievements.reduce((carry: Question[], curriculumAchievement) => {
                        carry.push({
                            question:
                                curriculumAchievement.curriculum_name_current,
                            answer:
                                curriculumAchievement.is_deleted === false
                                    ? curriculumAchievement.rate
                                    : '0',
                        });
                        return carry;
                    }, []);
                const flatten: string[] = filteredAchievements.reduce((carry: string[], curriculumAchievement) => {
                        carry.push(
                            curriculumAchievement.is_deleted === false
                                ? curriculumAchievement.rate
                                : '0',
                        );
                        return carry;
                    }, []);

                // MEMO: レッスンコメント入力時に入力データがないとレッスン進捗が初期値で上書きされてしまうため、ここで登録しておく
                lessonStore.setLessonRecord(String(customer.id), flatten);
                setQuestions(questions.concat());
                return;
            }
        }

        if (!lessonMaster) {
            return;
        }

        const {
            curriculum_name_1,
            curriculum_name_2,
            curriculum_name_3,
            curriculum_name_4,
            curriculum_name_5,
            curriculum_name_6,
            curriculum_name_7,
            curriculum_name_8,
            curriculum_name_9,
            curriculum_name_10,
        } = lessonMaster;

        // 進捗入力値とレッスンエンティティから最新のカリキュラムマップを生成して返す
        setQuestions(createCurriculumMap({
            name1: curriculum_name_1,
            name2: curriculum_name_2,
            name3: curriculum_name_3,
            name4: curriculum_name_4,
            name5: curriculum_name_5,
            name6: curriculum_name_6,
            name7: curriculum_name_7,
            name8: curriculum_name_8,
            name9: curriculum_name_9,
            name10: curriculum_name_10,
        }, target?.records ?? []));
    }, [target, lessonMaster, customer.id, customer.lesson_master_id]);
    return questions;
}

export function usePastLessonCurriculumMap(
    customer: CustomerDisplay,
    lesson: LessonDisplay,
): Question[] {
    const { targetNames, targets } = lesson;
    const results: Question[] = createCurriculumMap(targetNames, targets);
    return results.filter((p) => Boolean(p.answer));
}
