import React, { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { useStore } from '../../../contexts/StoreContext';
import { FormControlLabel, Switch } from '@material-ui/core';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${colors.lightGray2};
    border-bottom: solid 4px ${colors.gray};
    color: ${colors.white};
`;

type Props = {
    onChange: (checked: boolean) => void;
};
const CustomerConditionFilterCell: React.FC<Props> = ({ onChange }) => {
    const { lessonStore } = useStore();
    const handleChange = useCallback(
        (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            onChange(checked);
            lessonStore.isInclusionDeletedCustomer =
                !lessonStore.isInclusionDeletedCustomer;
        },
        [onChange, lessonStore],
    );

    return (
        <Wrapper>
            <FormControlLabel
                control={
                    <Switch
                        color='primary'
                        onChange={handleChange}
                        checked={lessonStore.isInclusionDeletedCustomer}
                    />
                }
                label='退会者も表示'
            />
        </Wrapper>
    );
};

export default CustomerConditionFilterCell;
