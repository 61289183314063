import dateFormat from 'date-fns/format';
import {
    forwardRef,
    HTMLAttributes,
    useImperativeHandle,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { FavoriteIcon, useFavorite } from '../../../hooks/useFavorite';
import useVideoJS from '../../../hooks/useVideoJs';
import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import TimelineImage from './TimelineImage';
import ToolImageViewer from './ToolImageViewer';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    .vjs-control-bar {
        z-index: 3;
    }
    .vjs-big-play-button {
        z-index: 3;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    font-weight: bold;
`;

const PlayerName = styled.label`
    display: block;
    font-size: 21px;
    color: ${colors.black};
    font-weight: bold;
`;

const LessonInfomation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 12px;
`;

const CreatedAt = styled.div`
    text-align: right;
`;

const InlineList = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    > li {
        display: block;
        box-sizing: border-box;
        padding-right: 15px;
        &:last-child {
            padding-right: 0;
        }
        &:before {
            content: '●';
        }
    }
`;

const FavIcon = styled(FavoriteIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const Timeline = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 480px;
    > img {
        height: auto;
        width: 48px;
    }
`;

interface Props extends HTMLAttributes<Element> {
    movieDisplay: MovieDisplay;
    showFav?: boolean;
    showTimeLine?: boolean;
    onChangeDuration?: (time: number) => void;
}

export type ForwardProps = {
    advance: () => void;
    goBack: () => void;
    togglePlay: () => Promise<void>;
};

const LessonMoviePlayer = forwardRef<ForwardProps, Props>(
    (
        { movieDisplay, showFav = true, showTimeLine = false, className },
        ref,
    ) => {
        const { tempoStore, lessonStore } = useStore();
        const [faved, toggleFav] = useFavorite(movieDisplay);
        const { Video, thumbnails, actions } = useVideoJS({
            controls: true,
            responsive: true,
            sources: [{ src: movieDisplay.src ?? '' }],
            height: 590,
            width: 410,
        });
        const { created_at, club, yards, customer_id_or_staff_id } =
            movieDisplay;

        const userName = useMemo(() => {
            return (
                tempoStore.resolveUserName(Number(customer_id_or_staff_id)) ||
                lessonStore.resolveCustomerName(Number(customer_id_or_staff_id)) ||
                []
            );
        }, [tempoStore, lessonStore, customer_id_or_staff_id]);

        const handleClickTimeline = (time: number) => {
            actions.skip(time);
        };

        useImperativeHandle(ref, () => ({
            advance: () => {
                actions.skipPlus();
            },
            goBack: () => {
                actions.skipMinus();
            },
            togglePlay: async () => {
                await actions.togglePlay();
            },
        }));

        return (
            <Container className={className}>
                <HeaderContainer>
                    <PlayerName>{userName.join(' ')}</PlayerName>
                    <LessonInfomation>
                        <CreatedAt>
                            {dateFormat(new Date(created_at), 'yyyy/MM/dd')}
                        </CreatedAt>
                        <InlineList>
                            <li>{club}</li>
                            <li>{yards}y</li>
                        </InlineList>
                    </LessonInfomation>
                </HeaderContainer>
                <VideoContainer>
                    {showFav && <FavIcon faved={faved} onClick={toggleFav} />}
                    <Video />
                    <ToolImageViewer
                        filePath={movieDisplay.tool_image_filename}
                    />
                </VideoContainer>
                {showTimeLine && (
                    <Timeline className='timeline'>
                        {thumbnails?.map(([img, time]) => (
                            <TimelineImage
                                key={time}
                                src={img}
                                time={time}
                                onClick={handleClickTimeline}
                            ></TimelineImage>
                        ))}
                    </Timeline>
                )}
            </Container>
        );
    },
);

export default LessonMoviePlayer;
