import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.section`
    display: flex;
    flex-redirection: row;
    width: 100%;
    height: 100px;
    border-top: 3px solid ${colors.black};
    padding: 28px 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${colors.black};
    position: relative;
`;

const Title = styled.h2`
    margin: 0;
    line-height: 1;
    font-size: 37px;
    font-weight: bold;
    color: ${colors.footerIconSelected};
    text-align: center;
`;

const PlayerHeaderNavigation: React.FC = ({ children }) => {
    return (
        <Container>
            <Title>{children}</Title>
        </Container>
    );
};

export default PlayerHeaderNavigation;
