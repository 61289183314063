import React, { Dispatch, FormEvent, useContext } from 'react';
import styled from 'styled-components';
import { CustomerAttributeTabContext } from '../../../contexts/CustomerAttributeTabContext';
import { FormReducerAction } from '../../../hooks/useForm';
import { CustomerDetailedInformationEntity } from '../../../utils/Api';
import { CUSTOMER_ATTRIBUTE_DETAILED } from '../../const/CustomerAttributeTabProperties';
import InlineFormLayout from '../layout/InlineFormLayout';
import BaseInput from './BaseInput';
import ProjectileSelector from './ProjectileSelector';
import InputErrorMessage from '../../base/feedback/InputErrorMessage';

type VisibleProps = {
    shouldVisible: boolean;
};

const Container = styled.div<VisibleProps>`
    display: ${({ shouldVisible }) => (shouldVisible ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;
`;

const ErrorMessageBox = styled.div`
    width: 100%;
    text-align: center;
`;

type Props = {
    values: CustomerDetailedInformationEntity;
    dispacher: Dispatch<FormReducerAction<CustomerDetailedInformationEntity>>;
    errors: Map<string, string[]>;
};

const DetailForm: React.FC<Props> = ({ values, dispacher, errors }) => {
    const handleInputHeadSpeed = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { head_speed: evt.currentTarget.value } });
    };
    const handleInputDistance = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { distance: evt.currentTarget.value } });
    };
    const handleChangeProjectile = (value: string) => {
        dispacher({ value: { projectile: value } });
    };
    const handleInputBestScore = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { best_score: evt.currentTarget.value } });
    };
    const handleInputAverageScore = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { avg_score: evt.currentTarget.value } });
    };
    const handleInputRoundPerYear = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { rounds_per_year: evt.currentTarget.value } });
    };
    const handleInputTotalPractice = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { total_practice: evt.currentTarget.value } });
    };
    const handleInputClubGoodAt = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { club_good_at: evt.currentTarget.value } });
    };
    const handleInputClubFavorite = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { club_favorite: evt.currentTarget.value } });
    };
    const handleInputYearsGolf = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { years_golf: evt.currentTarget.value } });
    };
    const handleInputYearsSport = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { years_sport: evt.currentTarget.value } });
    };
    const handleInputHomeCourse = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { home_course: evt.currentTarget.value } });
    };
    const handleInputMemo = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { memo: evt.currentTarget.value } });
    };
    const { selected } = useContext(CustomerAttributeTabContext);
    return (
        <Container shouldVisible={selected === CUSTOMER_ATTRIBUTE_DETAILED}>
            <InlineFormLayout title='ヘッドスピード(m/s)'>
                <InputErrorMessage fields={['head_speed']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.head_speed}
                    onInput={handleInputHeadSpeed}
                />
            </InlineFormLayout>
            <InlineFormLayout title='飛距離(y)'>
                <InputErrorMessage fields={['distance']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.distance}
                    onInput={handleInputDistance}
                />
            </InlineFormLayout>

            <ErrorMessageBox>
                <InputErrorMessage fields={['projectile']} errors={errors} />
            </ErrorMessageBox>
            <ProjectileSelector
                selected={values.projectile}
                onChange={handleChangeProjectile}
            />

            <InlineFormLayout title='ベストスコア'>
                <InputErrorMessage fields={['best_score']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.best_score}
                    onInput={handleInputBestScore}
                />
            </InlineFormLayout>
            <InlineFormLayout title='平均スコア'>
                <InputErrorMessage fields={['avg_score']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.avg_score}
                    onInput={handleInputAverageScore}
                />
            </InlineFormLayout>
            <InlineFormLayout title='年間ラウンド数(回)'>
                <InputErrorMessage
                    fields={['rounds_per_year']}
                    errors={errors}
                />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.rounds_per_year}
                    onInput={handleInputRoundPerYear}
                />
            </InlineFormLayout>
            <InlineFormLayout title='練習回数(月平均)'>
                <InputErrorMessage
                    fields={['total_practice']}
                    errors={errors}
                />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.total_practice}
                    onInput={handleInputTotalPractice}
                />
            </InlineFormLayout>
            <InlineFormLayout title='得意クラブ'>
                <InputErrorMessage fields={['club_good_at']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='text'
                    value={values.club_good_at}
                    onInput={handleInputClubGoodAt}
                />
            </InlineFormLayout>
            <InlineFormLayout title='好きなクラブ'>
                <InputErrorMessage fields={['club_favorite']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='text'
                    value={values.club_favorite}
                    onInput={handleInputClubFavorite}
                />
            </InlineFormLayout>
            <InlineFormLayout title='ゴルフ歴(年)'>
                <InputErrorMessage fields={['years_golf']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.years_golf}
                    onInput={handleInputYearsGolf}
                />
            </InlineFormLayout>
            <InlineFormLayout title='スポーツ歴'>
                <InputErrorMessage fields={['years_sport']} errors={errors} />
                <BaseInput
                    className='lg'
                    type='text'
                    value={values.years_sport}
                    onInput={handleInputYearsSport}
                />
            </InlineFormLayout>
            <InlineFormLayout title='ホームコース'>
                <InputErrorMessage fields={['home_course']} errors={errors} />
                <BaseInput
                    className='lg'
                    type='text'
                    value={values.home_course}
                    onInput={handleInputHomeCourse}
                />
            </InlineFormLayout>
            <InlineFormLayout title='メモ(非公開)'>
                <InputErrorMessage fields={['memo']} errors={errors} />
                <BaseInput
                    className='lg'
                    type='text'
                    value={values.memo}
                    onInput={handleInputMemo}
                />
            </InlineFormLayout>
        </Container>
    );
};

export default DetailForm;
