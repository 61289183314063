import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { DominantHands, DominantHandType } from '../../const/DominantHands';
import { colors } from '../../const/Styles';

const StyledRadio = styled(Radio)`
    &.Mui-checked {
        color: ${colors.footerIconSelected};
    }
`;

type Props = {
    selected: DominantHandType | null | undefined;
    onChange?: (value: DominantHandType) => void;
};
const DominantHandSelector: React.FC<Props> = ({ selected, onChange }) => {
    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(evt.currentTarget.value as DominantHandType);
        }
    };
    return (
        <RadioGroup row onChange={handleChange} value={selected}>
            {Object.entries(DominantHands).map(([key, value]) => (
                <FormControlLabel
                    value={key}
                    key={key}
                    label={value}
                    control={<StyledRadio />}
                ></FormControlLabel>
            ))}
        </RadioGroup>
    );
};

export default DominantHandSelector;
