import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import AuthStore from '../store/AuthStore';
import TitleLogo from '../assets/wingnote_logo.png';

import OutlinedInputText from './form/OutlinedInputText';

import { Box, Typography, Link, Button, Container } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const StyledInputText = OutlinedInputText;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleBox: {
            textAlign: 'center',
            marginBottom: '7rem',
        },
        title: {
            // typographyコンポーネントのバグ？でvariant=h2, h3のときだけpx指定になっていたのでremで上書きしている。
            fontSize: '4rem',
        },
        formSet: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
        },
        loginButton: {
            display: 'block',
            margin: 'auto',
            marginTop: 10,
            marginBottom: 10,
        },
    }),
);

const Login: React.FC = observer(() => {
    const classes = useStyles();
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [isEmptyId, setIsEmptyId] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    async function handleLogin() {
        if (id.length === 0) {
            setIsEmptyId(true);
            return;
        } else {
            setIsEmptyId(false);
        }
        if (password.length === 0) {
            setIsEmptyPassword(true);
            return;
        } else {
            setIsEmptyPassword(false);
        }
        try {
            await AuthStore.fetchLogin(id, password);
        } catch (error) {
            setIsFailed(true);
        }
    }

    return (
        <Container className={classes.root}>
            <div className={classes.titleBox}>
                <img
                    width='50%'
                    src={TitleLogo}
                />
            </div>
            <div>
                <Box className={classes.formSet}>
                    <Typography>ID</Typography>
                    <StyledInputText
                        id='tempo_id'
                        placeholder={'店舗ID'}
                        error={isEmptyId}
                        helperText={isEmptyId ? 'IDを入力してください' : ''}
                        onChange={(e) => setId(e.target.value)}
                    />
                </Box>
                <Box className={classes.formSet}>
                    <Typography>PASS&nbsp;</Typography>
                    <StyledInputText
                        id='mail_address'
                        placeholder={'パスワード'}
                        type='password'
                        error={isEmptyPassword}
                        helperText={
                            isEmptyPassword
                                ? 'パスワードを入力してください'
                                : ''
                        }
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Box>
                {isFailed && (
                    <Typography color='error'>
                        ログインに失敗しました。
                    </Typography>
                )}
                <Button
                    className={classes.loginButton}
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={handleLogin}
                >
                    ログイン
                </Button>
            </div>
            <Typography>
                パスワードを忘れた方は
                <Link href='/password/reset'>こちら</Link>
            </Typography>
        </Container>
    );
});

export default Login;
