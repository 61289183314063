import React, { ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import { MovieType } from '../../../contexts/VideoTypeTabContext';
import { colors } from '../../const/Styles';

const ButtonLikeLabel = styled.label`
    display: block;
    text-align: center;
    background-color: ${colors.gray};
    color: ${colors.white};
    width: 186px;
    font-size: 14px;
    border: solid 2px ${colors.footerIconUnSelected};
    border-radius: 4px;
    padding: 6px;
    box-sizing: border-box;
    &.active {
        background-color: ${colors.blue};
        font-weight: bold;
        border: solid 2px ${colors.blue};
    }
`;

const HiddenRadio = styled.input`
    display: none;
`;

type Props = {
    value: MovieType;
    selected: MovieType;
    onChange: (value: MovieType) => void;
};

const MovieTypeButton: React.FC<Props> = ({
    value,
    selected,
    onChange,
    children,
}) => {
    const isSelected = useMemo(() => {
        return value === selected;
    }, [value, selected]);

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number(evt.currentTarget.value) as MovieType;
        onChange(value);
    };

    return (
        <ButtonLikeLabel className={isSelected === true ? 'active' : ''}>
            <HiddenRadio
                type='radio'
                name='movieTypeRadio'
                value={value}
                onChange={handleChange}
            />
            {children}
        </ButtonLikeLabel>
    );
};

export default MovieTypeButton;
