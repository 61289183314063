import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import CustomerSelectableCard from '../surface/CustomerSelectableCard';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 4px));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    grid-gap: 4px;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 4px;
`;

type Props = {
    customers: CustomerDisplay[];
};

const CustomerGridView: React.FC<Props> = ({ customers }) => {
    const { lessonStore } = useStore();
    const { selectedCustomersId } = lessonStore;
    const handleSelect = (customer: CustomerDisplay) => {
        if (selectedCustomersId.length < 10) {
            lessonStore.selectCustomer(customer.id);
        }
    };

    const handleUnSelect = (customer: CustomerDisplay) => {
        lessonStore.unselectCustomer(customer.id);
    };

    return (
        <Wrapper>
            {customers.map((customer) => (
                <CustomerSelectableCard
                    key={customer.id}
                    selectedIndex={lessonStore.selectedCustomersId.findIndex(
                        (p) => p === customer.id,
                    )}
                    customer={customer}
                    isSelected={lessonStore.isSelected(customer.id)}
                    onSelect={handleSelect}
                    onUnSelect={handleUnSelect}
                />
            ))}
        </Wrapper>
    );
};

export default observer(CustomerGridView);
