import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BaseInput from './BaseInput';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: inherit;
    width: 100%;
    > input {
        font-size: inherit;
        margin-right: 5px;
    }
`;

const Item = styled(Container)`
    margin-right: 5px;
`;

type Props = {
    value?: string;
    onChange?: (value: string) => void;
};

const BirthdayInputGroup: React.FC<Props> = ({ value, onChange }) => {
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');

    useEffect(() => {
        const parsedBirthday = (value ?? '').split('-');
        setYear(parsedBirthday[0] ?? '');
        setMonth(parsedBirthday[1] ?? '');
        setDay(parsedBirthday[2] ?? '');
    }, [value]);

    const handleInputYear = useCallback(
        (evt: FormEvent<HTMLInputElement>) => {
            setYear(evt.currentTarget.value);
            if (onChange) {
                onChange(`${evt.currentTarget.value}-${month}-${day}`);
            }
        },
        [month, day, onChange],
    );

    const handleInputMonth = useCallback(
        (evt: FormEvent<HTMLInputElement>) => {
            setMonth(evt.currentTarget.value);
            if (onChange) {
                onChange(`${year}-${evt.currentTarget.value}-${day}`);
            }
        },
        [year, day, onChange],
    );

    const handleInputDay = useCallback(
        (evt: FormEvent<HTMLInputElement>) => {
            setDay(evt.currentTarget.value);
            if (onChange) {
                onChange(`${year}-${month}-${evt.currentTarget.value}`);
            }
        },
        [year, month, onChange],
    );

    return (
        <Container>
            <Item>
                <BaseInput
                    className='xs'
                    type='number'
                    max={9999}
                    min={1900}
                    value={year}
                    onInput={handleInputYear}
                />
                年
            </Item>
            <Item>
                <BaseInput
                    className='xs'
                    type='number'
                    max={12}
                    min={1}
                    value={month}
                    onInput={handleInputMonth}
                />
                月
            </Item>
            <Item>
                <BaseInput
                    className='xs'
                    type='number'
                    max={31}
                    min={1}
                    value={day}
                    onInput={handleInputDay}
                />
                日
            </Item>
        </Container>
    );
};

export default BirthdayInputGroup;
