import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import {
    colors,
    iconSize,
    profileBorders,
    profileNoSelectedBorder,
} from '../../const/Styles';
import CustomerImage from './CustomerImage';
import TimesIcon from './TimesIcon';

type Props = {
    customer?: CustomerDisplay;
    index: number;
    isSelected: boolean;
    onUnSelect: (customer: CustomerDisplay) => void;
};

const Wrapper = styled.article`
    display: flex;
    flex-direction: column;
    min-width: 100px;
    height: auto;
    position: relative;
    margin-right: 10px;
`;

const NameLabel = styled.label`
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    color: ${colors.white};
    text-align: center;
`;

const IconWrapper = styled.i`
    position: absolute;
    top: 6px;
    right: 4px;
`;

const CustomerSelectedCard: React.FC<Props> = ({
    customer,
    isSelected,
    index,
    onUnSelect,
}) => {
    const iconColor = profileBorders[index % profileBorders.length];
    const borderColor =
        isSelected === true ? iconColor : profileNoSelectedBorder;

    const handleUnSelect = useCallback(() => {
        if (customer) {
            onUnSelect(customer);
        }
    }, [onUnSelect, customer]);

    return (
        <Wrapper onClick={handleUnSelect}>
            <CustomerImage
                color={borderColor}
                iconColor={iconColor}
                src={customer?.s3Path}
            ></CustomerImage>
            <NameLabel>
                {customer?.name_sei} {customer?.name_mei}
            </NameLabel>
            {isSelected === true && (
                <IconWrapper>
                    <TimesIcon
                        color={borderColor}
                        size={iconSize.profileSm}
                        onClick={handleUnSelect}
                    ></TimesIcon>
                </IconWrapper>
            )}
        </Wrapper>
    );
};

export default CustomerSelectedCard;
