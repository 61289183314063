import { LessonEntity } from './Api';
import format from 'date-fns/format';

export type CurriculumNames = {
    name1: string,
    name2: string,
    name3: string,
    name4: string,
    name5: string,
    name6: string,
    name7: string,
    name8: string,
    name9: string,
    name10: string,
};

export class LessonDisplay {
    private readonly entity: LessonEntity;
    constructor(lessonEntity: LessonEntity) {
        this.entity = lessonEntity;
    }

    get lesson_id(): string {
        return `${this.entity.id}`;
    }

    get customer_id(): string {
        return `${this.entity.customer_id}`;
    }

    get created_at(): string {
        return this.entity.created_at;
    }

    get created_date_string(): string {
        return format(new Date(this.entity.created_at), 'y/MM/dd');
    }

    get staff_id(): string {
        return `${this.entity.staff_id}`;
    }

    get lesson_master_id(): string {
        return `${this.entity.lesson_master_id}`;
    }

    get comment(): string {
        return this.entity.comment;
    }

    get targetNames(): CurriculumNames {
        const {
            curriculum_name_1,
            curriculum_name_2,
            curriculum_name_3,
            curriculum_name_4,
            curriculum_name_5,
            curriculum_name_6,
            curriculum_name_7,
            curriculum_name_8,
            curriculum_name_9,
            curriculum_name_10,
        } = this.entity;
        return {
            name1: curriculum_name_1,
            name2: curriculum_name_2,
            name3: curriculum_name_3,
            name4: curriculum_name_4,
            name5: curriculum_name_5,
            name6: curriculum_name_6,
            name7: curriculum_name_7,
            name8: curriculum_name_8,
            name9: curriculum_name_9,
            name10: curriculum_name_10,
        };
    }

    get targets(): string[] {
        const {
            curriculum_value_1,
            curriculum_value_2,
            curriculum_value_3,
            curriculum_value_4,
            curriculum_value_5,
            curriculum_value_6,
            curriculum_value_7,
            curriculum_value_8,
            curriculum_value_9,
            curriculum_value_10,
        } = this.entity;
        return [
            curriculum_value_1,
            curriculum_value_2,
            curriculum_value_3,
            curriculum_value_4,
            curriculum_value_5,
            curriculum_value_6,
            curriculum_value_7,
            curriculum_value_8,
            curriculum_value_9,
            curriculum_value_10,
        ];
    }
}
