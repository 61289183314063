import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
    lineColorToRGB,
} from '../../../hooks/useEditorCanvas';
import {
    Values as AngleValues,
    Actions as AngleActions,
    MovingType,
} from '../../../hooks/useEditorAngle';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgAngleRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    angleValues: AngleValues;
    angleActions: AngleActions;
    height: number;
    width: number;
}

function AngleSVG(props: Props): React.ReactElement {
    const {
        svgAngleRef,
        canvasValues,
        angleValues,
        angleActions,
        height,
        width,
    } = props;
    const classes = useStyles();

    return (
        <svg
            ref={svgAngleRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.angle && classes.front,
            )}
            onPointerUp={angleActions.onUpCircle}
            onPointerMove={(e) =>
                angleActions.onMoveCircle(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                )
            }
            onClick={(e) =>
                angleActions.stamp(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                    ToolType.angle,
                    canvasValues.lineColor,
                )
            }
        >
            <g display={angleValues.display ? 'inline' : 'none'}>
                <path
                    d={`M ${angleValues.tipX1} ${angleValues.tipY1} L ${angleValues.angleX} ${angleValues.angleY} L ${angleValues.tipX2} ${angleValues.tipY2}`}
                    fill='transparent'
                    stroke={lineColorToRGB[angleValues.color]}
                    strokeWidth='3'
                />
                <circle
                    cx={angleValues.tipX1}
                    cy={angleValues.tipY1}
                    r='10'
                    fill='transparent'
                    stroke={lineColorToRGB[angleValues.color]}
                    strokeWidth='1'
                    onPointerDown={() =>
                        angleActions.onDownCircle(MovingType.tip1)
                    }
                />
                <circle
                    cx={angleValues.angleX}
                    cy={angleValues.angleY}
                    r='10'
                    fill='transparent'
                    stroke={lineColorToRGB[angleValues.color]}
                    strokeWidth='1'
                    onPointerDown={() =>
                        angleActions.onDownCircle(MovingType.angle)
                    }
                />
                <circle
                    cx={angleValues.tipX2}
                    cy={angleValues.tipY2}
                    r='10'
                    fill='transparent'
                    stroke={lineColorToRGB[angleValues.color]}
                    strokeWidth='1'
                    onPointerDown={() =>
                        angleActions.onDownCircle(MovingType.tip2)
                    }
                />
                <text
                    x={angleValues.angleX + 10}
                    y={angleValues.angleY - 10}
                    fill={lineColorToRGB[angleValues.color]}
                    strokeWidth='2'
                >
                    {angleValues.angle}
                </text>
                <circle
                    cx={angleValues.circlePositionX}
                    cy={angleValues.circlePositionY}
                    r='2'
                    fill='transparent'
                    stroke={lineColorToRGB[angleValues.color]}
                    strokeWidth='1'
                />
            </g>
        </svg>
    );
}

export default AngleSVG;
