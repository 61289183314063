import { GridListTile } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import {
    MOVIE_TYPE_COACH,
    VideoTypeTabContext,
} from '../../../contexts/VideoTypeTabContext';
import Api from '../../../utils/Api';
import {
    getMovieComparer,
    getMoviePredicate,
} from '../../../utils/MovieSorting';
import { MovieDisplay } from '../../../utils/Video';
import MovieThumbnail from '../display/MovieThumbnail';
import VideoGridLayout from '../layout/VideoGridLayout';

const StyledGridListTile = styled(GridListTile)`
    height: 150px !important;
    width: 150px,
    margin-right: 5px,
`;

type Props = {
    onClick?: (video: MovieDisplay) => void;
};

const CoachMovies: React.FC<Props> = ({ onClick }) => {
    const { movieType, SortClub, SortOrder } = useContext(VideoTypeTabContext);
    const [movies, setMovies] = useState<MovieDisplay[]>([]);

    // TODO: コーチビデオ追加時に検知できるようにしたい
    useAsync(async () => {
        const results = await Api.fetchAllStaffMovies();
        setMovies(
            results
                .map((result) => new MovieDisplay(result))
                .filter((p) => p.fetchable),
        );
    }, []);

    const sortedMovies = useMemo(() => {
        return movies
            .filter(getMoviePredicate(SortClub))
            .sort(getMovieComparer(SortOrder));
    }, [movies, SortClub, SortOrder]);

    return (
        <VideoGridLayout isVisible={movieType === MOVIE_TYPE_COACH}>
            {sortedMovies.map((video) => (
                <StyledGridListTile key={video.id}>
                    <MovieThumbnail
                        video={video}
                        onClick={onClick}
                        isVisibleFavoriteButton={false}
                    />
                </StyledGridListTile>
            ))}
        </VideoGridLayout>
    );
};

export default observer(CoachMovies);
