import {
    createStyles,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    StyleRules,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, {
    ChangeEvent,
    Dispatch,
    useContext,
    useEffect,
    useMemo, useState,
} from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import dateFormat from 'date-fns/format';
import styled from 'styled-components';
import rightArrow from '../../../assets/right_arrow.svg';
import { CustomerRegisterPropertyTabContext } from '../../../contexts/CustomerRegisterPropertyTabContext';
import { FormReducerAction } from '../../../hooks/useForm';
import useSeparateDateInput from '../../../hooks/useSeparateDateInput';
import TempoStore from '../../../store/TempoStore';
import { ErrorMessages } from '../../../utils/Api';
import { emptyToNull } from '../../../utils/strings';
import { CustomerBasicInfomationForm } from '../../@types/request';
import ErrorMessage from '../../base/feedback/ErrorMessage';
import MultiFieldErrorMessage from '../../base/feedback/MultiFieldErrorMessage';
import {
    CUSTOMER_REGIST_BASIC_PROFILE,
    CUSTOMER_REGIST_DETAIL_PROFILE,
} from '../../const/RegisterProperties';
import { colors } from '../../const/Styles';
import StyledInputText from '../../form/OutlinedInputText';
import AuthStore from '../../../store/AuthStore';
import {LoginKinds} from '../../const/LoginKinds';

const selectOverride = (theme: Theme): StyleRules<'root'> => ({
    root: {
        width: 360,
        backgroundColor: theme.palette.background.default,
    },
});

const StyledSelect = withStyles(selectOverride, {
    name: 'CustomerInformationForm-Select',
})(Select);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formSet: {
            display: 'flex',
            backgroundColor: `${colors.white}`,
            borderBottom: `1px solid ${colors.lightGray}`,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 13,
        },
        inputBox: {
            display: 'flex',
        },
        formInput: {
            flexGrow: 1,
        },
        select: {
            backgroundColor: theme.palette.background.default,
            width: 370,
            position: 'relative',
        },
        menuItem: {
            height: 50,
        },
        formTitle: {
            display: 'flex',
            alignItems: 'center',
            width: 160,
        },
        formInputFull: {
            width: 370,
        },
        deleteLabelSpace: {
            '& > div > div.MuiBox-root': {
                width: 0,
            },
        },
        spanText: {
            marginTop: 'auto',
            marginRight: 10,
        },
        mandatoryField: {
            color: 'red',
        },
        radioBoxWrap: {
            width: 382,
        },
        radioBox: {},
        rightArrowImgBox: {
            position: 'absolute',
            right: '-75px',
            bottom: '50%',
        },
        checkColor: {
            '&.Mui-checked': {
                color: `${colors.footerIconSelected}`,
            },
        },
        errorMsgBox: {},
        icon: {
            color: `${colors.footerIconSelected}`,
            top: -15,
            right: -10,
            fontSize: '4.2rem',
            position: 'absolute',
        },
    }),
);

type ContainerProps = {
    visible: boolean;
};
const Container = styled.section`
    width: 570px;
    height: 580px;
    overflow-y: scroll;
    margin-top: 27px;
    position: relative;
    backgroundcolor: ${colors.white};
`;

const Wrapper = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    position: relative;
`;

type MuiSelectChangeEvent = ChangeEvent<{
    name?: string | undefined;
    value: unknown;
}>;

type Props = {
    basicInfo: CustomerBasicInfomationForm;
    basicInfoDispatch: Dispatch<FormReducerAction<CustomerBasicInfomationForm>>;
    errorProps: ErrorMessages | undefined;
};

const CustomerRegisterBasic: React.FC<Props> = ({
    basicInfo,
    basicInfoDispatch,
    errorProps,
}) => {
    const classes = useStyles();

    const { selected } = useContext(CustomerRegisterPropertyTabContext);
    const visible = useMemo(() => {
        return CUSTOMER_REGIST_BASIC_PROFILE === selected;
    }, [selected]);

    const { onChange } = useContext(CustomerRegisterPropertyTabContext);
    const moveDetailPage = () => {
        if (onChange) {
            onChange(CUSTOMER_REGIST_DETAIL_PROFILE);
        }
    };

    const [birthOfDate, birthOfDateDispacher] = useSeparateDateInput(
        basicInfo.date_of_birth,
    );
    const handleInputYear = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { value } = event.currentTarget;
        birthOfDateDispacher({
            value: { year: value ? Number(value) : undefined },
        });
    };
    const handleInputMonth = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { value } = event.currentTarget;
        birthOfDateDispacher({
            value: { month: value ? Number(value) : undefined },
        });
    };
    const handleInputDay = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { value } = event.currentTarget;
        birthOfDateDispacher({
            value: { day: value ? Number(value) : undefined },
        });
    };

    useEffect(() => {
        const { isValid, date: dateInstance } = birthOfDate;
        if (isValid && dateInstance) {
            basicInfoDispatch({
                value: {
                    date_of_birth: dateFormat(dateInstance, 'yyyy-MM-dd'),
                },
            });
        }
    }, [basicInfoDispatch, birthOfDate]);

    const handleInputStringValue = (
        key: keyof CustomerBasicInfomationForm,
        validateFunction?: (value: string) => boolean,
        transform?: (value: string) => string | null,
    ) => {
        return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = event.currentTarget;
            // 入力チェックが挟まれた場合は合格できたら通す
            if (validateFunction && !validateFunction(value)) {
                return;
            }
            const valueMap = new Map<string, string | null>();
            valueMap.set(
                key,
                event.currentTarget.value.replace(/[\u3000\x20\t]/, ''),
            );
            // 変換が必要な場合
            if (transform) {
                valueMap.set(
                    key,
                    transform(
                        event.currentTarget.value.replace(/[\u3000\x20\t]/, ''),
                    ),
                );
            }
            basicInfoDispatch({ value: Object.fromEntries(valueMap) });
        };
    };

    const handleInputNumberValue = (key: keyof CustomerBasicInfomationForm) => {
        return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const valueMap = new Map<string, number | undefined>();
            const { value } = event.currentTarget;
            valueMap.set(key, value ? Number(value) : undefined);
            basicInfoDispatch({ value: Object.fromEntries(valueMap) });
        };
    };

    const handleChangeLesson = (event: MuiSelectChangeEvent) => {
        basicInfoDispatch({
            value: { lesson_master_id: Number(event.target.value) },
        });
    };

    const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    const handleVisiblePassword = () => {
        setIsVisiblePassword(!isVisiblePassword);
    };

    return (
        <>
            <Wrapper visible={visible}>
                <Container>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>
                                レッスン名
                                <span className={classes.mandatoryField}>
                                    *
                                </span>
                            </Typography>
                        </Grid>
                        <Grid>
                            <ErrorMessage
                                field='lesson_master_id'
                                errors={errorProps}
                            />
                            <StyledSelect
                                className={classes.select}
                                variant='outlined'
                                value={basicInfo.lesson_master_id}
                                onChange={handleChangeLesson}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                            >
                                {TempoStore.allLessons.map((lesson) => {
                                    return (
                                        <MenuItem
                                            key={lesson.id}
                                            className={classes.menuItem}
                                            value={lesson.id}
                                        >
                                            {lesson.name}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>
                                会員ID
                            </Typography>
                        </Grid>
                        <Grid>
                            <ErrorMessage
                                field='user_name'
                                errors={errorProps}
                            />
                            <StyledInputText
                                id='user_name'
                                value={basicInfo.user_name}
                                onChange={handleInputStringValue('user_name')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>
                                登録メールアドレス
                                {AuthStore.loginKind === LoginKinds.LOGIN_WITH_MAIL_ADDRESS_AND_PASSWORD &&
                                    <span className={classes.mandatoryField}>
                                    *
                                    </span>
                                }
                            </Typography>
                        </Grid>
                        <Grid className={classes.errorMsgBox}>
                            <ErrorMessage field='email' errors={errorProps} />
                            <StyledInputText
                                id='mail_address'
                                value={basicInfo.email}
                                onChange={handleInputStringValue('email')}
                                type='text'
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>
                                パスワード
                                {AuthStore.loginKind === LoginKinds.LOGIN_WITH_MAIL_ADDRESS_AND_PASSWORD &&
                                    <span className={classes.mandatoryField}>
                                    *
                                    </span>
                                }
                            </Typography>
                        </Grid>
                        <div>
                            <MultiFieldErrorMessage
                                fields={['password']}
                                errors={errorProps}
                            />
                            <Grid item className={classes.inputBox}>
                                <StyledInputText
                                    id='password'
                                    value={basicInfo.password ?? ''}
                                    onChange={handleInputStringValue(
                                        'password',
                                    )}
                                    type={
                                        isVisiblePassword ? 'text' : 'password'
                                    }
                                />
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleVisiblePassword}
                                >
                                    {isVisiblePassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>
                                氏名
                                <span className={classes.mandatoryField}>
                                    *
                                </span>
                            </Typography>
                        </Grid>
                        <div>
                            <MultiFieldErrorMessage
                                fields={['name_sei', 'name_mei']}
                                errors={errorProps}
                            />
                            <Grid
                                item
                                className={
                                    classes.inputBox +
                                    ' ' +
                                    classes.deleteLabelSpace
                                }
                            >
                                <StyledInputText
                                    id='name_sei'
                                    value={basicInfo.name_sei}
                                    onChange={handleInputStringValue(
                                        'name_sei',
                                    )}
                                    style={{ width: '180px' }}
                                    placeholder='山田'
                                    label={'姓'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <StyledInputText
                                    id='name_mei'
                                    value={basicInfo.name_mei}
                                    onChange={handleInputStringValue(
                                        'name_mei',
                                    )}
                                    style={{ width: '180px' }}
                                    placeholder='太郎'
                                    label={'名'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>
                                ふりがな
                                <span className={classes.mandatoryField}>
                                    *
                                </span>
                            </Typography>
                        </Grid>
                        <div>
                            <MultiFieldErrorMessage
                                fields={['name_sei_kana', 'name_mei_kana']}
                                errors={errorProps}
                            />
                            <Grid
                                item
                                className={
                                    classes.inputBox +
                                    ' ' +
                                    classes.deleteLabelSpace
                                }
                            >
                                <StyledInputText
                                    id='name_sei_kana'
                                    value={basicInfo.name_sei_kana}
                                    onChange={handleInputStringValue(
                                        'name_sei_kana',
                                    )}
                                    style={{ width: '180px' }}
                                    placeholder={'やまだ'}
                                    label={'せい'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <StyledInputText
                                    id='name_mei_kana'
                                    value={basicInfo.name_mei_kana}
                                    onChange={handleInputStringValue(
                                        'name_mei_kana',
                                    )}
                                    style={{ width: '180px' }}
                                    placeholder={'たろう'}
                                    label={'めい'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>生年月日</Typography>
                        </Grid>
                        <Grid item className={classes.inputBox}>
                            <StyledInputText
                                id='birth_year'
                                value={birthOfDate.year}
                                onChange={handleInputYear}
                                type='number'
                                style={{ width: '80px' }}
                                placeholder='1976'
                            />
                            <Typography className={classes.spanText}>
                                年
                            </Typography>
                            <StyledInputText
                                id='birth_month'
                                value={birthOfDate.month}
                                onChange={handleInputMonth}
                                type='number'
                                style={{ width: '80px' }}
                                placeholder='6'
                            />
                            <Typography className={classes.spanText}>
                                月
                            </Typography>
                            <StyledInputText
                                id='birth_day'
                                value={birthOfDate.day}
                                onChange={handleInputDay}
                                type='number'
                                style={{ width: '80px' }}
                                placeholder='24'
                            />
                            <Typography className={classes.spanText}>
                                日
                            </Typography>
                        </Grid>
                        <ErrorMessage
                            field='date_of_birth'
                            errors={errorProps}
                        />
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>性別</Typography>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-label='position'
                                    name='position'
                                    defaultValue='unknown'
                                    value={basicInfo.sex}
                                    onChange={handleInputStringValue('sex')}
                                >
                                    <FormControlLabel
                                        label='男性'
                                        value='male'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='女性'
                                        value='female'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='未設定'
                                        value='unknown'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>身長</Typography>
                        </Grid>
                        <Grid item>
                            <ErrorMessage field='height' errors={errorProps} />
                            <StyledInputText
                                id='height'
                                value={basicInfo.height}
                                onChange={handleInputNumberValue('height')}
                                type='number'
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>握力</Typography>
                        </Grid>
                        <div>
                            <MultiFieldErrorMessage
                                fields={['grip_right', 'grip_left']}
                                errors={errorProps}
                            />
                            <Grid
                                item
                                className={
                                    classes.inputBox +
                                    ' ' +
                                    classes.deleteLabelSpace
                                }
                            >
                                <StyledInputText
                                    id='right_grip_strength'
                                    label={'左'}
                                    value={basicInfo.grip_left}
                                    onChange={handleInputNumberValue(
                                        'grip_left',
                                    )}
                                    type='number'
                                    style={{ width: '180px' }}
                                />
                                <StyledInputText
                                    id='left_grip_strength'
                                    label={'右'}
                                    value={basicInfo.grip_right}
                                    onChange={handleInputNumberValue(
                                        'grip_right',
                                    )}
                                    type='number'
                                    style={{ width: '180px' }}
                                />
                            </Grid>
                        </div>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>利き手</Typography>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-label='position'
                                    name='position'
                                    defaultValue='unknown'
                                    value={basicInfo.dominant_hand}
                                    onChange={handleInputStringValue(
                                        'dominant_hand',
                                    )}
                                >
                                    <FormControlLabel
                                        label='右利き'
                                        value='right_handed'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='左利き'
                                        value='left_handed'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='未設定'
                                        value='unknown'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>学習スタイル</Typography>
                        </Grid>
                        <Grid item className={classes.radioBoxWrap}>
                            <FormControl>
                                <RadioGroup
                                    className={classes.radioBox}
                                    row
                                    aria-label='position'
                                    name='position'
                                    defaultValue='unknown'
                                    value={basicInfo.personality_1}
                                    onChange={handleInputStringValue(
                                        'personality_1',
                                    )}
                                >
                                    <FormControlLabel
                                        label='行動型'
                                        value='action'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='感覚型'
                                        value='sense'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='思案型'
                                        value='think'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='未設定'
                                        value='unknown'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>学習スタイル2</Typography>
                        </Grid>
                        <Grid item className={classes.radioBoxWrap}>
                            <FormControl>
                                <RadioGroup
                                    className={classes.radioBox}
                                    row
                                    aria-label='position'
                                    name='position'
                                    defaultValue='unknown'
                                    value={basicInfo.personality_2}
                                    onChange={handleInputStringValue(
                                        'personality_2',
                                    )}
                                >
                                    <FormControlLabel
                                        label='行動型'
                                        value='action'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='感覚型'
                                        value='sense'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='思案型'
                                        value='think'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='未設定'
                                        value='unknown'
                                        control={
                                            <Radio
                                                className={classes.checkColor}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>受講理由</Typography>
                        </Grid>
                        <Grid item className={classes.formInputFull}>
                            <ErrorMessage
                                field='purpose_begin'
                                errors={errorProps}
                            />
                            <StyledInputText
                                id='purpose_begin'
                                value={basicInfo.purpose_begin}
                                onChange={handleInputStringValue(
                                    'purpose_begin',
                                )}
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                    {/* className={classes.errorMsgBox} */}
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>目標設定</Typography>
                        </Grid>
                        <Grid item className={classes.formInputFull}>
                            <ErrorMessage field='target' errors={errorProps} />
                            <StyledInputText
                                id='target'
                                value={basicInfo.target}
                                onChange={handleInputStringValue('target')}
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formSet}>
                        <Grid item className={classes.formTitle}>
                            <Typography>登録日</Typography>
                        </Grid>
                        <Grid item>
                            <ErrorMessage
                                field='registered_at'
                                errors={errorProps}
                            />
                            <StyledInputText
                                id='registered_at'
                                value={basicInfo.registered_at}
                                onChange={handleInputStringValue(
                                    'registered_at',
                                    undefined,
                                    emptyToNull,
                                )}
                                type='date'
                            />
                        </Grid>
                    </Grid>
                </Container>
                <div className={classes.rightArrowImgBox}>
                    <img src={rightArrow} onClick={moveDetailPage} alt='' />
                </div>
            </Wrapper>
        </>
    );
};
export default observer(CustomerRegisterBasic);
