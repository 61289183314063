import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledBackDrop = styled(Backdrop)`
    z-index: 9999;
`;

type Props = {
    isOpen: boolean;
};
const Loader: React.FC<Props> = ({ isOpen }) => {
    return (
        <StyledBackDrop open={isOpen}>
            <CircularProgress></CircularProgress>
        </StyledBackDrop>
    );
};

export default Loader;
