import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import HeaderIcon from '../../assets/footer-library.svg';
import { CustomerTabPropertyProvider } from '../../contexts/CustomerTabContext';
import { useStore } from '../../contexts/StoreContext';
import Page from '../base/Page';
import { Routes } from '../const/Routes';
import { colors } from '../const/Styles';
import CustomerBasicProfile from './display/CustomerBasicProfile';
import CustomerDetailedProfile from './display/CustomerDetailedProfile';
import PastLessons from './display/PastLessons';
import Questionnaire from './display/Questionnaire';
import DetailContentsNavigation from './navigation/DetailContentsNavigation';

const Container = styled.section`
    display: grid;
    grid-template-rows: 40% auto 1fr;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

const ContentsContainer = styled.section`
    display: block;
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

type CustomerHistoryType = {
    id?: string;
};
const CustomerDetail: React.FC = () => {
    const { id } = useParams<CustomerHistoryType>();
    const { lessonStore } = useStore();
    const { allCustomers } = lessonStore;
    const history = useHistory();

    // idから引けない場合は一覧へ戻す
    const customer = allCustomers.find((p) => p.id === Number(id));
    if (!customer) {
        history.replace(Routes.LIBRARY_LIST);
        return null;
    }

    return (
        <Page headerIcon={HeaderIcon ?? ''} title='ライブラリー'>
            <CustomerTabPropertyProvider>
                <Container>
                    <CustomerBasicProfile customer={customer} />
                    <DetailContentsNavigation />
                    <ContentsContainer>
                        <CustomerDetailedProfile customerId={customer.id} />
                        <Questionnaire customerId={customer.id} />
                        <PastLessons customer={customer} />
                    </ContentsContainer>
                </Container>
            </CustomerTabPropertyProvider>
        </Page>
    );
};

export default observer(CustomerDetail);
