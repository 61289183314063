import {
    Box,
    Card,
    createStyles,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useAsyncFn } from 'react-use';

import DeleteMovieBtn from '../../../assets/delete_movie_button.svg';
import { useStore } from '../../../contexts/StoreContext';
import { SavedToolImageMap } from '../../../store/RecordingStore';
import { StaffDisplay } from '../../../store/TempoStore';
import Api from '../../../utils/Api';
import { colors } from '../../const/Styles';
import { SwingClubs, Club } from '../../const/SwingClub';
import DominantButton from '../../form/DominantButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hiddenInput: {
            display: 'none',
        },
        videoUploadCard: {
            position: 'relative',
            backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        absoluteLabel: {
            position: 'absolute',
            top: 30,
            right: 15,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        card: {
            position: 'absolute',
            width: 650,
            height: 850,
            backgroundColor: `${colors.black}`,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflow: 'scroll',
            // opacity: 0.8,
        },
        videoContainer: {
            height: '60%',
            margin: 20,
        },
        formBox: {
            margin: 20,
        },
        buttonBox: {
            width: '100%',
            display: 'flex',
            marginBottom: 46,
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
        saveButton: {
            backgroundColor: `${colors.footerIconSelected}`,
            width: 208,
            height: 30,
            marginRight: 22,
            color: `${colors.black}`,
            fontWeight: 'bold',
        },
        cancelButton: {
            backgroundColor: `${colors.footerIconSelected}`,
            width: 208,
            height: 30,
            marginRight: 36,
            color: `${colors.black}`,
            fontWeight: 'bold',
        },
        video: {
            height: '100%',
            width: '100%',
        },
        input: {
            backgroundColor: `${colors.white}`,
        },
        select: {
            display: 'block',
            width: 268,
            color: `${colors.white}`,
            border: `1px solid ${colors.footerIconUnSelected}`,
            backgroundColor: `${colors.gray}`,
            '& > div.MuiFilledInput-root': {
                width: 268,
                display: 'block',
            },
        },
        selectFormDiv: {
            display: 'flex',
            justifyContent: 'center',
        },
        textFieldFormDiv: {
            display: 'flex',
            justifyContent: 'center',
            '& > div > div > input.MuiFilledInput-input': {
                paddingTop: 10,
            },
        },
        unitText: {
            margin: 'auto 0 3px 3px',
            color: `${colors.white}`,
        },
        title: {
            color: `${colors.footerIconSelected}`,
            fontSize: 30,
            margin: '0 auto',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        movieBox: {
            boxSizing: 'border-box',
            width: 335,
            height: 485,
            backgroundColor: `${colors.white}`,
            margin: '0 auto',
            marginTop: 50,
            marginBottom: 30,
        },
        icon: {
            color: `${colors.white}`,
            top: -10,
            right: -10,
            fontSize: '4rem',
            position: 'absolute',
        },
    }),
);

interface Props {
    coach: StaffDisplay;
    video?: SavedToolImageMap;
    onClose?: () => void;
    onRemove: (data: SavedToolImageMap) => void;
}

const UploadMovieModal: React.FC<Props> = ({
    coach,
    video,
    onClose,
    onRemove,
}) => {
    const classes = useStyles();

    const [club, setClub] = useState<string>(SwingClubs[0]);
    const [yard, setYard] = useState<string>('');

    const { recordingStore } = useStore();
    useEffect(() => {
        const registered = recordingStore.getSingleRegisteredVideo(video?.id);
        if (registered) {
            setClub(registered.club ?? SwingClubs[0]);
            setYard(registered.yard ?? '');
        }
    }, [video, recordingStore]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = useCallback((evt: ChangeEvent<{ value: unknown }>) => {
        setClub(evt.target.value as Club);
    }, []);

    const handleInput = (evt: ChangeEvent<HTMLInputElement>) => {
        setYard(evt.currentTarget.value);
    };

    const handleRemove = () => {
        if (video) {
            onRemove(video);
        }
        if (onClose) {
            onClose();
        }
    };

    const swingNumber = useMemo(() => {
        const registeredVideo = (recordingStore.registeredVideos ?? []).find(
            (p) => p.id === recordingStore.viewedCustomerId,
        );
        // 親レコードが登録されてなければ1
        if (!registeredVideo) {
            return '1';
        }
        // 子レコードとして登録されてるなら現在の番号、なければ次の番号
        const registered = registeredVideo.videos.find(
            (p) => p.id === video?.id,
        );
        return `${registered ? registered.number : registeredVideo.videos.length + 1
            }`;
    }, [recordingStore, video]);

    const [, dispatch] = useAsyncFn(async () => {
        if (!video) return;
        // FIXME: ファイル拡張子がハードコーディングされているため、iPadとPC版クロームで切り分ける。!!!!video.canPlayFormat()は使わない!!!!
        // MediaTransRecorderかffmpegみたいなもので変換するのが現実的
        const response = await Api.createCoachVideo(
            String(coach.id),
            club,
            yard,
            'mp4',
        );
        const presignedUrl = await Api.getS3SignedUrlForUploadMovie(
            response.filename,
        );
        // 編集部分は別導線でアップロードする
        await Api.uploadToS3(
            presignedUrl.presignedUrl,
            new File([video.video], 'test'),
        );
        recordingStore.registerVideo(String(coach.id), {
            id: video.id,
            video: video.video,
            number: swingNumber,
            yard,
            club,
            toolImage: null,
            isBestSwing: false,
        });
    }, [club, coach, video, yard, recordingStore, swingNumber]);

    // FIXME 動画アップロード後,アップロードした動画も含めてfetchして動画一覧ページにもどるのが理想だが,
    // lambdaでのthumbnailの処理の終了のタイミングをハンドリングすることが難しいため、一旦動画の再度fetchはしないこととしている
    // MEMO: SQS, SNSを使ってポーリングするのが現実解
    const onClickUpload = useCallback(() => {
        setLoading(true);
        dispatch().finally(() => {
            setLoading(false);
            if (onClose) {
                onClose();
            }
        });
    }, [dispatch, onClose, setLoading]);

    return (
        <Modal className={classes.modal} open={typeof video !== 'undefined'}>
            <div className={classes.videoUploadCard}>
                <Card className={classes.card}>
                    <div className={classes.title}>コーチスイング登録</div>
                    <div className={classes.movieBox}>
                        <video
                            className={classes.video}
                            src={video?.videoUrl}
                            controls
                        />
                    </div>
                    <Box className={classes.formBox}>
                        <div className={classes.selectFormDiv}>
                            <Select
                                className={classes.select}
                                value={club}
                                onChange={handleChange}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                            >
                                {SwingClubs.map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <br />
                        <div className={classes.textFieldFormDiv}>
                            <TextField
                                className={classes.input}
                                value={yard}
                                onChange={handleInput}
                                variant='outlined'
                            />
                            <Typography className={classes.unitText}>
                                ヤード
                            </Typography>
                        </div>
                    </Box>
                    <Box className={classes.buttonBox}>
                        <DominantButton
                            className={classes.saveButton}
                            onClick={onClickUpload}
                            disabled={loading}
                        >
                            登録
                        </DominantButton>
                        <DominantButton
                            className={classes.cancelButton}
                            onClick={onClose}
                        >
                            キャンセル
                        </DominantButton>
                        <img
                            src={DeleteMovieBtn}
                            alt=''
                            onClick={handleRemove}
                        />
                    </Box>
                </Card>
            </div>
        </Modal>
    );
};

export default UploadMovieModal;
