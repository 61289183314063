import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import type { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import TrushIcon from '../../../assets/trush.svg';

import { useStore } from '../../../contexts/StoreContext';
import { colors } from '../../const/Styles';
import { ReactSVG } from 'react-svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
    TitlePaper: {
        height: 58,
        width: 664,
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${colors.footerIconUnSelected}`,
        margin: 'auto',
        color: theme.palette.text.secondary,
        position: 'relative',
    },
    select: {
        position: 'relative',
        borderRadius: 0,
        display: 'flex',
        width: 340,
        height: 30,
        backgroundColor: `${colors.white}`,
    },
    courseName: {
        color: `${colors.white}`,
        marginRight: 17,
    },
    icon: {
        color: `${colors.footerIconSelected}`,
        top: -20,
        right: -10,
        fontSize: '4.2rem',
        position: 'absolute',
    },
}));

const Icon = styled.i`
    display: flex;
    color: ${colors.footerIconSelected};
    cursor: pointer;
    margin-left: 16px;
    svg {
        height: 32px;
    }
`;

type MuiChangeEvent = React.ChangeEvent<{ name?: string; value: unknown }>;

type Props = {
    lessonId: number | undefined;
    onChange: (id: string, isNew: boolean) => void;
};

const CurriculumNameSelector: React.FC<Props> = ({ lessonId, onChange }) => {
    const classes = useStyles();
    const { tempoStore } = useStore();
    const handleChangeLesson = useCallback((event: MuiChangeEvent) => {
        const selectedId = String(event.target.value);
        onChange(selectedId, selectedId === "0");
    }, [onChange])

    const handleClickIcon = useCallback(async () => {
        if (typeof lessonId === 'undefined') {
            return;
        }

        if (!confirm('カリキュラムを削除します。よろしいですか？')) {
            return;
        }

        try {
            await tempoStore.deleteLesson(lessonId)
            onChange('0', true);
            alert('カリキュラムを削除しました')
        } catch (error: unknown) {
            alert('カリキュラムの削除に失敗しました。\n再度時間をおいて実行してください。');
            console.error(error);
        }
    }, [lessonId, onChange, tempoStore])

    return (
        <Paper className={classes.TitlePaper}>
            <p className={classes.courseName}>レッスン名</p>
            <Select
                className={classes.select}
                variant='standard'
                value={lessonId ? lessonId : "0"}
                onChange={handleChangeLesson}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
            >
                {tempoStore.allLessons.map(lesson => (
                    <MenuItem
                        key={lesson.id}
                        value={lesson.id}
                    >
                        {lesson.name}
                    </MenuItem>
                ))}
                <MenuItem value={0}>新規登録</MenuItem>
            </Select>
            {typeof lessonId === 'number' && (
                <Icon onClick={handleClickIcon}>
                    <ReactSVG src={TrushIcon ?? ''} wrapper='span' />
                </Icon>
            )}
        </Paper>
    );
}

export default CurriculumNameSelector;
