export type GenderKeyType = 'male' | 'female' | 'unknown';

export const GenderMaps = new Map<GenderKeyType, string>([
    ['male', '男性'],
    ['female', '女性'],
    ['unknown', 'その他'],
]);

export const Genders = {
    male: '男性',
    female: '女性',
    unknown: 'その他',
};
