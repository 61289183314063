import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import { CustomerTabPropertyTabContext } from '../../../contexts/CustomerTabContext';
import { useStore } from '../../../contexts/StoreContext';
import Api from '../../../utils/Api';
import { CUSTOMER_TAB_QUESTIONNAIRE } from '../../const/CustomerTabProperties';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import ProfileEditNavigationButton from '../surface/ProfileEditNavigationButton';
import QuestionnaireRow from '../surface/QuestionnaireRow';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'grid' : 'none')};
    grid-template-rows: 1fr auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.white};
    overflow: hidden;
`;

const ContentsContainer = styled.div`
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 90px;
    background-color: ${colors.white};
    overflow: auto;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: solid 2px ${colors.lightGray4};
    padding: 10px 0;
`;

type Props = {
    customerId: number;
};

type Question = {
    question: string;
    answer: string;
};

const Questionnaire: React.FC<Props> = ({ customerId }) => {
    const { selected } = useContext(CustomerTabPropertyTabContext);
    const { tempoStore } = useStore();
    const { enqueteQuestionNames } = tempoStore;
    const visible = useMemo(() => {
        return CUSTOMER_TAB_QUESTIONNAIRE === selected;
    }, [selected]);

    const { loading, error, value } = useAsync(async () => {
        return await Api.fetachCusomerLatestEnqueteAnswer(customerId);
    }, [customerId]);

    const questions = useMemo(() => {
        return value ? value : undefined;
    }, [value]);

    const questionMaps = useMemo<Question[]>(() => {
        if (!questions) {
            return [];
        }
        const {
            question_answer_1,
            question_answer_2,
            question_answer_3,
            question_answer_4,
            question_answer_5,
            question_answer_6,
            question_answer_7,
            question_answer_8,
            question_answer_9,
            question_answer_10,
        } = questions;
        const answers = [
            question_answer_1 ?? '',
            question_answer_2 ?? '',
            question_answer_3 ?? '',
            question_answer_4 ?? '',
            question_answer_5 ?? '',
            question_answer_6 ?? '',
            question_answer_7 ?? '',
            question_answer_8 ?? '',
            question_answer_9 ?? '',
            question_answer_10 ?? '',
        ];
        return enqueteQuestionNames
            .filter((p) => Boolean(p))
            .map<Question>((question, index) => {
                return {
                    question,
                    answer: answers[index],
                };
            });
    }, [enqueteQuestionNames, questions]);

    const history = useHistory();
    const handleClickEdit = () => {
        // TODO: アンケートを選択済みにして編集
        history.push(Routes.LIBRARY_EDIT.replace(':id', `${customerId}`));
    };
    return (
        <Container visible={visible}>
            {loading === true && <CircularProgress />}
            {loading === false && error && (
                <>アンケート情報の取得に失敗しました</>
            )}
            {loading === false && value && (
                <ContentsContainer>
                    {questionMaps.map(({ question, answer }, index) => (
                        <QuestionnaireRow
                            key={index}
                            index={index + 1}
                            answer={answer}
                            question={question}
                        />
                    ))}
                </ContentsContainer>
            )}
            <FooterContainer>
                <ProfileEditNavigationButton onClick={handleClickEdit}>
                    編集
                </ProfileEditNavigationButton>
            </FooterContainer>
        </Container>
    );
};

export default observer(Questionnaire);
