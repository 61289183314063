import React, { useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GridList, GridListTile } from '@material-ui/core';
import clsx from 'clsx';

import { DisplayState } from './RecordingVideo';
import { SavedToolImageMap } from '../../store/RecordingStore';
import { observer } from 'mobx-react-lite';
import RecordingStore from '../../store/RecordingStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            height: '100%',
            // 謎のmargin: -2pxを上書きするため
            margin: '0 !important',
        },
        video: {
            width: '97%',
            height: '100%',
            display: 'block',
            margin: 'auto',
        },
        selected: {
            borderColor: theme.palette.primary.main,
            border: 'solid',
            display: 'inline-block',
        },
        gridListTile: {
            // GridListTileコンポーネントのdefaultのhegiht: 184pxを上書き
            height: '100% !important',
        },
    }),
);

interface Props {
    displayState: DisplayState;
    setDisplayState: React.Dispatch<React.SetStateAction<DisplayState>>;
}

export default observer(function RecordedVideoList(
    props: Props,
): React.ReactElement {
    const { displayState, setDisplayState } = props;
    const classes = useStyles();

    const onClickThumbnail = useCallback(
        (video: SavedToolImageMap) => {
            setDisplayState({
                displayType: 'playing',
                video: video.video,
                toolImage: video.toolImage,
            });
        },
        [setDisplayState],
    );
    return (
        <GridList className={classes.gridList} cols={5}>
            {RecordingStore.customerToolImageMap.map((video, i) => {
                return (
                    <GridListTile
                        key={i}
                        classes={{
                            tile: clsx(
                                displayState.video === video.video &&
                                    classes.selected,
                            ),
                        }}
                        className={classes.gridListTile}
                    >
                        {/* onPlayイベントは、iPadでのサムネイル非生成への対策。動画がレンダリングされた直後に再生を止めることでサムネイルのようにしている。 */}
                        <video
                            src={`${URL.createObjectURL(video.video)}`}
                            className={classes.video}
                            onClick={() => onClickThumbnail(video)}
                            autoPlay={true}
                            onPlay={(e) => {
                                // このイベントハンドラはiPadでのサムネイル非生成対策。動画のレンダリング直後に再生を停止しサムネイルのように見せる。
                                e.currentTarget.pause();
                            }}
                        />
                    </GridListTile>
                );
            })}
        </GridList>
    );
});
