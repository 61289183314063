import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { SavedToolImageMap } from '../../../store/RecordingStore';
import { colors } from '../../const/Styles';

const Container = styled.span`
    position: relative;
    margin: 0 10px;
`;

const StyledVideo = styled.video`
    height: 114px;
    width: 114px;
`;

const Overlay = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    color: ${colors.white};
    border: solid 2px ${colors.footerIconSelected};
    box-sizing: border-box;
    font-size: 64px;
    opacity: 0.8;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

type Props = {
    data: SavedToolImageMap;
    onClick: (data: SavedToolImageMap) => void;
    className?: string;
};

const RecordVideoThumbnail: React.FC<Props> = ({
    data,
    onClick,
    className,
}) => {
    const handleClick = () => {
        onClick(data);
    };
    const { recordingStore } = useStore();
    const video = recordingStore.getSingleRegisteredVideo(data.id);
    return (
        <Container onClick={handleClick} className={className}>
            <StyledVideo
                src={URL.createObjectURL(data.video)}
                controls={false}
                autoPlay={false}
            />
            {video && <Overlay>{video.number}</Overlay>}
        </Container>
    );
};

export default RecordVideoThumbnail;
