import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
    Card,
    Box,
    Container,
    GridList,
    GridListTile,
    GridListTileBar,
    Select,
    MenuItem,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import { useAsync } from 'react-use';
import { observer } from 'mobx-react-lite';

import DominantButton from '../form/DominantButton';
import { PlayingType } from './Video';
import Api, { MovieEntity } from '../../utils/Api';
import TempoStore from '../../store/TempoStore';
import RecordingStore from '../../store/RecordingStore';
import {
    ClubFilter,
    clubFilters,
    clubFilterUnset,
    getMovieComparer,
    getMoviePredicate,
    Order,
    orderMenu,
} from '../../utils/MovieSorting';
import {
    MovieDisplay,
    MovieFav,
    RecordedVideo,
    VisibleVideo,
} from '../../utils/Video';
import { LocationState as LocationStateForPlayingVideoCard } from '../video/PlayingVideoCard';
import {
    groupAsMultiMapBy,
    isNotNull,
    isNotUndefined,
    useCols,
} from '../../utils/utilityFuncs';
import FavButton from './FavButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainCard: {
            width: '90%',
            margin: '10px auto 116px auto',
        },
        buttonBox: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: 10,
        },
        lowerBox: {
            textAlign: 'center',
            minHeight: 48,
            padding: 10,
            position: 'fixed',
            bottom: 56,
            width: '100%',
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            justifyContent: 'space-around',
        },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        videoListContainer: {
            paddingBottom: 10,
        },
        title: {
            color: theme.palette.secondary.light,
        },
        titleBar: {
            background: 'rgba(0,0,0,0)',
        },
        selectedVideo: {
            outline: '5px solid blue',
            outlineOffset: '-5px',
        },
        higherBox: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '10px 0',
            height: 75,
        },
        blueButton: {
            backgroundColor: theme.palette.primary.light,
        },
        selectSideBox: {
            width: 296,
        },
        selectSideButton: {
            height: 48,
            display: 'block',
            margin: '0 auto 5px',
        },
        borderButton: {
            border: 'solid 3px fuchsia',
        },
        thumbnail: {
            width: '100%',
            height: '100%',
        },
    }),
);

enum VideoType {
    customer,
    coach,
    fav,
}

interface LocationState {
    from: string;
    customerId: string;
}

interface Props {
    playingType: PlayingType;
    setPlayingType: React.Dispatch<React.SetStateAction<PlayingType>>;
}

export default observer(function VideoListCard(props: Props) {
    const { playingType, setPlayingType } = props;
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const { customerToolImageMap } = RecordingStore;

    const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
    const [selectedTwoVideos, setSelectedTwoVideos] = useState<
        [string | null, string | null]
    >([null, null]);
    const selectedSrcs = useMemo(
        () =>
            playingType == PlayingType.single
                ? [selectedVideo]
                : selectedTwoVideos,
        [playingType, selectedVideo, selectedTwoVideos],
    );

    const [type, setType] = useState<VideoType>(VideoType.customer);
    const [club, setClub] = useState<ClubFilter>(clubFilterUnset);
    const [order, setOrder] = useState<Order>(0);

    const typeMenu = [
        { title: 'お客様動画一覧', value: 0 },
        { title: 'コーチ動画一覧', value: 1 },
        { title: 'お気にいり', value: 2 },
    ];

    const [videos, setVideos] = useState<MovieDisplay[]>([]);
    const visibleVideos = useMemo(() => {
        const visibleVideos: VisibleVideo[] = [
            ...(type !== VideoType.customer || club !== clubFilterUnset
                ? []
                : customerToolImageMap.map(
                      (video) =>
                          new RecordedVideo(video.video, video.toolImage),
                  )),
            ...videos
                .filter((video) => {
                    return (
                        video.filepath !== undefined && video.filepath !== ''
                    );
                })
                .filter(getMoviePredicate(club))
                .sort(getMovieComparer(order)),
        ];
        return visibleVideos;
    }, [type, club, customerToolImageMap, videos, order]);
    const selectedVisibleVideos: VisibleVideo[] | undefined = useMemo(() => {
        if (!selectedSrcs.every(isNotNull)) {
            return undefined;
        }
        const selectedVideoOptions = selectedSrcs.map((src) =>
            visibleVideos.find((video) => video.src === src),
        );
        if (!selectedVideoOptions.every(isNotUndefined)) {
            return undefined;
        }
        return selectedVideoOptions;
    }, [selectedSrcs, visibleVideos]);

    const [favChanging, setFavChanging] = useState(false);

    const fav = useAsync(async () => {
        if (favChanging) {
            return undefined;
        }
        const response = await Api.fetchFavMovies(
            Number(location.state.customerId),
        );
        const favs = response.map(
            (staffFavResponse) => new MovieFav(staffFavResponse),
        );
        const movies = favs.map((fav) => fav.movie);
        const favIdToFavs = groupAsMultiMapBy(favs, (value) => value.id);
        return { movies, favIdToFavs };
    }, [location.state.customerId, favChanging]);

    const onChangeType = useCallback(
        async (type: VideoType) => {
            setType(type);
            let movies: MovieDisplay[];
            if (type === VideoType.fav) {
                movies = fav.value?.movies ?? [];
            } else {
                let fetchedVideos: MovieEntity[];
                if (type === VideoType.customer) {
                    fetchedVideos = await Api.fetchCustomerMovies(
                        Number(location.state.customerId),
                    );
                } else {
                    const { selectedCoach } = TempoStore;
                    if (selectedCoach === undefined)
                        throw new Error('selectedCoach is undefined');
                    fetchedVideos = await Api.fetchStaffMovies(
                        selectedCoach.id,
                    );
                }
                movies = fetchedVideos.map(
                    (entity) => new MovieDisplay(entity),
                );
            }
            await Promise.all(movies.map((movie) => movie.fetchSrc()));
            setVideos(movies);
        },
        [fav.value?.movies, location, setVideos],
    );

    useAsync(() => onChangeType(VideoType.customer), []);

    const onClickVideo = useCallback(
        (url: string | undefined) => {
            if (url === undefined) return;
            if (selectedTwoVideos.includes(url)) {
                setSelectedTwoVideos(
                    selectedTwoVideos.map((old) =>
                        old === url ? null : old,
                    ) as [string | null, string | null],
                );
                return;
            }
            if (!selectedTwoVideos.includes(null)) {
                return;
            }
            setSelectedVideo(url);
        },
        [selectedTwoVideos, setSelectedTwoVideos, setSelectedVideo],
    );

    const history = useHistory<
        LocationStateForPlayingVideoCard & { from: string }
    >();
    const onClickStart = useCallback(() => {
        if (selectedVisibleVideos === undefined) {
            return;
        }
        history.push({
            pathname: '/video/play',
            state: {
                from: location.state.from,
                customerId: location.state.customerId,
                video: selectedVisibleVideos.map((video) => {
                    if (video.type === 'MovieDisplay') {
                        return {
                            id: Number(video.id),
                            type: 'movie' as const,
                            filepath: video.filepath,
                            filename: video.filename,
                            customerIdOrStaffId: video.customer_id_or_staff_id,
                            movieCreatedAt: video.created_at,
                            toolImageFilename: video.tool_image_filename,
                            toolImageFilePath: video.tool_image_filepath,
                        };
                    }
                    return {
                        type: 'blob' as const,
                        videoSrc: video.src,
                        blob: video.video,
                        toolImage: video.toolImage,
                    };
                }),
            },
        });
    }, [
        selectedVisibleVideos,
        history,
        location.state.customerId,
        location.state.from,
    ]);

    const unselectAll = useCallback(() => {
        setSelectedVideo(null);
        setSelectedTwoVideos((prevState) =>
            prevState.every((id) => id === null) ? prevState : [null, null],
        );
    }, [setSelectedVideo, setSelectedTwoVideos]);

    const cols = useCols();

    const onFav = async (movie: MovieDisplay) => {
        const selfStaffId = TempoStore.selectedCoach?.id;
        if (selfStaffId === undefined) {
            throw new Error('コーチが選択されていません');
        }
        const faved = fav.value?.favIdToFavs.has(movie.favId);
        setFavChanging(true);
        try {
            if (faved) {
                await Api.removeFavFromCustomerMovie(
                    movie.id,
                    selfStaffId,
                    movie.customer_id_or_staff_id,
                    movie.created_at,
                );
            } else {
                await Api.addFavToCustomerMovie(
                    movie.id,
                    selfStaffId,
                    movie.customer_id_or_staff_id,
                    movie.created_at,
                );
            }
        } finally {
            setFavChanging(false);
        }
    };

    // 前回ページのスクロール量をリセットし、要素の表示と実際の当たり判定のずれを解消するハック
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getGridListTileBar = (video: MovieDisplay | RecordedVideo) => {
        return (
            <GridListTileBar
                titlePosition='top'
                classes={{
                    root: classes.titleBar,
                    title: classes.title,
                }}
                actionIcon={
                    video.type === 'RecordedVideo' ? undefined : (
                        <FavButton
                            faved={fav.value?.favIdToFavs.has(video.favId)}
                            onClick={() => onFav(video)}
                            className={classes.title}
                        />
                    )
                }
            />
        );
    };

    return (
        <>
            <Container className={classes.higherBox}>
                <DominantButton onClick={unselectAll}>全解除</DominantButton>
                <DominantButton
                    className={clsx(
                        classes.blueButton,
                        playingType == PlayingType.single &&
                            classes.borderButton,
                    )}
                    onClick={() => setPlayingType(PlayingType.single)}
                >
                    単一再生
                </DominantButton>
                <DominantButton
                    className={clsx(
                        classes.blueButton,
                        playingType == PlayingType.double &&
                            classes.borderButton,
                    )}
                    onClick={() => setPlayingType(PlayingType.double)}
                >
                    同時再生
                </DominantButton>
            </Container>
            <Card className={classes.mainCard}>
                <Box className={classes.buttonBox}>
                    <Select
                        value={type}
                        variant='outlined'
                        onChange={async (e) => {
                            await onChangeType(e.target.value as number);
                        }}
                    >
                        {typeMenu.map((menu) => {
                            return (
                                <MenuItem key={menu.value} value={menu.value}>
                                    {menu.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        value={club}
                        variant='outlined'
                        onChange={(e) => {
                            setClub(e.target.value as ClubFilter);
                        }}
                    >
                        {clubFilters.map((menu) => (
                            <MenuItem key={menu} value={menu}>
                                {menu}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        value={order}
                        variant='outlined'
                        onChange={(e) => {
                            setOrder(e.target.value as Order);
                        }}
                    >
                        {orderMenu.map((menu) => {
                            return (
                                <MenuItem key={menu.value} value={menu.value}>
                                    {menu.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
                <Container className={classes.videoListContainer}>
                    <GridList cols={cols} spacing={5}>
                        {visibleVideos.map((video) => (
                            <GridListTile key={video.id}>
                                {video instanceof MovieDisplay ? (
                                    <img
                                        className={clsx(
                                            classes.thumbnail,
                                            video.src !== undefined &&
                                                selectedSrcs.includes(
                                                    video.src,
                                                ) &&
                                                classes.selectedVideo,
                                        )}
                                        src={video.thumbnail}
                                        onClick={() => onClickVideo(video.src)}
                                    />
                                ) : (
                                    <video
                                        className={clsx(
                                            classes.thumbnail,
                                            video.src !== undefined &&
                                                selectedSrcs.includes(
                                                    video.src,
                                                ) &&
                                                classes.selectedVideo,
                                        )}
                                        src={video.src}
                                        onClick={() => onClickVideo(video.src)}
                                        autoPlay={true}
                                        onPlay={(e) => {
                                            // このイベントハンドラはiPadでのサムネイル非生成対策。動画のレンダリング直後に再生を停止しサムネイルのように見せる。
                                            e.currentTarget.pause();
                                        }}
                                    />
                                )}
                                {getGridListTileBar(video)}
                            </GridListTile>
                        ))}
                    </GridList>
                </Container>
                {playingType == PlayingType.double && (
                    <Container className={classes.buttonBox}>
                        <Box className={classes.selectSideBox}>
                            <DominantButton
                                className={classes.selectSideButton}
                                onClick={() =>
                                    setSelectedTwoVideos((prevState) => [
                                        prevState[0] != null
                                            ? null
                                            : selectedVideo,
                                        prevState[1],
                                    ])
                                }
                            >
                                左動画へ
                            </DominantButton>
                            <Box>
                                {selectedTwoVideos[0] != null && (
                                    <video
                                        className={classes.thumbnail}
                                        src={selectedTwoVideos[0]}
                                        autoPlay={true}
                                        onPlay={(e) => {
                                            // このイベントハンドラはiPadでのサムネイル非生成対策。動画のレンダリング直後に再生を停止しサムネイルのように見せる。
                                            e.currentTarget.pause();
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.selectSideBox}>
                            <DominantButton
                                className={classes.selectSideButton}
                                onClick={() =>
                                    setSelectedTwoVideos((prevState) => [
                                        prevState[0],
                                        prevState[1] != null
                                            ? null
                                            : selectedVideo,
                                    ])
                                }
                            >
                                右動画へ
                            </DominantButton>
                            <Box>
                                {selectedTwoVideos[1] != null && (
                                    <video
                                        className={classes.thumbnail}
                                        src={selectedTwoVideos[1]}
                                        autoPlay={true}
                                        onPlay={(e) => {
                                            // このイベントハンドラはiPadでのサムネイル非生成対策。動画のレンダリング直後に再生を停止しサムネイルのように見せる。
                                            e.currentTarget.pause();
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Container>
                )}
            </Card>
            <Box className={classes.lowerBox}>
                {/* TODO  ticket245 レッスン中かどうかを判定して戻るボタンの表示 & 戻る機能の実装*/}
                {/* <DominantButton>
                    戻る
                </DominantButton> */}
                <DominantButton
                    onClick={() => {
                        history.push(location.state.from);
                    }}
                >
                    戻る
                </DominantButton>
                <DominantButton onClick={onClickStart}>
                    {playingType === PlayingType.double ? '同時再生' : '再生'}
                </DominantButton>
            </Box>
        </>
    );
});
