import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useAsync } from 'react-use';
import CustomerPages from '../components/customer';

import LessonPages from '../components/lesson';
import NotFound from '../components/NotFound';

import MerchantPages from '../components/merchant/';
import CoachVideoList from '../components/merchant/CoachVideoList';
import CurriculumSettings from '../components/merchant/CurriculumSettings';
import QuestionnaireSettings from '../components/merchant/QuestionnaireSettings';

import SwingPages from '../components/swing';
import Recording from '../components/video/Recording';
import Video from '../components/video/Video';
import { useStore } from '../contexts/StoreContext';
import AuthStore from '../store/AuthStore';

/** TODO: ドメインの定義をしたのでルーティングはもっとページに寄せたい */
export default observer(function MainScreen() {
    const { lessonStore, tempoStore } = useStore();
    useAsync(async () => {
        await Promise.all([
            tempoStore.fetchAllStaffs(),
            lessonStore.fetchAllCustomers(AuthStore.tempoId),
            tempoStore.fetchAllLessons(),
            tempoStore.fetchEnqueteItems(),
        ]);
    });

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/'>
                    <Redirect to='/lesson/select' />
                </Route>
                <Route path='/lesson/*'>
                    <LessonPages />
                </Route>
                <Route path='/customer/*'>
                    <CustomerPages />
                </Route>
                <Route path='/merchant'>
                    <MerchantPages />
                </Route>
                <Route exact path='/merchant/curriculum'>
                    <CurriculumSettings />
                </Route>
                <Route exact path='/merchant/questionnaire'>
                    <QuestionnaireSettings />
                </Route>
                <Route exact path='/merchant/videos'>
                    <CoachVideoList />
                </Route>
                <Route exact path='/recording'>
                    <Recording />
                </Route>
                <Route path='/video'>
                    <Video />
                </Route>
                <Route path='/swing/*'>
                    <SwingPages />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </React.Fragment>
    );
});
