import createStyles from '@material-ui/styles/createStyles'
import makeStyles from '@material-ui/styles/makeStyles'

import { observer } from 'mobx-react-lite'
import React, { Dispatch, useContext, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import leftArrow from '../../../assets/left_arrow.svg'
import { CustomerRegisterPropertyTabContext } from '../../../contexts/CustomerRegisterPropertyTabContext'
import { FormReducerAction } from '../../../hooks/useForm'
import TempoStore from '../../../store/TempoStore'
import { EnqueteAnswerForm } from '../../@types/request'
import {
    CUSTOMER_REGIST_DETAIL_PROFILE,
    CUSTOMER_REGIST_QUESTIONNAIRE
} from '../../const/RegisterProperties'
import { colors } from '../../const/Styles'
import QuestionnaireOptions from '../input/QuestionnaireOption'

const useStyles = makeStyles(() =>
    createStyles({
        wrap: {
            display: 'flex',
            color: `${colors.lightGray}`,
            borderBottom: `1px solid ${colors.lightGray}`,
        },
        formSet: {
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 30,
        },
        inputBox: {
            display: 'flex',
        },
        formInput: {
            flexGrow: 1,
        },
        textSelected: {
            color: 'grey',
        },
        textUnselected: {
            color: 'blue',
        },
        formTitle: {
            display: 'flex',
            fontWeight: 'bold',
            color: `${colors.lightGray3}`,
        },
        indexBox: {
            width: 25,
            fontSize: 35,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
        },
        leftArrowImgBox: {
            position: 'absolute',
            left: '-75px',
            bottom: '50%',
        },
        checkColor: {
            '&.Mui-checked': {
                color: `${colors.footerIconSelected}`,
            },
        },
    }),
);

interface Props {
    questionnaire: EnqueteAnswerForm;
    questionnaireDispatch: Dispatch<FormReducerAction<EnqueteAnswerForm>>;
}

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section`
    width: 536px;
    height: 580px;
    overflow-y: scroll;
    margin-top: 27px;
    position: 'relative',
    backgroundcolor: ${colors.white};
`;

const Wrapper = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    position: relative;
`;

const CustomerRegisterQuestionnaire: React.FC<Props> = ({ questionnaire, questionnaireDispatch }) => {
    const classes = useStyles();

    const answerStateMap = useMemo(() => {
        return [
            questionnaire.question_answer_1,
            questionnaire.question_answer_2,
            questionnaire.question_answer_3,
            questionnaire.question_answer_4,
            questionnaire.question_answer_5,
            questionnaire.question_answer_6,
            questionnaire.question_answer_7,
            questionnaire.question_answer_8,
            questionnaire.question_answer_9,
            questionnaire.question_answer_10,
        ]
    }, [questionnaire]);

    const handleInputStringValue = useCallback((key: keyof EnqueteAnswerForm) => {
        return (value: string) => {
            const valueMap = new Map<string, string>();
            valueMap.set(key, value);
            questionnaireDispatch({ value: Object.fromEntries(valueMap) })
        }
    }, [questionnaireDispatch])

    const setAnswerStateMap = useMemo(() => {
        return [
            handleInputStringValue('question_answer_1'),
            handleInputStringValue('question_answer_2'),
            handleInputStringValue('question_answer_3'),
            handleInputStringValue('question_answer_4'),
            handleInputStringValue('question_answer_5'),
            handleInputStringValue('question_answer_6'),
            handleInputStringValue('question_answer_7'),
            handleInputStringValue('question_answer_8'),
            handleInputStringValue('question_answer_9'),
            handleInputStringValue('question_answer_10'),
        ];
    }, [handleInputStringValue])


    const { selected, onChange } = useContext(CustomerRegisterPropertyTabContext);
    const visible = useMemo(() => {
        return CUSTOMER_REGIST_QUESTIONNAIRE === selected;
    }, [selected]);

    const moveDetailPage = () => {
        if (onChange) {
            onChange(CUSTOMER_REGIST_DETAIL_PROFILE);
        }
    };

    return (
        <Wrapper visible={visible}>
            <Container>
                {TempoStore.enqueteQuestionNames.map((question, i) => {
                    return question && (
                        <div key={i.toString()} className={classes.wrap}>
                            <div className={classes.indexBox}>{i + 1}.</div>
                            <div className={classes.formSet}>
                                <div className={classes.formTitle}>
                                    {question}
                                </div>
                                <div>
                                    <QuestionnaireOptions
                                        value={answerStateMap[i]}
                                        onChange={setAnswerStateMap[i]}
                                    ></QuestionnaireOptions>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Container>
            <div className={classes.leftArrowImgBox}>
                <img src={leftArrow} onClick={moveDetailPage} alt='' />
            </div>
        </Wrapper>
    );
}
export default observer(CustomerRegisterQuestionnaire);
