import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import { colors } from '../../const/Styles';
import FemaleIcon from '../../../assets/gender-female.svg';
import MaleIcon from '../../../assets/gender-male.svg';
import { ReactSVG } from 'react-svg';
import DisplayDate from '../../base/surface/DisplayDate';
import LearningStyleDescription from './LearingStyleDescription';

const Name = styled.label`
    display: block;
    font-size: 3rem;
    line-height: 1.2;
`;

const PhoneticName = styled(Name)`
    font-size: 1.2rem;
    line-height: 1;
    margin-bottom: 10px;
`;

const DescriptionLine = styled.dl`
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    dt {
        white-space: nowrap;
    }
    dd {
        margin: 0;
    }
`;

const PhisicalDescription = styled(DescriptionLine)`
    line-height: 1;
    width: 100%;
    padding-bottom: 2px;
    align-items: baseline;
    border-bottom: solid 1px ${colors.lightGray};
    dt {
        margin-right: 0.5rem;
    }
    dd {
        margin-right: 0.5rem;
    }
`;

const InfomationBox = styled.div`
    display: flex;
    flex-direction: column;
    border: solid 1px ${colors.black};
    position: relative;
    box-sizing: border-box;
`;

const Row = styled.div`
    display: flex;
    flex-direction row;
    width: 100%;
    flex-wrap: wrap;
`;

const Box = styled.div`
    display: flex;
    flex-direction column;
    width: fit-content;
    height: fit-content;
    align-items: center;
`;

const PhisicalBox = styled(Box)`
    padding: 10px;
    align-items: flex-start;
    flex-grow: 2;
`;

const BoxTitle = styled.label`
    display: flex;
    flex-direction: row;
    line-height: 1.1;
    width: fit-content;
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 0 10px;
`;

const GenderIcon = styled.i`
    display: block;
    box-sizing: border-box;
    margin: 20px 0;
    color: ${colors.lighterGray};
    svg {
        width: 40px;
        height: 40px;
    }
`;

type Props = {
    customer: CustomerDisplay;
};

const CustomerBasicProfileRightPain: React.FC<Props> = ({ customer }) => {
    const {
        name_sei,
        name_mei,
        name_sei_kana,
        name_mei_kana,
        purpose_begin,
        target,
        sex,
        date_of_birth,
        grip_right,
        grip_left,
        height,
        dominant_hand,
        personality_1,
        personality_2,
    } = customer;

    const genderIcon = useMemo(() => {
        if (sex === 'male') {
            return MaleIcon ?? '';
        }
        if (sex === 'female') {
            return FemaleIcon ?? '';
        }
        return '';
    }, [sex]);

    const dominantHand = useMemo(() => {
        switch (dominant_hand) {
            case 'left_handed':
                return '左利き';
            case 'right_handed':
                return '右利き';
            default:
                return '不明';
        }
    }, [dominant_hand]);
    return (
        <div>
            <Name>
                {name_sei}
                {name_mei}
            </Name>
            <PhoneticName>
                {name_sei_kana}
                {name_mei_kana}
            </PhoneticName>
            <DescriptionLine>
                <dt>受講理由：</dt>
                <dd>{purpose_begin}</dd>
            </DescriptionLine>
            <DescriptionLine>
                <dt>目標設定：</dt>
                <dd>{target}</dd>
            </DescriptionLine>
            <InfomationBox>
                <Row>
                    <Box>
                        <BoxTitle>基本情報</BoxTitle>
                        <GenderIcon>
                            <ReactSVG src={genderIcon}></ReactSVG>
                        </GenderIcon>
                    </Box>
                    <PhisicalBox>
                        <PhisicalDescription>
                            <dt>生年月日</dt>
                            <dd>
                                <DisplayDate source={date_of_birth} />
                            </dd>
                        </PhisicalDescription>
                        <PhisicalDescription>
                            <dt>身長</dt>
                            <dd>{height}cm</dd>
                        </PhisicalDescription>
                        <PhisicalDescription>
                            <dt>握力</dt>
                            <dd>左 :{grip_left}kg</dd>
                            <dd>右 :{grip_right}kg</dd>
                            <dd>
                                <BoxTitle>{dominantHand}</BoxTitle>
                            </dd>
                        </PhisicalDescription>
                    </PhisicalBox>
                </Row>
                <Row>
                    <LearningStyleDescription data={personality_1}>
                        学習スタイル１
                    </LearningStyleDescription>
                    <LearningStyleDescription data={personality_2}>
                        学習スタイル２
                    </LearningStyleDescription>
                </Row>
            </InfomationBox>
        </div>
    );
};

export default CustomerBasicProfileRightPain;
