import React from 'react';
import styled from 'styled-components';
import AngleIcon from '../../../assets/angle.svg';
import CircleIcon from '../../../assets/circle.svg';
import ForwardTiltGuideIcon from '../../../assets/forward-tilt-guide.svg';
import FreeHandIcon from '../../../assets/freehand.svg';
import FrontGuideIcon from '../../../assets/front-guide.svg';
import RectangleIcon from '../../../assets/rectangle.svg';
import StraightLineIcon from '../../../assets/straight-line.svg';
import SwingPlaneIcon from '../../../assets/swingplane.svg';
import { LineColor, ToolType } from '../../../hooks/useEditorCanvas';
import ColorPicker from '../surface/ColorPicker';
import ToolItem from '../surface/ToolItem';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    top: 0;
    right: 0;
    box-sizing: border-box;
    height: 100%;
    padding: 12px;
    z-index: 9999;
`;

const tools = [
    {
        label: '直線',
        type: ToolType.straightLine,
        icon: StraightLineIcon ?? '',
    },
    { label: '角度付直線', type: ToolType.angle, icon: AngleIcon ?? '' },
    {
        label: 'フリーハンド',
        type: ToolType.freeHand,
        icon: FreeHandIcon ?? '',
    },
    { label: '四角', type: ToolType.rectangle, icon: RectangleIcon ?? '' },
    { label: '円', type: ToolType.circle, icon: CircleIcon ?? '' },
    {
        label: 'スイングプレーン',
        type: ToolType.swingPlane,
        icon: SwingPlaneIcon ?? '',
    },
    {
        label: '正面ガイド',
        type: ToolType.frontGuid,
        icon: FrontGuideIcon ?? '',
    },
    {
        label: '前傾ガイド',
        type: ToolType.forwardTiltGuid,
        icon: ForwardTiltGuideIcon ?? '',
    },
];

type Props = {
    toolVisible: boolean;
    selected: ToolType;
    selectedColor: LineColor;
    onClick?: (value: ToolType) => void;
    onLineColorChange?: (value: LineColor) => void;
};

const MovieEditorToolRight: React.FC<Props> = ({
    toolVisible,
    selected,
    selectedColor,
    onClick,
    onLineColorChange,
}) => {
    return (
        <Container>
            {toolVisible && (
                <>
                    <ColorPicker
                        value={selectedColor}
                        onChange={onLineColorChange}
                    />
                    {tools.map(({ label, type, icon }) => (
                        <ToolItem
                            key={type}
                            icon={icon}
                            value={type}
                            selected={selected}
                            onClick={onClick}
                        >
                            {label}
                        </ToolItem>
                    ))}
                </>
            )}
        </Container>
    );
};

export default MovieEditorToolRight;
