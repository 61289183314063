import React, { useMemo } from 'react';
import { ErrorMessages } from '../../../utils/Api';

type Props = {
    field: string;
    errors?: ErrorMessages;
};

const ErrorMessage: React.FC<Props> = ({ field, errors }) => {
    const messages = useMemo(() => {
        const errorMap = new Map<string, string[]>(Object.entries(errors ?? {}));
        return errorMap.get(field) ?? [];
    }, [errors, field]);
    if (messages.length === 0) {
        return null;
    }
    return (
        <p style={{ color: '#d50000' }}>
            {messages[0]}
        </p>
    );
}

export default ErrorMessage;
