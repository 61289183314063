import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Icon from '../../../assets/windows.svg';
import { CustomerDisplay } from '../../../store/LessonStore';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    background-color: ${colors.white};
    box-sizing: border-box;
    border-top: solid 2px ${colors.lightGray4};
    padding: 10px 0;
    align-items: center;
`;

const IconWrapper = styled.i`
    width: 35px;
    height: 35px;
    color: ${colors.footerIconSelected};
`;

const Label = styled.label`
    display: block;
    color: ${colors.footerIconSelected};
    font-size: 1.2rem;
    padding-left: 20px;
`;

type Props = {
    customer: CustomerDisplay;
};

const ToSwingListFooterNavigation: React.FC<Props> = ({ customer }) => {
    const history = useHistory();
    const handleClick = () => {
        history.push(Routes.SWING_LIST, {
            customerId: customer.id,
            from: Routes.LIBRARY_DETAIL,
        });
    };

    return (
        <Container>
            <IconWrapper onClick={handleClick}>
                <ReactSVG src={Icon ?? ''} />
            </IconWrapper>
            <Label onClick={handleClick}>スイング一覧</Label>
        </Container>
    );
};

export default ToSwingListFooterNavigation;
