import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import CloseModalBtn from '../../../assets/close_modalbtn.svg';

const useStyles = makeStyles(() =>
    createStyles({
        closeBtnBox: {
            position: 'absolute',
            top: 20,
            right: 20,
        },
    }),
);

type Props = {
    isOpended: boolean;
    onClose: () => void;
};

function CloseModalButton(props: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.closeBtnBox}>
            <img src={CloseModalBtn} onClick={props.onClose} />
        </div>
    );
}

export default CloseModalButton;
