import { createStyles, makeStyles } from '@material-ui/core';
import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';
import FavButton from '../components/video/FavButton';
import { useStore } from '../contexts/StoreContext';
import Api from '../utils/Api';
import { MovieDisplay } from '../utils/Video';

const FavBtnBox = styled.div`
    display flex;
    flex-direction: column;
    z-index: 3;
    opacity: 0.7;
    color:white;
`;

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            width: 40,
            height: 40,
            color: 'white',
        },
    }),
);

type FavedCallbackType = (video: MovieDisplay) => void;

interface FavoriteIconProps extends HTMLAttributes<HTMLDivElement> {
    faved: boolean;
    onClick: () => void;
}

export const FavoriteIcon: React.FC<FavoriteIconProps> = ({
    faved,
    onClick,
    className,
}) => {
    const classes = useStyles();
    return (
        <FavBtnBox onClick={onClick} className={className}>
            <FavButton faved={faved} className={classes.title} />
        </FavBtnBox>
    );
};

export const useFavorite = (
    video?: MovieDisplay,
    callback?: FavedCallbackType,
): [boolean, () => void] => {
    const { tempoStore } = useStore();
    const { selectedCoach, favoriteMovies } = tempoStore;
    const [faved, setFaved] = useState(false);

    useEffect(() => {
        setFaved(favoriteMovies.some((p) => p.movie.id === video?.id));
    }, [favoriteMovies, video]);

    const [, dispatcher] = useAsyncFn(async () => {
        if (!selectedCoach) {
            throw new Error('コーチが選択されていません');
        }
        if (!video) {
            throw new Error('動画が選択されていません');
        }
        const { id: selfStaffId } = selectedCoach;
        const { id, customer_id_or_staff_id, created_at } = video;
        if (faved === true) {
            await Api.removeFavFromCustomerMovie(
                id,
                selfStaffId,
                customer_id_or_staff_id,
                created_at,
            );
        } else {
            await Api.addFavToCustomerMovie(
                id,
                selfStaffId,
                customer_id_or_staff_id,
                created_at,
            );
        }
        setFaved(!faved);
        if (callback) {
            callback(video);
        }

        // TODO: APIがcustomer_id_or_staff_idにcustomer_id（owner_id）を入れてくれるのでコーチのお気に入り動画が取得されることはないが、後の実装者に混乱を与えそう
        await tempoStore.reloadFavoriteMovies(Number(customer_id_or_staff_id));
    }, [callback, faved, selectedCoach, video]);

    const handleClickFav = useCallback(() => {
        dispatcher().finally(() => undefined);
    }, [dispatcher]);

    return [faved, handleClickFav];
};
