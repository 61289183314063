export const CustomerTabProperies: { [key: number]: string } = {
    1: '詳細情報',
    2: 'アンケート',
    3: '過去のレッスン',
};

export const CUSTOMER_TAB_DETAIL_PROFILE = 1;
export const CUSTOMER_TAB_QUESTIONNAIRE = 2;
export const CUSTOMER_TAB_PAST_LESSONS = 3;

export type CustomerTabPropertyType =
    | typeof CUSTOMER_TAB_DETAIL_PROFILE
    | typeof CUSTOMER_TAB_QUESTIONNAIRE
    | typeof CUSTOMER_TAB_PAST_LESSONS;
