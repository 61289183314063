import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
} from '../../../hooks/useEditorCanvas';
import {
    Values as StampValues,
    Actions as StampActions,
} from '../../../hooks/useEditorGuidStamp';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgStampRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    stampValues: StampValues;
    stampActions: StampActions;
    height: number;
    width: number;
}

function FrontGuidSVG(props: Props): React.ReactElement {
    const {
        svgStampRef,
        canvasValues,
        stampValues,
        stampActions,
        height,
        width,
    } = props;
    const classes = useStyles();
    return (
        <svg
            ref={svgStampRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.frontGuid && classes.front,
            )}
            onPointerDown={(e) =>
                stampActions.stamp(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
            }
            onPointerUp={stampActions.onMouseUp}
            onPointerMove={(e) =>
                stampActions.onMouseMove(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                )
            }
        >
            <g display={stampValues.display ? 'inline' : 'none'}>
                <line
                    x1={stampValues.x - 100}
                    y1={stampValues.y}
                    x2={stampValues.x + 100}
                    y2={stampValues.y}
                    stroke='yellow'
                />
                <line
                    x1={stampValues.x}
                    y1={stampValues.y - 220}
                    x2={stampValues.x}
                    y2={stampValues.y + 125}
                    stroke='yellow'
                />
                <line
                    x1={stampValues.x}
                    y1={stampValues.y}
                    x2={stampValues.x}
                    y2={stampValues.y - 220}
                    stroke='blue'
                    transform={`rotate(349, ${stampValues.x}, ${stampValues.y})`}
                />
                <line
                    x1={stampValues.x}
                    y1={stampValues.y}
                    x2={stampValues.x}
                    y2={stampValues.y - 220}
                    stroke='red'
                    transform={`rotate(353, ${stampValues.x}, ${stampValues.y})`}
                />
                <circle
                    cx={stampValues.x}
                    cy={stampValues.y}
                    r='8'
                    fill='yellow'
                />
            </g>
        </svg>
    );
}

export default FrontGuidSVG;
