import React, { useMemo } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import { MovieDisplay } from '../../../utils/Video';

import CircularProgress from '@material-ui/core/CircularProgress';
import { FavoriteIcon, useFavorite } from '../../../hooks/useFavorite';

const Container = styled.figure`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 200px;
    max-width: calc(50% - 20px);
    margin: 0;
    box-sizing: border-box;
    position: relative;
`;

const Img = styled.img`
    height: auto;
    width: 100%;
    object-fit: cover;
`;

const Description = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 10px;
    .clubtype {
        font-weight: bold;
    }
`;

const FavIcon = styled(FavoriteIcon)`
    position: absolute;
    top: 0;
    right: 0;
    .MuiIconButton-root {
        padding: 0;
    }
    .MuiSvgIcon-root {
        width: 30px;
        height: 30px;
    }
`;

type Props = {
    movie: MovieDisplay;
    onClick?: (movie: MovieDisplay) => void;
};

const MovieThumbnail: React.FC<Props> = ({ movie, onClick }) => {
    // MEMO: サムネ出力が何故か動画と一体になっていないような実装になっている
    // なのでサムネが読み込み可能な場合のみ表示するようにする
    const { loading, error, value } = useAsync(async () => {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await movie.fetchSrc();
        return movie;
    }, []);

    const [faved, toggleFav] = useFavorite(movie);

    const club = useMemo(() => {
        return movie.club ? `●${movie.club}` : '';
    }, [movie.club]);

    const yards = useMemo(() => {
        return movie.yards ? `${movie.yards}y` : '';
    }, [movie.yards]);

    const handleClickImage = () => {
        if (onClick) {
            onClick(movie);
        }
    };

    if (loading === true) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return <Container>サムネイルの読み込みに失敗しました</Container>;
    }

    if (!value?.thumbnail) {
        return null;
    }

    return (
        <Container>
            <FavIcon faved={faved} onClick={toggleFav} />
            {value?.thumbnail && (
                <Img src={value?.thumbnail} onClick={handleClickImage}></Img>
            )}
            <Description>
                <span className='clubtype'>{club}</span>
                <span>{yards}</span>
            </Description>
        </Container>
    );
};

export default MovieThumbnail;
