import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import PickerIcon from '../../../assets/color-picker.svg';
import { LineColor, lineColorToRGB } from '../../../hooks/useEditorCanvas';
import { colors } from '../../const/Styles';
import ColorPalletButton from './ColorPalletButton';

const Container = styled.i`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const Label = styled.label`
    display: block;
    color: ${colors.white};
    font-size: 10px;
`;

type PalletContainerProps = {
    isVertical: boolean;
    visible: boolean;
};
const ColorPalletContainer = styled.div<PalletContainerProps>`
    position: absolute;
    left: ${({ isVertical }) => (isVertical ? '0' : '-145px')};
    bottom: ${({ isVertical }) => (isVertical ? '-145px' : '0')};
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
    padding: 10px;
    justify-content: space-between;
    background-color: ${colors.black}B3;
    border-radius: 4px;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    > span {
        display: flex;
        flex-direction: row;
        span {
            display: flex;
            flex-direction: row;
        }
        svg {
            circle {
                fill: ${colors.black};
            }
            width: 50px;
            height: 50px;
        }
    }
`;

type Props = {
    value: LineColor;
    vertical?: boolean;
    onChange?: (value: LineColor) => void;
};

const pickables = [
    { color: lineColorToRGB[LineColor.red], type: LineColor.red },
    { color: lineColorToRGB[LineColor.blue], type: LineColor.blue },
    { color: lineColorToRGB[LineColor.yellow], type: LineColor.yellow },
    { color: lineColorToRGB[LineColor.green], type: LineColor.green },
];

const ColorPicker: React.FC<Props> = ({
    value,
    vertical = false,
    onChange,
}) => {
    const [visible, setVisible] = useState(false);
    const handleClickContainer = () => {
        setVisible(!visible);
    };
    return (
        <Container onClick={handleClickContainer}>
            <IconContainer>
                <ColorPalletContainer isVertical={vertical} visible={visible}>
                    {visible &&
                        pickables.map(({ color, type }) => (
                            <ColorPalletButton
                                key={type}
                                value={type}
                                onClick={onChange}
                                selected={value === type}
                                bgColor={color}
                            />
                        ))}
                </ColorPalletContainer>
                <ReactSVG src={PickerIcon ?? ''} wrapper='span' />
            </IconContainer>
            <Label>カラー</Label>
        </Container>
    );
};

export default ColorPicker;
