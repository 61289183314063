import React from 'react';
import styled from 'styled-components';
import { ProjectileType } from '../../const/Projectile';
import { colors } from '../../const/Styles';

const ButtonLike = styled.label`
    display: flex;
    margin: 0;
    flex-direction: row;
    height: fit-content;
    padding: 10px;
    justify-content: center;
    min-width: 110px;
    width: 100%;
    background-color: ${colors.lighterGray};
    color: ${colors.white};
    border-radius: 4px;
    box-sizing: border-box;
    &.selected {
        background-color: ${colors.blue};
    }
    input {
        display: none;
    }
`;

type Props = {
    selected: string;
    value: ProjectileType;
    onClick: (value: ProjectileType) => void;
};
const ProjectileButton: React.FC<Props> = ({ selected, value, onClick }) => {
    const handleChangeRadio = () => {
        onClick(value);
    };
    return (
        <ButtonLike className={value === selected ? 'selected' : ''}>
            <input
                type='radio'
                name='projectile'
                value={value}
                onChange={handleChangeRadio}
            />
            {value}
        </ButtonLike>
    );
};

export default ProjectileButton;
