import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid ${colors.lighterGray};
    padding: 5px 0;
    box-sizing: border-box;
    font-size: 15pt;
`;

const Label = styled.label`
    display: block;
    flex-direction: row;
    min-width: 11em;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    padding-right: 60px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    > input {
        font-size: 15pt;
    }
`;

const RequiredMark = styled.span`
    color: ${colors.red};
`;

type Props = {
    title?: string;
    required?: boolean;
};

const InlineFormLayout: React.FC<Props> = ({
    title,
    required = false,
    children,
}) => {
    return (
        <Container>
            <Label>
                {title}
                {required === true && <RequiredMark>*</RequiredMark>}
            </Label>
            <InputContainer>{children}</InputContainer>
        </Container>
    );
};

export default InlineFormLayout;
