import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
    LessonPropertyType,
    TODAY_LESSON,
} from '../components/const/LessonProperties';

type State = {
    selected: LessonPropertyType;
    onChange: (value: LessonPropertyType) => void;
};

export const LessonPropertyTabContext = createContext<State>({
    selected: TODAY_LESSON,
    onChange: () => undefined,
});

export const LessonPropertyTabProvider: React.FC = ({ children }) => {
    const [selected, setSelected] = useState<LessonPropertyType>(TODAY_LESSON);

    const handleChange = useCallback((value: LessonPropertyType) => {
        setSelected(value);
    }, []);

    const context = useMemo<State>(() => {
        return {
            selected,
            onChange: handleChange,
        };
    }, [selected, handleChange]);

    return (
        <LessonPropertyTabContext.Provider value={context}>
            {children}
        </LessonPropertyTabContext.Provider>
    );
};
