const base64ToFile = (base64: string, fileName: string): File | false => {
    let file;
    const bin = atob(base64.replace(/^.\*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    try {
        file = new File([buffer.buffer], fileName, {
            type: 'image/png',
        });
    } catch (e) {
        return false;
    }
    return file;
};

export default base64ToFile;
