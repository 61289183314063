import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';

import { useStore } from '../../../contexts/StoreContext';
import Api, { LessonMasterEntity } from '../../../utils/Api';
import { colors } from '../../const/Styles';

const useStyles = makeStyles(() =>
    createStyles({
        lessonName: {
            width: 472,
            height: 35,
            borderRadius: 0,
            margin: 'auto',
            display: 'flex',
            padding: 10,
            marginTop: 10,
            position: 'relative',
        },
        formTitle: {
            margin: 'auto',
            fontWeight: 'bold',
            color: `${colors.footerIconUnSelected}`,
        },
        lessonNameForm: {
            margin: 'auto',
            width: 370,
            borderRadius: 1,
        },
        button: {
            color: `${colors.black}`,
            height: 21,
            margin: 'auto',
        },
        createButton: {
            backgroundColor: `${colors.footerIconSelected}`,
            position: 'absolute',
            top: 17,
            right: 20,
            zIndex: 100,
            fontWeight: 'bold',
        },
    })
);

type Props = {
    visible: boolean,
    tempoId: string;
    onSaved?: (lessonMaster: LessonMasterEntity) => void;
}

const LessonMasterNameInputCard: React.FC<Props> = ({ visible, tempoId, onSaved }) => {
    const { tempoStore } = useStore();
    const classes = useStyles();
    const [lessonName, setLessonName] = useState('');
    const handleChangeLessonName = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setLessonName(event.target.value);
    }
    const handleClickSave = useCallback(async () => {
        // LessonMasterの新規登録
        const savedLessonMaster = await Api.createLessonCourse(tempoId, lessonName);
        tempoStore.addLesson(savedLessonMaster);
        setLessonName('');
        if (onSaved) {
            onSaved(savedLessonMaster);
        }
    }, [lessonName, setLessonName, tempoId, tempoStore, onSaved]);

    if (!visible) {
        // MEMO: 新規登録のコース名を選択された場合のみ表示する
        return <Fragment></Fragment>
    }

    return (
        <Card className={classes.lessonName}>
            <Typography className={classes.formTitle}>
                レッスン名:
            </Typography>
            <TextField
                className={classes.lessonNameForm}
                variant='outlined'
                value={lessonName}
                onChange={handleChangeLessonName}
                inputProps={{
                    style: {
                        padding: 5,
                        borderRadius: 0,
                    },
                }}
            />
            <Button
                className={`${classes.button} + ${classes.createButton}`}
                variant='contained'
                onClick={handleClickSave}
            >
                作成
            </Button>
        </Card>
    );
}

export default LessonMasterNameInputCard;
