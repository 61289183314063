import { useState, useCallback } from 'react';

import { LineColor } from './useEditorCanvas';

interface LineValues {
    startX: number | null;
    startY: number | null;
    endX: number | null;
    endY: number | null;
    color: LineColor;
}

export interface Values {
    startX: number | null;
    startY: number | null;
    endX: number | null;
    endY: number | null;
    color: LineColor;
    drawnLines: LineValues[];
}

export interface Actions {
    onPointerDown: (x: number, y: number, color: LineColor) => void;
    onPointerMove: (x: number, y: number) => void;
    onPointerUp: () => void;
    reset: () => void;
}

const useEditorLine = (): [Values, Actions] => {
    const [startX, setStartX] = useState<number | null>(null);
    const [startY, setStartY] = useState<number | null>(null);
    const [endX, setEndX] = useState<number | null>(null);
    const [endY, setEndY] = useState<number | null>(null);
    const [color, setColor] = useState<LineColor>(LineColor.red);
    const [drawing, setDrawing] = useState(false);
    const [drawnLines, setDrawnLines] = useState<LineValues[]>([]);

    const onPointerDown = useCallback(
        (x: number, y: number, color: LineColor) => {
            setDrawing(true);
            setStartX(x);
            setStartY(y);
            setColor(color);
        },
        [],
    );

    const onPointerMove = useCallback(
        (x: number, y: number) => {
            if (drawing) {
                setEndX(x);
                setEndY(y);
            }
        },
        [drawing],
    );

    const onPointerUp = useCallback(() => {
        setDrawing(false);
        setDrawnLines([
            ...drawnLines,
            {
                startX,
                startY,
                endX,
                endY,
                color,
            },
        ]);
        setStartX(0);
        setStartY(0);
        setEndX(0);
        setEndY(0);
    }, [color, drawnLines, endX, endY, startX, startY]);

    const reset = useCallback(() => {
        setDrawnLines([]);
        setStartX(0);
        setStartY(0);
        setEndX(0);
        setEndY(0);
    }, []);

    const values = {
        startX,
        startY,
        endX,
        endY,
        color,
        drawnLines,
    };
    const actions = { onPointerDown, onPointerMove, onPointerUp, reset };

    return [values, actions];
};

export default useEditorLine;
