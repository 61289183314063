export const colors = {
    white: '#FFFFFF',
    black: '#1E1E1E',
    blue: '#0067B2',
    gray: '#333333',
    lightGray: '#787878',
    lightGray2: '#606060',
    lightGray3: '#5B5B5B',
    lightGray4: '#C5C5C5',
    lighterGray: '#C8C8C8',
    formBg: '#F1F1F1',
    footerIconUnSelected: '#787878',
    footerIconSelected: '#d5b45c',
    red: '#97295A',
    vividRed: '#C32300',
};

export const border = {
    none: 'none',
    gray: '#C8C8C8',
};

export const iconSize = {
    footer: '24px',
    header: '24px',
    profileSm: '20px',
    profileMd: '35px',
};

/** 選択した顧客の枠の色 */
export const profileBorders = [
    '#0083CF',
    '#219F7E',
    '#FFA500',
    '#E16503',
    '#C32300',
];
export const profileNoSelectedBorder = '#1E1E1E';

export const MerChantBackGroundColor = '#F1F1F1';
export const movietype = {
    backgroundColor: '#707070',
};
