import React from 'react';
import styled from 'styled-components';
import ProjectileImage from '../display/ProjectileImage';

import InlineFormLayout from '../layout/InlineFormLayout';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

type Props = {
    selected?: string | null | undefined;
    onChange?: (value: string) => void;
};

const ProjectileSelector: React.FC<Props> = ({ selected, onChange }) => {
    return (
        <Container>
            <InlineFormLayout title='球筋'>{selected}</InlineFormLayout>
            <ProjectileImage selected={selected} onConfirm={onChange} />
        </Container>
    );
};

export default ProjectileSelector;
