import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
    lineColorToRGB,
} from '../../../hooks/useEditorCanvas';
import {
    Values as RectangleValues,
    Actions as RectangleActions,
} from '../../../hooks/useEditorRectangle';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgRectangleRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    rectValues: RectangleValues;
    rectActions: RectangleActions;
    height: number;
    width: number;
}

function RectangleSVG(props: Props): React.ReactElement {
    const {
        svgRectangleRef,
        canvasValues,
        rectValues,
        rectActions,
        height,
        width,
    } = props;
    const classes = useStyles();

    return (
        <svg
            ref={svgRectangleRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.rectangle && classes.front,
            )}
            onPointerDown={(e) => {
                rectActions.onPointerDown(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                    canvasValues.lineColor,
                );
            }}
            onPointerMove={(e) => {
                rectActions.onPointerMove(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                );
            }}
            onPointerUp={rectActions.onPointerUp}
        >
            {rectValues.drawnRectangles.map((rect) => {
                return (
                    <rect
                        key={rect.firstX}
                        x={rect.upperLeftX}
                        y={rect.upperLeftY}
                        width={rect.width}
                        height={rect.height}
                        fill='transparent'
                        stroke={lineColorToRGB[rect.color]}
                    />
                );
            })}
            <rect
                x={rectValues.upperLeftX}
                y={rectValues.upperLeftY}
                width={rectValues.width}
                height={rectValues.height}
                fill='transparent'
                stroke={lineColorToRGB[rectValues.color]}
            />
        </svg>
    );
}

export default RectangleSVG;
