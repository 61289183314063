import React from 'react';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import {
    border,
    colors,
    iconSize,
    profileBorders,
    profileNoSelectedBorder,
} from '../../const/Styles';
import CustomerImage from './CustomerImage';
import TimesIcon from './TimesIcon';

type WrapperProps = {
    borderColor: string;
};

const Wrapper = styled.article<WrapperProps>`
    display: flex;
    width: 100%;
    position: relative;
    border: solid 4px ${({ borderColor }) => borderColor};
    box-sizing: border-box;
    min-height: 161px;
    height: 100%;
`;

const NameContainer = styled.div`
    display flex;
    flex-direction: column;
    z-index: 3;
    background-color: ${colors.black};
    opacity: 0.7;
    position: absolute;;
    width: 100%;
    bottom: 0;
    left: 0;
`;

const NameLabel = styled.label`
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    color: ${colors.white};
    text-align: center;
`;

const KanaLabel = styled(NameLabel)`
    font-size: 0.4rem;
`;

const IconWrapper = styled.i`
    position: absolute;
    top: 6px;
    right: 4px;
`;

type Props = {
    customer: CustomerDisplay;
    selectedIndex: number;
    isSelected: boolean;
    onUnSelect: (customer: CustomerDisplay) => void;
    onSelect: (customer: CustomerDisplay) => void;
};

const CustomerSelectableCard: React.FC<Props> = ({
    customer,
    isSelected,
    selectedIndex,
    onUnSelect,
    onSelect,
}) => {
    const iconColor =
        isSelected === true
            ? profileBorders[selectedIndex % profileBorders.length]
            : profileNoSelectedBorder;
    const borderColor = iconColor;

    const handleSelect = () => {
        if (isSelected === false) {
            onSelect(customer);
        }
        if (isSelected === true) {
            onUnSelect(customer);
        }
    };

    const handleUnSelect = () => {
        if (isSelected === true) {
            onUnSelect(customer);
        }
    };

    return (
        <Wrapper onClick={handleSelect} borderColor={borderColor}>
            <CustomerImage
                color={border.none}
                iconColor={iconColor}
                src={customer?.s3Path}
            ></CustomerImage>
            <NameContainer>
                <NameLabel>
                    {customer.name_sei} {customer.name_mei}
                </NameLabel>
                <KanaLabel>
                    {customer?.name_sei_kana} {customer?.name_mei_kana}
                </KanaLabel>
            </NameContainer>
            {isSelected === true && (
                <IconWrapper>
                    <TimesIcon
                        color={borderColor}
                        size={iconSize.profileMd}
                        onClick={handleUnSelect}
                    ></TimesIcon>
                </IconWrapper>
            )}
        </Wrapper>
    );
};

export default CustomerSelectableCard;
