import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 150px;
    justify-conten: center;
    box-sizing: border-box;
    border-top: 2px solid ${colors.lighterGray};
    width: 100%;
`;

const ActionButton = styled.button`
    display: flex;
    flex-direction: row;
    padding: 3px;
    outline: none;
    border: none;
    border-radius: 4px;
    color: ${colors.black};
    background-color: ${colors.footerIconSelected};
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    width: 100%;
`;

type Props = {
    onClick: () => void;
};
const MovieEditFooter: React.FC<Props> = ({ children, onClick }) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <Container>
            <ActionButton type='button' onClick={handleClick}>
                {children}
            </ActionButton>
        </Container>
    );
};

export default MovieEditFooter;
