import React from 'react';
import { Route } from 'react-router-dom';
import { VideoTypeTabContextProvider } from '../../contexts/VideoTypeTabContext';
import { Routes } from '../const/Routes';
import SwingListPage from './list';
import PlayDoublePage from './playdouble';
import PlaySinglePage from './playsingle';

const SwingTopLists: React.FC = () => {
    return (
        <React.Fragment>
            <VideoTypeTabContextProvider>
                <Route path={Routes.SWING_LIST} exact>
                    <SwingListPage />
                </Route>
                <Route path={Routes.SWING_PLAY_SINGLE} exact>
                    <PlaySinglePage />
                </Route>
                <Route path={Routes.SWING_PLAY_DOUBLE} exact>
                    <PlayDoublePage />
                </Route>
            </VideoTypeTabContextProvider>
        </React.Fragment>
    );
};

export default SwingTopLists;
