import React, { useCallback, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import TrushIcon from '../../../assets/trush.svg';
import { useStore } from '../../../contexts/StoreContext';
import { usePastLessonCurriculumMap } from '../../../hooks/useQuestionnaireMapCreater';
import { CustomerDisplay } from '../../../store/LessonStore';
import TempoStore from '../../../store/TempoStore';
import { LessonDisplay } from '../../../utils/Lesson';
import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import MovieThumbnails from './MovieThumbnails';


const Container = styled.article`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    color: ${colors.white};
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    color: ${colors.white};
`;

const LessonHeaderContainer = styled.div`
    display: grid;
    padding: 5px;
    gap: 0 5px;
    grid-template-columns: 1fr repeat(2, min-content);
    border-top: solid 1px ${colors.white};
    border-bottom: solid 1px ${colors.white};
    background-color: ${colors.gray};
    box-sizing: border-box;
`;

const LessonHeaderColumn = styled.p`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    color: ${colors.white};
    font-weight: 0.8rem;
    font-size: 1.1em;
    font-weight: bold;
`;

const Comment = styled.div`
    display: block;
    white-space: pre-wrap;
    box-sizing: border-box;
    padding: 5px;
    font-size: 0.8rem;
`;

const CurriculumList = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    border-top: solid 1px ${colors.white};
    list-style: none;
`;
const CurriculumRow = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px ${colors.white};
    padding: 5px 20px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
`;

const Icon = styled.i`
    display: flex;
    color: ${colors.footerIconSelected};
    cursor: pointer;
    svg {
        height: 32px;
    }
`;

type Props = {
    customer: CustomerDisplay;
    lesson: LessonDisplay;
    movies: MovieDisplay[];
    onChange: () => void
};

const PastLesson: React.FC<Props> = ({ customer, lesson, movies, onChange }) => {
    const { created_date_string, comment } = lesson;
    const curriculumsMap = usePastLessonCurriculumMap(customer, lesson);

    const currentCoach = useMemo(() => {
        return TempoStore.includeDeletedStaffs.find(
            (coach) => `${coach.id}` === lesson.staff_id,
        )
    }, [lesson.staff_id]);

    const coachName = useMemo(() => {
        if (typeof currentCoach === 'undefined' || typeof currentCoach.deleted_at === 'string') {
            return '削除済みコーチ';
        }

        return `${currentCoach.name_sei} ${currentCoach.name_mei}`;
    }, [currentCoach]);

    const { lessonStore } = useStore();

    const handleClickIcon = useCallback(async () => {
        if (!confirm('このレッスンを削除してもよろしいですか？')) {
            return;
        }

        if (!confirm('削除するとスイング映像も削除されます。\nそれでも削除しますか？')) {
            return;
        }

        try {
            await lessonStore.deletePastLesson(lesson.customer_id, lesson.lesson_id);
            alert('レッスンを削除しました。')
            onChange()
        } catch (error: unknown) {
            alert('レッスンの削除に失敗しました。\n再度時間をおいて実行してください。');
        }

    }, [lesson, lessonStore, onChange]);

    return (
        <Container>
            <LessonHeaderContainer>
                <LessonHeaderColumn>
                    {coachName}
                </LessonHeaderColumn>
                <LessonHeaderColumn>{created_date_string}</LessonHeaderColumn>
                <Icon onClick={handleClickIcon}>
                    <ReactSVG src={TrushIcon ?? ''} wrapper='span' />
                </Icon>
            </LessonHeaderContainer>
            <ContentContainer>
                <Comment>{comment || 'コメントはありません'}</Comment>
                {curriculumsMap.length > 0 && (
                    <CurriculumList>
                        {curriculumsMap.map(({ question, answer }, index) => (
                            <CurriculumRow key={index}>
                                <span>{question}</span>
                                <span>{`${answer}%`}</span>
                            </CurriculumRow>
                        ))}
                    </CurriculumList>
                )}
                <MovieThumbnails movies={movies} />
            </ContentContainer>
        </Container>
    );
};

export default PastLesson;
