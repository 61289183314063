import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CrownIcon } from './CrownIcon';
import { colors } from '../../const/Styles';

const Wrapper = styled.i<{ $isActive: boolean }>`
    display: flex;
    flex-direction :column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-raius: 4px;
    color: ${colors.black};
    background-color: ${colors.footerIconSelected};
    gap: 4px;
    opacity: ${(props) => props.$isActive ? 1 : 0.5};
    padding: 4px;
`

const Icon = styled(CrownIcon)`
    height: 25px;
    width: 25px;
    color: ${colors.black};
`

const Description = styled.span`
    font-size: 12px;
    line-height: 0.9;
    font-weight: 700;
    font-style: normal;
    white-space: nowrap;
`

type Props = {
    className?: string
    isActive: boolean
    onClick: (isActive: boolean) => void
}

const BestSwingButton: React.FC<Props> = ({ className, isActive, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(!isActive);
    }, [isActive, onClick])

    return (
        <Wrapper className={className} $isActive={isActive} onClick={handleClick}>
            <Icon />
            <Description>ベスト</Description>
            <Description>スイング</Description>
        </Wrapper>
    )
}

export default BestSwingButton;
