import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/StopRounded';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

type Props = {
    recording: boolean;
    onClick?: () => void;
    className?: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledRecordIcon = styled(FiberManualRecordIcon)`
    width: 40px;
    height: 40px;
    color: ${colors.vividRed};
`;

const StyledStopIcon = styled(StopIcon)`
    width: 40px;
    height: 40px;
    color: ${colors.vividRed};
`;

const Label = styled.label`
    color: ${colors.vividRed};
    font-size: 15px;
`;

const RecordControlButton: React.FC<Props> = ({
    recording,
    onClick,
    className,
}) => {
    return (
        <Container onClick={onClick} className={className}>
            {recording ? <StyledStopIcon /> : <StyledRecordIcon />}
            <Label>REC</Label>
        </Container>
    );
};

export default RecordControlButton;
