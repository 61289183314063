import React, { useCallback } from 'react';

type Props = {
    src: string;
    time: number;
    onClick?: (time: number) => void;
};

const TimelineImage: React.FC<Props> = ({ src, time, onClick }) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick(time);
        }
    }, [onClick, time]);
    return <img src={src} onClick={handleClick} />;
};

export default TimelineImage;
