import { MenuItem } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { StaffDisplay } from '../../../store/TempoStore';

type Props = {
    coach: StaffDisplay;
    onClick: (coach: StaffDisplay) => void;
};

const CoachMenuItem: React.FC<Props> = ({ coach, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(coach);
    }, [coach, onClick]);

    const coachName = useMemo(() => {
        const { name_sei, name_mei } = coach;
        return `${name_sei} ${name_mei}`;
    }, [coach]);

    return (
        <MenuItem onClick={handleClick} value={coach.id}>
            {coachName}
        </MenuItem>
    );
};

export default CoachMenuItem;
