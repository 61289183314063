import { Card, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useStore } from '../../contexts/StoreContext';
import AuthStore from '../../store/AuthStore';
import Api from '../../utils/Api';
import { EnqueteQuestionEntity } from '../@types/mysqlEntities';
import QuestionnaireFillingItems from './display/QuestionnaireFillingItems';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: 663,
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 23,
            paddingBottom: 31,
            position: 'relative',
        },
        gridContainer: {
            width: '80%',
            margin: 'auto',
        },
    }),
);

interface EnqueteQuestionForm {
    value: EnqueteQuestionEntity | undefined,
    isEditable: boolean;
    isDeletable: boolean;
}

export default observer(function QuestionnaireSettingsForm() {
    const classes = useStyles();
    const [questions, setQuestions] = useState<EnqueteQuestionEntity[]>([]);
    useEffect(() => {
        Api.fetchEnqueteItems(AuthStore.tempoId).then((questions) => {
            setQuestions(questions.concat());
        }).catch(e => {
            console.log(e);
        });
    }, []);

    const forms = useMemo(() => {
        return Array.from({ length: 10 }).map<EnqueteQuestionForm>((_, index) => {
            const current = questions[index];
            const previuos = questions[index - 1];
            const isEditable = index === 0 || (previuos?.name || '').length > 0;
            const isDeletable = Boolean(current?.name);
            return {
                value: current,
                isEditable,
                isDeletable,
            }
        })
    }, [questions]);

    const { tempoStore } = useStore();
    const handleChange = (questions: EnqueteQuestionEntity[]) => {
        setQuestions(questions.concat());
        void tempoStore.fetchEnqueteItems();
    }

    return (
        <Card className={classes.root}>
            <Grid container className={classes.gridContainer}>
                {forms.map(({ value, isEditable, isDeletable }, index) => (
                    <QuestionnaireFillingItems
                        key={index}
                        index={index}
                        value={value}
                        isEditable={isEditable}
                        isDeletable={isDeletable}
                        onChange={handleChange}
                    />
                ))}
            </Grid>
        </Card>
    );
});
