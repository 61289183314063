import { Box, Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { ForwardedRef, useRef, useState } from 'react';
import { useAsyncFn } from 'react-use';
import useEditorCanvas, {
    LineColor,
    ToolType,
} from '../../hooks/useEditorCanvas';
import { VideoActions } from '../../hooks/useVideoJs';
import RecordingStore from '../../store/RecordingStore';
import Api from '../../utils/Api';
import base64ToFile from '../../utils/base64ToFile';
import { colors } from '../const/Styles';
import MovieEditorFooter from '../swing/display/MovieEditorFooter';
import MovieEditorToolHorizontal from '../swing/display/MovieEditorToolHorizontal';
import { VideoBlob, VideoMovie } from './PlayingVideoCard';
import VideoEditorSide, { ForwardProps } from './VideoEditorSide';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'absolute',
            width: '95%',
            height: '95%',
            border: '4px solid #333333',
            boxShadow: theme.shadows[5],
            overflow: 'auto',
            paddingTop: '100px',
            backgroundColor: colors.black,
            boxSizing: 'border-box',
        },
        editorBox: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        toggleButton: {
            border: 'None',
            borderRadius: 'None',
        },
    }),
);

interface Props {
    videoSrc: (VideoMovie | VideoBlob)[];
    height: number;
    width: number;
    scaleRatio: number;
}

export default React.forwardRef(function VideoEditorDouble(
    props: Props,
    ref: ForwardedRef<HTMLDivElement>,
): React.ReactElement {
    const { videoSrc, height, width, scaleRatio } = props;
    const classes = useStyles();

    const [canvasValuesLeft, canvasActionsLeft] = useEditorCanvas(
        videoSrc[0],
        height,
        width,
        scaleRatio,
    );

    const [canvasValuesRight, canvasActionsRight] = useEditorCanvas(
        videoSrc[1],
        height,
        width,
        scaleRatio,
    );

    const [toolType, setToolType] = React.useState<ToolType>(
        ToolType.straightLine,
    );

    const handleToolType = (newToolType: ToolType) => {
        setToolType(newToolType);
        canvasActionsLeft.setToolType(newToolType);
        canvasActionsRight.setToolType(newToolType);
    };

    const [lineColor, setLineColor] = React.useState<LineColor>(LineColor.red);
    const handleLineColor = (newLineColor: LineColor) => {
        setLineColor(newLineColor);
        canvasActionsLeft.setLineColor(newLineColor);
        canvasActionsRight.setLineColor(newLineColor);
    };

    const editor1 = useRef<ForwardProps>(null);
    const editor2 = useRef<ForwardProps>(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const [, dispacher] = useAsyncFn(async () => {
        const commands = [editor1, editor2].map(({ current }) => {
            if (current) {
                return current.togglePlay();
            }
            return Promise.resolve();
        });
        await Promise.all(commands);
        setIsPlaying(!isPlaying);
    }, [isPlaying, setIsPlaying]);

    const handleStart = () => {
        void dispacher();
    };

    const [, saveDispacher] = useAsyncFn(async () => {
        const editors = [editor1, editor2];
        const converts = editors.map(({ current }) => {
            if (current) {
                return current.convertToImg();
            }
            return Promise.resolve();
        });
        // 編集内容の画像変換
        await Promise.all(converts);
        // それぞれ変換した画像をバックエンドに投げる
        const canvasValues = [canvasValuesLeft, canvasValuesRight];
        const ajax = videoSrc.map(async (src, index) => {
            const base64 = canvasValues[index].canvasRef.current
                ?.toDataURL('image/png')
                .split(',')[1];
            if (base64) {
                const imgFile = base64ToFile(base64, 'test');
                if (imgFile) {
                    if (src.type === 'movie') {
                        const result = await Api.updateToolImage(
                            src.id,
                            src.customerIdOrStaffId,
                            src.movieCreatedAt,
                            src.filename,
                        );
                        const presignedUrl =
                            await Api.getS3SignedUrlForUploadToolImage(
                                result.tool_image_filename,
                            );
                        await Api.uploadToS3(
                            presignedUrl.presignedUrl,
                            imgFile,
                        );
                    } else {
                        RecordingStore.saveToolImage(src, imgFile);
                    }
                }
            }
        });
        await Promise.allSettled(ajax);
    }, []);

    const handleOnSave = () => {
        // TODO: 保存時の処理実装
        void saveDispacher();
    };

    const handleFooterActions: VideoActions = {
        onChangePlayBackRate: (rate: number) => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.onChangePlayBackRate(rate);
                }
            });
        },
        skipPlus: () => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skipPlus();
                }
            });
        },
        skipMinus: () => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skipMinus();
                }
            });
        },
        skip: (second: number) => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skip(second);
                }
            });
        },
        togglePlay: async () => {
            const commands = [editor1, editor2].map(({ current }) => {
                if (current) {
                    return current.togglePlay();
                }
                return Promise.resolve();
            });
            await Promise.all(commands);
        },
    };

    return (
        <Card ref={ref} tabIndex={-1} className={classes.card}>
            <Box>
                <MovieEditorToolHorizontal
                    selected={toolType}
                    selectedColor={lineColor}
                    onClick={handleToolType}
                    onLineColorChange={handleLineColor}
                />
            </Box>
            <Box className={classes.editorBox}>
                <VideoEditorSide
                    videoSrc={videoSrc[0]}
                    canvasValues={canvasValuesLeft}
                    canvasActions={canvasActionsLeft}
                    height={height}
                    width={width}
                    ref={editor1}
                />
                <VideoEditorSide
                    videoSrc={videoSrc[1]}
                    canvasValues={canvasValuesRight}
                    canvasActions={canvasActionsRight}
                    height={height}
                    width={width}
                    ref={editor2}
                />
            </Box>
            <MovieEditorFooter
                isPlaying={isPlaying}
                onSave={handleOnSave}
                onStart={handleStart}
                actions={handleFooterActions}
            />
        </Card>
    );
});
