import getMonth from 'date-fns/getMonth';
import dateValidate from 'date-fns/isValid';
import dateParse from 'date-fns/parse';
import isMatch from 'date-fns/isMatch';
import { Dispatch, Reducer, useReducer } from 'react';

type DateArgs = {
    year: number | undefined;
    month: number | undefined;
    day: number | undefined;
    date: Date | undefined;
    isValid: boolean;
}

type ReducerActionKey = 'year' | 'month' | 'day';
type ReducerAction = {
    value: Partial<Omit<DateArgs, 'isValid' | 'date'>>
}

type DateReducer = Reducer<DateArgs, ReducerAction>;

const InitialValue: DateArgs = {
    year: undefined,
    month: undefined,
    day: undefined,
    date: undefined,
    isValid: false,
}

export default function useSeparateDateInput(initialData: string | undefined | null): [DateArgs, Dispatch<ReducerAction>] {
    // データ初期化
    const createInitialData = (data: string | undefined | null): DateArgs => {
        if (data && isMatch(data, 'yyyy-MM-dd')) {
            const parsedData = dateParse(data, 'yyyy-MM-dd', new Date());
            return {
                year: parsedData.getFullYear(),
                month: getMonth(parsedData) + 1,
                day: parsedData.getDate(),
                date: parsedData,
                isValid: true,
            }
        }
        return InitialValue;
    };
    const [state, dispatch] = useReducer<DateReducer>((state, action) => {
        const inputMap = new Map<ReducerActionKey, number | null>();
        Object.entries(action.value).forEach(([key, value]) => inputMap.set(key as ReducerActionKey, value));
        // 入力内容のマージ
        const nextState = { ...state, ...Object.fromEntries(inputMap) };
        // 妥当性チェック
        const { year, month, day } = nextState;
        if (year && month && day) {
            const parsedDate = dateParse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
            const valid = dateValidate(parsedDate);
            nextState.isValid = valid;
            // OKならDateインスタンスを投入
            if (valid) {
                nextState.date = parsedDate;
            }
        }
        return nextState;
    }, createInitialData(initialData));

    return [state, dispatch];
}
