import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import HeaderIcon from '../../assets/merchant.svg';
import { useStore } from '../../contexts/StoreContext';
import MerchantPage from '../base/MerchantPage';
import { profileBorders } from '../const/Styles';
import MerchantTitleView from './display/TitleView';
import StaffSettings from './StaffSettings';
import MerchantPropertyNavigation from './navigation/MerchantPropertyNavigation';
import { MerchantTabProvider } from '../../contexts/MerchantTabContext';
import QuestionnaireSettings from './QuestionnaireSettings';
import CurriculumSettings from './CurriculumSettings';
import CoachVideoList from './CoachVideoList';
import MerchantDetailPage from './detail';

const CoarchListPage: React.FC = () => {
    const { lessonStore } = useStore();
    const { selectedCustomersId, viewedCustomer } = lessonStore;

    const activeColor = useMemo(() => {
        const index = selectedCustomersId.findIndex(
            (p) => p === viewedCustomer.id,
        );

        return profileBorders[(index + 1) % 5];
    }, [selectedCustomersId, viewedCustomer]);

    const [isVisibleBackButton, setIsVisibleBackButton] = useState(false);

    const [currentStaffId, setCurrentStaffId] = useState<number | undefined>(
        undefined,
    );

    return (
        <MerchantPage headerIcon={HeaderIcon} title='設定'>
            <MerchantTabProvider>
                <MerchantTitleView isVisible={isVisibleBackButton} />
                <MerchantPropertyNavigation
                    activeColor={activeColor}
                    setIsVisibleBackButton={setIsVisibleBackButton}
                />
                <StaffSettings setCurrentStaffId={setCurrentStaffId} />
                <MerchantDetailPage currentStaffId={currentStaffId} />
                <CoachVideoList />
                <CurriculumSettings />
                <QuestionnaireSettings />
            </MerchantTabProvider>
        </MerchantPage>
    );
};

export default observer(CoarchListPage);
