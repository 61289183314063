import React, { Fragment, useMemo } from 'react';
import { convertYearsToYearsString } from '../../../utils/utilityFuncs';

type Props = {
    years?: string | undefined;
};
const DisplayExperienceYear: React.FC<Props> = ({ years }) => {
    const convertedText = useMemo(() => {
        if (!years) {
            return '';
        }
        const convert = convertYearsToYearsString(new Date(), new Date(years));
        return convert !== 'NaN年' ? convert : '-';
    }, [years]);
    return <Fragment>{convertedText}</Fragment>;
};

export default DisplayExperienceYear;
