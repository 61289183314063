export const CUSTOMER_ATTRIBUTE_BASIC = 1;
export const CUSTOMER_ATTRIBUTE_DETAILED = 2;
export const CUSTOMER_ATTRIBUTE_ENQUETE = 3;

export type CustomerAttributeType =
    | typeof CUSTOMER_ATTRIBUTE_BASIC
    | typeof CUSTOMER_ATTRIBUTE_DETAILED
    | typeof CUSTOMER_ATTRIBUTE_ENQUETE;

export const CustomerAttributeTabProperties = {
    1: '基本情報',
    2: '詳細情報',
    3: 'アンケート',
};
