import { IconButton } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import React from 'react';
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import { ClassNameMap } from '@material-ui/styles';

type IconProps = DefaultComponentProps<SvgIconTypeMap>;
export interface Props extends Omit<IconProps, 'onClick' | 'classes'> {
    faved: boolean | undefined;
    classes?: Partial<ClassNameMap<'button' | 'icon'>>;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function FavButton(props: Props): React.ReactElement {
    const { faved, classes, onClick, ...restProps } = props;
    if (faved === undefined) {
        return <></>;
    }
    return (
        <IconButton onClick={onClick} className={classes?.button}>
            {faved ? (
                <StarIcon className={classes?.icon} {...restProps} />
            ) : (
                <StarBorderIcon className={classes?.icon} {...restProps} />
            )}
        </IconButton>
    );
}
