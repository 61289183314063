import React from 'react';
import ReactDOM from 'react-dom';

import AppContainer from './routing/AppContainer';

const App: React.FC = () => {
    return <AppContainer />;
};

ReactDOM.render(<App />, document.getElementById('root'));
