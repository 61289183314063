import {
    Card,
    Container,
    createStyles,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';
import { ErrorMessages } from '../../utils/Api';
import ErrorMessage from '../base/feedback/ErrorMessage';
import MultiFieldErrorMessage from '../base/feedback/MultiFieldErrorMessage';
import { colors } from '../const/Styles';
import OutlinedInputText from '../form/OutlinedInputText';

const StyledInputText = OutlinedInputText;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            paddingRight: '74px',
            paddingLeft: '74px',
        },
        container: {
            width: '100%',
            margin: 'auto',
            padding: 0,
        },
        formTitle: {
            display: 'flex',
            alignItems: 'center',
            width: 174,
        },
        formSet: {
            display: 'flex',
            paddingTop: 20,
            paddingBottom: 30,
            borderBottom: `1px solid ${colors.lightGray}`,
        },
        formSet__NoBorder: {
            display: 'flex',
            paddingTop: 20,
            paddingBottom: 30,
        },
        inputBox: {
            display: 'flex',
        },
        deleteLabelSpace: {
            '& > div > div.MuiBox-root': {
                width: 0,
            },
        },
        spanText: {
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: 10,
        },
        checkColor: {
            '&.Mui-checked': {
                color: `${colors.footerIconSelected}`,
            },
        },
        titleBox__titleImgBox: {},
        titleBox__selectImgBox: {},
    }),
);

interface Props {
    type: string;
    nameSei: string;
    nameMei: string;
    nameSeiKana: string;
    nameMeiKana: string;
    sex: string;
    certification1Name: string;
    certification1Date: string;
    certification2Name: string;
    certification2Date: string;
    certification3Name: string;
    certification3Date: string;
    errorProps: ErrorMessages | undefined;

    setType: React.Dispatch<React.SetStateAction<string>>;
    setNameSei: React.Dispatch<React.SetStateAction<string>>;
    setNameMei: React.Dispatch<React.SetStateAction<string>>;
    setNameSeiKana: React.Dispatch<React.SetStateAction<string>>;
    setNameMeiKana: React.Dispatch<React.SetStateAction<string>>;
    setDateOfBirth: React.Dispatch<React.SetStateAction<string>>;
    setSex: React.Dispatch<React.SetStateAction<string>>;
    setCertification1Name: React.Dispatch<React.SetStateAction<string>>;
    setCertification1Date: React.Dispatch<React.SetStateAction<string>>;
    setCertification2Name: React.Dispatch<React.SetStateAction<string>>;
    setCertification2Date: React.Dispatch<React.SetStateAction<string>>;
    setCertification3Name: React.Dispatch<React.SetStateAction<string>>;
    setCertification3Date: React.Dispatch<React.SetStateAction<string>>;
}

function StaffRegisterCard(props: Props): React.ReactElement {
    const {
        type,
        nameSei,
        nameMei,
        nameSeiKana,
        nameMeiKana,
        sex,
        certification1Name,
        certification1Date,
        certification2Name,
        certification2Date,
        certification3Name,
        certification3Date,
        errorProps,

        setType,
        setNameSei,
        setNameMei,
        setNameSeiKana,
        setNameMeiKana,
        setDateOfBirth,
        setSex,
        setCertification1Name,
        setCertification1Date,
        setCertification2Name,
        setCertification2Date,
        setCertification3Name,
        setCertification3Date,
    } = props;
    const classes = useStyles();

    const [year, setYear] = useState<number | string>('');
    const [month, setMonth] = useState<number | string>('');
    const [day, setDay] = useState<number | string>('');

    useEffect(() => {
        if (year && month && day) {
            setDateOfBirth(`${year}-${month}-${day}`);
        }
    }, [year, month, day, setDateOfBirth]);

    const filterHiragana = useCallback(
        (text: string, type: 'sei' | 'mei') => {
            if (type == 'sei') {
                setNameSeiKana(text);
            } else {
                setNameMeiKana(text);
            }
        },
        [setNameMeiKana, setNameSeiKana],
    );

    return (
        <Card>
            <Card className={classes.root}>
                <Container className={classes.container}>
                    <Grid container>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>スタッフ種別</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-label='position'
                                        name='position'
                                        defaultValue='unknown'
                                        value={type}
                                        onChange={(e) =>
                                            setType(e.target.value)
                                        }
                                    >
                                        <FormControlLabel
                                            label='コーチ'
                                            value='coach'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label='事務員'
                                            value='clerk'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <ErrorMessage field='type' errors={errorProps} />
                        </Grid>

                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>氏名</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={clsx(
                                    classes.inputBox,
                                    classes.deleteLabelSpace,
                                )}
                            >
                                <StyledInputText
                                    id='name_sei'
                                    value={nameSei}
                                    onChange={(e) => setNameSei(e.target.value)}
                                    style={{ width: '137px' }}
                                    placeholder='山田'
                                    label={'名字'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <StyledInputText
                                    id='name_mei'
                                    value={nameMei}
                                    onChange={(e) => setNameMei(e.target.value)}
                                    style={{ width: '137px' }}
                                    placeholder='太郎'
                                    label={'名前'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <MultiFieldErrorMessage fields={['name_mei', 'name_sei']} errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>ふりがな</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={
                                    classes.inputBox +
                                    ' ' +
                                    classes.deleteLabelSpace
                                }
                            >
                                <StyledInputText
                                    id='name_sei_kana'
                                    value={nameSeiKana}
                                    onChange={(e) =>
                                        filterHiragana(e.target.value, 'sei')
                                    }
                                    style={{ width: '137px' }}
                                    placeholder={'やまだ'}
                                    label={'みょうじ'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <StyledInputText
                                    id='name_mei_kana'
                                    value={nameMeiKana}
                                    onChange={(e) =>
                                        filterHiragana(e.target.value, 'mei')
                                    }
                                    style={{ width: '137px' }}
                                    placeholder={'たろう'}
                                    label={'なまえ'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <MultiFieldErrorMessage fields={['name_mei_kana', 'name_sei_kana']} errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>生年月日</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='birth_year'
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    type='number'
                                    style={{ width: '80px' }}
                                    placeholder='1976'
                                />
                                <Typography className={classes.spanText}>
                                    年
                                </Typography>
                                <StyledInputText
                                    id='birth_month'
                                    value={month}
                                    onChange={(e) => setMonth(e.target.value)}
                                    type='number'
                                    style={{ width: '80opx' }}
                                    placeholder='6'
                                />
                                <Typography className={classes.spanText}>
                                    月
                                </Typography>
                                <StyledInputText
                                    id='birth_day'
                                    value={day}
                                    onChange={(e) => setDay(e.target.value)}
                                    type='number'
                                    style={{ width: '80opx' }}
                                    placeholder='24'
                                />
                                <Typography className={classes.spanText}>
                                    日
                                </Typography>
                            </Grid>
                            <ErrorMessage field='type' errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>性別</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-label='position'
                                        name='position'
                                        defaultValue='unknown'
                                        value={sex}
                                        onChange={(e) => setSex(e.target.value)}
                                    >
                                        <FormControlLabel
                                            label='男性'
                                            value='male'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label='女性'
                                            value='female'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label='未設定'
                                            value='unknown'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle}>
                                <Typography>資格 / 資格取得年月日</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='certification1'
                                    value={certification1Name}
                                    onChange={(e) =>
                                        setCertification1Name(e.target.value)
                                    }
                                    style={{ width: '197px' }}
                                />
                                <StyledInputText
                                    id='certification1-date'
                                    type='date'
                                    value={certification1Date}
                                    onChange={(e) =>
                                        setCertification1Date(e.target.value)
                                    }
                                    style={{ width: '137px' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle} />
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='certification2'
                                    value={certification2Name}
                                    onChange={(e) =>
                                        setCertification2Name(e.target.value)
                                    }
                                    style={{ width: '197px' }}
                                />
                                <StyledInputText
                                    id='certification2-date'
                                    type='date'
                                    value={certification2Date}
                                    onChange={(e) =>
                                        setCertification2Date(e.target.value)
                                    }
                                    style={{ width: '137px' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle} />
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='certification3'
                                    value={certification3Name}
                                    onChange={(e) =>
                                        setCertification3Name(e.target.value)
                                    }
                                    style={{ width: '197px' }}
                                />
                                <StyledInputText
                                    id='certification3-date'
                                    type='date'
                                    value={certification3Date}
                                    onChange={(e) =>
                                        setCertification3Date(e.target.value)
                                    }
                                    style={{
                                        width: '137px',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Card>
        </Card>
    );
}

export default StaffRegisterCard;
