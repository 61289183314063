import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';

import { MerchantTabContext } from '../../contexts/MerchantTabContext';
import { QUESTIONNAIRE } from '../const/MerchantProperties';
import QuestionnaireSettingsForm from './QuestionnaireSettingsForm';

import styled from 'styled-components';
import { colors, MerChantBackGroundColor } from '../const/Styles';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    flexgrow: 1px;
    padding: 30px;
    background-color: ${MerChantBackGroundColor};
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        TitlePaper: {
            // padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        questionnaireChoices: {
            width: 663,
            height: 58,
            backgroundColor: `${colors.lightGray}`,
            color: `${colors.white}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
        },
        choicesBox: {
            width: 110,
            height: 20,
            border: `1px solid ${colors.black}`,
            borderRadius: 100,
            marginRight: 10,
            padding: '4px 12px',
            backgroundColor: `${colors.blue}`,
            fontWeight: 'bold',
            fontSize: '15px',
            textAlign: 'center',
        },
    }),
);

const QuestionnaireSettings: React.FC = () => {
    const classes = useStyles();

    const { selected } = useContext(MerchantTabContext);
    const visible = useMemo(() => {
        return QUESTIONNAIRE === selected;
    }, [selected]);

    return (
        <Container visible={visible}>
            <Grid container className={classes.questionnaireChoices}>
                <p>アンケート選択肢：</p>
                <p className={classes.choicesBox}>当てはまる</p>
                <p className={classes.choicesBox}>やや当てはまる</p>
                <p className={classes.choicesBox}>当てはまらない</p>
            </Grid>
            <QuestionnaireSettingsForm />
        </Container>
    );
};

export default observer(QuestionnaireSettings);
