import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from '../const/Routes';
import CustomerList from './list';
import CustomerDetail from './detail';
import CustomerEdit from './edit';
import CustomerRegister from './register';

const CustomerPages: React.FC = () => {
    return (
        <React.Fragment>
            <Route path={Routes.LIBRARY_LIST} exact>
                <CustomerList />
            </Route>
            <Route path={Routes.LIBRARY_DETAIL}>
                <CustomerDetail />
            </Route>
            <Route path={Routes.LIBRARY_EDIT}>
                <CustomerEdit />
            </Route>
            <Route path={Routes.CUSTOMER_REGISTER}>
                <CustomerRegister />
            </Route>
        </React.Fragment>
    );
};

export default CustomerPages;
