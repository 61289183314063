import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
} from '../../../hooks/useEditorCanvas';
import {
    Values as StampValues,
    Actions as StampActions,
} from '../../../hooks/useEditorGuidStamp';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgStampRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    stampValues: StampValues;
    stampActions: StampActions;
    height: number;
    width: number;
}

function ForwardTiltGuidSVG(props: Props): React.ReactElement {
    const {
        svgStampRef,
        canvasValues,
        stampValues,
        stampActions,
        height,
        width,
    } = props;
    const classes = useStyles();
    return (
        <svg
            ref={svgStampRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.forwardTiltGuid &&
                    classes.front,
            )}
            onPointerDown={(e) =>
                stampActions.stamp(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
            }
            onPointerUp={stampActions.onMouseUp}
            onPointerMove={(e) =>
                stampActions.onMouseMove(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                )
            }
        >
            <g display={stampValues.display ? 'inline' : 'none'}>
                <line
                    x1={stampValues.x - 50}
                    y1={stampValues.y}
                    x2={stampValues.x + 200}
                    y2={stampValues.y}
                    stroke='yellow'
                />
                <line
                    x1={stampValues.x}
                    y1={stampValues.y - 245}
                    x2={stampValues.x}
                    y2={stampValues.y + 130}
                    stroke='yellow'
                />
                <g transform={`rotate(20, ${stampValues.x}, ${stampValues.y})`}>
                    <line
                        x1={stampValues.x}
                        y1={stampValues.y}
                        x2={stampValues.x}
                        y2={stampValues.y - 250}
                        stroke='red'
                    />
                    <line
                        x1={stampValues.x}
                        y1={stampValues.y}
                        x2={stampValues.x + 200}
                        y2={stampValues.y}
                        stroke='red'
                        style={{ strokeDasharray: '8 8' }}
                    />
                </g>
                <g transform={`rotate(25, ${stampValues.x}, ${stampValues.y})`}>
                    <line
                        x1={stampValues.x}
                        y1={stampValues.y}
                        x2={stampValues.x}
                        y2={stampValues.y - 250}
                        stroke='lime'
                    />
                    <line
                        x1={stampValues.x}
                        y1={stampValues.y}
                        x2={stampValues.x + 200}
                        y2={stampValues.y}
                        stroke='lime'
                        style={{ strokeDasharray: '8 8' }}
                    />
                </g>
                <g transform={`rotate(30, ${stampValues.x}, ${stampValues.y})`}>
                    <line
                        x1={stampValues.x}
                        y1={stampValues.y}
                        x2={stampValues.x}
                        y2={stampValues.y - 250}
                        stroke='blue'
                    />
                    <line
                        x1={stampValues.x}
                        y1={stampValues.y}
                        x2={stampValues.x + 200}
                        y2={stampValues.y}
                        stroke='blue'
                        style={{ strokeDasharray: '8 8' }}
                    />
                </g>
                <circle
                    cx={stampValues.x}
                    cy={stampValues.y}
                    r='8'
                    fill='yellow'
                />
            </g>
        </svg>
    );
}

export default ForwardTiltGuidSVG;
