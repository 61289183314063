import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../const/Styles';
import Footer from './navigation/Footer';
import Header from './navigation/Header';
import Loader from './display/Loader';
import { useStore } from '../../contexts/StoreContext';

const Container = styled.main`
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

const Content = styled.section`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
`;

type Props = {
    headerIcon: string | undefined;
    title: string;
};

const MerchantPage: React.FC<Props> = ({ headerIcon, title, children }) => {
    const { lessonStore, tempoStore } = useStore();
    const { isCustomerLoaded } = lessonStore;
    const { isAllLessonsLoaded, isEnqueteItemsLoaded } = tempoStore;

    const loading = useMemo(() => {
        return (
            isCustomerLoaded === false ||
            isAllLessonsLoaded === false ||
            isEnqueteItemsLoaded === false
        );
    }, [isCustomerLoaded, isAllLessonsLoaded, isEnqueteItemsLoaded]);
    return (
        <Container>
            {loading === false && (
                <>
                    <Header icon={headerIcon} title={title}></Header>
                    <Content>{children}</Content>
                    <Footer></Footer>
                </>
            )}
            <Loader isOpen={loading}></Loader>
        </Container>
    );
};

export default observer(MerchantPage);
