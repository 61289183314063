import React, { useState } from 'react';
import styled from 'styled-components';
import { MovieDisplay } from '../../../utils/Video';
import MovieThumbnail from '../display/MovieThumbnail';
import PlayMovieModal from '../display/PlayMovieModal';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 180px;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 10px;
`;

type Props = {
    movies: MovieDisplay[];
};
const MovieThumbnails: React.FC<Props> = ({ movies }) => {
    const [isOpened, setOpened] = useState(false);
    const [movie, setMovie] = useState(movies[0]);
    const handleOpenModal = (movie: MovieDisplay) => {
        setMovie(movie);
        setOpened(true);
    };

    const handleCloseModal = () => {
        setOpened(false);
    };
    return (
        <Container>
            {movies.map((movie) => (
                <MovieThumbnail
                    key={movie.id}
                    movie={movie}
                    onClick={handleOpenModal}
                />
            ))}
            {movies.length > 0 && (
                <PlayMovieModal
                    isOpened={isOpened}
                    movie={movie}
                    movies={movies}
                    onClose={handleCloseModal}
                />
            )}
        </Container>
    );
};

export default MovieThumbnails;
