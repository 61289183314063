import { CoachDisplay } from "./CoachStore";

export const testCustomers = [
    { name: '井上', name_kana: 'いのうえ' },
    { name: '上原', name_kana: 'うえはら' },
    { name: '江藤', name_kana: 'えとう' },
    { name: 'あ', name_kana: 'あ' },
    { name: 'い', name_kana: 'い' },
    { name: 'う', name_kana: 'う' },
    { name: 'え', name_kana: 'え' },
    { name: 'お', name_kana: 'お' },
    { name: 'か', name_kana: 'か' },
    { name: 'き', name_kana: 'き' },
    { name: 'く', name_kana: 'く' },
].map<CoachDisplay>((customer, index) => {
    return { ...customer, id: index };
});
