import React from 'react';

import OutlinedInputText from './form/OutlinedInputText';

import { Box, Typography, Button, Container } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const StyledInputText = OutlinedInputText;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            marginBottom: '2rem',
            // typographyコンポーネントのバグ？でvariant=h2, h3のときだけpx指定になっていたのでremで上書きしている。
            fontSize: '3rem',
        },
        contents: {
            marginBottom: 10,
        },
        formSet: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 10,
        },
    }),
);

function App(): React.ReactElement {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <Typography variant='h2' gutterBottom className={classes.title}>
                パスワードの再設定
            </Typography>
            <Typography variant='body2' className={classes.contents}>
                パスワードを再設定するためのメールを送信します。
                <br />
                ご登録済みのメールアドレスを入力して「送信」をタップして下さい。
                <br />
                パスワード再設定のご案内メールが届きます。
            </Typography>
            <Box className={classes.formSet}>
                <Typography>Emailアドレス&nbsp;</Typography>
                <StyledInputText id='mail_address' placeholder={'Email'} />
            </Box>
            <Button variant='contained' color='primary'>
                送信
            </Button>
        </Container>
    );
}

export default App;
