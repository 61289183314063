import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import UnSelectedBgImg from '../../../assets/bg-customer-unselected.svg';
import { border } from '../../const/Styles';

type ContainerProps = {
    color: string;
    iconColor: string;
};
const Container = styled.figure<ContainerProps>`
    display: flex;
    flex-grow: 2;
    margin: 0;
    min-height: 100px;
    max-height: 145px;
    max-width: 180px;
    box-sizing: border-box;
    ${({ color }) => {
        if (color === border.none) {
            return '';
        }
        return `border: 2px solid ${color};`;
    }}
    color: ${({ color }) => color};

    .Icon {
        display: flex;
        width: 100%;
        height: 100%;

        > div {
            display: flex;
            width: 100%;
            height: 100%;
            color: ${({ iconColor }) => iconColor};
            > svg {
                height: 100%;
                width: auto;
            }
        }
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

type Props = {
    src?: string;
    color: string;
    iconColor: string;
};

const CustomerImage: React.FC<Props> = ({ src, color, iconColor }) => {
    return (
        <Container color={color} iconColor={iconColor}>
            {src ? (
                <Img src={src}></Img>
            ) : (
                <ReactSVG
                    src={UnSelectedBgImg ?? ''}
                    className='Icon'
                ></ReactSVG>
            )}
        </Container>
    );
};

export default CustomerImage;
