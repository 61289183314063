import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import {
    Values as CanvasValues,
    ToolType,
    lineColorToRGB,
} from '../../../hooks/useEditorCanvas';
import {
    Values as CircleValues,
    Actions as CircleActions,
} from '../../../hooks/useEditorCircle';

const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            position: 'absolute',
            touchAction: 'none',
        },
        front: { zIndex: 1 },
    }),
);

interface Props {
    svgCircleRef: React.RefObject<SVGSVGElement>;
    canvasValues: CanvasValues;
    circleValues: CircleValues;
    circleActions: CircleActions;
    height: number;
    width: number;
}

function CircleSVG(props: Props): React.ReactElement {
    const {
        svgCircleRef,
        canvasValues,
        circleValues,
        circleActions,
        height,
        width,
    } = props;
    const classes = useStyles();

    return (
        <svg
            ref={svgCircleRef}
            id='svg'
            xmlns='http://www.w3.org/2000/svg'
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio='xMidYMid meet'
            strokeWidth='3'
            className={clsx(
                classes.svg,
                canvasValues.toolType == ToolType.circle && classes.front,
            )}
            onPointerDown={(e) => {
                circleActions.onPointerDown(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                    canvasValues.lineColor,
                );
            }}
            onPointerMove={(e) => {
                circleActions.onPointerMove(
                    e.nativeEvent.offsetX,
                    e.nativeEvent.offsetY,
                );
            }}
            onPointerUp={circleActions.onPointerUp}
        >
            {circleValues.drawnCircles.map((circle) => {
                return (
                    <circle
                        key={circle.firstX}
                        cx={circle.firstX}
                        cy={circle.firstY}
                        r={circle.radius}
                        fill='transparent'
                        stroke={lineColorToRGB[circle.color]}
                    />
                );
            })}
            <circle
                key={circleValues.firstX}
                cx={circleValues.firstX}
                cy={circleValues.firstY}
                r={circleValues.radius}
                fill='transparent'
                stroke={lineColorToRGB[circleValues.color]}
            />
        </svg>
    );
}

export default CircleSVG;
