export const LessonProperies = {
    1: '本日のレッスン',
    2: '基本情報',
    3: '詳細情報',
    4: 'アンケート',
};

export const TODAY_LESSON = 1;
export const CUSTOMER_BASIC_PROFILE = 2;
export const CUSTOMER_DETAIL_PROFILE = 3;
export const QUESTIONNAIRE = 4;

export type LessonPropertyType =
    | typeof TODAY_LESSON
    | typeof CUSTOMER_BASIC_PROFILE
    | typeof CUSTOMER_DETAIL_PROFILE
    | typeof QUESTIONNAIRE;
