import React from 'react';
import styled from 'styled-components';
import { Toolbar } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { colors, iconSize } from '../../const/Styles';

type Props = {
    icon?: string;
    title: string;
};

const StyledToolbar = styled(Toolbar)`
    min-height: 30px;
    background-color: ${colors.blue};
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    font-size: 1.5rem;
    svg {
        width: auto;
        height: ${iconSize.header};
    }

    .Icon {
        display: flex;
        div {
            display: flex;
        }
    }
`;

const Header: React.FC<Props> = ({ icon, title }) => {
    return (
        <StyledToolbar>
            <Content>
                {icon && <ReactSVG src={icon} className='Icon' />}
                {title}
            </Content>
        </StyledToolbar>
    );
};

export default Header;
