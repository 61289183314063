import React, { HTMLAttributes } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.i`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > span {
        display: flex;
        flex-direction: row;

        span {
            display: flex;
            flex-direction: row;
        }

        svg {
            width: 50px;
            height: 50px;
        }
    }
`;

const Label = styled.label`
    display: block;
    color: ${colors.white};
    font-size: 10px;
`;

type Props = {
    onClick?: () => void;
    icon: string;
    className?: string;
} & HTMLAttributes<HTMLElement>;

const ActionToolItem: React.FC<Props> = ({
    icon,
    onClick,
    children,
    className,
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Container onClick={handleClick} className={className}>
            <ReactSVG src={icon} wrapper='span' />
            <Label>{children}</Label>
        </Container>
    );
};

export default ActionToolItem;
