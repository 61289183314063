import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: inline-block;
    width: 100%;
    padding-left: 15px;
    margin: 15px 0;
    box-sizing: border-box;
`;

const Message = styled.small`
    display: block;
    color: ${colors.red};
    font-size: 0.8rem;
`;

type Props = {
    fields: string[];
    errors?: Map<string, string[]>;
};

const InputErrorMessage: React.FC<Props> = ({ fields, errors }) => {
    // エラーメッセージをユニークなものに絞る
    const errorMessage = useMemo(() => {
        const messages = new Set<string>();
        fields.forEach((field) => {
            errors?.get(field)?.forEach((message) => {
                messages.add(message);
            });
        });
        return Array.from(messages);
    }, [fields, errors]);

    // メッセージ自体がなければ何もしない
    if (errorMessage.length === 0) {
        return null;
    }

    return (
        <Container>
            {errorMessage.map((message) => (
                <Message key={message}>{message}</Message>
            ))}
        </Container>
    );
};

export default InputErrorMessage;
