import axios, { AxiosError } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { SchemaDefinition } from 'validate';
import LibraryIcon from '../../assets/footer-library.svg';
import { CustomerAttributeTabProvider } from '../../contexts/CustomerAttributeTabContext';
import { useStore } from '../../contexts/StoreContext';
import { useForm } from '../../hooks/useForm';
import { useValidate } from '../../hooks/useValidate';
import { CustomerDisplay } from '../../store/LessonStore';
import Api, {
    CustomerDetailedInformationEntity,
    ErrorResponse,
} from '../../utils/Api';
import { extractDiffYears } from '../../utils/utilityFuncs';
import { EnqueteAnswerForm } from '../@types/request';
import Page from '../base/Page';
import { Routes } from '../const/Routes';
import { colors } from '../const/Styles';
import EditFormHeader from './display/EditFormHeader';
import BasicForm from './input/BasicForm';
import DetailForm from './input/DetailForm';
import EnqueteForm from './input/EnqueteForm';
import FormPageLayout from './layout/FormPageLayout';
import FormTypeNavigation from './navigation/FormTypeNavigation';

const Container = styled.section`
    display: grid;
    grid-template-rows: 150px auto 1fr;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

type QueryParams = {
    id: string;
};

const DefaultDetailForm: CustomerDetailedInformationEntity = {
    best_score: '',
    avg_score: '',
    rounds_per_year: '',
    total_practice: '',
    club_good_at: '',
    club_favorite: '',
    years_golf: '',
    years_sport: '',
    home_course: '',
    memo: '',
    head_speed: '',
    distance: '',
    projectile: '',
};

const DefaultAnswerForm: EnqueteAnswerForm = {
    question_answer_1: '',
    question_answer_2: '',
    question_answer_3: '',
    question_answer_4: '',
    question_answer_5: '',
    question_answer_6: '',
    question_answer_7: '',
    question_answer_8: '',
    question_answer_9: '',
    question_answer_10: '',
};

const requiredProps = {
    required: true,
    length: { min: 1 },
    message: '入力してください',
};

const validationSchema: SchemaDefinition = {
    lesson_master_id: requiredProps,
    name_sei: requiredProps,
    name_mei: requiredProps,
    name_sei_kana: requiredProps,
    name_mei_kana: requiredProps,
};

const CustomerEdit: React.FC = () => {
    const { id } = useParams<QueryParams>();
    const { lessonStore } = useStore();
    const customer = useMemo(() => {
        return lessonStore.allCustomers.filter((p) => p.id === Number(id))[0];
    }, [id, lessonStore]);

    const [profileImage, setProfileImage] = useState<File | null>(null);
    const handleChangeProfileImage = (value: File | null) => {
        setProfileImage(value);
    };

    const [basicFormValue, basicFormDispacher] =
        useForm<CustomerDisplay>(customer);

    const [detailFormValue, detailFormDispacher] =
        useForm<CustomerDetailedInformationEntity>(DefaultDetailForm);

    const [, loadDetail] = useAsyncFn(async () => {
        const [detail] = await Api.getCustomerDetail(customer.id);
        const years_golf = extractDiffYears(detail.years_golf);
        detailFormDispacher({ value: { ...detail, years_golf } });
    }, [customer.id]);

    const [answerFormValue, answerFormDispacher] =
        useForm<EnqueteAnswerForm>(DefaultAnswerForm);

    const [, loadAnswer] = useAsyncFn(async () => {
        const response = await Api.fetachCusomerLatestEnqueteAnswer(
            customer.id,
        );
        answerFormDispacher({ value: response });
    }, [customer.id]);

    useEffect(() => {
        void loadDetail();
        void loadAnswer();
    }, [loadDetail, loadAnswer]);

    const history = useHistory();
    const dispatchValidate = useValidate(validationSchema);
    const [errors, setErrors] = useState<Map<string, string[]>>(new Map());

    const handleSave = useCallback(async () => {
        const validationError = dispatchValidate(basicFormValue);
        if (validationError.size > 0) {
            setErrors(validationError);
            return;
        }
        // MEMO: APIは動作のドメインになるのでeditは不適切
        // エンティティを返してこないのもキャッシュ戦略の考慮不足
        // これが無いから都度引かないとダメというかその辺全然考慮されない？？？
        try {
            const { filename } = await Api.editCustomer({
                id: customer.id,
                basicInfo: basicFormValue,
                detailInfo: detailFormValue,
                questionnaire: answerFormValue,
                image: profileImage?.name || '',
            });
            if (profileImage) {
                // MEMO: この辺はフロントエンドで頑張りすぎ + ファイル名は変えるべき
                // というのもReactのライフサイクルに即してないのでランダムな名称で変えないとダメ
                const presignedUrl =
                    await Api.getS3SignedUrlForUploadCustomerPicture(filename);
                await Api.uploadToS3(presignedUrl.presignedUrl, profileImage);
                // FIXME: 本来単独で問い合わせた差分で表示させるべき
                basicFormValue.s3Path = presignedUrl.presignedUrl;
            }

            // 論理削除時には見えなくなるのでリダイレクト先を変える
            history.push(
                basicFormValue.is_deleted
                    ? Routes.LIBRARY_LIST
                    : Routes.LIBRARY_DETAIL.replace(':id', `${customer.id}`),
            );

            const customerIndex = lessonStore.allCustomers.findIndex(
                (p) => p.id === customer.id,
            );
            // MEMO:登録ボタン押下で即パスワード入力済かどうかを判定する必要があるので、やむなくここで変える
            if (!basicFormValue.has_password) {
                basicFormValue.has_password = !!basicFormValue.password;
            }

            // 同じく論理削除されたら削除して見えなくする
            if (basicFormValue.is_deleted) {
                lessonStore.allCustomers.splice(customerIndex, 1)
            } else {
                lessonStore.allCustomers.splice(customerIndex, 1, {
                    ...basicFormValue,
                });
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const { response } = error as AxiosError<ErrorResponse>;
                setErrors(
                    new Map<string, string[]>(
                        Object.entries(response?.data.message ?? {}),
                    ),
                );
            }
        }
    }, [
        profileImage,
        basicFormValue,
        detailFormValue,
        answerFormValue,
        customer.id,
        history,
        lessonStore,
        dispatchValidate,
    ]);

    return (
        <CustomerAttributeTabProvider>
            <Page title='ライブラリー' headerIcon={LibraryIcon ?? ''}>
                <Container>
                    <EditFormHeader
                        s3Path={customer.s3Path}
                        onChangeProfile={handleChangeProfileImage}
                    ></EditFormHeader>
                    <FormTypeNavigation />
                    <FormPageLayout onSave={handleSave}>
                        <BasicForm
                            values={basicFormValue}
                            errors={errors}
                            dispacher={basicFormDispacher}
                        />
                        <DetailForm
                            values={detailFormValue}
                            errors={errors}
                            dispacher={detailFormDispacher}
                        />
                        <EnqueteForm
                            values={answerFormValue}
                            dispacher={answerFormDispacher}
                        />
                    </FormPageLayout>
                </Container>
            </Page>
        </CustomerAttributeTabProvider>
    );
};

export default CustomerEdit;
