import React, { ChangeEvent, useMemo } from 'react';
import StyledInputText from '../../form/OutlinedInputText';
import dateFormat from 'date-fns/format';

type Props = {
    id: string;
    value: string | undefined | null;
    onChange: (value: string) => void;
}

type MuiChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const NativeDateInput: React.FC<Props> = ({ id, value, onChange }) => {

    const handleChange = (event: MuiChangeEvent) => {
        onChange(event.currentTarget.value);
    }

    const dateString = useMemo(() => {
        if (value) {
            return dateFormat(new Date(value), 'yyyy-MM-dd');
        }
        return '';
    }, [value])
    return (
        <StyledInputText
            id={id}
            type='date'
            value={dateString}
            onChange={handleChange}
            style={{
                width: '137px',
            }}
        />
    )
}

export default NativeDateInput;
