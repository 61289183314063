import React, { useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { ReactSVG } from 'react-svg';
import RegisterIcon from '../../../assets/footer-new-customer.svg';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
    justify-content: center;
    color: ${colors.lighterGray};
    border-bottom: solid 2px ${colors.gray};
    flex-grow: 1;
    background-color: ${colors.lightGray2};
`;

const IconWrapper = styled.i`
    display: block;
    height: 45px;
    width: 63px;
`;

type Props = {
    onClick: () => void;
};

const CustomerRegisterCell: React.FC<Props> = ({ onClick }) => {
    const handleClick = useCallback(() => {
        onClick();
    }, [onClick]);

    return (
        <Wrapper onClick={handleClick}>
            <IconWrapper>
                <ReactSVG src={RegisterIcon ?? ''}></ReactSVG>
            </IconWrapper>
        </Wrapper>
    );
};

export default CustomerRegisterCell;
