import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, Modal } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../contexts/StoreContext';
import { Question } from '../../../hooks/useQuestionnaireMapCreater';
import { CustomerDisplay } from '../../../store/LessonStore';
import { colors } from '../../const/Styles';
import LessonTargetProgressSlider from './LessonTargetProgressSlider';

const StyledModal = styled(Modal)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    gap: 50px;
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 40px 20px;
    border: solid 4px ${colors.white};
    border-radius: 4px;
    background-color: ${colors.black};
    overflow-y: auto;
`;

const Header = styled.h1`
    margin: 0;
    font-weight: bold;
    color: ${colors.footerIconSelected};
    font-size: 2.5rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
`;
const Footer = styled(Button)`
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    margin: 0 100px;
    font-size: 1.2rem;
    font-weight: bold;
`;

type Props = {
    isOpended: boolean;
    onClose: () => void;
    customer: CustomerDisplay;
    questions: Question[];
    color: string;
};

const LessonTargetModal: React.FC<Props> = ({
    isOpended,
    onClose,
    customer,
    questions,
    color,
}) => {
    const { lessonStore } = useStore();

    const [answers, setAnswers] = useState<string[]>(
        questions.map((question) => question.answer),
    );

    const answersRef = useRef<string[]>(answers);

    const handleSubmit = () => {
        lessonStore.setLessonRecord(String(customer.id), answersRef.current);
        onClose();
    };

    useEffect(() => {
        answersRef.current = questions.map((question) => question.answer);
        setAnswers(answersRef.current);
    }, [isOpended, questions]);

    const handleChange = useCallback((index: number, value: string) => {
        answersRef.current[index] = value;
        setAnswers(answersRef.current.concat());
    }, []);

    return (
        <StyledModal open={isOpended} onClose={onClose}>
            <Container>
                <Header>レッスン目標</Header>
                <Content>
                    {questions.map((question, index) => (
                        <LessonTargetProgressSlider
                            key={index}
                            index={index}
                            color={color}
                            title={question.question}
                            value={answers[index]}
                            onChange={handleChange}
                        />
                    ))}
                </Content>
                <Footer onClick={handleSubmit}>登録</Footer>
            </Container>
        </StyledModal>
    );
};

export default observer(LessonTargetModal);
