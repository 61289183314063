import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useMemo } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import { LessonPropertyTabContext } from '../../../contexts/LessonPropertyTabContext';
import { useStore } from '../../../contexts/StoreContext';
import Api from '../../../utils/Api';
import { QUESTIONNAIRE } from '../../const/LessonProperties';
import { colors } from '../../const/Styles';
import QuestionnaireRow from '../surface/QuestionnaireRow';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    height: 100%;
    margin: 0 40px;
    padding: 20px 50px;
    box-sizing: border-box;
    background-color: ${colors.white};
    overflow: auto;
`;

type Props = {
    customerId: number;
};

type Question = {
    question: string;
    answer: string;
};

const Questionnaire: React.FC<Props> = ({ customerId }) => {
    const { selected } = useContext(LessonPropertyTabContext);
    const { tempoStore } = useStore();
    const { enqueteQuestionNames } = tempoStore;
    const visible = useMemo(() => {
        return QUESTIONNAIRE === selected;
    }, [selected]);

    const { loading, error, value } = useAsync(async () => {
        return await Api.fetachCusomerLatestEnqueteAnswer(Number(customerId));
    }, [customerId]);

    const questions = useMemo(() => {
        return value ? value : undefined;
    }, [value]);

    const questionMaps = useMemo<Question[]>(() => {
        if (!questions) {
            return [];
        }
        const {
            question_answer_1,
            question_answer_2,
            question_answer_3,
            question_answer_4,
            question_answer_5,
            question_answer_6,
            question_answer_7,
            question_answer_8,
            question_answer_9,
            question_answer_10,
        } = questions;
        const answers = [
            question_answer_1 ?? '',
            question_answer_2 ?? '',
            question_answer_3 ?? '',
            question_answer_4 ?? '',
            question_answer_5 ?? '',
            question_answer_6 ?? '',
            question_answer_7 ?? '',
            question_answer_8 ?? '',
            question_answer_9 ?? '',
            question_answer_10 ?? '',
        ];
        return enqueteQuestionNames
            .filter((p) => Boolean(p))
            .map<Question>((question, index) => {
                return {
                    question,
                    answer: answers[index],
                };
            });
    }, [enqueteQuestionNames, questions]);
    return (
        <Container visible={visible}>
            {loading === true && <CircularProgress />}
            {loading === false && error && (
                <>アンケート情報の取得に失敗しました</>
            )}
            {loading === false && value && (
                <Fragment>
                    {questionMaps.map(({ question, answer }, index) => (
                        <QuestionnaireRow
                            key={index}
                            index={index + 1}
                            answer={answer}
                            question={question}
                        />
                    ))}
                </Fragment>
            )}
        </Container>
    );
};

export default observer(Questionnaire);
