import { createMuiTheme, lighten } from '@material-ui/core/styles';
// import { dark } from '@material-ui/core/styles/createPalette';

const space = 8;
const SiteCommonTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#3b86ff',
        },
        secondary: {
            main: '#ff7277',
            light: lighten('#ff7277', 0.2),
            contrastText: '#fff',
        },
        // dominant: {
        //     main: '#43425b',
        //     dark: darken('#43425b', 0.2 * 1.5),
        //     light: lighten('#43425b', 0.2),
        //     contrastText: dark.text.primary,
        // },
        background: {
            default: '#f1f0f8',
            // component: '#f5f6fa',
            // tableHeader: '#e8e9ec',
        },
    },
    typography: {
        h2: {
            fontSize: 24,
        },
        h3: {
            fontSize: 22,
        },
        button: {
            textTransform: 'none',
        },
    },
    spacing: space,
    overrides: {
        MuiInputBase: {
            input: {
                padding: '10px',
            },
            root: {
                lineHeight: 'inherit',
            },
        },
        MuiOutlinedInput: {
            multiline: {
                padding: '8px 12px',
            },
        },
        MuiSelect: {
            root: {
                padding: '10px',
            },
        },
        MuiButton: {
            root: {
                padding: '0px 10px',
            },
            outlined: {
                padding: '0px 10px',
                backgroundColor: 'white',
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTypography: {
            h2: {
                margin: `0 ${space * 3}px ${space * 2}px`,
            },
            h3: {
                margin: `0 ${space * 3}px ${space * 2}px`,
            },
        },
    },
});
export default SiteCommonTheme;
