export const Routes = {
    LESSON_LIST: '/lesson/select',
    LESSON_START: '/lesson/start',
    LESSON_PAST_SWING: '/lesson/pastswing',
    LESSON_RECORD_SWING: '/lesson/recordswing',
    LESSON_PLAY_SWING: '/lesson/playswing',
    LIBRARY_LIST: '/customer/list',
    LIBRARY_DETAIL: '/customer/detail/:id',
    LIBRARY_EDIT: '/customer/edit/:id',
    CUSTOMER_REGISTER: '/customer/register',
    SETTINGS: '/settings',
    SWING_LIST: '/swing/list',
    MERCHANT: '/merchant/list',
    MERCHANT_DETAIL: '/merchant/detail/:id',
    MERCHANT_SWING: '/merchant/swing',
    MERCHANT_SWING_RECORD: '/merchant/swing/record',
    SWING_PLAY_SINGLE: '/swing/play/single',
    SWING_PLAY_DOUBLE: '/swing/play/double',
};

export const LessonRoutes = [
    Routes.LESSON_LIST,
    Routes.LESSON_START,
    Routes.LESSON_PAST_SWING,
    Routes.LESSON_RECORD_SWING,
    Routes.LESSON_PLAY_SWING,
];
