import React, { useContext } from 'react';
import styled from 'styled-components';
import { LessonPropertyTabContext } from '../../../contexts/LessonPropertyTabContext';
import {
    CUSTOMER_BASIC_PROFILE,
    CUSTOMER_DETAIL_PROFILE,
    QUESTIONNAIRE,
    TODAY_LESSON,
} from '../../const/LessonProperties';
import LessonPropertyItem from './LessonPropertyItem';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
`;

type Props = {
    activeColor: string;
};
const LessonPropertyNavigation: React.FC<Props> = ({ activeColor }) => {
    const { selected } = useContext(LessonPropertyTabContext);
    return (
        <Wrapper>
            <LessonPropertyItem
                typeVal={TODAY_LESSON}
                selected={selected}
                activeColor={activeColor}
            >
                本日のレッスン
            </LessonPropertyItem>
            <LessonPropertyItem
                typeVal={CUSTOMER_BASIC_PROFILE}
                selected={selected}
                activeColor={activeColor}
            >
                基本情報
            </LessonPropertyItem>
            <LessonPropertyItem
                typeVal={CUSTOMER_DETAIL_PROFILE}
                selected={selected}
                activeColor={activeColor}
            >
                詳細情報
            </LessonPropertyItem>
            <LessonPropertyItem
                typeVal={QUESTIONNAIRE}
                selected={selected}
                activeColor={activeColor}
            >
                アンケート
            </LessonPropertyItem>
        </Wrapper>
    );
};

export default LessonPropertyNavigation;
