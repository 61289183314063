import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import LeftPain from '../surface/CustomerBasicProfileLeftPain';
import RightPain from '../surface/CustomerBasicProfileRightPain';
import ProfileEditNavigationButton from '../surface/ProfileEditNavigationButton';

const GridContainer = styled.section`
    display: grid;
    grid-template-columns: 220px 1fr;
    row-gap: 10px;
    column-gap: 20px;
    height: 100%;
    margin: 0 40px;
    padding: 10px;
    box-sizing: border-box;
    background-color: ${colors.white};
    overflow: auto;
`;

type Props = {
    customer: CustomerDisplay;
};

const CustomerBasicProfile: React.FC<Props> = ({ customer }) => {
    const history = useHistory();
    const handleClickEdit = () => {
        history.push(Routes.LIBRARY_EDIT.replace(':id', `${customer.id}`));
    };

    return (
        <GridContainer>
            <LeftPain customer={customer} />
            <RightPain customer={customer} />
            <ProfileEditNavigationButton onClick={handleClickEdit}>
                編集
            </ProfileEditNavigationButton>
        </GridContainer>
    );
};

export default CustomerBasicProfile;
