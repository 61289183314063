import { CircularProgress } from '@material-ui/core';
import React, { Fragment, useContext, useMemo } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import { LessonPropertyTabContext } from '../../../contexts/LessonPropertyTabContext';
import Api from '../../../utils/Api';
import DisplayExperienceYear from '../../base/surface/DisplayExperienceYear';
import { CUSTOMER_DETAIL_PROFILE } from '../../const/LessonProperties';
import { colors } from '../../const/Styles';
import CustomerFeatureCard from '../surface/CustomerFeatureCard';
import FeatureDescription from '../surface/FeatureDescription';

type ContainerProps = {
    visible: boolean;
};

const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    height: 100%;
    margin: 0 40px;
    padding: 20px 50px;
    box-sizing: border-box;
    background-color: ${colors.white};
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 10px;
`;

const PartialDescriptionBox = styled.div`
    border-top: solid 1px ${colors.lighterGray};
`;

type Props = {
    customerId: number;
};

const CustomerDetailedProfile: React.FC<Props> = ({ customerId }) => {
    const { selected } = useContext(LessonPropertyTabContext);

    const visible = useMemo(() => {
        return CUSTOMER_DETAIL_PROFILE === selected;
    }, [selected]);

    const { loading, error, value } = useAsync(async () => {
        return await Api.getCustomerDetail(customerId);
    }, [customerId]);

    const customerDetail = useMemo(() => {
        return value ? value[0] : undefined;
    }, [value]);

    const PerYearRoundTitle = (
        <>
            年間
            <br />
            ラウンド数
        </>
    );
    return (
        <Container visible={visible}>
            {loading === true && <CircularProgress />}
            {loading === false && error && <>詳細情報の取得に失敗しました</>}
            {loading === false && customerDetail && (
                <Fragment>
                    <Grid>
                        <CustomerFeatureCard
                            title='ヘッドスピード'
                            footer='m/s'
                        >
                            {customerDetail.head_speed}
                        </CustomerFeatureCard>
                        <CustomerFeatureCard title='飛距離' footer='y/d'>
                            {customerDetail.distance}
                        </CustomerFeatureCard>
                        <CustomerFeatureCard title='球筋' footer='型'>
                            {customerDetail.projectile}
                        </CustomerFeatureCard>
                        <PartialDescriptionBox>
                            <FeatureDescription title='得意クラブ'>
                                {customerDetail.club_good_at}
                            </FeatureDescription>
                            <FeatureDescription title='ゴルフ歴'>
                                <DisplayExperienceYear
                                    years={customerDetail.years_golf}
                                />
                            </FeatureDescription>
                            <FeatureDescription title='スポーツ歴'>
                                {customerDetail.years_sport}
                            </FeatureDescription>
                            <FeatureDescription title={PerYearRoundTitle}>
                                {customerDetail.rounds_per_year}回
                            </FeatureDescription>
                        </PartialDescriptionBox>
                        <CustomerFeatureCard title='ベストスコア' footer=''>
                            {customerDetail.best_score}
                        </CustomerFeatureCard>
                        <CustomerFeatureCard title='平均スコア/HDCP' footer=''>
                            {customerDetail.avg_score}
                        </CustomerFeatureCard>
                    </Grid>
                    <FeatureDescription title='ホームコース'>
                        {customerDetail.home_course}
                    </FeatureDescription>
                    <FeatureDescription title='練習回数'>
                        {customerDetail.total_practice}回
                    </FeatureDescription>
                    <FeatureDescription title='メモ(非公開)'>
                        {customerDetail.memo}
                    </FeatureDescription>
                </Fragment>
            )}
        </Container>
    );
};

export default CustomerDetailedProfile;
