export interface Vector {
    x: number;
    y: number;
}

const calcAngle = (vector1: Vector, vector2: Vector): number => {
    const dot = vector1.x * vector2.x + vector1.y * vector2.y;
    const abs1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
    const abs2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);
    const cosTheta = dot / (abs1 * abs2);
    const theta = Math.acos(cosTheta);
    return Math.round((theta / Math.PI) * 180);
};

export default calcAngle;
