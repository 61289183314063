import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { CustomerEnqueteAnswers } from '../../../utils/Api';
import { colors } from '../../const/Styles';

type RowProps = {
    show: boolean;
};

const Row = styled.article<RowProps>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px 0;
    width: 100%;
    border-bottom: solid 1px ${colors.lightGray3};
`;

const RowIndex = styled.label`
    display: flex;
    flex-direction: row;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1;
    margin-right: 20px;
    color: #5b5b5b;
`;

const AnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;
`;

const Question = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    color: #5b5b5b;
    font-size: 1.3rem;
    line-height: 1;
    padding-bottom: 5px;
`;

const Answer = styled(Question)`
    color: ${colors.lightGray3};
    font-size: 1.1rem;
    justify-content: space-between;
`;

const StyledRadio = styled(Radio)`
    &.Mui-checked {
        color: ${colors.footerIconSelected};
    }
`;

const InlineRadioGroup = styled(RadioGroup)`
    &.MuiFormGroup-root {
        flex-direction: row;
    }
`;
type Props = {
    question: string;
    answer: string;
    index: number;
    objectKey: keyof CustomerEnqueteAnswers;
    onChange?: (value: string, objectKey: keyof CustomerEnqueteAnswers) => void;
};

const QuestionnaireRow: React.FC<Props> = ({
    question,
    answer,
    index,
    objectKey,
    onChange,
}) => {
    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(evt.currentTarget.value, objectKey);
        }
    };
    return (
        <Row show={question.length > 0}>
            <RowIndex>{index}.</RowIndex>
            <AnswerContainer>
                <Question>{question}</Question>
                <Answer>
                    <InlineRadioGroup value={answer} onChange={handleChange}>
                        <FormControlLabel
                            label='当てはまる'
                            value='当てはまる'
                            control={<StyledRadio />}
                        />
                        <FormControlLabel
                            label='やや当てはまる'
                            value='やや当てはまる'
                            control={<StyledRadio />}
                        />
                        <FormControlLabel
                            label='当てはまらない'
                            value='当てはまらない'
                            control={<StyledRadio />}
                        />
                    </InlineRadioGroup>
                </Answer>
            </AnswerContainer>
        </Row>
    );
};

export default QuestionnaireRow;
