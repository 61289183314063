/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';
import Schema, { SchemaDefinition } from 'validate';

type FunctionReturnType = (value: {
    [key: string]: any;
}) => Map<string, string[]>;
export function useValidate(schema: SchemaDefinition): FunctionReturnType {
    const validationSchema = useMemo(() => {
        return new Schema(schema);
    }, [schema]);

    const dispatch = useCallback(
        (value: { [key: string]: any }): Map<string, string[]> => {
            const clonedValue = Object.assign({}, value);
            const results = validationSchema.validate(clonedValue);
            const errors = new Map<string, string[]>();
            for (const error of results) {
                const errorMessage = (error as unknown as Error).message;
                console.log(errorMessage);
                // メッセージ無しは無視
                if (!errorMessage) {
                    continue;
                }
                const messages = errors.get(error.path) ?? [];
                messages.push(String(errorMessage));
                errors.set(error.path, messages);
            }
            return errors;
        },
        [validationSchema],
    );
    return dispatch;
}
