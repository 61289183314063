import { Button, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import DeleteQuestionnaireButton from '../../../assets/delete_button.svg';
import AuthStore from '../../../store/AuthStore';
import Api from '../../../utils/Api';
import { EnqueteQuestionEntity } from '../../@types/mysqlEntities';
import { colors } from '../../const/Styles';

const useStyles = makeStyles(() =>
    createStyles({
        numberTypography: {
            margin: 'auto',
            fontSize: 23,
            fontWeight: 'bold',
            color: `${colors.footerIconUnSelected}`,
        },
        disabledTextForm: {
            height: 35,
            width: '100%',
            backgroundColor: `${colors.white}`,
        },
        fiilingtextForm: {
            height: 35,
            width: '100%',
        },
        button: {
            color: `${colors.black}`,
            height: 25,
            margin: 'auto',
            fontWeight: 'bold',
            width: 48,
            position: 'absolute',
        },
        deleteButton: {
            position: 'absolute',
            top: 15,
            right: 15,
            zIndex: 100,
        },
        createButton: {
            backgroundColor: `${colors.footerIconSelected}`,
            zIndex: 100,
        },
        TypographyGrid: {
            diplay: 'block',
            width: '5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        finishButtonGrid: {
            position: 'absolute',
            top: 15,
            right: -5,
        },
        inputGrid: {
            position: 'relative',
            width: '472px',
            height: 60,
            display: 'flex',
            justifyContent: `center`,
            borderBottom: `1px solid ${colors.lighterGray}`,
        },
        inputBox: {
            width: '100%',
            height: 35,
            marginTop: 10,
            marginLeft: 20,
        },
        errorMsg: {
            color: 'red',
            margin: '5px auto 0',
        },
    }),
);


const ErrorMsg = styled.section`
    display: 'block';
    margin: 5px auto 0;
    color: red;
`;


type Props = {
    index: number;
    value: EnqueteQuestionEntity | undefined;
    isEditable: boolean;
    isDeletable: boolean;
    onChange?: (changed: EnqueteQuestionEntity[]) => void;
};

type MuiChangeType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
const QuestionnaireFillingItems: React.FC<Props> = ({
    index,
    value,
    isEditable,
    isDeletable,
    onChange
}) => {
    const classes = useStyles();

    const [name, setName] = useState('');
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        if (value) {
            setName(value.name ?? '');
        }
    }, [value]);

    const handleInputName = useCallback((event: MuiChangeType) => {
        if (isEditable) {
            setName(event.currentTarget.value);
        }
    }, [isEditable]);

    const handleClickSave = useCallback(async () => {
        const isError = name.length === 0;
        setHasErrors(isError);
        if (!isError) {
            // 保存処理
            const questions = await Api.saveEnqueteItem({
                id: value ? value.id : null,
                tempo_id: Number(AuthStore.tempoId),
                name,
                display_order: index,
            });
            if (onChange) {
                onChange(questions);
            }
        }
    }, [name, onChange, value, index]);

    const handleClickDelete = useCallback(async () => {
        // 削除処理
        if (value) {
            const questions = await Api.deleteEnqueteItem(value.id, value.tempo_id);
            setName('');
            if (onChange) {
                onChange(questions);
            }
        }
    }, [onChange, value]);

    return (
        <React.Fragment>
            {hasErrors && (
                <ErrorMsg>入力してください </ErrorMsg>
            )}
            <div className={classes.inputGrid}>
                <div className={classes.TypographyGrid}>
                    <Typography
                        variant='h3'
                        className={classes.numberTypography}
                    >
                        {index + 1}.
                    </Typography>
                </div>
                <div className={classes.inputBox}>
                    <TextField
                        className={classes.fiilingtextForm}
                        variant='outlined'
                        value={name}
                        onChange={handleInputName}
                        inputProps={{
                            style: {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 10,
                                height: 35,
                            },
                        }}
                    />
                </div>
                {isDeletable && (
                    <div>
                        <img
                            className={`${classes.button} + ${classes.deleteButton}`}
                            id={index.toString()}
                            src={DeleteQuestionnaireButton}
                            onClick={handleClickDelete}
                        ></img>
                    </div>
                )}
                {isEditable && (
                    <div className={classes.finishButtonGrid}>
                        <Button
                            className={`${classes.button} + ${classes.createButton}`}
                            variant='contained'
                            onClick={handleClickSave}
                        >
                            完了
                        </Button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default QuestionnaireFillingItems;
