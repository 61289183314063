import { Button, Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import TrushIcon from '../../../assets/trush.svg';
import { useStore } from '../../../contexts/StoreContext';
import { SavedToolImageMap } from '../../../store/RecordingStore';
import { Club } from '../../const/SwingClub';
import { colors } from '../../const/Styles';
import SwingClubSelector from '../input/SwingClubSelector';
import SwingYardInput from '../input/SwingYardInput';

const Container = styled.div`
    position: absolute;
    top: 56px;
    left: 56px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 112px);
    height: calc(100% - 112px);
    overflow: auto;
    padding: 40px;
    background-color: ${colors.black};
    box-sizing: border-box;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 20px;
    color: ${colors.footerIconSelected};
`;

const Title = styled.h3`
    margin: 0;
    padding: 0;
    font-size: 38px;
    font-weight: bold;
`;

const SwingNo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    .text {
        font-size: 18px;
    }
    .number {
        font-size: 59px;
        font-weight: bold;
    }
`;

const Footer = styled.div`
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px 30px;
    box-sizing: border-box;
`;

const StyledButton = styled(Button)`
    width: 40%;
    &.MuiButton-root {
        background-color: ${colors.footerIconSelected} !important;
        color: ${colors.black};
        font-weight: bold;
        font-size: 16px;
    }
`;

const Icon = styled.i`
    display: flex;
    color: ${colors.footerIconSelected};
    svg {
        height: 39px;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    align-items: center;
`;

type Props = {
    toolmap: SavedToolImageMap | undefined;
    onlyUpdate?: boolean;
    onClose: () => void;
};

const SaveSwingModal: React.FC<Props> = ({
    toolmap,
    onlyUpdate = false,
    onClose,
}) => {
    const [club, setClub] = useState<string | null>(null);
    const handleChangeClub = (value: Club) => {
        setClub(value);
    };

    const [yard, setYard] = useState<string | null>(null);
    const handleInputYard = (value: string) => {
        setYard(value);
    };

    const { recordingStore } = useStore();
    const { viewedCustomerId } = recordingStore;

    useEffect(() => {
        const registeredVideo = recordingStore.getSingleRegisteredVideo(
            toolmap?.id,
        );
        setYard(registeredVideo?.yard ?? null);
        setClub(registeredVideo?.club ?? null);
    }, [recordingStore, toolmap?.id]);

    const isRegistered = useMemo(() => {
        return recordingStore.getSingleRegisteredVideo(toolmap?.id);
    }, [recordingStore, toolmap?.id]);

    const swingNumber = useMemo(() => {
        const registeredVideo = (recordingStore.registeredVideos ?? []).find(
            (p) => p.id === viewedCustomerId,
        );
        // 親レコードが登録されてなければ1
        if (!registeredVideo) {
            return '1';
        }
        // toolmap無しも1
        if (!toolmap) {
            return '1';
        }
        // 子レコードとして登録されてるなら現在の番号、なければ次の番号
        const video = registeredVideo.videos.find((p) => p.id === toolmap.id);
        return `${video
            ? video.number
            : Math.min(2, registeredVideo.videos.length + 1)
            }`;
    }, [recordingStore.registeredVideos, toolmap, viewedCustomerId]);

    const handleSave = useCallback(() => {
        if (!toolmap) {
            return;
        }
        const { id, video, toolImage = null } = toolmap;
        recordingStore.registerVideo(viewedCustomerId, {
            id,
            number: swingNumber,
            club,
            yard,
            video,
            toolImage,
            isBestSwing: false,
        });
        onClose();
    }, [
        toolmap,
        recordingStore,
        viewedCustomerId,
        swingNumber,
        club,
        yard,
        onClose,
    ]);

    const handleDeregister = useCallback(() => {
        if (!toolmap) {
            return;
        }
        const { id } = toolmap;
        recordingStore.deregisterVideo(id);
        onClose();
    }, [toolmap, recordingStore, onClose]);

    const handleRemove = useCallback(() => {
        if (!toolmap) {
            return;
        }
        const { id } = toolmap;
        recordingStore.removeVideo(id);
        onClose();
    }, [toolmap, recordingStore, onClose]);

    const handleCancel = useCallback(() => {
        onClose();
    }, [onClose])

    return (
        <Modal open={typeof toolmap !== 'undefined'} onBackdropClick={handleCancel}>
            <Container>
                <Header>
                    <Title>スイング登録</Title>
                    <SwingNo>
                        <span className='text'>スイング</span>
                        <span className='number'>{swingNumber}</span>
                    </SwingNo>
                </Header>
                <Body>
                    <video
                        controls
                        width={336}
                        height={485}
                        src={
                            toolmap?.video
                                ? URL.createObjectURL(toolmap.video)
                                : ''
                        }
                    ></video>
                    <SwingClubSelector
                        value={club}
                        onChange={handleChangeClub}
                    />
                    <SwingYardInput value={yard} onInput={handleInputYard} />
                </Body>
                <Footer>
                    {isRegistered && onlyUpdate === false && (
                        <StyledButton onClick={handleDeregister}>
                            登録解除
                        </StyledButton>
                    )}
                    {(!isRegistered || onlyUpdate) && (
                        <StyledButton onClick={handleSave}>登録</StyledButton>
                    )}
                    <StyledButton onClick={handleCancel}>キャンセル</StyledButton>
                    <Icon onClick={handleRemove}>
                        <ReactSVG src={TrushIcon ?? ''} />
                    </Icon>
                </Footer>
            </Container>
        </Modal>
    );
};

export default SaveSwingModal;
