import { useState, useCallback } from 'react';

import { LineColor } from './useEditorCanvas';

interface CircleValues {
    firstX: number;
    firstY: number;
    radius: number;
    color: LineColor;
}

export interface Values {
    firstX: number;
    firstY: number;
    radius: number;
    color: LineColor;
    drawnCircles: CircleValues[];
}

export interface Actions {
    onPointerDown: (x: number, y: number, color: LineColor) => void;
    onPointerMove: (x: number, y: number) => void;
    onPointerUp: () => void;
    reset: () => void;
}

const useEditorCircle = (): [Values, Actions] => {
    const [firstX, setFirstX] = useState(0);
    const [firstY, setFirstY] = useState(0);
    const [radius, setRadius] = useState(0);
    const [color, setColor] = useState<LineColor>(LineColor.red);
    const [drawing, setDrawing] = useState(false);
    const [drawnCircles, setDrawnCircles] = useState<CircleValues[]>([]);

    const onPointerDown = useCallback(
        (x: number, y: number, color: LineColor) => {
            setDrawing(true);
            setFirstX(x);
            setFirstY(y);
            setColor(color);
        },
        [],
    );

    const onPointerMove = useCallback(
        (x: number, y: number) => {
            if (drawing) {
                const length = Math.sqrt(
                    (firstX - x) * (firstX - x) + (firstY - y) * (firstY - y),
                );
                setRadius(length);
            }
        },
        [drawing, firstX, firstY],
    );

    const onPointerUp = useCallback(() => {
        setDrawing(false);
        setDrawnCircles([
            ...drawnCircles,
            {
                firstX,
                firstY,
                radius,
                color,
            },
        ]);
        setFirstX(0);
        setFirstY(0);
        setRadius(0);
    }, [color, drawnCircles, firstX, firstY, radius]);

    const reset = useCallback(() => {
        setDrawnCircles([]);
        setFirstX(0);
        setFirstY(0);
        setRadius(0);
    }, []);

    const values = {
        firstX,
        firstY,
        radius,
        color,
        drawnCircles,
    };
    const actions = { onPointerDown, onPointerMove, onPointerUp, reset };

    return [values, actions];
};

export default useEditorCircle;
