import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.div`
    grid-column: 1 / 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: fit-content;
`;

const Button = styled.button`
    outline: none;
    border: none;
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    border-radius: 4px;
    padding: 5px;
    width: 447px;
    font-weight: bold;
    font-size: 1rem;
`;

type Props = {
    onClick?: () => void;
};

const ProfileEditNavigationButton: React.FC<Props> = ({
    onClick,
    children,
}) => {
    return (
        <Container>
            <Button onClick={onClick}>{children}</Button>
        </Container>
    );
};

export default ProfileEditNavigationButton;
