import React, { forwardRef, SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

type SizeType = 'sm' | 'lg';
type SelectProps = {
    className?: SizeType;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${colors.formBg};
    border: solid 2px ${colors.lighterGray};
    border-radius: 2px;
    width: fit-content;
    box-sizing: border-box;
    padding: 0 5px;
    &.sm {
        width: 7em;
    }
    &.lg {
        width: 100%;
    }
    &:after {
        content: '▼';
        color: ${colors.footerIconSelected};
        font-size: 1.5em;
    }
`;

const Select = styled.select<SelectProps>`
    display: flex;
    flex-direction: row;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: inherit;
    appearance: none;
    width: 100%;
    padding: 5px;
`;

const BaseSelect = forwardRef<
    HTMLSelectElement,
    React.PropsWithChildren<
        SelectHTMLAttributes<HTMLSelectElement> & SelectProps
    >
>((props, ref) => {
    return (
        <Container className={props.className}>
            <Select {...props} ref={ref}>
                {props.children}
            </Select>
        </Container>
    );
});

export default BaseSelect;
