import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { createStyles, makeStyles, Modal } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseSharp';
import { observer } from 'mobx-react-lite';
import HeaderIcon from '../../assets/note.svg';
import { MovieDisplay } from '../../utils/Video';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import { VideoMovie } from '../video/PlayingVideoCard';
import VideoEditor from '../video/VideoEditor';
import MovieSingleEditFooter from './navigation/MovieSingleEditFooter';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import LessonMoviePlayer from '../swing/surface/LessonMoviePlayer';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.gray};
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${colors.white};
    .userName {
        color: ${colors.white};
    }
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 30px;
    right: 30px;
    color: ${colors.lightGray};
    z-index: 9999;
    svg {
        width: 60px;
        height: 60px;
    }
`;

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

type LocationProps = {
    movie: MovieDisplay;
    movieIndex: number;
};

const PlaySinglePage: React.FC = () => {
    const location = useLocation<LocationProps>();
    const { state } = location;
    const classes = useStyles();
    const [modalOpened, setModalOpened] = useState(false);
    const [saveCounter, setSaveCounter] = useState<number>(0);
    const { movie, movieIndex } = state;
    const {
        id,
        src,
        filename,
        customer_id_or_staff_id,
        created_at,
        tool_image_filename,
        tool_image_filepath,
    } = movie;
    const videoMovie: VideoMovie = {
        id: Number(id),
        type: 'movie',
        filepath: src ?? '',
        filename: filename,
        customerIdOrStaffId: customer_id_or_staff_id,
        movieCreatedAt: created_at,
        toolImageFilename: tool_image_filename,
        toolImageFilePath: tool_image_filepath,
    };

    const handleModalOpen = () => {
        setModalOpened(true);
    };

    const handleModalClose = () => {
        setModalOpened(false);
    };

    // TODO: 構造の問題でなぜか更新処理がuseEffectで実装されているので２系統で分離
    useEffect(() => {
        setModalOpened(false);
    }, [saveCounter]);

    return (
        <Page title='レッスンノート' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation
                    customerId={movie.customer_id_or_staff_id}
                >
                    過去のスイング
                </PlayerHeaderNavigation>
                <VideoContainer>
                    <LessonMoviePlayer
                        togglableBestSwing={true}
                        movieIndex={movieIndex}
                        movieDisplay={movie}
                        reloadToolImage={modalOpened}
                    ></LessonMoviePlayer>
                </VideoContainer>
                <MovieSingleEditFooter onClick={handleModalOpen}>
                    編集
                </MovieSingleEditFooter>
            </ContentContainer>
            <Modal
                className={classes.modal}
                open={modalOpened}
                onClose={handleModalClose}
            >
                <>
                    <StyledIconButton onClick={handleModalClose}>
                        <CloseIcon></CloseIcon>
                    </StyledIconButton>
                    <VideoEditor
                        videoSrc={videoMovie}
                        height={700}
                        width={550}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </>
            </Modal>
        </Page>
    );
};

export default observer(PlaySinglePage);
