import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    justify-content: space-evenly;
    box-sizing: border-box;
    border-top: 2px solid ${colors.lighterGray};
    width: 100%;
`;

const ActionButton = styled.button`
    display: flex;
    flex-direction: row;
    padding: 3px;
    outline: none;
    border: none;
    border-radius: 4px;
    color: ${colors.black};
    background-color: ${colors.footerIconSelected};
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    width: 40%;
`;

type Props = {
    onClickToolMapEdit?: () => void;
    onClickPropEdit?: () => void;
};
const RecordedMovieEditFooter: React.FC<Props> = ({
    onClickToolMapEdit,
    onClickPropEdit,
}) => {
    const handleClickEdit = () => {
        if (onClickToolMapEdit) {
            onClickToolMapEdit();
        }
    };

    const handleClickEditProps = () => {
        if (onClickPropEdit) {
            onClickPropEdit();
        }
    };

    return (
        <Container>
            <ActionButton type='button' onClick={handleClickEdit}>
                編集
            </ActionButton>
            <ActionButton type='button' onClick={handleClickEditProps}>
                スイング情報変更
            </ActionButton>
        </Container>
    );
};

export default RecordedMovieEditFooter;
