export type PersonalityType =
    | 'action'
    | 'sense'
    | 'observe'
    | 'think'
    | 'unknown';

export const PersonalityMaps = new Map<PersonalityType, string>([
    ['action', '行動型'],
    ['sense', '感覚型'],
    ['think', '思案型'],
    ['observe', '観察型'],
    ['unknown', '未設定'],
]);

export const Personalities = Object.fromEntries(PersonalityMaps);
