import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { RegisteredVideoMap } from '../../../store/RecordingStore';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';

const Container = styled.span`
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px;
    width: 50%;
`;

const StyledVideo = styled.video`
    height: 100%;
    width: 100%;
`;

const Overlay = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    color: ${colors.white};
    font-size: 128px;
    font-weight: bold;
    opacity: 0.8;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

type Props = {
    customerId: string;
    data: RegisteredVideoMap;
};

const RegisteredMovieThumbnail: React.FC<Props> = ({ customerId, data }) => {
    const { video, number } = data;

    const history = useHistory();
    const handleClick = () => {
        history.push(Routes.LESSON_PLAY_SWING, {
            customerId,
            video: data.video,
        });
    };
    return (
        <Container>
            {video && (
                <StyledVideo
                    src={URL.createObjectURL(video)}
                    controls={false}
                    autoPlay={false}
                />
            )}
            {video && <Overlay onClick={handleClick}>{number}</Overlay>}
        </Container>
    );
};

export default RegisteredMovieThumbnail;
