import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import { colors } from '../../const/Styles';
import CustomerCard from '../surface/CustomerCard';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    flex-grow: 2;
    padding: 20px;
    background-color: ${colors.gray};
`;

const LessonCustmers: React.FC = () => {
    const { lessonStore } = useStore();
    const { selectedCustomers, viewedCustomer } = lessonStore;

    const customerList: Array<CustomerDisplay | undefined> =
        Array.from(selectedCustomers);
    customerList.unshift(undefined);

    const handleSelectCustomer = (customer: CustomerDisplay) => {
        lessonStore.setViewedCustomer(customer);
    };

    return (
        <Container>
            {customerList.map((customer, index) => (
                <CustomerCard
                    key={index}
                    customer={customer}
                    index={index}
                    isSelected={viewedCustomer.id === customer?.id}
                    onClick={handleSelectCustomer}
                />
            ))}
        </Container>
    );
};

export default observer(LessonCustmers);
