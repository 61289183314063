import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import LibraryIcon from '../../assets/footer-library.svg';
import { useStore } from '../../contexts/StoreContext';
import { CustomerDisplay } from '../../store/LessonStore';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import CustomerNameFilter from './surface/CustomerNameFilter';
import CustomerGridViewer from './display/CustomerGridViewer';
import { useHistory } from 'react-router-dom';
import { Routes } from '../const/Routes';
import { Modal } from '@material-ui/core';
import { useLocation } from 'react-router';

const PageLayout = styled.section`
    display: flex;
    width: 100%;
    height: 100%;
`;

const CustomerSelectWrapper = styled.section`
    grid-column: 1 / 3;
    overflow: hidden;
    border-top 4px solid ${colors.gray};
    display: flex;
    width: 100%;
`;

const ModalView = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalBox = styled.div`
    width: 466px;
    height: 233px;
    background-color: ${colors.blue};
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    font-size: 25px;
`;

interface LocationState {
    success: boolean;
    message: string;
}

const CustomerList: React.FC = () => {
    const [filteredCustomers, setFilteredCustomers] = useState<
        CustomerDisplay[]
    >([]);
    const location = useLocation<LocationState>();

    const handleClickFilter = useCallback((customers: CustomerDisplay[]) => {
        setFilteredCustomers(customers);
    }, []);

    const { lessonStore } = useStore();
    const { allCustomers } = lessonStore;
    useEffect(() => {
        setFilteredCustomers(allCustomers);
    }, [allCustomers]);

    const [open, setOpen] = useState(true);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const history = useHistory();
    const handleSelectCustomer = (customer: CustomerDisplay) => {
        history.push(Routes.LIBRARY_DETAIL.replace(':id', `${customer.id}`));
    };

    return (
        <>
            {location.state?.success ? (
                <ModalView open={open} onClose={handleClose}>
                    <ModalBox>{location.state?.message}</ModalBox>
                </ModalView>
            ) : (
                []
            )}
            <Page headerIcon={LibraryIcon ?? ''} title='ライブラリー'>
                <PageLayout>
                    <CustomerSelectWrapper>
                        <CustomerNameFilter
                            onClick={handleClickFilter}
                        ></CustomerNameFilter>
                        <CustomerGridViewer
                            onSelect={handleSelectCustomer}
                            customers={filteredCustomers.concat()}
                        ></CustomerGridViewer>
                    </CustomerSelectWrapper>
                </PageLayout>
            </Page>
        </>
    );
};

export default observer(CustomerList);
