import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';

import { useStore } from '../../../contexts/StoreContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import Api from '../../../utils/Api';
import { LessonDisplay } from '../../../utils/Lesson';
import { MovieDisplay } from '../../../utils/Video';
import PastLesson from '../surface/PastLesson';

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

type Props = {
    customer: CustomerDisplay;
};

type LessonMovieMapType = Map<string, MovieDisplay[]>;

const PastLessons: React.FC<Props> = ({ customer }) => {
    const { lessonStore } = useStore();

    const [{ loading, error, value }, dispacher] = useAsyncFn(async (): Promise<
        [LessonDisplay[], LessonMovieMapType]
    > => {
        const lessons = (await lessonStore.fetchPastLessons(customer.id)) ?? [];
        const movies = (await Api.fetchCustomerMovies(customer.id))
            .map((movie) => new MovieDisplay(movie))
            .filter((p) => p.fetchable === true);

        // エンティティ設計の都合で別引きする
        // レッスン <=> 動画の形態でMapを作る
        // 既存のモジュールは手続き的に実装しているが、ここではせず下位コンポーネントでLazyLoadする
        const lessonMovieMap: LessonMovieMapType = new Map();
        lessons.forEach(({ lesson_id }) => {
            const filteredMovies = movies.filter(
                (movie) => lesson_id === movie.lesson_id,
            );
            if (filteredMovies.length > 0) {
                lessonMovieMap.set(lesson_id, filteredMovies);
            }
        });
        return [lessons, lessonMovieMap];
    }, [customer.id]);

    useEffect(() => {
        dispacher().catch((error) => {
            console.error(error)
        });
    }, [customer.id, dispacher]);

    const handlChange = useCallback(async () => {
        await dispacher()
    }, [dispacher])

    if (loading === true) {
        return (
            <ProgressContainer>
                <CircularProgress />
            </ProgressContainer>
        );
    }

    if (error) {
        return (
            <ProgressContainer>
                レッスンの取得でエラーが発生しました。
            </ProgressContainer>
        );
    }

    const [lessons = [], lessonMovieMap = new Map<string, MovieDisplay[]>()] =
        value ?? [];

    return (
        <Fragment>
            {lessons.map((lesson) => (
                <PastLesson
                    key={lesson.lesson_id}
                    lesson={lesson}
                    customer={customer}
                    movies={lessonMovieMap.get(lesson.lesson_id) ?? []}
                    onChange={handlChange}
                />
            ))}
        </Fragment>
    );
};

export default observer(PastLessons);
