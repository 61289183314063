import React from 'react';
import styled from 'styled-components';
import { usePastLessonCurriculumMap } from '../../../hooks/useQuestionnaireMapCreater';
import { CustomerDisplay } from '../../../store/LessonStore';
import { LessonDisplay } from '../../../utils/Lesson';
import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import MovieThumbnails from './MovieThumbnails';
import TempoStore from '../../../store/TempoStore';
import { ReactSVG } from "react-svg";
import PencilIcon from '../../../assets/pencil.svg';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    color: ${colors.black};
`;

const LessonHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: solid 1px ${colors.white};
    border-bottom: solid 1px ${colors.white};
    background-color: ${colors.lighterGray};
    box-sizing: border-box;
`;

const LessonHeaderColumn = styled.h4`
    margin: 0;
    color: ${colors.black};
    font-weight: 1rem;
    padding: 6px;
    font-weight: bold;
`;

const CommentWrapper = styled.div`
    display: grid;
    grid-template-columns: 8fr 1fr;
`;
const Comment = styled.div`
    display: block;
    white-space: pre-wrap;
    box-sizing: border-box;
    padding: 5px;
    font-size: 1rem;
`;

const CurriculumList = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    border-top: solid 1px ${colors.lightGray4};
    list-style: none;
    margin-top: 50px;
`;
const CurriculumRow = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 1px ${colors.lightGray4};
    padding: 5px 20px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
`;

type Props = {
    customer: CustomerDisplay;
    lesson: LessonDisplay;
    movies: MovieDisplay[];
    onIconClick: (lessonAchievementId: number) => void;
};

const PastLesson: React.FC<Props> = ({ customer, lesson, movies, onIconClick }) => {
    const { created_date_string, comment } = lesson;
    const curriculumsMap = usePastLessonCurriculumMap(customer, lesson);
    const currentCoach = TempoStore.allCoaches.find(
        (coach) => `${coach.id}` === lesson.staff_id,
    );

    return (
        <Container>
            <LessonHeaderContainer>
                <LessonHeaderColumn>
                    {currentCoach
                        ? `${currentCoach.name_sei} ${currentCoach.name_mei}`
                        : '削除済みコーチ'}
                </LessonHeaderColumn>
                <LessonHeaderColumn>{created_date_string}</LessonHeaderColumn>
            </LessonHeaderContainer>
            <ContentContainer>
                <div>
                    <CommentWrapper>
                        <Comment>{comment || 'コメントはありません'}</Comment>
                        <ReactSVG
                            src={PencilIcon ?? ''}
                            wrapper='span'
                            onClick={() => onIconClick(Number(lesson.lesson_id))}
                            beforeInjection={(svg) => {
                                svg.setAttribute(
                                    'style',
                                    `color: width: 30px; height: 30px;`
                                )
                            }}
                        >
                        </ReactSVG>
                    </CommentWrapper>
                    {curriculumsMap.length > 0 && (
                        <CurriculumList>
                            {curriculumsMap.map(
                                ({ question, answer }, index) => (
                                    <CurriculumRow key={index}>
                                        <span>{question}</span>
                                        <span>{`${answer}%`}</span>
                                    </CurriculumRow>
                                ),
                            )}
                        </CurriculumList>
                    )}
                </div>
                <MovieThumbnails movies={movies} />
            </ContentContainer>
        </Container>
    );
};

export default PastLesson;
