import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import VideoListCard from './VideoListCard';
import PlayingVideoCard from './PlayingVideoCard';
import NotFound from '../NotFound';
export enum PlayingType {
    single,
    double,
    // transparent,
}

function Video(): React.ReactElement {
    const [playingType, setPlayingType] = useState<PlayingType>(
        PlayingType.single,
    );

    return (
        <div>
            {/* <VideoButtonItems
                playingType={playingType}
                onChangePlayingType={onChangePlayingType}
            /> */}
            <Switch>
                <Route exact path='/video/list'>
                    <VideoListCard
                        playingType={playingType}
                        setPlayingType={setPlayingType}
                    />
                </Route>
                <Route exact path='/video/play'>
                    <PlayingVideoCard type={playingType} />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </div>
    );
}

export default Video;
