import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayArrowSharp from '@material-ui/icons/PlayArrowSharp';
import StopIcon from '@material-ui/icons/Stop';
import React from 'react';
import styled from 'styled-components';
import { VideoActions } from '../../../hooks/useVideoJs';
import { colors } from '../../const/Styles';
import PlaybackRateSelector from './PlaybackRateSelector';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    box-sizing: border-box;
`;

const StyledButton = styled(Button)`
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    font-weight: bold;
    padding: 10px 62px;
`;

const StyledIconButton = styled(IconButton)`
    svg {
        height: 50px;
        width: 50px;
    }
`;

type Props = {
    isPlaying: boolean;
    onStart: () => void;
    onSave: () => void;
    actions: VideoActions;
};

const MovieEditorFooter: React.FC<Props> = ({
    actions,
    isPlaying,
    onStart,
    onSave,
}) => {
    return (
        <Container>
            <PlaybackRateSelector onChange={actions.onChangePlayBackRate} />
            <Box>
                <StyledIconButton onClick={actions.skipMinus}>
                    <ChevronLeftIcon
                        fontSize='large'
                        htmlColor={colors.footerIconSelected}
                    />
                </StyledIconButton>
                <StyledIconButton onClick={onStart}>
                    {isPlaying ? (
                        <StopIcon
                            fontSize='large'
                            htmlColor={colors.footerIconSelected}
                        />
                    ) : (
                        <PlayArrowSharp
                            fontSize='large'
                            htmlColor={colors.footerIconSelected}
                        />
                    )}
                </StyledIconButton>
                <StyledIconButton onClick={actions.skipPlus}>
                    <ChevronRightIcon
                        fontSize='large'
                        htmlColor={colors.footerIconSelected}
                    />
                </StyledIconButton>
            </Box>
            <Box>
                <StyledButton onClick={onSave}>保存</StyledButton>
            </Box>
        </Container>
    );
};

export default MovieEditorFooter;
