import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import BaseInput, { PropType } from './BaseInput';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
    > input {
        font-size: inherit;
    }
`;

const Label = styled.label`
    display: block;
    font-size: 12.5pt;
    line-height: 1;
    color: ${colors.lightGray};
    padding-left: 10px;
    box-sizing: border-box;
`;

type LabelProps = {
    title: string;
};
type Props = PropType & LabelProps;

const AttributedInput: React.FC<Props> = (props) => {
    return (
        <Container>
            <Label>{props.title}</Label>
            <BaseInput {...props} />
        </Container>
    );
};

export default AttributedInput;
