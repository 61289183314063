import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import GoBack from '../../../assets/goback.svg';
import { ReactSVG } from 'react-svg';
import { useContext } from 'react';
import { MerchantTabContext } from '../../../contexts/MerchantTabContext';
import { COACH_LIST } from '../../const/MerchantProperties';

type svgViewProps = {
    visible: boolean;
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92px;
    width: 100vw;
    font-size: 37px;
    font-weight: bold;
    position: relative;
    color: ${colors.footerIconSelected};
    background-color: ${colors.black};
`;
const GoBackIcon = styled.i<svgViewProps>`
    display: ${({ visible }) => (visible === true ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 80px;
    position: absolute;
    top: 15px;
    right: 50px;
    color: ${colors.footerIconSelected};
    font-size: 13px;
    svg {
        height: 40px;
        width: 40px;
    }
`;

type Props = {
    isVisible: boolean;
};

const MerchantTitleView: React.FC<Props> = ({ isVisible }) => {
    const { onChange } = useContext(MerchantTabContext);
    const handleClickBackIcon = () => {
        onChange(COACH_LIST);
    };
    return (
        <Container>
            各種登録
            <GoBackIcon visible={isVisible} onClick={handleClickBackIcon}>
                <ReactSVG src={GoBack ?? ''}></ReactSVG>
                コーチ一覧に戻る
            </GoBackIcon>
        </Container>
    );
};

export default observer(MerchantTitleView);
