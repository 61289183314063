import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CustomerDisplay } from '../../../store/LessonStore';
import { Routes } from '../../const/Routes';
import {
    colors,
    profileBorders,
    profileNoSelectedBorder,
} from '../../const/Styles';
import CustomerImage from './CustomerImage';

type WrapperProps = {
    isSelected: boolean;
};
const Wrapper = styled.article<WrapperProps>`
    display: flex;
    flex-direction: column;
    min-width: 130px;
    height: auto;
    position: relative;
    margin-right: 10px;
    opacity: ${({ isSelected }) => (isSelected === true ? '1' : '0.5')};
`;

const NameLabel = styled.label`
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    color: ${colors.white};
    text-align: center;
    height: 1.2rem;
`;

type Props = {
    customer?: CustomerDisplay;
    index: number;
    isSelected?: boolean;
    onClick: (customer: CustomerDisplay) => void;
};

const CustomerCard: React.FC<Props> = ({
    customer,
    index,
    isSelected = false,
    onClick,
}) => {
    const iconColor = profileBorders[index % profileBorders.length];
    const borderColor = customer ? iconColor : profileNoSelectedBorder;
    const history = useHistory();
    const handleClick = () => {
        if (customer) {
            onClick(customer);
            return;
        }
        history.push(Routes.LESSON_LIST);
    };

    return (
        <Wrapper isSelected={isSelected || !customer} onClick={handleClick}>
            <CustomerImage
                color={borderColor}
                iconColor={iconColor}
                src={customer?.s3Path}
            ></CustomerImage>
            <NameLabel>
                {customer?.name_sei} {customer?.name_mei}
            </NameLabel>
        </Wrapper>
    );
};

export default CustomerCard;
