import { Popover } from '@material-ui/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { StaffDisplay } from '../../../store/TempoStore';
import { colors } from '../../const/Styles';
import CoachMenuItem from '../navigation/CoachMenuItem';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    font-size: 1rem;
    color: ${colors.white};
    max-width: 100%;
    position: relative;
`;

const Label = styled.label`
    display: block;
    font-size: 1rem;
`;

const SelectableLabel = styled.label`
    display: block;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    max-width: 170px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    &::after {
        position: relative;
        position: absolute;
        content: '▼';
        right: -0.5rem;
        color: ${colors.footerIconSelected};
        font-size: 1.2rem;
    }
`;

type Props = {
    coaches: StaffDisplay[];
    selectedCoach?: StaffDisplay;
    onSelect: (coach: StaffDisplay) => void;
};
const CoachSelector: React.FC<Props> = ({
    coaches,
    selectedCoach,
    onSelect,
}) => {
    const [menuOpened, setMenuOpened] = useState(false);
    const popoverElRef = useRef<HTMLLabelElement>(null);
    const handleOpenMenu = useCallback(() => {
        setMenuOpened(true);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setMenuOpened(false);
    }, []);

    const handleSelectCoach = (coach: StaffDisplay) => {
        onSelect(coach);
        setMenuOpened(false);
    };

    const coachName = useMemo(() => {
        if (selectedCoach) {
            const { name_sei, name_mei } = selectedCoach;
            return `${name_sei} ${name_mei}`;
        }
        return '';
    }, [selectedCoach]);

    return (
        <Wrapper>
            <Label>担当</Label>
            <SelectableLabel onClick={handleOpenMenu} ref={popoverElRef}>
                {coachName}
            </SelectableLabel>
            <Popover
                open={menuOpened}
                onClose={handleCloseMenu}
                anchorEl={popoverElRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {coaches.map((coach) => {
                    return (
                        <CoachMenuItem
                            key={coach.id}
                            coach={coach}
                            onClick={handleSelectCoach}
                        />
                    );
                })}
            </Popover>
        </Wrapper>
    );
};

export default CoachSelector;
