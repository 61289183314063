import React, { Fragment, useMemo } from 'react';
import dateFormat from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isMatch from 'date-fns/isMatch';
import parse from 'date-fns/parse';

type Props = {
    source: string | null;
    sourceFormat?: string;
    formatString?: string;
};
const DisplayDate: React.FC<Props> = ({
    source,
    sourceFormat = 'yyyy-MM-dd',
    formatString = 'y/MM/dd',
}) => {
    const formatted = useMemo(() => {
        const sourceString = source || '';
        const plainDate = new Date(sourceString);
        if (isValid(plainDate)) {
            return dateFormat(plainDate, formatString);
        }
        if (isMatch(sourceString, sourceFormat)) {
            return dateFormat(
                parse(sourceString, sourceFormat, new Date()),
                formatString,
            );
        }
        return '';
    }, [source, sourceFormat, formatString]);
    return <Fragment>{formatted}</Fragment>;
};

export default DisplayDate;
