import React, { useContext } from 'react';
import styled from 'styled-components';
import {
    MOVIE_TYPE_COACH,
    MOVIE_TYPE_CUSTOMER,
    MOVIE_TYPE_FAVORITED,
    VideoTypeTabContext,
} from '../../../contexts/VideoTypeTabContext';
import { colors, movietype } from '../../const/Styles';
import MovieTypeButton from '../input/MovieTypeButton';

const Container = styled.main`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 83px;
    width: 100%;
    color: ${colors.footerIconSelected};
    border-top: solid 4px ${colors.footerIconUnSelected};
    background-color: ${movietype.backgroundColor};
    box-sizing: border-box;
`;

const VideoType: React.FC = () => {
    const { movieType, setMovieType } = useContext(VideoTypeTabContext);

    return (
        <Container>
            <MovieTypeButton
                value={MOVIE_TYPE_CUSTOMER}
                selected={movieType}
                onChange={setMovieType}
            >
                個人スイング
            </MovieTypeButton>

            <MovieTypeButton
                value={MOVIE_TYPE_COACH}
                selected={movieType}
                onChange={setMovieType}
            >
                コーチスイング
            </MovieTypeButton>
            <MovieTypeButton
                value={MOVIE_TYPE_FAVORITED}
                selected={movieType}
                onChange={setMovieType}
            >
                お気に入り
            </MovieTypeButton>
        </Container>
    );
};

export default VideoType;
