import { Box, Checkbox, FormControlLabel, Grid, GridList, GridListTile, Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import CoachRegisterImg from '../../assets/coach_register.png';
import { MerchantTabContext } from '../../contexts/MerchantTabContext';
import { useStore } from '../../contexts/StoreContext';
import {
    COACH_INFO_DETAIL,
    COACH_LIST,
    COACH_SWING_MOVIE,
} from '../const/MerchantProperties';
import { colors } from '../const/Styles';
import MerchantModal from './input/MerchantModal';

type ContainerProps = {
    visible: boolean;
};
const Container = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        TitlePaper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        ItemPaper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.primary,
            width: '100%',
            height: '100%',
        },
        gridList: {
            display: ' grid',
            gridTemplateColumns: 'repeat(5, calc(20% - 4px))',
            gridTemplateRows: 'max-content',
            gridAutoRows: ' max-content',
            // gridGap: 5,
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
        },
        gridListTile: {
            position: 'relative',
            width: '150px !important',
            height: '150px !important',
            minWidth: 150,
            minHeight: 150,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: 4,
        },
        gridListTile__register: {
            width: '150px !important',
            height: '150px !important',
            marginTop: 4,
            marginBottom: 4,
        },
        tile: {
            width: '100%',
            height: '100%',
            minWidth: 150,
            minHeight: 150,
        },
        img: {
            width: '100%',
            height: '100%',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        nameBox: {
            position: 'absolute',
            bottom: 0,
            backgroundColor: `${colors.black}`,
            color: `${colors.white}`,
            opacity: 0.7,
            height: '25%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
        },
        nameLabel: {
            fontSize: '14px',
            color: `${colors.white}`,
            textAlign: 'center',
        },
        kanalabel: {
            fontSize: '10px',
            color: `${colors.white}`,
            textAlign: 'center',
        },
        card: {
            position: 'absolute',
            width: '90%',
            height: '90%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(3, 0),
            overflowY: 'scroll',
        },
        modalbox: {
            display: 'grid',
            gridTemplateRows: 'max-content 1fr max-content',
            height: 'calc(100% - 100px)',
            width: 'calc(100% - 100px)',
            boxSizing: 'border-box',
            backgroundColor: 'blue',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
    }),
);

interface LocationState {
    changePageValue: boolean;
}

type Props = {
    setCurrentStaffId: (currentStaffId: number) => void;
};

const StaffSettings: React.FC<Props> = ({ setCurrentStaffId }) => {
    const classes = useStyles();
    const [openedModal, setOpenedModal] = useState<boolean>(false);

    const handleOpen = useCallback(() => {
        setOpenedModal(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpenedModal(false);
    }, [setOpenedModal]);

    const location = useLocation<LocationState>();
    const { selected, onChange } = useContext(MerchantTabContext);

    useEffect(() => {
        if (location.state?.changePageValue === true) {
            onChange(COACH_SWING_MOVIE);
            location.state.changePageValue = false;
        }
    });

    const visible = useMemo(() => {
        return COACH_LIST === selected;
    }, [selected]);

    const handleClickStaff = (staffId: number) => {
        setCurrentStaffId(staffId);
        onChange(COACH_INFO_DETAIL);
    };

    const { tempoStore, coachStore } = useStore();

    const handleChangeVisibility = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        coachStore.isInclusionDeletedStaff = event.currentTarget.checked;
    }, [coachStore]);

    return (
        <Container visible={visible}>
            <Box>
                <Grid container justify='center'>
                    <FormControlLabel
                        label='退職者も表示する'
                        labelPlacement='start'
                        control={
                            <Checkbox
                                checked={coachStore.isInclusionDeletedStaff}
                                onChange={handleChangeVisibility} />
                        }
                    />
                </Grid>
                <GridList className={classes.gridList} spacing={5} cols={5}>
                    <GridListTile
                        className={classes.gridListTile}
                        classes={{ tile: classes.tile }}
                        cols={1}
                    >
                        <Link onClick={handleOpen}>
                            <img
                                className={classes.img}
                                src={CoachRegisterImg}
                            />
                        </Link>
                    </GridListTile>
                    {(coachStore.isInclusionDeletedStaff ? tempoStore.includeDeletedStaffs : tempoStore.allStaffs).map((staffdata) => {
                        return (
                            <GridListTile
                                key={staffdata.id}
                                className={classes.gridListTile}
                                classes={{ tile: classes.tile }}
                                cols={1}
                            >
                                <img
                                    className={classes.img}
                                    src={staffdata.s3Path}
                                    onClick={() =>
                                        handleClickStaff(staffdata.id)
                                    }
                                />
                                <div className={classes.nameBox}>
                                    <div className={classes.nameLabel}>
                                        {staffdata.name_sei +
                                            staffdata.name_mei}
                                    </div>
                                    <div className={classes.kanalabel}>
                                        {staffdata.name_sei_kana +
                                            staffdata.name_mei_kana}
                                    </div>
                                </div>
                            </GridListTile>
                        );
                    })}
                </GridList>
                <MerchantModal isOpended={openedModal} onClose={handleClose} />
            </Box>
        </Container>
    );
};
export default observer(StaffSettings);
