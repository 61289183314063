import {
    Box,
    // InputLabel,
    // OutlinedInput,
    // OutlinedInputProps,
    TextField,
    TextFieldProps,
    Theme,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';

export interface OutlinedInputTextProps
    extends Omit<TextFieldProps, 'variant' | 'classes'> {
    id: string;
    // error?: string[] | string;
    label?: string;
    placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // marginBottom: 16,
    },
    labelContainer: {
        display: 'flex',
    },
    label: {
        marginBottom: 8,
        color: theme.palette.text.primary,
    },
    error: {
        paddingLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
    inputRoot: {
        marginRight: 10,
    },
    input: {
        borderColor: 'black',
        borderWidth: 1,
        padding: 12,
    },
    textField: {
        backgroundColor: '#f1f0f8',
    },
}));

function OutlinedInputText(props: OutlinedInputTextProps): React.ReactElement {
    const { error: propError, id, label, placeholder, ...restProps } = props;

    const classes = useStyles(props);
    const inputClasses = useMemo(
        () => ({ root: classes.inputRoot }),
        [classes.inputRoot],
    );
    const error = useMemo(() => {
        if (Array.isArray(propError)) {
            return propError.join('');
        } else {
            return propError;
        }
    }, [propError]);

    return (
        <Box className={classes.root}>
            {label && (
                <Box className={classes.labelContainer}>
                    {/* <InputLabel className={classes.label} htmlFor={id}>
                        {label}
                    </InputLabel> */}
                    <Typography className={classes.error} color='error'>
                        {error}
                    </Typography>
                </Box>
            )}
            {/* <OutlinedInput */}
            <TextField
                error={!!error}
                id={id}
                label={label}
                placeholder={placeholder}
                variant='outlined'
                {...restProps}
                classes={inputClasses}
                className={classes.textField}
                size='small'
            />
        </Box>
    );
}

export default OutlinedInputText;
