import { useState, useCallback } from 'react';

import { LineColor } from './useEditorCanvas';

interface RectangleValues {
    firstX: number;
    firstY: number;
    upperLeftX: number;
    upperLeftY: number;
    width: number;
    height: number;
    color: LineColor;
}

export interface Values {
    firstX: number;
    firstY: number;
    upperLeftX: number;
    upperLeftY: number;
    width: number;
    height: number;
    color: LineColor;
    drawnRectangles: RectangleValues[];
}

export interface Actions {
    onPointerDown: (x: number, y: number, color: LineColor) => void;
    onPointerMove: (x: number, y: number) => void;
    onPointerUp: () => void;
    reset: () => void;
}

const useEditorRectangle = (): [Values, Actions] => {
    const [firstX, setFirstX] = useState(0);
    const [firstY, setFirstY] = useState(0);
    const [upperLeftX, setUpperLeftX] = useState(0);
    const [upperLeftY, setUpperLeftY] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [color, setColor] = useState<LineColor>(LineColor.red);
    const [drawing, setDrawing] = useState(false);
    const [drawnRectangles, setDrawnRectangles] = useState<RectangleValues[]>(
        [],
    );

    const onPointerDown = useCallback(
        (x: number, y: number, color: LineColor) => {
            setDrawing(true);
            setFirstX(x);
            setFirstY(y);
            setUpperLeftX(x);
            setUpperLeftY(y);
            setColor(color);
        },
        [],
    );

    const onPointerMove = useCallback(
        (x: number, y: number) => {
            if (drawing) {
                if (x - firstX >= 0) {
                    setWidth(x - upperLeftX);
                } else {
                    setUpperLeftX(x);
                    setWidth(firstX - x);
                }
                if (y - firstY >= 0) {
                    setHeight(y - upperLeftY);
                } else {
                    setUpperLeftY(y);
                    setHeight(firstY - y);
                }
            }
        },
        [drawing, firstX, firstY, upperLeftX, upperLeftY],
    );

    const onPointerUp = useCallback(() => {
        setDrawing(false);
        setDrawnRectangles([
            ...drawnRectangles,
            {
                firstX,
                firstY,
                upperLeftX,
                upperLeftY,
                width,
                height,
                color,
            },
        ]);
        setFirstX(0);
        setFirstY(0);
        setUpperLeftX(0);
        setUpperLeftY(0);
        setWidth(0);
        setHeight(0);
    }, [
        color,
        drawnRectangles,
        firstX,
        firstY,
        height,
        upperLeftX,
        upperLeftY,
        width,
    ]);

    const reset = useCallback(() => {
        setDrawnRectangles([]);
        setFirstX(0);
        setFirstY(0);
        setUpperLeftX(0);
        setUpperLeftY(0);
        setWidth(0);
        setHeight(0);
    }, []);

    const values = {
        firstX,
        firstY,
        upperLeftX,
        upperLeftY,
        width,
        height,
        color,
        drawnRectangles,
    };
    const actions = { onPointerDown, onPointerMove, onPointerUp, reset };

    return [values, actions];
};

export default useEditorRectangle;
