import React from 'react';
import { BottomNavigation } from '@material-ui/core';
import FooterItem from '../display/FooterItem';
import ConfigIcon from '../../../assets/footer-config.svg';
import LibraryIcon from '../../../assets/footer-library.svg';
import LessonNoteIcon from '../../../assets/footer-lesson-note.svg';
import NewCustomerIcon from '../../../assets/footer-new-customer.svg';
import { Routes } from '../../const/Routes';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import FooterItemLogout from '../display/FooterItemLogout';

const StyledBottomNavigation = styled(BottomNavigation)`
    background-color: ${colors.black};
    padding-top: 10px;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
`;
const Footer: React.FC = () => {
    return (
        <StyledBottomNavigation>
            <FooterItem
                label='新規登録'
                icon={NewCustomerIcon}
                path={Routes.CUSTOMER_REGISTER}
                pathRegex='^\/customer/register$'
            />
            <FooterItem
                label='レッスンノート'
                icon={LessonNoteIcon}
                path={Routes.LESSON_LIST}
                pathRegex='^\/lesson\/.*$'
            />
            <FooterItem
                label='ライブラリー'
                icon={LibraryIcon}
                path={Routes.LIBRARY_LIST}
                pathRegex='^\/customer/(list|detail|edit)|lesson/detail.$'
            />
            <FooterItem
                label='設定'
                icon={ConfigIcon}
                path={Routes.MERCHANT}
                pathRegex='^\/merchant\/.*$'
            />
            <FooterItemLogout />
        </StyledBottomNavigation>
    );
};

export default Footer;
