import React from 'react';
import { BottomNavigationAction } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import LogoutIcon from '../../../assets/logout.svg';
import Api from '../../../utils/Api';
import { colors, iconSize } from '../../const/Styles';

const StyledAction = styled(BottomNavigationAction)`
    svg {
        width: auto;
        height: ${iconSize.footer};
    }

    color: ${colors.footerIconUnSelected};

    .MuiBottomNavigationAction-label {
        font-size: 0.8rem;
        font-weight: 600;
        &.Mui-selected {
            color: ${colors.footerIconSelected};
        }
    }

    &.Mui-selected {
        color: ${colors.footerIconSelected};
    }
`;

const FooterItemLogout: React.FC = () => {

    const handleClick = async () => {
        if (confirm('アプリを終了してもよろしいですか？\n作業している場合は保存してください。')) {
            await Api.logout();
            window.location.reload();
        }
    }

    return (
        <StyledAction
            icon={<ReactSVG src={LogoutIcon || ''} wrapper='span' />}
            label='アプリ終了'
            showLabel
            onChange={handleClick}
        />
    );
};

export default FooterItemLogout;
