import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { createStyles, makeStyles, Modal } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseSharp';
import { observer } from 'mobx-react-lite';
import HeaderIcon from '../../assets/note.svg';
import RecordingStore, { SavedToolImageMap } from '../../store/RecordingStore';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import RecordedMoviePlayer from '../swing/surface/RecordedMoviePlayer';
import { VideoBlob } from '../video/PlayingVideoCard';
import VideoEditor from '../video/VideoEditor';
import SaveSwingModal from './display/SaveSwingModal';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import RecordedMovieEditFooter from './navigation/RecordedMovieEditFooter';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.gray};
`;

const VideoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${colors.white};
    .userName {
        color: ${colors.white};
    }
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 30px;
    right: 30px;
    color: ${colors.lightGray};
    z-index: 9999;
    svg {
        width: 60px;
        height: 60px;
    }
`;

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

type LocationProps = {
    customerId: string;
    video: Blob;
};

const PlaySwingPage: React.FC = () => {
    const location = useLocation<LocationProps>();
    const { state } = location;
    const { customerId, video } = state;
    const classes = useStyles();
    const [modalOpened, setModalOpened] = useState(false);
    const [saveCounter, setSaveCounter] = useState<number>(0);
    const handleModalOpen = () => {
        setModalOpened(true);
    };

    const handleModalClose = () => {
        setModalOpened(false);
    };

    // TODO: 構造の問題でなぜか更新処理がuseEffectで実装されているので２系統で分離
    useEffect(() => {
        setModalOpened(false);
    }, [saveCounter]);
    const [selected, setSelected] = useState<SavedToolImageMap>();

    const videos = RecordingStore.getRegisteredVideo(customerId);
    const movie = videos?.find((v) => v.video === video);

    const history = useHistory();
    useEffect(() => {
        if (!movie) {
            history.goBack();
        }
    }, [movie, history]);

    const handleSwingModalOpen = useCallback(() => {
        if (!movie) {
            return;
        }
        const { id, video } = movie;
        if (video) {
            setSelected({
                id,
                video,
                videoUrl: URL.createObjectURL(video),
            });
        }
    }, [movie]);

    const handleSwingModalClose = useCallback(() => {
        setSelected(undefined);
    }, []);

    if (!movie || !movie.video) {
        return null;
    }

    const videoBlob: VideoBlob = {
        id: movie.id,
        type: 'blob',
        videoSrc: URL.createObjectURL(movie.video),
        blob: movie.video,
        toolImage: movie.toolImage ?? undefined,
    };

    return (
        <Page title='レッスンノート' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation customerId={customerId}>
                    スイング再生
                </PlayerHeaderNavigation>
                <VideoContainer>
                    <RecordedMoviePlayer
                        customerId={customerId}
                        movie={movie}
                        reloadToolImage={modalOpened}
                    ></RecordedMoviePlayer>
                </VideoContainer>
                <RecordedMovieEditFooter
                    onClickToolMapEdit={handleModalOpen}
                    onClickPropEdit={handleSwingModalOpen}
                >
                    編集
                </RecordedMovieEditFooter>
            </ContentContainer>
            <Modal
                className={classes.modal}
                open={modalOpened}
                onClose={handleModalClose}
            >
                <>
                    <StyledIconButton onClick={handleModalClose}>
                        <CloseIcon></CloseIcon>
                    </StyledIconButton>
                    <VideoEditor
                        videoSrc={videoBlob}
                        height={700}
                        width={550}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </>
            </Modal>
            <SaveSwingModal
                onlyUpdate
                toolmap={selected}
                onClose={handleSwingModalClose}
            ></SaveSwingModal>
        </Page>
    );
};

export default observer(PlaySwingPage);
