export type ProjectileType =
    | '1型'
    | '2型'
    | '3型'
    | '4型'
    | '5型'
    | '6型'
    | '7型'
    | '8型'
    | '9型';

export const ProjectileType1: ProjectileType = '1型';
export const ProjectileType2: ProjectileType = '2型';
export const ProjectileType3: ProjectileType = '3型';
export const ProjectileType4: ProjectileType = '4型';
export const ProjectileType5: ProjectileType = '5型';
export const ProjectileType6: ProjectileType = '6型';
export const ProjectileType7: ProjectileType = '7型';
export const ProjectileType8: ProjectileType = '8型';
export const ProjectileType9: ProjectileType = '9型';

import projectile01 from '../../assets/projectile01.png';
import projectile02 from '../../assets/projectile02.png';
import projectile03 from '../../assets/projectile03.png';
import projectile04 from '../../assets/projectile04.png';
import projectile05 from '../../assets/projectile05.png';
import projectile06 from '../../assets/projectile06.png';
import projectile07 from '../../assets/projectile07.png';
import projectile08 from '../../assets/projectile08.png';
import projectile09 from '../../assets/projectile09.png';

const ImagePaths = new Map<string, string>([
    ['1型', projectile01],
    ['2型', projectile02],
    ['3型', projectile03],
    ['4型', projectile04],
    ['5型', projectile05],
    ['6型', projectile06],
    ['7型', projectile07],
    ['8型', projectile08],
    ['9型', projectile09],
]);

export const projectileImagePath = (value: ProjectileType = '1型'): string => {
    return ImagePaths.get(value) ?? projectile01;
};
