import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import RecordingStore, {
    RegisteredVideoMap,
} from '../../../store/RecordingStore';

const ToolImage = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    touch-action: none;
`;

type Props = {
    movie: RegisteredVideoMap;
    reload: boolean;
};

const RecordedToolImageViewer: React.FC<Props> = ({ movie, reload }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const { current } = canvasRef;
        const { video, id, toolImage } = movie;
        if (current && video) {
            const ctx = current.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, current.width, current.height);
                const img = new Image();
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                };
                img.src = RecordingStore.getToolImage({
                    id: id,
                    type: 'blob',
                    videoSrc: URL.createObjectURL(video),
                    blob: video,
                    toolImage: toolImage ?? undefined,
                });
            }
        }
    }, [movie, reload]);
    return <ToolImage ref={canvasRef} width={550} height={700}></ToolImage>;
};

export default RecordedToolImageViewer;
