import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { MovieDisplay } from '../../../utils/Video';
import { colors } from '../../const/Styles';
import MultiplePlayControl from '../surface/MultiplePlayControl';

const Container = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: solid 3px #333333;
    background-color: ${colors.blue};
    padding: 25px 0;
    font-weight: bold;
`;

type LabelProps = {
    checked: boolean;
};

const Label = styled.label<LabelProps>`
    display: flex;
    flex-direction: row;
    margin-right: 24px;
    width: 38px;
    height: 38px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: 38px;
        height: 38px;
        left: 0;
        top: 0;
        border: solid 2px ${colors.footerIconSelected};
        background-color: ${colors.white};
    }

    &:after {
        content: "";
        position: absolute;
        display: ${({ checked }) => (checked === true ? 'block' : 'none')};
        box-sizing: border-box;
        width: 30px;
        height: 18px;
        top: 5px;
        left: 4px;
        transform: rotate(-45deg);
        border-bottom: 8px solid;
        border-left: 8px solid;
        border-color:  ${colors.footerIconSelected}; /
    }
`;

const Input = styled.input`
    display: none;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 36px;
    color: ${colors.footerIconSelected};
    line-height: 1;
    box-sizing: border-box;
`;

type Props = {
    onChange?: (isChecked: boolean) => void;
    selectedVideos: MovieDisplay[];
    onUnSelect: (video: MovieDisplay) => void;
    onUnSelectAll: () => void;
};

const TitleView: React.FC<Props> = ({
    onChange,
    selectedVideos,
    onUnSelect,
    onUnSelectAll,
}) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const { checked } = evt.currentTarget;
        setIsChecked(checked);
        if (onChange) {
            onChange(checked);
        }
    };

    const handleUnSelect = (video: MovieDisplay) => {
        onUnSelect(video);
    };

    const handleUnSelectAll = () => {
        onUnSelectAll();
    };

    return (
        <Container>
            <InputContainer>
                <Label checked={isChecked}>
                    <Input
                        type='checkbox'
                        onChange={handleChange}
                        checked={isChecked}
                    />
                </Label>
                同時再生
            </InputContainer>
            <MultiplePlayControl
                visible={isChecked}
                selectedVideos={selectedVideos}
                onUnSelect={handleUnSelect}
                onUnSelectAll={handleUnSelectAll}
            />
        </Container>
    );
};

export default TitleView;
