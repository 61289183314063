import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    border solid 2px ${colors.lighterGray};
    box-sizing: border-box;
    background-color: ${colors.white};
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 500;
    width: 100%;
    color: ${colors.black};
    background-color: ${colors.lighterGray};
    box-sizing: border-box;
`;

const Content = styled.div`
    display: block;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    color: ${colors.black};
    box-sizing: border-box;
    line-height: 1;
`;

const Footer = styled(Content)`
    padding-top: 0;
    padding-bottom: 10px;
    font-size: 1rem;
    box-sizing: border-box;
`;

type Props = {
    title: string;
    footer: string;
};

const CustomerFeatureCard: React.FC<Props> = ({ title, footer, children }) => {
    return (
        <Container>
            <Header>{title}</Header>
            <Content>{children}</Content>
            <Footer>{footer}</Footer>
        </Container>
    );
};

export default CustomerFeatureCard;
