import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import styled from 'styled-components';

import { CustomerTabPropertyTabContext } from '../../../contexts/CustomerTabContext';
import { useStore } from '../../../contexts/StoreContext';
import { CustomerDisplay } from '../../../store/LessonStore';
import Api from '../../../utils/Api';
import { LessonDisplay } from '../../../utils/Lesson';
import { MovieDisplay } from '../../../utils/Video';
import { CUSTOMER_TAB_PAST_LESSONS } from '../../const/CustomerTabProperties';
import ToSwingListFooterNavigation from '../navigation/ToSwingListFooterNavigation';
import PastLesson from '../surface/PastLesson';
import PastLessonCommentModal from '../input/PastLessonCommentModal';

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

const Container = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

const LessonContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 90px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

type Props = {
    customer: CustomerDisplay;
};

type LessonMovieMapType = Map<string, MovieDisplay[]>;

const PastLessons: React.FC<Props> = ({ customer }) => {
    const { lessonStore, tempoStore } = useStore();
    const { selected } = useContext(CustomerTabPropertyTabContext);
    useAsync(async () => {
        await tempoStore.reloadFavoriteMovies(customer.id);
    }, [customer.id]);

    const { loading, error, value } = useAsync(async (): Promise<
        [LessonDisplay[], LessonMovieMapType]
    > => {
        const lessons = (await lessonStore.fetchPastLessons(customer.id)) ?? [];
        const movies = (await Api.fetchCustomerMovies(customer.id))
            .map((movie) => new MovieDisplay(movie))
            .filter((result) =>
                result.movie_thumbnail_filename === ''
                    ? ((result.movie_thumbnail_filepath =
                          result.filename.replace('.mp4', '.png')),
                      (result.filepath = result.filename))
                    : [],
            )
            .filter((p) => p.fetchable === true);

        // エンティティ設計の都合で別引きする
        // レッスン <=> 動画の形態でMapを作る
        // 既存のモジュールは手続き的に実装しているが、ここではせず下位コンポーネントでLazyLoadする
        const lessonMovieMap: LessonMovieMapType = new Map();
        lessons.forEach(({ lesson_id }) => {
            const filteredMovies = movies.filter(
                (movie) => lesson_id === movie.lesson_id,
            );
            if (filteredMovies.length > 0) {
                lessonMovieMap.set(lesson_id, filteredMovies);
            }
        });
        return [lessons, lessonMovieMap];
    }, [customer.id, lessonStore.pastLessons]);

    const [lessons = [], lessonMovieMap = new Map<string, MovieDisplay[]>()] =
        value ?? [];

    const [selectedLessonAchievement, setSelectedLessonAchievement] =
        useState<LessonDisplay>();
    const [selectedLessonAchievementId, setSelectedLessonAchievementId] =
        useState<number>();
    useEffect(() => {
        setSelectedLessonAchievement(
            lessons.filter((lesson: LessonDisplay) => {
                return Number(lesson.lesson_id) === selectedLessonAchievementId;
            })?.[0],
        );
    }, [selectedLessonAchievementId, lessons]);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const handleClickEditIcon = (lessonAchievementId: number) => {
        setSelectedLessonAchievementId(lessonAchievementId);
        setIsVisibleModal(true);
    };
    const handleCloseModal = () => {
        setIsVisibleModal(false);
    };
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (latestLessonComment: string) => {
        if (!latestLessonComment || !selectedLessonAchievementId) {
            return;
        }

        try {
            setIsLoading(true);
            await lessonStore.setPastLessonComment(
                `${customer.id}`,
                `${selectedLessonAchievementId}`,
                latestLessonComment,
            );
        } catch (e) {
            alert('コメントの修正に失敗しました。');
        } finally {
            setIsLoading(false);
        }
    };

    if (loading === true || isLoading === true) {
        return (
            <ProgressContainer>
                <CircularProgress />
            </ProgressContainer>
        );
    }
    if (error) {
        console.error(error);
        return (
            <ProgressContainer>
                レッスンの取得でエラーが発生しました。
            </ProgressContainer>
        );
    }

    if (CUSTOMER_TAB_PAST_LESSONS !== selected) {
        return null;
    }

    return (
        <Container>
            <LessonContainer>
                {lessons.map((lesson) => (
                    <PastLesson
                        key={lesson.lesson_id}
                        lesson={lesson}
                        customer={customer}
                        movies={lessonMovieMap.get(lesson.lesson_id) ?? []}
                        onIconClick={handleClickEditIcon}
                    />
                ))}
                {lessons.length === 0 && (
                    <Fragment>受講済みのレッスンがありません</Fragment>
                )}
                <PastLessonCommentModal
                    isVisibleModal={isVisibleModal}
                    onClose={handleCloseModal}
                    onSubmit={handleSubmit}
                    lessonAchievement={selectedLessonAchievement}
                />
            </LessonContainer>
            <ToSwingListFooterNavigation customer={customer} />
        </Container>
    );
};

export default observer(PastLessons);
