import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import HeaderIcon from '../../assets/note.svg';
import { useStore } from '../../contexts/StoreContext';
import { CustomerDisplay } from '../../store/LessonStore';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import CoachProfile from './display/CoachProfile';
import CustomerGridViewer from './display/CustomerGridViewer';
import WillLessonCustomers from './display/WillLessonCustomers';
import CustomerNameFilter from './surface/CustomerNameFilter';
import AuthStore from '../../store/AuthStore';

const PageLayout = styled.section`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 210px 1fr;
    grid-template-columns: 300px 1fr;
`;

const CustomerSelectWrapper = styled.section`
    grid-column: 1 / 3;
    overflow: hidden;
    border-top: 4px solid ${colors.gray};
    display: flex;
`;

const LessonListPage: React.FC = () => {
    const [filteredCustomers, setFilteredCustomers] = useState<
        CustomerDisplay[]
    >([]);
    const [selectedFilterName, setSelectedFilterName] = useState<string>('ALL');

    const { lessonStore } = useStore();
    const { allCustomers } = lessonStore;

    const handleClickFilter = useCallback((filterName: string) => {
        setFilteredCustomers(lessonStore.customersPerRow(filterName));
        setSelectedFilterName(filterName);
    }, [lessonStore]);


    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        lessonStore.fetchAllCustomers(AuthStore.tempoId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredCustomers(lessonStore.customersPerRow(selectedFilterName));
    }, [allCustomers, lessonStore, selectedFilterName]);

    return (
        <Page headerIcon={HeaderIcon} title='レッスンノート'>
            <PageLayout>
                <CoachProfile />
                <WillLessonCustomers />
                <CustomerSelectWrapper>
                    <CustomerNameFilter
                        onClick={handleClickFilter}
                    ></CustomerNameFilter>
                    <CustomerGridViewer
                        customers={filteredCustomers.concat()}
                    ></CustomerGridViewer>
                </CustomerSelectWrapper>
            </PageLayout>
        </Page>
    );
};

export default observer(LessonListPage);
