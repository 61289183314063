import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Row = styled.article`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px 20px;
    width: 100%;
    border-bottom: solid 1px ${colors.lightGray};
`;

const RowIndex = styled.label`
    display: flex;
    flex-direction: row;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1;
    margin-right: 20px;
    color: #5b5b5b;
`;

const AnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;
`;

const Question = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    color: #5b5b5b;
    font-size: 1.3rem;
    line-height: 1;
    padding-bottom: 5px;
`;

const Answer = styled(Question)`
    color: ${colors.lighterGray};
    font-size: 1.1rem;
    justify-content: space-between;
`;

const AnswerPill = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border-radius: 9999999px;
    box-sizing: border-box;
    padding: 5px 0;
    &.active {
        background-color: ${colors.blue};
        color: ${colors.white};
    }
`;

type Props = {
    question: string;
    answer: string;
    index: number;
};

const QuestionnaireRow: React.FC<Props> = ({ question, answer, index }) => {
    return (
        <Row>
            <RowIndex>{index}.</RowIndex>
            <AnswerContainer>
                <Question>{question}</Question>
                <Answer>
                    <AnswerPill
                        className={'当てはまる' === answer ? 'active' : ''}
                    >
                        当てはまる
                    </AnswerPill>
                    <AnswerPill
                        className={'やや当てはまる' === answer ? 'active' : ''}
                    >
                        やや当てはまる
                    </AnswerPill>
                    <AnswerPill
                        className={'当てはまらない' === answer ? 'active' : ''}
                    >
                        当てはまらない
                    </AnswerPill>
                </Answer>
            </AnswerContainer>
        </Row>
    );
};

export default QuestionnaireRow;
