import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const Row = styled.dl`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    padding: 0 5px;
    font-size: 0.8rem;
    border-bottom: solid 1px ${colors.lighterGray};
    box-sizing: border-box;
    height: 2.2rem;
    line-height: 1;
`;

const Title = styled.dt`
    display: flex;
    flex-direction: row;
    width: 6rem;
    break-word: keep-all;
    box-sizing: border-box;
    align-items: center;
    margin: 0;
`;

const Description = styled.dd`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
`;

type Props = {
    title: string | ReactNode;
};

const FeatureDescription: React.FC<Props> = ({ title, children }) => {
    return (
        <Row>
            <Title>{title}</Title>
            <Description>{children}</Description>
        </Row>
    );
};

export default FeatureDescription;
