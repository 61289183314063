import React, { ChangeEvent, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import BackIcon from '../../../assets/goback.svg';
import CameraIcon from '../../../assets/select_img.svg';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 18px 54px;
    box-sizing: border-box;
    background-color: ${colors.white};
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: fit-content;
`;

const ProfileImage = styled.img`
    width: 108px;
    height: 108px;
    object-fit: contain;
`;

const Title = styled.h2`
    color: ${colors.footerIconSelected};
    font-size: 40px;
    font-weight: normal;
    margin: 0;
    padding: 0;
`;

const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    color: ${colors.footerIconSelected};
`;

const NavigationIcon = styled(ReactSVG)`
    width: 50px;
    height: 50px;
`;

const HiddenInput = styled.input`
    display: none;
`;

type Props = {
    s3Path?: string | undefined;
    onChangeProfile?: (source: File | null) => void;
};

const EditFormHeader: React.FC<Props> = ({ s3Path, onChangeProfile }) => {
    const history = useHistory();
    const handleClickNavigation = () => {
        history.goBack();
    };

    const [selectedImg, setSelectedImg] = useState<string | undefined>();
    const inputRef = useRef<HTMLInputElement>(null);
    const handleClickCameraIcon = () => {
        inputRef.current?.click();
    };

    const handleChangeImg = (evt: ChangeEvent<HTMLInputElement>) => {
        const { files } = evt.currentTarget;
        const file: File | undefined = Array.from(files ?? [])[0];
        setSelectedImg(file ? URL.createObjectURL(file) : undefined);
        if (onChangeProfile) {
            onChangeProfile(file);
        }
    };

    return (
        <Container>
            <ImageContainer>
                <ProfileImage src={selectedImg ? selectedImg : s3Path} />
                <ReactSVG
                    src={CameraIcon ?? ''}
                    onClick={handleClickCameraIcon}
                />
                <HiddenInput
                    type='file'
                    ref={inputRef}
                    onChange={handleChangeImg}
                    accept='image/*'
                ></HiddenInput>
            </ImageContainer>
            <Title>ユーザー情報</Title>
            <Navigation onClick={handleClickNavigation}>
                <NavigationIcon src={BackIcon ?? ''} />
                戻る
            </Navigation>
        </Container>
    );
};

export default EditFormHeader;
