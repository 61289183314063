import React from 'react';
import { ReactSVG } from 'react-svg';
import Icon from '../../../assets/times.svg';

type Props = {
    color?: string;
    size: string;
    onClick?: () => void;
};

const TimesIcon: React.FC<Props> = ({ color, size, onClick }) => {
    return (
        <ReactSVG
            onClick={onClick}
            src={Icon ?? ''}
            style={{
                color,
                width: size,
                height: size,
            }}
        ></ReactSVG>
    );
};

export default TimesIcon;
