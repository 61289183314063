import { Button, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import DeleteQuestionnaireButton from '../../../assets/delete_button.svg';
import Api from '../../../utils/Api';
import { LessonCurriculumEntity } from '../../@types/mysqlEntities';
import { colors } from '../../const/Styles';

const useStyles = makeStyles(() =>
    createStyles({
        numberTypography: {
            margin: 'auto',
            fontSize: 23,
            fontWeight: 'bold',
            color: `${colors.footerIconUnSelected}`,
        },
        disabledTextForm: {
            height: 35,
            width: '100%',
            backgroundColor: `${colors.white}`,
        },
        fillingtextForm: {
            height: 35,
            width: '100%',
        },
        button: {
            color: `${colors.black}`,
            height: 25,
            margin: 'auto',
            fontWeight: 'bold',
            width: 48,
            position: 'absolute',
        },
        deleteButton: {
            position: 'absolute',
            top: 15,
            right: 15,
            zIndex: 100,
        },
        createButton: {
            backgroundColor: `${colors.footerIconSelected}`,
            zIndex: 100,
        },
        TypographyGrid: {
            diplay: 'block',
            width: '5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        finishButtonGrid: {
            position: 'absolute',
            top: 15,
            right: -5,
        },
        inputGrid: {
            position: 'relative',
            width: '472px',
            height: 60,
            display: 'flex',
            borderBottom: `1px solid ${colors.lighterGray}`,
        },
        inputBox: {
            width: '100%',
            height: 35,
            marginTop: 10,
            marginLeft: 20,
        },
    }),
);

const ErrorMsg = styled.section`
    display: block;
    margin: 5px auto 0;
    color: red;
`;

interface Props {
    index: number;
    lessonMasterId: number,
    curriculum: LessonCurriculumEntity | undefined,
    isEditable: boolean;
    isDeletable: boolean;
    onChange: (curriculums: LessonCurriculumEntity[]) => void;
}

const CurriculumFillingItem: React.FC<Props> = ({
    index,
    lessonMasterId,
    curriculum,
    isEditable,
    isDeletable,
    onChange,
}) => {
    const classes = useStyles();
    // TODO: 前の項目を見て編集可能なのか判定
    const [name, setName] = useState('');
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setName(curriculum?.name || '');
    }, [curriculum]);

    const handleInputName = useCallback((event: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isEditable) {
            setName(event.currentTarget.value);
        }
    }, [isEditable]);

    const handleSaveCurriculum = useCallback(async () => {
        // 空は許可しない
        const isEmpty = name.length === 0;
        setHasError(isEmpty);
        if (isEmpty) {
            return;
        }
        // 保存処理
        const savedResults = await Api.saveCurriculum({
            id: curriculum ? Number(curriculum.id) : null,
            display_order: index,
            lesson_master_id: Number(lessonMasterId),
            name,
        });
        setHasError(false);
        if (onChange) {
            onChange(savedResults);
        }
    }, [name, lessonMasterId, index, curriculum, onChange]);

    const handleDeleteCurriculum = useCallback(async () => {
        // 削除処理
        if (curriculum) {
            const deleteAfterCurriculums =
                await Api.deleteCurriculum(Number(curriculum.id), Number(lessonMasterId));
            setHasError(false);
            if (onChange) {
                onChange(deleteAfterCurriculums);
            }
        }
    }, [lessonMasterId, curriculum, onChange]);

    return (
        <React.Fragment>
            {hasError && (
                <ErrorMsg>入力してください </ErrorMsg>
            )}
            <div className={classes.inputGrid}>
                <div className={classes.TypographyGrid}>
                    <Typography
                        variant='h3'
                        className={classes.numberTypography}
                    >
                        {/* 項目番号1~10で表示するため */}
                        {index + 1}.
                    </Typography>
                </div>
                <div className={classes.inputBox}>
                    <TextField
                        className={classes.fillingtextForm}
                        variant='outlined'
                        value={name}
                        onChange={handleInputName}
                        inputProps={{
                            style: {
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: 10,
                                height: 35,
                            },
                        }}
                    />
                </div>
                {isDeletable && (
                    <div>
                        <img
                            className={`${classes.button} + ${classes.deleteButton}`}
                            id={index.toString()}
                            src={DeleteQuestionnaireButton}
                            onClick={handleDeleteCurriculum}
                        >
                        </img>
                    </div>
                )}
                {isEditable && (
                    <div className={classes.finishButtonGrid}>
                        <Button
                            className={`${classes.button} + ${classes.createButton}`}
                            variant='contained'
                            onClick={handleSaveCurriculum}
                        >
                            完了
                        </Button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CurriculumFillingItem;
