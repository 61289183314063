import React, { useRef, useState, useCallback } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, IconButton, Modal } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Webcam from 'react-webcam';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';

import RegisterRecordingModal from './RegisterRecordingModal';
import RecordedVideoList from './RecordedVideoList';
import DominantButton from '../form/DominantButton';
import RecordingStore from '../../store/RecordingStore';

export type DisplayState =
    | { displayType: 'recording'; video?: undefined; toolImage?: undefined }
    | { displayType: 'playing'; video: Blob; toolImage: File | undefined };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerGridContainer: {
            backgroundColor: theme.palette.background.paper,
            padding: 8,
        },
        headerButton: {
            marginTop: 8,
        },
        headerButtonBox: {
            textAlign: 'center',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        recordingVideoContainer: {
            marginTop: 5,
        },
        editOptionBox: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '10%',
        },
        playButtonBox: {
            width: '100%',
            textAlign: 'center',
            position: 'fixed',
            bottom: 56,
            backgroundColor: theme.palette.background.default,
            minHeight: 74,
        },
        playButton: {
            backgroundColor: theme.palette.grey[300],
        },
        lineToolButtonBox: {
            display: 'flex',
            alignItems: 'center',
        },
        lineToolButton: {
            backgroundColor: theme.palette.grey[300],
            margin: '0 5px',
        },
        recordingDisplay: {
            display: 'block',
            // TODO この辺りいじると録画中画面の背面に横長の黒いボックスが出現することがある。要検証。
            maxWidth: '80vw',
            maxHeight: '50vh',
            margin: '0 auto',
        },
        iconButton: {
            color: '#ff14149e',
        },
        iconSize: {
            fontSize: 50,
        },
        toRecordingButton: {
            marginTop: 8,
        },
    }),
);

export default observer(function RecordingVideo() {
    const classes = useStyles();
    const history = useHistory();

    const webcamRef = useRef<Webcam>(null);
    const [capturing, setCapturing] = useState(false);
    const [displayState, setDisplayState] = useState<DisplayState>({
        displayType: 'recording',
    });

    const onClickCapture = useCallback(() => {
        RecordingStore.handleStartCaptureClick(webcamRef);
        setCapturing(true);
    }, []);
    const onClickStop = useCallback(() => {
        RecordingStore.handleStopCaptureClick();
        setCapturing(false);
    }, []);

    const onClickRecording = useCallback(() => {
        setDisplayState({ displayType: 'recording' });
    }, []);

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onclickToVideoList = useCallback(() => {
        history.push({
            pathname: '/video/list',
            state: {
                from: '/lesson/start',
                customerId: RecordingStore.viewedCustomerId,
            },
        });
    }, [history]);

    const videoConstraints = {
        facingMode: { exact: 'environment' },
    };

    return (
        <>
            <Grid container className={classes.headerGridContainer}>
                <Grid item xs={9}>
                    <RecordedVideoList
                        displayState={displayState}
                        setDisplayState={setDisplayState}
                    />
                </Grid>
                <Grid item xs={3} className={classes.headerButtonBox}>
                    <DominantButton
                        onClick={handleOpen}
                        disabled={displayState.displayType === 'recording'}
                        className={classes.headerButton}
                    >
                        レッスンへ保存
                    </DominantButton>
                    <DominantButton
                        onClick={onclickToVideoList}
                        className={classes.headerButton}
                    >
                        動画一覧
                    </DominantButton>
                </Grid>
            </Grid>
            <Modal className={classes.modal} open={open} onClose={handleClose}>
                <RegisterRecordingModal
                    video={displayState.video ?? null}
                    toolImage={displayState.toolImage ?? null}
                    handleClose={handleClose}
                />
            </Modal>
            <Container className={classes.recordingVideoContainer}>
                {displayState.displayType === 'recording' ? (
                    <Webcam
                        className={classes.recordingDisplay}
                        audio={false}
                        ref={webcamRef}
                        videoConstraints={videoConstraints}
                    />
                ) : (
                    <video
                        className={classes.recordingDisplay}
                        src={URL.createObjectURL(displayState.video)}
                        controls
                    />
                )}
            </Container>
            <Box className={classes.playButtonBox}>
                {displayState.displayType === 'playing' ? (
                    <DominantButton
                        className={classes.toRecordingButton}
                        onClick={onClickRecording}
                    >
                        録画
                    </DominantButton>
                ) : capturing ? (
                    <IconButton onClick={onClickStop}>
                        <StopIcon
                            className={classes.iconButton}
                            fontSize='large'
                            classes={{
                                fontSizeLarge: classes.iconSize,
                            }}
                        />
                    </IconButton>
                ) : (
                    <IconButton onClick={onClickCapture}>
                        <FiberManualRecordIcon
                            className={classes.iconButton}
                            fontSize='large'
                            classes={{
                                fontSizeLarge: classes.iconSize,
                            }}
                        />
                    </IconButton>
                )}
            </Box>
        </>
    );
});
