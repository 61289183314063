import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Api from '../../../utils/Api';

const ToolImage = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    touch-action: none;
`;

type Props = {
    filePath: string;
    reload: boolean;
};

const ToolImageViewer: React.FC<Props> = ({ filePath, reload }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const { current } = canvasRef;
        if (filePath && current) {
            void Api.getS3SignedUrlForDownloadToolImage(filePath).then(
                ({ presignedUrl }) => {
                    // HACK: 同期されてないっぽいので少し待つ
                    setTimeout(() => {
                        const ctx = current.getContext('2d');
                        if (ctx) {
                            ctx.clearRect(0, 0, current.width, current.height);
                            const img = new Image();
                            img.onload = () => {
                                ctx.drawImage(img, 0, 0, img.width, img.height);
                            };
                            img.src = presignedUrl;
                        }
                    }, 100);
                },
            );
        }
    }, [filePath, reload]);
    return <ToolImage ref={canvasRef} width={550} height={700}></ToolImage>;
};

export default ToolImageViewer;
