import { LinearProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

type ContainerProps = {
    progress: string;
};

const Container = styled.article<ContainerProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    background-color: ${colors.black};

    > * {
        color: ${colors.white};
    }
`;

const IndicatorContainter = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 30px;
`;

const Label = styled.label`
    display: block;
    width: 100%;
    font-size: 1.2rem;
`;

type ProgressProps = {
    barcolor: string;
};

const Indicator = styled(LinearProgress)<ProgressProps>`
    height: 20px;
    width: 100%;
    color: ${({ barcolor }) => barcolor};
    &.MuiLinearProgress-colorPrimary {
        background-color: ${colors.lightGray};
    }
    .MuiLinearProgress-barColorPrimary {
        background-color: ${({ barcolor }) => barcolor};
    }
`;

const ProgressContainter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    min-width: 6em;
`;

const ProgressText = styled.span`
    display: inline-block;
    vertical-align: bottom;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 0.7;
`;

const ProgressUnit = styled(ProgressText)`
    font-size: 1.5rem;
`;

type Props = {
    title: string;
    progress: string;
    color: string;
};

const LessonTargetIndicator: React.FC<Props> = ({ title, progress, color }) => {
    return (
        <Container progress={progress}>
            <IndicatorContainter>
                <Label>{title}</Label>
                <Indicator
                    variant='determinate'
                    value={Number(progress)}
                    barcolor={color}
                />
            </IndicatorContainter>
            <ProgressContainter>
                <ProgressText>{progress}</ProgressText>
                <ProgressUnit>%</ProgressUnit>
            </ProgressContainter>
        </Container>
    );
};

export default LessonTargetIndicator;
