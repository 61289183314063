import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import ArrowLeftIcon from '../../../assets/arrow-left.svg';
import ArrowRightIcon from '../../../assets/arrow-right.svg';
import PlayIcon from '../../../assets/play.svg';
import { colors } from '../../const/Styles';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    height: fit-content;
`;

const ArrowIconWrapper = styled.i`
    display: flex;
    flex-direction: row;
    color: ${colors.footerIconSelected};
    svg {
        width: 17px;
        height: 29px;
    }
`;

const PlayIconWrapper = styled(ArrowIconWrapper)`
    margin: 0 40px;
    svg {
        width: 33px;
        height: 39px;
    }
`;

type Props = {
    onClickPlay?: () => void;
    advanceSecond?: () => void;
    goBackSecond?: () => void;
    className?: string;
};

const DoublePlayerControl: React.FC<Props> = ({
    onClickPlay,
    advanceSecond,
    goBackSecond,
    className,
}) => {
    return (
        <Container className={className}>
            <ArrowIconWrapper onClick={goBackSecond}>
                <ReactSVG src={ArrowLeftIcon ?? ''} />
            </ArrowIconWrapper>
            <PlayIconWrapper onClick={onClickPlay}>
                <ReactSVG src={PlayIcon ?? ''} />
            </PlayIconWrapper>
            <ArrowIconWrapper onClick={advanceSecond}>
                <ReactSVG src={ArrowRightIcon ?? ''} />
            </ArrowIconWrapper>
        </Container>
    );
};

export default DoublePlayerControl;
