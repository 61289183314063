import { SwingClubs } from '../components/const/SwingClub';
import { MovieEntity } from './Api';
const collator = new Intl.Collator(undefined, { numeric: true });
const compare = (x: MovieEntity, y: MovieEntity, k: keyof MovieEntity) =>
    collator.compare(<string>x[k], <string>y[k]);

export const clubFilterUnset = '(クラブ選択)';
export const clubFilters = [clubFilterUnset, ...SwingClubs] as const;

export type ClubFilter = typeof clubFilters[number];

export function getMoviePredicate(
    club: ClubFilter,
): (x: MovieEntity) => boolean {
    if (club === clubFilterUnset) return () => true;
    return (video) => video.club === club;
}

export const ORDER_VALUE_YARD_ASK = 0;
export const ORDER_VALUE_YARD_DESC = 1;
export const ORDER_VALUE_CREATED_AT_ASK = 2;
export const ORDER_VALUE_CREATED_AT_DESC = 3;
export type OrderValueType =
    | typeof ORDER_VALUE_YARD_ASK
    | typeof ORDER_VALUE_YARD_DESC
    | typeof ORDER_VALUE_CREATED_AT_ASK
    | typeof ORDER_VALUE_CREATED_AT_DESC;

export const orderMenu = [
    { title: '昇順（ヤード）', value: ORDER_VALUE_YARD_ASK },
    { title: '降順（ヤード）', value: ORDER_VALUE_YARD_DESC },
    { title: '昇順（年月日）', value: ORDER_VALUE_CREATED_AT_ASK },
    { title: '降順（年月日）', value: ORDER_VALUE_CREATED_AT_DESC },
] as const;

export type Order = typeof orderMenu[number]['value'];

export function getMovieComparer(
    order: Order,
): (x: MovieEntity, y: MovieEntity) => number {
    if (order === 0 || order === 1) {
        return (x: MovieEntity, y: MovieEntity) =>
            [1, -1][order] *
            (compare(x, y, 'yards') || compare(x, y, 'created_at'));
    }
    return (x: MovieEntity, y: MovieEntity) =>
        [1, -1][order - 2] * compare(x, y, 'created_at');
}
