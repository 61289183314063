import React from 'react';
import styled from 'styled-components';

import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { colors } from '../../const/Styles';

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        border-radius: 0;
        border: solid 1px ${colors.white};
        background-color: transparent;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    &.MuiDialogContent-root {
        background-color: ${colors.blue}99;
        color: ${colors.white};
        font-size: 2.3rem;
        padding: 40px 40px 10px 40px;
        text-align: center;
    }
`;

const StyledDialogActions = styled(DialogActions)`
    &.MuiDialogActions-root {
        background-color: ${colors.blue}99;
        justify-content: space-evenly;
        padding: 10px 40px 40px 40px;
    }
`;

const ActionButton = styled.button`
    display: flex;
    width: 300px;
    height: 90px;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 4px;
    &:nth-child(1) {
        margin-right: 30px;
    }
`;

type Props = {
    isOpended: boolean;
    onClose: (agreed: boolean) => void;
};

const CloseLessonDialog: React.FC<Props> = ({ isOpended, onClose }) => {
    const handleClickCancel = () => {
        onClose(false);
    };
    const handleClickOK = () => {
        onClose(true);
    };
    return (
        <StyledDialog open={isOpended}>
            <StyledDialogContent>
                「本日のレッスン」を
                <br />
                全て終了して閉じますか？
            </StyledDialogContent>
            <StyledDialogActions>
                <ActionButton onClick={handleClickOK}>OK</ActionButton>
                <ActionButton onClick={handleClickCancel}>
                    キャンセル
                </ActionButton>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default CloseLessonDialog;
