import { useState, useCallback, useEffect } from 'react';

import calcAngle, { Vector } from '../utils/calcAngle';
import { ToolType, LineColor } from './useEditorCanvas';

export enum MovingType {
    tip1,
    tip2,
    tip3,
    angle,
}

export interface Values {
    angleX: number;
    angleY: number;
    tipX1: number;
    tipX2: number;
    tipX3: number;
    tipY1: number;
    tipY2: number;
    tipY3: number;
    color: LineColor;
    display: boolean;
    angle: number;
    circlePositionX: number;
    circlePositionY: number;
}

export interface Actions {
    onDownCircle: (type: MovingType) => void;
    onUpCircle: () => void;
    onMoveCircle: (x: number, y: number) => void;
    stamp: (x: number, y: number, type: ToolType, color: LineColor) => void;
    reset: () => void;
}

const useEditorAngle = (): [Values, Actions] => {
    const [movingType, setMovingType] = useState<MovingType | null>(null);

    const [angleX, setAngleX] = useState(0);
    const [angleY, setAngleY] = useState(0);
    const [tipX1, setTipX1] = useState(0);
    const [tipY1, setTipY1] = useState(0);
    const [tipX2, setTipX2] = useState(0);
    const [tipY2, setTipY2] = useState(0);
    const [tipX3, setTipX3] = useState(0);
    const [tipY3, setTipY3] = useState(0);

    const [color, setColor] = useState<LineColor>(LineColor.red);

    const [display, setDisplay] = useState(false);
    const stamp = useCallback(
        (x: number, y: number, type: ToolType, color: LineColor) => {
            if (!display) {
                setAngleX(x);
                setAngleY(y);
                switch (type) {
                    case ToolType.angle:
                        setTipX1(x - 80);
                        setTipY1(y - 150);
                        setTipX2(x - 180);
                        setTipY2(y - 55);
                        break;
                    case ToolType.swingPlane:
                        setTipX1(x - 250);
                        setTipY1(y - 155);
                        setTipX2(x - 100);
                        setTipY2(y - 330);
                        setTipX3(x - 170);
                        setTipY3(y - 290);
                }
                setColor(color);
                setDisplay(true);
            }
        },
        [display],
    );
    const reset = useCallback(() => {
        setDisplay(false);
    }, []);

    const onDownCircle = useCallback((type: MovingType) => {
        setMovingType(type);
    }, []);
    const onUpCircle = useCallback(() => {
        setMovingType(null);
    }, []);
    const onMoveCircle = useCallback(
        (x, y) => {
            switch (movingType) {
                case MovingType.tip1:
                    setTipX1(x);
                    setTipY1(y);
                    break;
                case MovingType.angle:
                    setTipX1(tipX1 - (angleX - x));
                    setTipY1(tipY1 - (angleY - y));
                    setTipX2(tipX2 - (angleX - x));
                    setTipY2(tipY2 - (angleY - y));
                    setTipX3(tipX3 - (angleX - x));
                    setTipY3(tipY3 - (angleY - y));
                    setAngleX(x);
                    setAngleY(y);
                    break;
                case MovingType.tip2:
                    setTipX2(x);
                    setTipY2(y);
                    break;

                case MovingType.tip3:
                    setTipX3(x);
                    setTipY3(y);
                    break;
            }
        },
        [angleX, angleY, movingType, tipX1, tipX2, tipX3, tipY1, tipY2, tipY3],
    );

    const [angle, setAngle] = useState(0);
    const [circlePositionX, setCirclePositionX] = useState(0);
    const [circlePositionY, setCirclePositionY] = useState(0);
    useEffect(() => {
        const vector1: Vector = {
            x: tipX1 - angleX,
            y: tipY1 - angleY,
        };
        const vector2: Vector = {
            x: tipX2 - angleX,
            y: tipY2 - angleY,
        };
        const newAngle = calcAngle(vector1, vector2);
        setAngle(newAngle);
        let adjustedPositionX;
        if (newAngle < 10) {
            adjustedPositionX = angleX + 24;
        } else if (newAngle < 100) {
            adjustedPositionX = angleX + 34;
        } else {
            adjustedPositionX = angleX + 45;
        }
        setCirclePositionX(adjustedPositionX);
        setCirclePositionY(angleY - 22);
    }, [angleX, angleY, tipX1, tipX2, tipY1, tipY2]);

    const values = {
        angleX,
        angleY,
        tipX1,
        tipX2,
        tipX3,
        tipY1,
        tipY2,
        tipY3,
        color,
        display,
        angle,
        circlePositionX,
        circlePositionY,
    };
    const actions = { onDownCircle, onUpCircle, onMoveCircle, stamp, reset };

    return [values, actions];
};

export default useEditorAngle;
